/**
 * LabelTooltipPie component.
 * @module components/charts/LabelTooltipPie/LabelTooltipPie
 */

import React from "react";
import { VictoryTooltip, VictoryLabel } from "victory";
import { connect } from "react-redux";

class LabelTooltipPie extends React.Component {
  render() {
    const { showLabels, hideSmallData } = this.props;
    const sum_y = this.props.data.reduce((sum, element) => sum + element.y, 0);

    return (
      <g>
        {showLabels && (
          <VictoryLabel
            {...this.props}
            text={({ datum }) => {
              if ((datum && datum.y / sum_y >= 0.05) || !hideSmallData) {
                if (datum.label !== undefined) {
                  return datum.label;
                } else {
                  return datum.x;
                }
              } else {
                return "";
              }
            }}
          />
        )}

        <VictoryTooltip
          {...this.props}
          text={({ datum }) =>
            `${datum.label !== undefined ? datum.label : datum.x}: ${datum.y}`
          }
          flyoutStyle={{
            stroke: "none",
            fill: "white",
          }}
          flyoutHeight={25}
          constrainToVisibleArea
        />
      </g>
    );
  }
}

LabelTooltipPie.defaultEvents = VictoryTooltip.defaultEvents;
export default connect()(LabelTooltipPie);
