/**
 * Filters actions.
 * @module actions/filters/filters
 */

import {
  APPLY_FILTERS,
  APPLY_FILTER,
  TOGGLE_PIE_FILTER,
  SET_BAR_FILTER,
  UNSET_FILTER,
  SET_MAP_FILTER,
  SET_STACKED_BAR_FILTER,
  REMOVE_FILTER,
  REMOVE_FILTERS,
  SET_FILTER_APPLIED,
  GET_FILTER_PRESETS,
  GET_FILTER_PRESET_USER,
  GET_FILTER_PRESET_PROJECT,
  STORE_FILTER_PRESET,
  DELETE_FILTER_PRESET_PROJECT,
  DELETE_FILTER_PRESET_USER
} from "../../constants/ActionTypes";

import { viewsDashboardsFilters } from "../../constants/Dashboards";

/**
 * Apply all filters that have been set and not applied
 * @function applyFilters
 * @returns {Object} Apply filters action.
 */
export function applyFilters() {
  return {
    type: APPLY_FILTERS
  };
}

/**
 * Apply a filter set for a single chart.
 * @function applyFilter
 * @returns {Object} Apply filter action.
 */
export function applyFilter(idChart) {
  return {
    type: APPLY_FILTER,
    idChart
  };
}

/**
 *  add a filter that has already been applied to the
 *  set filters. Used to set applied filters as initial set fiters.
 * @function applyFilter
 * @returns {Object} Apply filter action.
 */
export function setFilterApplied(idChart) {
  return {
    type: SET_FILTER_APPLIED,
    idChart
  };
}

/**
 * Toggle pie filter.
 * @function togglePieFilter
 * @param {string} filter Id of the filter
 * @param {string} value Value to toggle
 * @returns {Object} Toggle pie filter action.
 */
export function togglePieFilter(filter, value) {
  return {
    type: TOGGLE_PIE_FILTER,
    filter,
    value
  };
}

/**
 * Set bar filter.
 * @function setBarFilter
 * @param {string} filter Id of the filter
 * @param {string} value Value to set
 * @returns {Object} Set bar filter action.
 */
export function setBarFilter(filter, value) {
  return {
    type: SET_BAR_FILTER,
    filter,
    value
  };
}
export function unsetFilter(idChart) {
  return {
    type: UNSET_FILTER,
    idChart
  };
}

/**
 * Set stacked bar filter.
 * @function setStackedBarFilter
 * @param {string} filter Id of the filter
 * @param {string} value Value to set
 * @returns {Object} Set stacked bar filter action.
 */
export function setStackedBarFilter(filter, value) {
  return {
    type: SET_STACKED_BAR_FILTER,
    filter,
    value
  };
}

/**
 * Set map filter.
 * @function setMapFilter
 * @param {Object} value Value to set
 * @returns {Object} Set map filter action.
 */
export function setMapFilter(value) {
  return {
    type: SET_MAP_FILTER,
    value
  };
}

/**
 * Remove a filter that had been applied already.
 * @function removeFilter
 * @returns {Object} Remove filter action.
 */
export function removeFilter(chartId) {
  return {
    type: REMOVE_FILTER,
    chartId
  };
}

/**
 * Remove all filters that had been applied already.
 * @function removeFilters
 * @returns {Object} Remove filters action.
 */
export function removeFilters() {
  return {
    type: REMOVE_FILTERS
  };
}

/**
 * Obtain available filter presets
 * @function getFilterPresets
 * @returns {Object} Obtain available filter presets action.
 */
export function getFilterPresets(idProject, currentDashboard) {
  return {
    type: GET_FILTER_PRESETS,
    request: {
      op: "get",
      path: `filter-presets/${idProject}`,
      params: {
        visibleViews: viewsDashboardsFilters[currentDashboard]
      }
    }
  };
}

/**
 * Obtain available filter presets
 * @function getFilterPreset
 * @returns {Object} Obtain available filter presets action.
 */
export function getFilterPresetUser(idProject, idPreset, nameView) {
  return {
    type: GET_FILTER_PRESET_USER,
    nameView,
    request: {
      op: "post",
      path: `filter-preset/user`,
      data: {
        id_project: idProject,
        id_filter: idPreset,
        name_view: nameView
      }
    }
  };
}

/**
 * Obtain available filter presets
 * @function getFilterPreset
 * @returns {Object} Obtain available filter presets action.
 */
export function getFilterPresetProject(idProject, idPreset, nameView) {
  return {
    type: GET_FILTER_PRESET_PROJECT,
    nameView,
    request: {
      op: "post",
      path: `filter-preset/project`,
      data: {
        id_project: idProject,
        id_filter: idPreset,
        name_view: nameView
      }
    }
  };
}

/**
 * Store a filter preset
 * @function storeFilterPreset
 * @returns {Object}
 */
export function storeFilterPreset(idProject, view, name, isPrivate, filters) {
  return {
    type: STORE_FILTER_PRESET,
    request: {
      op: "put",
      path: `filter-preset/store`,
      data: {
        view,
        name,
        is_private: isPrivate,
        filters,
        id_project: idProject
      }
    }
  };
}

/**
 * Delete a filter preset
 * @function storeFilterPreset
 * @returns {Object}
 */
export function deleteFilterPresetUser(idProject, idPreset) {
  return {
    type: DELETE_FILTER_PRESET_USER,
    request: {
      op: "delete",
      path: `filter-preset/user`,
      data: {
        id_project: idProject,
        id_filter: idPreset
      }
    }
  };
}
/**
 * Delete a filter preset
 * @function storeFilterPreset
 * @returns {Object}
 */
export function deleteFilterPresetProject(idProject, idPreset) {
  return {
    type: DELETE_FILTER_PRESET_PROJECT,
    request: {
      op: "delete",
      path: `filter-preset/project`,
      data: {
        id_project: idProject,
        id_filter: idPreset
      }
    }
  };
}
