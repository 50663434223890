/**
 * Api helper.
 * @module helpers/Api
 */

import superagent from "superagent";
import cookie from "react-cookie";

const methods = ["get", "post", "put", "patch", "del", "delete"];

/**
 * Format the url.
 * @function formatUrl
 * @param {string} path Path to be formatted.
 * @returns {string} Formatted path.
 */
export function formatUrl(path) {
  if (process.env.NODE_ENV === "development") {
    return `/${path}`;
  }
  return `/api/${path}`;
}

/**
 * Api class.
 * @class Api
 */
export class Api {
  /**
   * Constructor
   * @method constructor
   * @constructs Api
   */
  constructor() {
    methods.forEach((method) => {
      this[method] = (path, { params, data, type } = {}) =>
        new Promise((resolve, reject) => {
          const request = superagent[method](formatUrl(path));

          if (params) {
            request.query(params);
          }

          const authToken = cookie.load("auth_token");
          if (authToken) {
            request.set("Authorization", `JWT ${authToken}`);
          }

          request.set("Accept", "application/json");

          if (type) {
            request.type(type);
          }

          if (data) {
            request.send(data);
          }

          request.end((err, { body } = {}) =>
            err ? reject(body || err) : resolve(body)
          );
        });
    });
  }
}
