/**
 * Inspektionsdaten reducer.
 * @module reducers/inspektionsdaten/inspektionsdaten
 */

import {
  GET_INSPEKTIONSDATEN,
  SET_PROJECT,
  SET_BUCKET,
} from "../../constants/ActionTypes";
import { isEmpty } from "lodash";

const initialState = {
  error: null,
  data: {
    inspektionslaenge: [],
    inspektionsgrund: [],
    inspektionsart: [],
    inspektionsjahr: [],
    kodiersystem: [],
  },
  loaded: false,
  loading: false,
};

/**
 * Inspektionsdaten reducer.
 * @function inspektionsdaten
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function inspektionsdaten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_INSPEKTIONSDATEN}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_INSPEKTIONSDATEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        data: !isEmpty(action.result) ? action.result : initialState.data,
        loaded: true,
        loading: false,
      };
    case `${GET_INSPEKTIONSDATEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loaded: false,
        loading: false,
      };
    case SET_BUCKET:
      return {
        ...initialState,
      };
    case SET_PROJECT:
      return { ...initialState };
    default:
      return state;
  }
}
