/**
 * Login component.
 * @module components/views/Login/Login
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

import { login, logout } from "../../../actions/userSession/userSession";
import BrandLogo from "../../../theme/app-assets/images/logo/logo.png";

/**
 * Login component class.
 * @class Login
 * @extends Component
 */
class Login extends Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    //    this.onLogin = this.onLogin.bind(this);
    //    this.onChangeUsername = this.onChangeUsername.bind(this);
    //    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      username: "",
      password: "",
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    document.body.classList.add("bg-full-screen-image", "blank-page");
    this.props.token && this.props.logout();
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentWillUnmount() {
    document.body.classList.remove("bg-full-screen-image", "blank-page");
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  //  componentWillReceiveProps(nextProps) {
  //    if (nextProps.token) {
  //      this.props.history.push("/");
  //    }
  //  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.token) {
      prevProps.history.push("/");
    }
  }

  /**
   * On login handler
   * @method onLogin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onLogin = (event) => {
    this.props.login(this.state.username, this.state.password);
    event.preventDefault();
  };

  /**
   * On change username handler
   * @method onChangeUsername
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangeUsername = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  /**
   * On change password handler
   * @method onChangePassword
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangePassword = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container>
        <section className="flexbox-container">
          <Row className="col-12 d-flex align-items-center justify-content-center">
            <Col className="col-10 box-shadow-2 p-0" md="4">
              <div className="card card-login border-grey border-lighten-3 px-1 py-1 m-0">
                <div className="card-header border-0">
                  <div className="card-title text-center">
                    <img
                      className="brand-logo"
                      alt="modern admin logo"
                      src={BrandLogo}
                    />
                  </div>
                </div>
                <div className="card-content">
                  <div className="card-body card-body-login">
                    <Form className="form-horizontal" onSubmit={this.onLogin}>
                      <FormGroup className="position-relative has-icon-left">
                        <Input
                          placeholder="E-Mail-Adresse"
                          id="username"
                          required="required"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          style={{ height: "2.75rem" }}
                        />
                        <div className="form-control-position">
                          <i className="ft-user" />
                        </div>
                      </FormGroup>
                      <FormGroup className="position-relative has-icon-left">
                        <Input
                          placeholder="Passwort eingeben"
                          type="password"
                          id="password"
                          required="required"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          style={{ height: "2.75rem" }}
                        />
                        <div className="form-control-position">
                          <i className="la la-key" />
                        </div>
                      </FormGroup>
                      <Button className="btn btn-outline-info btn-block">
                        <i className="ft-unlock" /> Einloggen
                      </Button>
                    </Form>
                    <Link to="/register">
                      <Button
                        className="btn btn-outline-info btn-block"
                        style={{ marginTop: 20 }}
                      >
                        Registrieren
                      </Button>
                    </Link>
                    <br />
                    {this.props.error && (
                      <Alert color="danger">{this.props.error}</Alert>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    token: state.userSession.token,
    error: state.userSession.login.error,
  }),
  { login, logout }
)(withRouter(Login));
