// Names for view elements

export const VIEW_GRUNDDATEN = "grunddaten";
export const VIEW_INSPEKTIONSDATEN = "inspektionsdaten";
export const VIEW_GRUNDDATEN_STRATEGY_COMPARISON =
  "grunddatenStrategyComparison";
export const VIEW_GRUNDDATEN_STRATEGY = "grunddatenStrategy";
export const VIEW_SCHADENSKLASSIFIZIERUNG = "schadensklassifizierung";
export const VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE =
  "objektklassifizierungSchutzziele";
export const VIEW_OBJEKTKLASSIFIZIERUNG = "objektklassifizierung";
export const VIEW_STRATEGIE = "strategie";
export const VIEW_STRATEGIE_VERGLEICH = "strategieVergleich";
export const VIEW_KARTE_DASHBOARD = "karteDashboard";
export const VIEW_KARTE_EINZELOBJEKTANSICHT = "karteEinzelobjektansicht";
export const VIEW_EINZELOBJEKTANSICHT_OBJEKTLISTE =
  "einzelobjektansichtObjekliste";
export const VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE =
  "einzelobjektansichtInspektionsliste";
export const VIEW_EINZELOBJEKTANSICHT_PROGNOSE = "einzelobjektansichtPrognose";
export const VIEW_EINZELOBJEKTANSICHT_RND = "einzelobjektansichtRND";
export const VIEW_EINZELOBJEKTANSICHT_STAMMDATEN =
  "einzelobjektansichtStammdaten";

export const namesViewsDisplay = {
  [VIEW_GRUNDDATEN]: "Grunddaten",
  [VIEW_GRUNDDATEN_STRATEGY_COMPARISON]: "Grunndaten Differenzen",
  [VIEW_GRUNDDATEN_STRATEGY]: "Grunndaten Strategie",
  [VIEW_INSPEKTIONSDATEN]: "Inspektiosdaten",
  [VIEW_SCHADENSKLASSIFIZIERUNG]: "Schadensklassifizierung",
  [VIEW_OBJEKTKLASSIFIZIERUNG]: "Objektklassifizierung",
  [VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE]:
    "Objektklassifizierung: Schutzziele",
  [VIEW_STRATEGIE]: "Strategie",
  [VIEW_STRATEGIE_VERGLEICH]: "Strategievergleich (Monitoring)",
  [VIEW_KARTE_DASHBOARD]: "Karte",
  [VIEW_KARTE_EINZELOBJEKTANSICHT]: "Karte",
  [VIEW_EINZELOBJEKTANSICHT_OBJEKTLISTE]: "Objektliste",
  [VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE]: "Inspektionsliste",
  [VIEW_EINZELOBJEKTANSICHT_PROGNOSE]: "Prognose",
  [VIEW_EINZELOBJEKTANSICHT_RND]: "Restnutzungsdauern",
  [VIEW_EINZELOBJEKTANSICHT_STAMMDATEN]: "Grunddaten"
};
