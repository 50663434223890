/**
 * App component.
 * @module components/views/App/App
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";
import PropTypes from "prop-types";

import Daten from "../Daten/Daten";
import Login from "../Login/Login";
import Logout from "../Logout/Logout";
import Register from "../Register/Register";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Grunddaten from "../Grunddaten/Grunddaten";
import Inspektionsdaten from "../Inspektionsdaten/Inspektionsdaten";
import Schadensklassifizierung from "../Schadensklassifizierung/Schadensklassifizierung";
import Objektklassifizierung from "../Objektklassifizierung/Objektklassifizierung";
import ObjektklassifizierungSchutzziele from "../ObjektklassifizierungSchutzziele/ObjektklassifizierungSchutzziele";
import ObjectviewGrunddaten from "../Objectview/ObjectviewGrunddaten";
import ObjectviewObject from "../Objectview/ObjectviewObject";
import Strategie from "../Strategie/Strategie";
import StrategieVergleich from "../StrategieVergleich/StrategieVergleich";
import Users from "../Users/Users";

import {
  DASHBOARD_GRUNDDATEN,
  DASHBOARD_INSPEKTIONSDATEN,
  DASHBOARD_SCHADENSKLASSIFIZIERUNG,
  DASHBOARD_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE,
  DASHBOARD_OBJEKTKLASSIFIZIERUNG,
  DASHBOARD_STRATEGIE,
  DASHBOARD_STRATEGIE_VERGLEICH,
  DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE,
  DASHBOARD_EINZELOBJEKTANSICHT_PROGNOSE,
} from "../../../constants/Dashboards";

import SidebarDashboard from "../../elements/Sidebar/SidebarDashboard";
import SidebarStrategyComparison from "../../elements/Sidebar/SidebarStrategyComparison";
import SidebarStrategy from "../../elements/Sidebar/SidebarStrategy";
import Navbar from "../../elements/Navbar/Navbar";
import User from "../../elements/User/User";

/**
 * App component class.
 * @class App
 * @extends Component
 */
class App extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div>
        {this.props.token && (
          <User showProjects={this.props.location.pathname !== "/"} />
        )}
        {this.props.token && this.props.location.pathname !== "/" && <Navbar />}
        <div className="app-content content">
          <div className="content-wrapper" style={{ padding: "0" }}>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/" component={Daten} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute
              exact
              path="/grunddaten"
              render={() => (
                <SidebarDashboard
                  typeDashboard={DASHBOARD_GRUNDDATEN}
                  key={this.props.project}
                >
                  <Grunddaten />
                </SidebarDashboard>
              )}
            />
            <PrivateRoute
              exact
              path="/inspektionsdaten"
              render={() => (
                <SidebarDashboard
                  typeDashboard={DASHBOARD_INSPEKTIONSDATEN}
                  key={this.props.project}
                >
                  <Inspektionsdaten />
                </SidebarDashboard>
              )}
            />
            <PrivateRoute
              exact
              path="/schadensklassifizierung"
              render={() => (
                <SidebarDashboard
                  typeDashboard={DASHBOARD_SCHADENSKLASSIFIZIERUNG}
                  key={this.props.project}
                >
                  <Schadensklassifizierung />
                </SidebarDashboard>
              )}
            />
            <PrivateRoute
              exact
              path="/objektklassifizierung"
              render={() => (
                <SidebarDashboard
                  typeDashboard={DASHBOARD_OBJEKTKLASSIFIZIERUNG}
                  key={this.props.project}
                >
                  <Objektklassifizierung />
                </SidebarDashboard>
              )}
            />
            <PrivateRoute
              exact
              path="/objektklassifizierung-schutzziele"
              render={() => (
                <SidebarDashboard
                  typeDashboard={DASHBOARD_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
                  key={this.props.project}
                >
                  <ObjektklassifizierungSchutzziele />
                </SidebarDashboard>
              )}
            />
            <PrivateRoute
              exact
              path="/strategie"
              render={() => (
                <SidebarStrategy
                  typeDashboard={DASHBOARD_STRATEGIE}
                  key={this.props.project}
                >
                  <Strategie />
                </SidebarStrategy>
              )}
            />
            <PrivateRoute
              exact
              path="/strategie-vergleich"
              render={() => (
                <SidebarStrategyComparison
                  typeDashboard={DASHBOARD_STRATEGIE_VERGLEICH}
                  key={this.props.project}
                >
                  <StrategieVergleich />
                </SidebarStrategyComparison>
              )}
            />
            <PrivateRoute
              exact
              path="/objektansicht-grunddaten"
              render={() => (
                <ObjectviewGrunddaten
                  key={this.props.project}
                  typeDashboard={DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/objektansicht-objekt"
              render={() => (
                <ObjectviewObject
                  key={this.props.project}
                  typeDashboard={DASHBOARD_EINZELOBJEKTANSICHT_PROGNOSE}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    token: state.userSession.token,
    // Remark: Project is used as key in order to ensure proper
    //  Remount on project change
    project: state.projects.project,
  }),
  {}
)(withRouter(App));
