/**
 * StrategieVergleich component.
 * @module components/views/StrategieVergleich/StrategieVergleich
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { find, map, isEqual } from "lodash";

import { getColor } from "../../../helpers/colors/colors";

import {
  getStrategieVergleich,
  setStrategiesComparison,
  setYearStrategy,
  setGroupCharts,
  setShowDifferences,
} from "../../../actions/strategieVergleich/strategieVergleich";

import Chart from "../../charts/Chart/Chart";
import Map from "../../elements/Map/Map";

import { getNameStrategy } from "../../../helpers/charts/common_functions";
import { diagramsViews } from "../../../constants/Dashboards";

import { VIEW_STRATEGIE_VERGLEICH } from "../../../constants/NamesViews";
import InOutCarat from "../../elements/InOutCarat/InOutCarat";

/**
 * Strategie component class.
 * @class Objektklassifizierung
 * @extends Component
 */
class StrategieVergleich extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearStrategy: undefined,
      yearStrategyInput: undefined,
      showDifferences: props.showDifferenceCharts,
      groupCharts: props.groupCharts,
    };
    this.onToggleModal = this.onToggleModal.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.strategiesComparisonIds.length === 2) {
      this.props.getStrategieVergleich(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.strategiesComparisonIds,
        this.props.typeDashboard
      );
    }
  }
  onToggleModal(event) {
    //    event.preventDefault();
    this.setState({
      showModal: true,
    });
  }
  hideModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.setShowDifferences(this.state.showDifferences);
    this.props.setGroupCharts(this.state.groupCharts);
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleChangeShowDifferences = (event) => {
    this.setState({
      checked: event.target.showDifferences,
    });
  };

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (!isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
        !isEqual(
          this.props.strategiesComparisonIds,
          nextProps.strategiesComparisonIds
        ) ||
        !isEqual(this.props.scales, nextProps.scales) ||
        !isEqual(this.props.units, nextProps.units) ||
        !isEqual(this.props.bucket, nextProps.bucket) ||
        !isEqual(this.props.project, nextProps.project)) &&
      this.props.strategiesComparisonIds.length === 2
    ) {
      this.props.getStrategieVergleich(
        nextProps.project,
        nextProps.bucket,
        nextProps.filtersApplied,
        nextProps.scales,
        nextProps.units,
        nextProps.strategiesComparisonIds,
        nextProps.typeDashboard
      );
    }
    // Set yearStrategy to first year after receiving strategies

    if (
      nextProps.yearStrategy === undefined &&
      nextProps.minYearStrategy !== undefined &&
      !isNaN(nextProps.minYearStrategy)
    ) {
      this.setState({ yearStrategy: nextProps.minYearStrategy });
      this.props.setYearStrategy(nextProps.minYearStrategy);
    }
    if (
      this.state.yearStrategyInput === undefined &&
      nextProps.minYearStrategy !== undefined &&
      !isNaN(nextProps.minYearStrategy)
    ) {
      this.setState({ yearStrategyInput: nextProps.minYearStrategy });
    }
  }

  /**
   * Set the strategy to display as reference strategy
   * @method setStrategyReference
   * @param {Integer} id The strategy's ID
   * @returns {undefined}
   */
  setStrategyReference(id) {
    this.props.setStrategiesComparison([
      id,
      this.props.strategiesComparisonIds[1],
    ]);
  }

  /**
   * Set the strategy to display as comparison strategy
   * @method setStrategyCompare
   * @param {Integer} id The strategy's ID
   * @returns {undefined}
   */
  setStrategyCompare(id) {
    this.props.setStrategiesComparison([
      this.props.strategiesComparisonIds[0],
      id,
    ]);
  }

  renderCharts() {
    const result = Object.keys(
      this.props.groupsCharts[this.props.groupCharts]
    ).reduce(
      (listCharts, idChart) => [
        ...listCharts,
        this.renderChart({
          ...this.props.groupsCharts[this.props.groupCharts][idChart],
          id: idChart,
        }),
        ...(this.props.showDifferenceCharts
          ? [
              this.renderChart(
                // TODO: Danger! This assumes that each chart's difference chart has the form chartID+'Difference'
                {
                  ...this.props.groupsCharts[this.props.groupCharts][idChart],
                  showDetailButton: false,
                  id: idChart,
                  isDifference: true,
                }
              ),
            ]
          : []),
      ],
      []
    );
    return result;
  }

  renderChart({
    id,
    showUnitButton,
    showScaleButton,
    scalesDisplay,
    unitsDisplay,
    showDetailButton,
    isDifference,
  }) {
    return (
      <Chart
        data={this.props.strategieVergleich[id]}
        id={isDifference ? `${id}Difference` : id}
        showUnitButton={showUnitButton}
        showScaleButton={showScaleButton}
        scalesDisplay={scalesDisplay}
        unitsDisplay={unitsDisplay}
        typeView={VIEW_STRATEGIE_VERGLEICH}
        positionMark={this.state.yearStrategy}
        showDetailButton={showDetailButton}
      />
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      strategiesComparisonIds,
      typeDashboard,
      minYearStrategy,
      mapMaximized,
      mapMinimized,
    } = this.props;

    let height = "50%";
    if (mapMaximized) {
      height = "0%";
    } else if (mapMinimized) {
      height = "100%";
    }

    const nameReference = getNameStrategy(
      strategiesComparisonIds[0],
      this.props.strategies
    );
    const nameCompare = getNameStrategy(
      strategiesComparisonIds[1],
      this.props.strategies
    );

    const strategiesChosen =
      strategiesComparisonIds.length === 2 &&
      strategiesComparisonIds[0] !== undefined &&
      strategiesComparisonIds[1] !== undefined;

    const yearStrategyInputValid =
      this.state.yearStrategyInput >= this.props.minYearStrategy &&
      this.state.yearStrategyInput <= this.props.maxYearStrategy;

    return (
      <div className="mapWrapper">
        <div className="viewHeader">
          <Row>
            <Col
              style={{
                paddingTop: "4px",
              }}
            >
              <Slider
                // Remark: Due to bug in Slider, key must be set so that slider updates on value change
                key={this.props.yearStrategy}
                className="yearSlider"
                step={1}
                disabled={!strategiesChosen || minYearStrategy === undefined}
                defaultValue={this.props.minYearStrategy}
                value={this.state.yearStrategy ? this.state.yearStrategy : " "}
                min={this.props.minYearStrategy}
                max={this.props.maxYearStrategy}
                onChangeCommitted={(event, value) =>
                  strategiesChosen && this.props.setYearStrategy(value)
                }
                onChange={(event, value) =>
                  this.setState({
                    yearStrategy: value,
                    yearStrategyInput: value,
                  })
                }
                onValueChange={this.state.yearStrategy}
              />
              <input
                className="yearNumber"
                style={{ width: "70px", float: "left" }}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                value={
                  this.state.yearStrategyInput
                    ? this.state.yearStrategyInput
                    : " "
                }
                disabled={!strategiesChosen || minYearStrategy === undefined}
                invalid={
                  !yearStrategyInputValid &&
                  this.props.minYearStrategy !== undefined
                }
                onChange={(event) =>
                  this.setState({
                    yearStrategyInput: parseInt(event.target.value),
                  })
                }
                onKeyPress={(event) => {
                  if (yearStrategyInputValid && event.key === "Enter") {
                    this.setState({
                      yearStrategy: this.state.yearStrategyInput,
                    });
                    this.props.setYearStrategy(this.state.yearStrategyInput);
                  }
                }}
                onKeyUp={(event) => {
                  if (
                    yearStrategyInputValid &&
                    (event.key === "ArrowUp" || event.key === "ArrowDown")
                  ) {
                    this.setState({
                      yearStrategy: this.state.yearStrategyInput,
                    });
                    this.props.setYearStrategy(this.state.yearStrategyInput);
                  }
                }}
              />
            </Col>

            <Col
              style={{
                paddingTop: "4px",
              }}
            >
              <UncontrolledDropdown
                style={{
                  float: "right",
                  paddingBottom: 10,
                  paddingRight: 6,
                }}
              >
                <DropdownToggle caret tag="span" style={{ cursor: "pointer" }}>
                  <span
                    style={{
                      color: nameCompare
                        ? getColor("strategien", strategiesComparisonIds[1])
                        : undefined,
                    }}
                  >
                    {nameCompare ? nameCompare : "Vergleichsstrategie"}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  {map(this.props.strategies, (strategyDisplay) => (
                    <DropdownItem
                      key={strategyDisplay.id}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.setStrategyCompare(strategyDisplay.id)
                      }
                    >
                      {strategyDisplay.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown
                style={{
                  float: "right",
                  paddingBottom: 10,
                  paddingRight: 26,
                }}
              >
                <DropdownToggle
                  caret
                  tag="span"
                  style={{ cursor: "pointer", paddingRight: "4px" }}
                >
                  <span
                    style={{
                      color: nameReference
                        ? getColor("strategien", strategiesComparisonIds[0])
                        : undefined,
                    }}
                  >
                    {nameReference ? nameReference : "Referenzstrategie"}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  {map(this.props.strategies, (strategyDisplay) => (
                    <DropdownItem
                      key={strategyDisplay.id}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.setStrategyReference(strategyDisplay.id)
                      }
                    >
                      {strategyDisplay.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col
              xs={3}
              style={{
                maxWidth: "9%",
                backgroundColor: "#8499b5",
                color: "white",
                paddingTop: "4px",
              }}
            >
              <span
                style={{
                  paddingLeft: "14px",
                  cursor: "pointer",
                  padding: "auto",
                  height: "100%",
                }}
                onClick={this.onToggleModal}
              >
                Ansicht
              </span>
            </Col>
          </Row>
        </div>
        <div className="mapDetail" style={{ height: height }}>
          <Container className="containerDiagrams inner">
            <Row>{this.renderCharts()}</Row>
          </Container>
        </div>
        <InOutCarat />
        <Map view={VIEW_STRATEGIE_VERGLEICH} typeDashboard={typeDashboard} />
        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.showModal}
          toggle={this.onToggleModal}
        >
          <ModalHeader className="dataDownloadModal">
            Ansischt Konfigurieren
          </ModalHeader>
          <ModalBody>
            <select
              /* todo ensure that cookies is not loaded at each render
               */
              value={this.state.groupCharts}
              onChange={(event) =>
                this.setState({ groupCharts: event.target.value })
              }
            >
              {Object.keys(this.props.groupsCharts).map((X, Y) => (
                <option key={Y}>{X}</option>
              ))}
            </select>
            <FormControlLabel
              style={{ marginLeft: "3px" }}
              control={
                <Checkbox
                  checked={this.state.showDifferences}
                  color="primary"
                  onChange={(event) =>
                    this.setState({ showDifferences: event.target.checked })
                  }
                  // onChange={(value) => this.props.setShowDifferences(value)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Differenzen Anzeigen"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.hideModal}>
              Ok
            </Button>
            <Button color="secondary" onClick={this.closeModal}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });

    const diagrams = diagramsViews[VIEW_STRATEGIE_VERGLEICH];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    // Get min and max year
    const years =
      state.strategieVergleich.data.substanzAvg !== undefined &&
      state.strategieVergleich.data.substanzAvg[0] !== undefined
        ? state.strategieVergleich.data.substanzAvg[0].data.map(
            (element) => element.x
          )
        : undefined;

    const minYearStrategy =
      years !== undefined ? Math.min(...years) : undefined;
    const maxYearStrategy =
      years !== undefined ? Math.max(...years) : undefined;

    return {
      strategieVergleich: state.strategieVergleich.data,
      filtersApplied: state.filters.filtersApplied,
      //reload: state.filters.reload || state.chartSettings.dirty,
      project: state.projects.project,
      bucket: state.projects.bucket,
      strategiesComparisonIds: state.strategieVergleich.strategiesComparisonIds,
      yearStrategy: state.strategieVergleich.yearStrategy,
      strategies: project ? project.strategies : [],
      units: units,
      scales: scales,
      minYearStrategy: minYearStrategy,
      maxYearStrategy: maxYearStrategy,
      mapMaximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      mapMinimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
      groupsCharts: state.strategieVergleich.groupsCharts,
      groupCharts: state.strategieVergleich.groupCharts,
      showDifferenceCharts: state.strategieVergleich.showDifferenceCharts,
    };
  },
  {
    getStrategieVergleich,
    setStrategiesComparison,
    setYearStrategy,
    setGroupCharts,
    setShowDifferences,
  }
)(StrategieVergleich);
