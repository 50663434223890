/**
 * Detailview actions.
 * @module actions/detailview/detailview
 */

import {
  SET_DETAILVIEW_OPEN,
  RESET_DETAILVIEW
} from "../../constants/ActionTypes";

/**
 * Set whether detailview is visible.
 * @function setDetailviewOpen
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @returns {Object} Get grunddaten action.
 */
export function setDetailviewOpen(isOpen, idChart, typeChart, typeView) {
  return {
    type: SET_DETAILVIEW_OPEN,
    isOpen: isOpen,
    idChart: idChart,
    typeChart: typeChart,
    typeView: typeView
  };
}

/**
 * Reset Detailview.
 * @function resetDetailview
 * @returns {Object} resetDetailview action.
 */
export function resetDetailview() {
  return {
    type: RESET_DETAILVIEW
  };
}
