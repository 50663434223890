import { SET_CURRENT_DASHBOARD } from "../../constants/ActionTypes";

// todo: is reload needed?

const initialState = {
  dashboard: undefined
};

export default function views(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboard
      };
    default:
      return state;
  }
}
