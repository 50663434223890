/**
 * Grunddaten reducer.
 * @module reducers/grunddaten/grunddaten
 */

import {
  GET_GRUNDDATEN,
  SET_SIDEBAR_OPEN,
  SET_PROJECT,
  SET_BUCKET,
  SET_EXPORTDATA_PROPERTIES,
} from "../../constants/ActionTypes";
import { isEmpty } from "lodash";

const initialState = {
  error: null,
  data: {
    baujahr: [],
    materialgruppe: [],
    mittlereTiefe: [],
    kanalnutzung: [],
    haltungslaenge: [],
    kanalart: [],
    nennweite: [],
    lageImVerkehrsraum: [],
    profilart: [],
    alter: [],
    restnutzungsdauer: [],
    sanierungslaenge: [],
    verifikation: [],
  },
  widthSidebar: 50,
  loaded: false,
  loading: false,
  sidebarIsOpen: true,
  properties: [],
};

/**
 * Grundaten reducer.
 * @function grunddaten
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function grunddaten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_GRUNDDATEN}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_GRUNDDATEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        data: !isEmpty(action.result) ? action.result : initialState.data,
        loaded: true,
        loading: false,
      };
    case `${GET_GRUNDDATEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loaded: false,
        loading: false,
      };
    case `${SET_SIDEBAR_OPEN}`:
      return {
        ...state,
        sidebarIsOpen: action.sidebarIsOpen,
      };
    case SET_BUCKET:
      return {
        ...initialState,
        sidebarIsOpen: state.sidebarIsOpen,
      };
    case SET_EXPORTDATA_PROPERTIES:
      return {
        ...initialState,
        properties: action.properties,
      };

    case SET_PROJECT:
      return { ...initialState };
    default:
      return state;
  }
}
