/**
 * Private route component.
 * @module components/views/PrivteRoute/PrivteRoute
 */

import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/**
 * PrivateRoute component class.
 * @class PrivateRoute
 * @extends Component
 */
class PrivateRoute extends React.Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { token, render, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          token ? (
            (typeof render === "function" && render()) || (
              <Component {...props} />
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

export default connect(state => ({
  token: state.userSession.token
}))(PrivateRoute);
