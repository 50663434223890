/**
 * BarGeneric component.
 * @module components/charts/BarGeneric/BarGeneric
 */

import React, { Component } from "react";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from "victory";
import { getColor } from "../../../helpers/colors/colors";
import { connect } from "react-redux";

import { COLOR_PIE_FILTERED } from "../../../constants/Colors";

import { VictoryTheme } from "../../../constants/VictoryTheme";
import {
  setFilterApplied,
  togglePieFilter,
} from "../../../actions/filters/filters";
import LabelTooltipPie from "../LabelTooltipPie/LabelTooltipPie";

import { CHART_SETTINGS } from "../../../constants/LabelsAxes";
import { units as unitLabels } from "../../../constants/chartSettings";

import {
  VIEW_GRUNDDATEN_STRATEGY,
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
} from "../../../constants/NamesViews";

import { labels } from "../../../constants/Filters";

import { formatLabelUnit } from "../../../helpers/charts/common_functions";

/**
 * BarHorizontalGeneric component class.
 * @class BarHorizontalGeneric
 * @extends Component
 */

class BarHorizontalGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
    };
  }
  handleChange = (e, value) => {
    this.setState({ value: value });
  };
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  componentDidMount() {
    this.props.setFilterApplied(this.props.id);
  }
  //
  componentWillReceiveProps(nextProps) {
    // this.forceUpdate()
    // if (this.props.filtersSet !== nextProps.filtersSet) {
    //   this.forceUpdate()
    // }
    // if (!!domainFilter) {
    //   this.refBrush.domain.x = domainFilter
    // } else {
    //   this.refBrush.domain.x = undefined
    // }
    if (this.state.value === undefined && nextProps.data.length !== 0) {
      this.setState({
        value: [
          nextProps.data[0].x,
          nextProps.data[nextProps.data.length - 1].x,
        ],
      });
    }
  }

  getLabelX() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.
    const titleChart = labels[this.props.id];
    const unit = this.props.unitsCharts[this.props.id].unit[0];

    return formatLabelUnit(titleChart, unit);
  }

  getLabelY() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.

    if (
      this.props.typeView === VIEW_GRUNDDATEN_STRATEGY &&
      this.props.idChartStrategy !== undefined
    ) {
      return CHART_SETTINGS.scales[this.props.scale](
        labels[this.props.idChartStrategy],
        this.props.unitsCharts[this.props.idChartStrategy].unit[0]
      );
    } else if (
      this.props.typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON &&
      this.props.idChartDifferences !== undefined
    ) {
      return CHART_SETTINGS.scales[this.props.scale](
        labels[this.props.idChartDifferences],
        this.props.unitsCharts[this.props.idChartDifferences].unit[0]
      );
    }

    return CHART_SETTINGS.scales[this.props.scale](
      unitLabels[this.props.unit],
      CHART_SETTINGS.units[this.props.unit]
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, filterSet, data, disableFilter, reference } = this.props;

    //    const ZoomBrush = createContainer( "zoom","brush");

    // drawn filter is always the set one.
    const filterActive = filterSet;

    const yMin = Math.min(...data.map((element) => element.y));

    return (
      <div>
        <VictoryChart
          theme={VictoryTheme}
          domainPadding={(28, 112)}
          key={Date.now()}
          all
          height={265}
          width={800}
          ref={reference}
          padding={{ left: 90, right: 80, top: 30, bottom: 70 }}
        >
          <VictoryBar
            horizontal
            height={200}
            //            width={800}

            labels={data.map((datapoint) => datapoint.x)}
            labelComponent={
              <LabelTooltipPie showLabels={true} hideSmallData={false} />
            }
            style={{
              data: {
                fill: ({ datum }) => getColor(id, datum.x),
                stroke: ({ datum }) =>
                  filterActive && filterActive.indexOf(datum.x) !== -1
                    ? COLOR_PIE_FILTERED
                    : undefined,
                strokeWidth: 3,
              },
            }}
            data={data}
            events={
              !disableFilter && [
                {
                  target: ["data", "labels"],
                  eventHandlers: {
                    onClick: (event, props) => {
                      this.props.togglePieFilter(id, props.data[props.index].x);
                    },
                  },
                },
              ]
            }
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "#cecece" },
              ticks: { stroke: "#cecece", size: 5 },
            }}
            crossAxis={yMin >= 0}
            axisLabelComponent={
              <VictoryLabel
                lineHeight={1}
                capHeight={2}
                style={[{ fill: "black" }]}
              />
            }
            label={this.getLabelY()}
          />
          <VictoryAxis
            fixLabelOverlap={true}
            style={{
              axis: { stroke: "#cecece" },
              ticks: { stroke: "transparent", size: 0 },
              tickLabels: { fill: "transparent" },
            }}
            axisLabelComponent={
              <VictoryLabel capHeight={-2} style={[{ fill: "black" }]} />
            }
            label={this.getLabelX()}
          />
        </VictoryChart>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      filterSet: state.filters.filtersSet[props.id],
      idChartDifferences: state.strategieVergleich.idChartDifferences,
      idChartStrategy: state.strategie.idChart,
      unit: state.chartSettings.unit[props.id],
      scale: state.chartSettings.scale[props.id],
      // FIXME: handle case that no unit for chart is present in state
      unitsCharts: state.projects.metadataProject.unitsCharts,
    };
  },
  { togglePieFilter, setFilterApplied }
)(BarHorizontalGeneric);
