/**
 * Map reducer.
 * @module reducers/map/map
 */

import {
  GET_MAP,
  SET_DIAGRAM_COLOR_MAP,
  SET_MAP_COLOR_RENDERING,
  SET_MAP_VIEWPORT,
  GET_OBJECT_DATA,
  RESET_MAP,
  SET_PROJECT,
  SET_MAP_DASHBOARDS_HEIGHT,
  SET_MAP_DASHBOARDS_MAXIMIZED,
  SET_MAP_DASHBOARDS_MINIMIZED,
  SELECT_MAP_LAYER,
  SET_ID_OBJECT_ACTIVE
} from "../../constants/ActionTypes";

import { COLOR_MAP_SELECTED } from "../../constants/Colors";

import Leaflet from "leaflet";

const initialState = {
  error: null,
  data: null,
  loaded: false,
  loading: false,
  objectLoading: false,
  diagramColor: undefined,
  colorRendering: "grayscale",
  viewport: undefined,
  mapDashboardsHeight: 50,
  mapDashboardsMaximized: false,
  mapDashboardsMinimized: false,
  mapLayer: undefined
};

function formatTable(data) {
  // Build plain HTML displaying an object's data for popup on click
  // TODO: Properly handle nested dicts for multiple categories (tabbed view)
  const content = Object.entries(data).map(
    ([key, value]) =>
      `<tr><td style="padding-left: 10px; padding-right: 10px;">${key}</td><td>${
        value !== null ? value : "-"
      }</td></tr>`
  );
  return `<b>Stammdaten:</b><table>${content.join("")}</table>`;
}

function bindPopup(action, content) {
  var popup = Leaflet.popup({ autoPan: false })
    .setContent(content)
    .on("remove", () => {
      action.setState({
        popupIsVisible: false,
        idObjectActive: undefined
      });
      action.layer.setStyle(action.styleInitial);
    })
    .on("add", () => {
      action.setState({
        popupIsVisible: true,
        idObjectActive: action.objectId
      });
      action.layer.setStyle({
        ...action.styleInitial,
        color: COLOR_MAP_SELECTED
      });
    });
  action.layer.bindPopup(popup).openPopup();
}

/**
 * Map reducer.
 * @function map
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function map(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_MAP}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true
      };
    case `${GET_MAP}_SUCCESS`:
      return {
        ...state,
        error: null,
        data: action.result,
        loaded: true,
        loading: false
      };
    case `${GET_MAP}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loaded: false,
        loading: false
      };
    case SET_ID_OBJECT_ACTIVE:
      return {
        ...state,
        idObjectActive: action.objectId
      };
    case `${GET_OBJECT_DATA}_PENDING`:
      return {
        ...state,
        error: null,
        objectLoading: true
      };
    case `${GET_OBJECT_DATA}_SUCCESS`:
      // Fetch data and bind a new popup with the fetched content.
      // Set state on open and close to that Map component can prevent
      // re-rendering map on viewport change if popup is open,
      // so that the popup does not disappear.
      const content = formatTable(action.result);

      bindPopup(action, content);
      return {
        ...state,
        error: null,
        objectLoading: false
      };
    case `${GET_OBJECT_DATA}_FAIL`:
      return {
        ...state,
        error: action.error,
        objectLoading: false
      };
    case SET_DIAGRAM_COLOR_MAP:
      return {
        ...state,
        diagramColor: action.idChart
      };
    case SET_MAP_DASHBOARDS_HEIGHT:
      return {
        ...state,
        mapDashboardsHeight: action.height
      };
    case SET_MAP_DASHBOARDS_MAXIMIZED:
      return {
        ...state,
        mapDashboardsMaximized: action.maximized
      };
    case SET_MAP_DASHBOARDS_MINIMIZED:
      return {
        ...state,
        mapDashboardsMinimized: action.minimized
      };
    case SET_MAP_COLOR_RENDERING:
      return {
        ...state,
        colorRendering: action.colorRendering
      };
    case SET_MAP_VIEWPORT:
      return {
        ...state,
        viewport: action.viewport
      };
    case RESET_MAP:
      return initialState;
    case SET_PROJECT:
      return initialState;
    case SELECT_MAP_LAYER:
      return {
        ...state,
        mapLayer: action.mapLayer
      };
    default:
      return state;
  }
}
