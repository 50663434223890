/**
 * Strategie reducer.
 * @module reducers/strategie/strategie
 */

import {
  GET_STRATEGIE,
  SET_STRATEGY_DISPLAY,
  SET_PROJECT,
  SET_BUCKET,
  SET_YEAR_STRATEGY,
  GET_GRUNDDATEN_STRATEGY,
  SET_ID_CHART_STRATEGY,
} from "../../constants/ActionTypes";
import cookie from "react-cookie";
import { buildInitialStateData } from "../../helpers/common_functions";
import { diagramsViews } from "../../constants/Dashboards";
import { VIEW_STRATEGIE } from "../../constants/NamesViews";
import { isEmpty } from "lodash";

const initialState = {
  error: null,
  data: buildInitialStateData(diagramsViews[VIEW_STRATEGIE]),
  // data: {
  //   zustandStandsicherheitStrategie: [],
  //   substanzStandsicherheitStrategie: [],
  //   zustandBetriebStrategie: [],
  //   substanzBetriebStrategie: [],
  //   zustandDichtheitStrategie: [],
  //   substanzDichtheitStrategie: [],
  //   zustandsklasseStrategie: [],
  //   substanzklasseStrategie: [],
  //   gebuehrenanteile: [],
  //   sanierungen: [],
  //   sanierungskosten: []
  // },
  dataGrunddaten: {
    baujahr: [],
    materialgruppe: [],
    mittlereTiefe: [],
    kanalnutzung: [],
    haltungslaenge: [],
    kanalart: [],
    nennweite: [],
    lageImVerkehrsraum: [],
    profilart: [],
    alter: [],
    restnutzungsdauer: [],
    sanierungslaenge: [],
    verifikation: [],
  },
  strategyDisplayId: undefined,
  strategies: [],
  loaded: false,
  loadedGrunddaten: false,
  loading: false,
  loadingGrunddaten: false,
  yearStrategy: undefined,
  // id of chart for grunddaten
  idChart: undefined,
};

/**
 * Strategie reducer.
 * @function strategie
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function strategie(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_STRATEGIE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_STRATEGIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        data: !isEmpty(action.result) ? action.result : initialState.data,
        loaded: true,
        loading: false,
      };
    case `${GET_STRATEGIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loaded: false,
        loading: false,
      };
    case `${GET_GRUNDDATEN_STRATEGY}_PENDING`:
      return {
        ...state,
        error: null,
        loadedGrunddaten: false,
        loadingGrunddaten: true,
      };
    case `${GET_GRUNDDATEN_STRATEGY}_SUCCESS`:
      return {
        ...state,
        error: null,
        dataGrunddaten: !isEmpty(action.result)
          ? action.result
          : initialState.data,
        loadedGrunddaten: true,
        loadingGrunddaten: false,
      };
    case `${GET_GRUNDDATEN_STRATEGY}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loadedGrunddaten: false,
        loadingGrunddaten: false,
      };
    case SET_ID_CHART_STRATEGY:
      return {
        ...state,
        idChart: action.idChart,
      };
    case SET_STRATEGY_DISPLAY:
      cookie.save("strategyDisplayId", action.id);
      return {
        ...state,
        strategyDisplayId: action.id,
      };
    case SET_YEAR_STRATEGY:
      return {
        ...state,
        yearStrategy: action.yearStrategy,
      };
    case SET_PROJECT:
      return initialState;
    case SET_BUCKET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
