/**
 * StackedBarGeneric component.
 * @module components/charts/StackedBarGeneric/StackedBarGeneric
 */

// TODO: Stacked Bars cannot support filtering in strategy context.

import React, { Component } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryTooltip,
  VictoryLabel,
} from "victory";
import { connect } from "react-redux";

import { VictoryTheme } from "../../../constants/VictoryTheme";
import { setStackedBarFilter } from "../../../actions/filters/filters";
import { getColor } from "../../../helpers/colors/colors";
import {
  getMinStepsizeX,
  formatTick,
  formatLabelUnit,
  formatTickForStackBar,
} from "../../../helpers/charts/common_functions";
import { COLOR_MARK_YEAR_STRATEGY } from "../../../constants/Colors";

import { CHART_SETTINGS } from "../../../constants/LabelsAxes";
import { unitLabels } from "../../../constants/chartSettings";

/**
 * StackedBarGeneric component class.
 * @class StackedBarGeneric
 * @extends Component
 */
class StackedBarGeneric extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  getLabelX() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.

    return formatLabelUnit(this.props.unitsCharts[this.props.id].unit[1], null);
  }

  getLabelY() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.
    return CHART_SETTINGS.scales[this.props.scale](
      unitLabels[this.props.unit],
      this.props.unitsCharts[this.props.id].unit[0]
    );
  }

  render() {
    const { id, data, reference, positionMark } = this.props;

    const minStepSizeX = data[0]
      ? getMinStepsizeX(data[0].data.map((elem) => elem.x))
      : undefined;

    // Obtain workaround values for fixing axis position
    // TODO: Remove workaround and handle properly
    const data_first_layer = data[0] ? data[0].data : undefined;
    const maxData = data_first_layer
      ? data_first_layer[data_first_layer.length - 1].x
      : 0;
    const posAxisX = data[0] ? data[0].data[0].x - minStepSizeX / 2 : undefined;

    return (
      <VictoryChart
        theme={VictoryTheme}
        key={Date.now()}
        height={265}
        width={800}
        ref={reference}
        padding={{ left: 90, right: 30, top: 30, bottom: 60 }}
        domain={{ x: [posAxisX, maxData + minStepSizeX / 2] }}
        // FIXME: Crash when clicking on bar. see #446
        // containerComponent={<VictoryZoomContainer zoomDimension="x" />}
      >
        <VictoryStack>
          {data.map((object_layer, index) => {
            return (
              <VictoryBar
                data={object_layer["data"]}
                key={object_layer["name"]}
                height={250}
                width={800}
                labels={(datum) => {
                  return `${
                    "label" in object_layer
                      ? object_layer["label"]
                      : object_layer["name"]
                  }: ${datum.datum.y ? datum.datum.y.toFixed(0) : ""}`;
                }}
                labelComponent={
                  <VictoryTooltip
                    constrainToVisibleArea
                    flyoutStyle={{ stroke: null }}
                  />
                }
                style={{
                  data: {
                    fill: getColor(id, object_layer["name"]),
                  },
                }}
              />
            );
          })}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "#cecece" },
            ticks: { stroke: "#cecece", size: 5 },
          }}
          axisValue={posAxisX}
          minDomain={-3}
          tickFormat={ formatTickForStackBar}
          axisLabelComponent={
            <VictoryLabel
              lineHeight={1}
              capHeight={id == "sanierungen" || id == "sanierungenkosten"  ? -3.5 : -1.5}
              style={[{ fill: "black" }]}
            />
          }
          label={this.getLabelY()}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "#cecece" },
            ticks: { stroke: "#cecece", size: 5 },
          }}
          tickFormat={formatTick}
          minDomain={-3}
          axisLabelComponent={
            <VictoryLabel capHeight={2} style={[{ fill: "black" }]} />
          }
          label={this.getLabelX()}
        />
        {positionMark !== undefined && (
          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                stroke: COLOR_MARK_YEAR_STRATEGY,
                strokeWidth: "3px",
                strokeDasharray: "10",
              },
              ticks: { stroke: "#transparent" },
              tickLabels: { fill: "none" },
            }}
            axisValue={positionMark}
          />
        )}
      </VictoryChart>
    );
  }
}

export default connect(
  (state, props) => ({
    idChartDifferences: state.strategieVergleich.idChartDifferences,
    idChartStrategy: state.strategie.idChart,
    unit: state.chartSettings.unit[props.id],
    scale: state.chartSettings.scale[props.id],
    // FIXME: handle case that no unit for chart is present in state
    unitsCharts: state.projects.metadataProject.unitsCharts,
  }),
  { setStackedBarFilter }
)(StackedBarGeneric);
