/**
 * Permission labels.
 * @module constants/Permissions
 */

export const permissions = {
  grunddaten: "Grunddaten",
  inspektionsdaten: "Inspektionsdaten",
  schadensklassifizierung: "Schadensklassifizierung",
  objektklassifizierung: "Objektklassifizierung",
  "objektklassifizierung-schutzziele": "Objektklassifizierung: Schutzziele",
  strategie: "Strategie",
  "strategie-vergleich": "Strategievergleich (Monitoring)",
  "objektansicht-grunddaten": "Objektansicht (Grunddaten)",
  "objektansicht-objekt": "Objektansicht (Objekt)",
  daten: "Daten"
};
