// TODO: This is a workaround until chart metadata is implemented.
export const mappingSubstanz = {
  0: "Voll",
  1: "Sehr hoch",
  2: "Hoch",
  3: "Mittel",
  4: "Niedrig",
  5: "Aufgebraucht"
};

export const mappingZustandsklasse = {
  0: "Schadensfrei",
  1: "Nachrangig",
  2: "Langfristig",
  3: "Mittelfristig",
  4: "Kurzfristig",
  5: "Sofort"
};

const mappingSubstanzAndStrategie = {
  0: "Schadensfrei / Voll",
  1: "Nachrangig / Sehr hoch",
  2: "Langfristig / Hoch",
  3: "Mittelfristig / Mittel",
  4: "Kurzfristig / Niedrig",
  5: "Sofort / Aufgebraucht"
};

export const mappingLabels = {
  zustandStandsicherheit: mappingZustandsklasse,
  substanzStandsicherheit: mappingSubstanz,
  zustandBetrieb: mappingZustandsklasse,
  substanzBetrieb: mappingSubstanz,
  zustandDichtheit: mappingZustandsklasse,
  substanzDichtheit: mappingSubstanz,
  substanzklasse: mappingSubstanz,
  zustandsklasse: mappingZustandsklasse,
  zustandsklasseStrategie: mappingZustandsklasse,
  substanzklasseStrategie: mappingSubstanz,
  classes: mappingSubstanzAndStrategie
};
