/**
 * Detailview componnt.
 * @module components/elements/Detailview/Detailview
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Button } from "reactstrap";
import { find } from "lodash";

import Filterpanel from "../../elements/Filterpanel/Filterpanel";
import { diagramsViews } from "../../../constants/Dashboards";
import { setDetailviewOpen } from "../../../actions/detailview/detailview";
import {
  scalesDifferencesAvg,
  scalesDifferencesSum,
} from "../../../constants/chartSettings";
import {
  getValuesX,
  getNameStrategy,
} from "../../../helpers/charts/common_functions";

import {
  add_label_to_data,
  getScaleDisplayDifferences,
  mapClassConvention,
} from "../../../helpers/charts/common_functions";

import {
  CHARTS_MAP_FOR_CONVENTION,
  CHARTS_MAP_CORRELATED_FOR_CONVENTION,
} from "../../../constants/ClassConventions";

import Chart from "../../charts/Chart/Chart";

import {
  DASHBOARD_STRATEGIE,
  DASHBOARD_STRATEGIE_VERGLEICH,
  DASHBOARD_GRUNDDATEN,
} from "../../../constants/Dashboards";
import {
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
  VIEW_GRUNDDATEN_STRATEGY,
  VIEW_GRUNDDATEN,
} from "../../../constants/NamesViews";

// TODO: management of labels for tables should not happen hear but also in
//  Chart.js!

/**
 * Detailview component class. Represents a detailed view for a diagram.
 * @class Detailview
 * @extends Component
 */
class Detailview extends Component {
  /**
   * Obtain name of view where the currently displayed chart is located in.
   * @method getViewName
   * @param {String} idChart the chart's identifier
   * @returns {String} View name
   */
  getViewName(idChart) {
    const nameView = find(
      Object.keys(diagramsViews),
      (nameView) => idChart in diagramsViews[nameView]
    );

    return nameView;
  }

  // TODO: Fix the charts

  /**
   * Render the detailed diagram(s)
   * For all diagrams, a large diagram is drawn.
   * For stacked Barchart, an additional TemporalHalfPie is drawn.
   * For Line, if exactly two datasets exist, an addiditonal difference plot is
   * drawn.
   * @method drawDiagramContent
   * @returns {Object} R component with diagram(s)
   */
  drawDiagramContent() {
    const {
      idChart,
      typeChart,
      dataChart,
      typeView,
      showDifferences,
      idChartDifferences,
    } = this.props;

    const unitsDisplay = undefined;
    const scalesDisplay = getScaleDisplayDifferences(
      showDifferences,
      idChartDifferences,
      scalesDifferencesAvg,
      scalesDifferencesSum
    );

    // Remark: Need to pass typeChart explicitly because otherwise it is
    //  constructed from dashboard constants according to typeDashboard,
    //  which does not work for grunddaten panel, as typeDashboard is always
    //  the displayed dashboard
    switch (typeChart) {
      case "Bar":
        return (
          <Row>
            <Chart
              data={dataChart}
              id={idChart}
              showDetailButton={false}
              showUnitButton={false}
              showScaleButton={false}
              typeView={typeView}
              typeChart={"Bar"}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
          </Row>
        );
      case "Pie": {
        return (
          <Row>
            <Chart
              data={dataChart}
              id={idChart}
              showDetailButton={false}
              showScaleButton={false}
              typeChart="Pie"
              typeView={typeView}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={dataChart}
              id={idChart}
              showUnitButton={false}
              showDetailButton={false}
              showScaleButton={false}
              showMaximizeButton={false}
              typeView={typeView}
              typeChart={"LegendPie"}
              prefixLabel={"Legende: "}
            />
          </Row>
        );
      }
      case "BarHorizontal": {
        return (
          <Row>
            <Chart
              data={dataChart}
              id={idChart}
              showDetailButton={false}
              typeChart="BarHorizontal"
              showUnitButton={false}
              showScaleButton={false}
              typeView={typeView}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={dataChart}
              id={idChart}
              showUnitButton={false}
              showDetailButton={false}
              showScaleButton={false}
              showMaximizeButton={false}
              typeView={typeView}
              typeChart={"LegendPie"}
              prefixLabel={"Legende: "}
            />
          </Row>
        );
      }
      case "StackedBar": {
        let labelsLegend = dataChart.map((layer) => ({ x: layer.name }));

        return (
          <Container>
            <Row>
              <Chart
                data={dataChart}
                id={idChart}
                showDetailButton={false}
                showUnitButton={false}
                showScaleButton={false}
                typeView={typeView}
                typeChart={"TemporalHalfPie"}
              />
              <Chart
                data={labelsLegend}
                id={idChart}
                showUnitButton={false}
                showDetailButton={false}
                showScaleButton={false}
                showMaximizeButton={false}
                typeView={typeView}
                typeChart={"LegendPie"}
                prefixLabel={"Legende: "}
              />
            </Row>
            <Row>
              <Chart
                data={dataChart}
                id={idChart}
                showUnitButton={false}
                showDetailButton={false}
                showScaleButton={false}
                typeView={typeView}
              />
            </Row>
          </Container>
        );
      }
      case "Line": {
        let labelsLegend = dataChart.map((layer) => ({ x: layer.name }));

        // If the dashboard is strategieVergleich, layer.name corresponds
        //  to the strategy ID. Get the strategy name for display.
        // TODO: Obtain typeDashboard properly
        if (this.props.typeDashboard === DASHBOARD_STRATEGIE_VERGLEICH) {
          labelsLegend = labelsLegend.map((layer) => ({
            x: getNameStrategy(layer.x, this.props.strategies),
            id: layer.x,
          }));
        }

        return (
          <Container>
            <Row>
              <Chart
                data={dataChart}
                id={idChart}
                showDetailButton={false}
                showUnitButton={false}
                typeView={typeView}
              />
              {dataChart.length === 2 && (
                <Chart
                  data={dataChart}
                  id={idChart}
                  prefixLabel={"Differenz "}
                  typeChart={"LineDifference"}
                  typeView={typeView}
                  showUnitButton={false}
                  showDetailButton={false}
                  showScaleButton={false}
                />
              )}
              <Chart
                data={labelsLegend}
                id={idChart}
                showUnitButton={false}
                showDetailButton={false}
                showScaleButton={false}
                showMaximizeButton={false}
                typeView={typeView}
                typeChart={"LegendPie"}
                prefixLabel={"Legende: "}
              />
            </Row>
          </Container>
        );
      }
      default:
        return null;
    }
  }

  /**
   * Render the detailed table with numerical diagram values.
   * For stacked Barchart, each bar secton is represented by a column
   * For Line, if exactly two datasets exist, an addiditonal difference column
   * is given.
   * @method drawDiagramContent
   * @returns {Object} R component with diagram(s)
   */
  drawTableContent() {
    // TODO: Rounding? Or should this be handled by STATUS Backend
    const { dataChart, idChart, typeChart, metadataProject } = this.props;

    const dataChartLabelled = add_label_to_data(
      dataChart,
      idChart,
      typeChart,
      metadataProject.ref
    );

    const class_convention = metadataProject.class_convention;

    var rows;
    var namesColumns;

    switch (typeChart) {
      case "BarHorizontal":
      case "Bar":
        rows = dataChart
          ? dataChartLabelled.map((row) => {
              let valueY = row.y;
              if (
                this.props.typeView === VIEW_GRUNDDATEN_STRATEGY &&
                CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
                  this.props.idChartStrategy
                )
              ) {
                console.log("STRATEGY MAP");
                valueY = mapClassConvention(class_convention, valueY, false);
              } else if (
                this.props.typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON &&
                CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
                  this.props.idChartDifferences
                )
              ) {
                valueY = mapClassConvention(class_convention, valueY, true);
              }

              return row.label !== undefined
                ? [row.label, valueY]
                : [row.x, valueY];
            })
          : [];
        namesColumns = [idChart, "Wert"];
        break;
      case "Pie":
        rows = dataChart
          ? dataChartLabelled.map((row) =>
              row.label !== undefined ? [row.label, row.y] : [row.x, row.y]
            )
          : [];
        rows = dataChart ? dataChart.map((row) => [row.x, row.y]) : [];
        namesColumns = [idChart, "Wert"];
        break;
      case "StackedBar": {
        const valuesX = getValuesX(dataChart);
        rows = [];
        // For each row, construct list with x value followed by y values for
        // each layer.
        valuesX.forEach((valueX, index) => {
          let valuesRow = dataChart.map((layer) =>
            layer.data[index] ? layer.data[index].y : "-"
          );
          valuesRow.unshift(valueX);
          rows.push(valuesRow);
        });

        // Although mapping is done with reference values in tables,
        //  for the detailview, we need to set the column names here
        //  because the TableGeneric cannot map column names
        //  according to a chart's reference mapping
        namesColumns = dataChartLabelled.map((layer) =>
          layer.label !== undefined ? layer.label : layer.name
        );
        // TODO: Like it has to be done for all charts axis labels, print
        //  the unit.
        namesColumns.unshift("X-Achse");
        break;
      }
      case "Line": {
        const valuesX = getValuesX(dataChart);

        // if the data contains exactly two lines, construct difference.
        // TODO: use DRY function in helpers
        let dataDifference = null;
        if (dataChart.length === 2) {
          dataDifference = valuesX.map((value, index) => {
            if (dataChart[0].data[index] && dataChart[1].data[index]) {
              return dataChart[0].data[index].y - dataChart[1].data[index].y;
            } else {
              return "-";
            }
          });
        }

        rows = [];
        // For each row, construct list with x value followed by y values for
        // each layer.
        valuesX.forEach((valueX, index) => {
          let valuesRow = dataChart.map((layer) => {
            if (index in layer.data) {
              if (
                CHARTS_MAP_FOR_CONVENTION.includes(idChart) ||
                (this.props.typeView === VIEW_GRUNDDATEN_STRATEGY &&
                  CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
                    this.props.idChartStrategy
                  ))
              ) {
                return mapClassConvention(
                  class_convention,
                  layer.data[index].y,
                  false
                );
              } else if (
                this.props.typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON &&
                CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
                  this.props.idChartDifferences
                )
              ) {
                return mapClassConvention(
                  class_convention,
                  layer.data[index].y,
                  true
                );
              }
              return layer.data[index].y;
            } else {
              return "-";
            }
          });
          valuesRow.unshift(valueX);
          rows.push(valuesRow);
          if (dataDifference) {
            if (CHARTS_MAP_FOR_CONVENTION.includes(idChart)) {
              valuesRow.push(
                mapClassConvention(
                  class_convention,
                  dataDifference[index],
                  true
                )
              );
            } else {
              valuesRow.push(dataDifference[index]);
            }
          }
        });

        namesColumns = dataChart.map((layer) => layer.name);

        // If the dashboard is strategieVergleich, layer.name corresponds
        //  to the strategy ID. Get the strategy name for display.
        if (this.props.typeDashboard === DASHBOARD_STRATEGIE_VERGLEICH) {
          namesColumns = namesColumns.map((id) =>
            getNameStrategy(id, this.props.strategies)
          );
        }

        // TODO: Like it has to be done for all charts axis labels, print
        //  the unit.
        namesColumns.unshift("X-Achse");
        if (dataChart) {
          namesColumns.push("Differenz");
        }
        break;
      }
      default:
        rows = [];
        namesColumns = [];
    }

    const dataTable = { namesColumns, rows };

    return (
      <Row>
        <Chart
          data={dataTable}
          id={idChart}
          typeChart="Table"
          prefixLabel="Tabelle: "
          showUnitButton={false}
          showDetailButton={false}
          showScaleButton={false}
          showMaximizeButton={false}
          // convention mapping is done above in detailview code
          //  because the current implementaion in chat can only nhandle
          //  transformation if individual columns reflect individual charts.
          disable_convention_mapping={true}
        />
      </Row>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { idChart, typeChart, typeView } = this.props;

    // TODO: Define distinct width for detailview bar.
    //  For now, use hardcoded value.
    const widthSidebar = 35;
    const widthContent = 100 - widthSidebar;
    return (
      <div>
        <Filterpanel
          typeView={typeView}
          width={widthSidebar}
          title={
            <div>
              <span className="filter-label">Detailansicht: Filter</span>
              <Button
                className="filter-apply"
                onClick={() =>
                  this.props.setDetailviewOpen(false, idChart, typeChart)
                }
              >
                Detailansicht Schließen
              </Button>
            </div>
          }
        />
        <div
          className={
            this.props.sidebarIsOpen
              ? "mainColumn sidebarOpen"
              : "mainColumn sidebarClosed"
          }
          style={{
            width: this.props.sidebarIsOpen ? `${widthContent}%` : "100%",
          }}
        >
          <Container className="containerDiagrams mainSidebarContainer inner">
            {this.drawDiagramContent()}
            {this.drawTableContent()}
          </Container>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const isStrategyComparison =
      state.views.dashboard === DASHBOARD_STRATEGIE_VERGLEICH;

    const isStrategy = state.views.dashboard === DASHBOARD_STRATEGIE;

    const typeView = state.detailview.typeView;
    const typeDashboard = state.views.dashboard;

    const idChart = state.detailview.idChart;
    let dataChart;
    if (state[typeDashboard] !== undefined) {
      if (
        isStrategyComparison &&
        typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON
      ) {
        dataChart = state.strategieVergleich.dataDifferences[idChart];
      } else if (isStrategy && typeView === VIEW_GRUNDDATEN_STRATEGY) {
        dataChart = state.strategie.dataGrunddaten[idChart];
      } else if (typeView === VIEW_GRUNDDATEN) {
        dataChart = state[DASHBOARD_GRUNDDATEN].data[idChart];
      } else {
        dataChart = state[typeDashboard].data[idChart];
      }
    } else {
      dataChart = [];
    }
    // const dataChart = state[viewName] ? state[viewName].data[idChart] : [];
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });

    const idChartDifferences = state.strategieVergleich.idChartDifferences;
    const strategiesComparisonIds =
      state.strategieVergleich.strategiesComparisonIds;

    const showDifferences =
      state.views.dashboard === DASHBOARD_STRATEGIE_VERGLEICH &&
      idChartDifferences !== undefined &&
      strategiesComparisonIds.length === 2 &&
      strategiesComparisonIds[0] !== undefined &&
      strategiesComparisonIds[1] !== undefined;

    return {
      widthSidebar: state.grunddaten.widthSidebar,
      sidebarIsOpen: state.grunddaten.sidebarIsOpen,
      project: state.projects.project,
      bucket: state.projects.bucket,
      idChart: idChart,
      typeChart: state.detailview.typeChart,
      typeView: typeView,
      dataChart: dataChart,
      strategies: project ? project.strategies : [],
      unit: state.chartSettings.unit[idChart],
      scale: state.chartSettings.scale[idChart],
      typeDashboard: typeDashboard,
      showDifferences: showDifferences,
      idChartDifferences: state.strategieVergleich.idChartDifferences,
      idChartStrategy: state.strategie.idChart,
      metadataProject: state.projects.metadataProject,
    };
  },
  { setDetailviewOpen }
)(Detailview);
