/**
 * BarFilterSetter component.
 * @module components/elements/BarFilterSetter/BarFilterSetter
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { InputGroup, Input } from "reactstrap";
import { isEqual } from "lodash";
import {
  setBarFilter,
  removeFilter,
  applyFilter,
} from "../../../actions/filters/filters";

// TODO: Handle DateTime Strings

/**
 * Filterpanel component class.
 * @class Detailview
 * @extends Component
 */
class BarFilterSetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: "",
      end: "",
    };
  }

  /**
   * Get filter range of a bar chart
   * @method setFilter
   * @param {String} idChart the chart's identifier
   * @returns {Array} Array with start and end values or [null, null]
   *  if Filter is not set.
   */
  getFilterRange(idChart) {
    const filtersChart = this.props.filterSet;
    return filtersChart ? filtersChart : [null, null];
  }

  componentDidMount() {
    if (this.props.filterSet) {
      const filterRange = this.props.filterSet;
      this.setState({ start: filterRange[0], end: filterRange[1] });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isEqual(this.props.filterSet, nextProps.filterSet) ||
      !isEqual(this.props.filterApplied, nextProps.filterApplied)
    ) {
      // Update the input fields if set filter changed (e.g. by loading preset or deleting filter)
      if (nextProps.filterSet) {
        const filterRange = nextProps.filterSet;
        this.setState({ start: filterRange[0], end: filterRange[1] });
      } else {
        this.setState({ start: "", end: "" });
      }
    }
  }

  checkRangeValid(start, end) {
    return !(start !== "" && end !== "" && Number(start) > Number(end));
  }

  updateFilter(values) {
    //
    const nextState = Object.assign(this.state, values);
    //
    if (this.checkRangeValid(nextState.start, nextState.end)) {
      this.props.setBarFilter(this.props.idChart, [
        nextState.start,
        nextState.end,
      ]);
    }
    this.setState(nextState);
  }

  handleEnterPress(event) {
    if (event.key === "Enter") {
      this.props.applyFilter(this.props.idChart);
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { idChart } = this.props;
    // if (idChart === "baujahr") {
    //
    //
    // }

    // if the range is not set yet, obtain it from the set filter range
    if (this.state.start === undefined && this.state.end === undefined) {
      let filterRange = this.getFilterRange(idChart);
      this.setState({ start: filterRange[0], end: filterRange[1] });
    }

    // get the filter Range from the set filters

    // TODO: Validate form. Both values must be filled, lie within chart Range
    //  and must represent appropriate type

    const inputsValid = this.checkRangeValid(this.state.start, this.state.end);

    return (
      <InputGroup size={this.props.size} className={this.props.className}>
        <Input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          ref={this.props.refInputMin}
          size={this.props.size}
          value={this.state.start}
          invalid={!inputsValid}
          onChange={(event) =>
            this.updateFilter({
              start: event.target.value ? event.target.value : "",
            })
          }
          onKeyPress={(event) => inputsValid && this.handleEnterPress(event)}
        />
        <div style={{ margin: "2px" }}>-</div>
        <Input
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          ref={this.props.refInputMax}
          size={this.props.size}
          value={this.state.end}
          invalid={!inputsValid}
          innerRef="end"
          onChange={(event) =>
            this.updateFilter({
              end: event.target.value ? event.target.value : "",
            })
          }
          onKeyPress={(event) => inputsValid && this.handleEnterPress(event)}
        />
      </InputGroup>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      filterSet: state.filters.filtersSet[props.idChart],
    };
  },
  { setBarFilter, removeFilter, applyFilter }
)(BarFilterSetter);
