/**
 * Users actions.
 * @module actions/users/users
 */

import {
  ADD_USER,
  GET_USERS,
  UPDATE_USER,
  REMOVE_USER
} from "../../constants/ActionTypes";

/**
 * Get users.
 * @function getUsers
 * @param {Number} projectId Project id.
 * @returns {Object} Get users action.
 */
export function getUsers(projectId) {
  return {
    type: GET_USERS,
    request: {
      op: "post",
      path: `project/users`,
      data: {
        id_project: projectId
      }
    }
  };
}

/**
 * Add user.
 * @function addUser
 * @param {Number} projectId Project id.
 * @param {String} username Username.
 * @param {String} password Password.
 * @param {String} fullname Fullname.
 * @returns {Object} Add user action.
 */
export function addUser(projectId, email) {
  return {
    type: ADD_USER,
    request: {
      op: "put",
      path: `project/user`,
      data: {
        id_project: projectId,
        email: email
      }
    }
  };
}

/**
 * Update user.
 * @function updateUser
 * @param {Number} projectId Project id.
 * @param {String} username Username.
 * @param {Boolean} admin Admin.
 * @param {Array} permissions Permissions.
 * @returns {Object} Update user action.
 */
export function updateUser(projectId, idUser, admin, permissions) {
  return {
    type: UPDATE_USER,
    request: {
      op: "patch",
      path: `project/user`,
      data: {
        id_project: projectId,
        id_user: idUser,
        admin: admin,
        permissions: permissions
      }
    }
  };
}

/**
 * Delete user.
 * @function removeUser
 * @param {Number} projectId Project id.
 * @param {Int} idUser Username.
 * @returns {Object} Update user action.
 */
export function removeUser(projectId, idUser) {
  return {
    type: REMOVE_USER,
    request: {
      op: "delete",
      path: `project/user`,
      data: {
        id_project: projectId,
        id_user: idUser
      }
    }
  };
}
