/**
 * Users component.
 * @module components/views/Users/Users
 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardTitle,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Alert,
  ModalFooter,
} from "reactstrap";
import { map, keys, xor, find } from "lodash";

import {
  getUsers,
  addUser,
  updateUser,
  removeUser,
} from "../../../actions/users/users";
import { permissions } from "../../../constants/Permissions";

/*
  TODO: Show errors from request to user if deletion, adding or editing
  failed
 */

/**
 * Users component class.
 * @class Users
 * @extends Component
 */
class Users extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsComponent
   */
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      admin: false,
      permissions: [],
      add: false,
      edit: false,
      error: "",
      modalRemoveUserIsOpen: false,
      indexUserRemove: undefined,
      indexUserEdit: undefined,
    };

    this.onEditShow = this.onEditShow.bind(this);
    this.onChangePermissions = this.onChangePermissions.bind(this);
    //    this.onReload = this.onReload.bind(this);
    //    this.onAdd = this.onAdd.bind(this);
    //    this.onEdit = this.onEdit.bind(this);
    //    this.onAddToggle = this.onAddToggle.bind(this);
    //    this.onToggleRemoveUser = this.onToggleRemoveUser.bind(this);
    //    this.onRemoveUser = this.onRemoveUser.bind(this);
    //    this.onEditHide = this.onEditHide.bind(this);
    //    this.onChangeEmail = this.onChangeEmail.bind(this);
    //    this.onChangeAdmin = this.onChangeAdmin.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getUsers(this.props.project);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.project !== this.props.project) {
      this.props.getUsers(nextProps.project);
    }
    if (
      this.props.userRemoving &&
      nextProps.userRemoved &&
      !nextProps.userRemoving
    ) {
      this.setState({
        modalRemoveUserIsOpen: false,
        indexUserRemove: undefined,
      });
      this.props.getUsers(this.props.project);
    }
  }

  /**
   * On add toggle handler
   * @method onAddToggle
   * @returns {undefined}
   */
  onAddToggle = () => {
    this.setState({
      error: "",
      add: !this.state.add,
    });
  };

  /**
   * On add handler
   * @method onAdd
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onAdd = (event) => {
    if (
      this.state.email !== undefined &&
      this.state.email.indexOf("@") !== -1
    ) {
      this.props.addUser(this.props.project, this.state.email);
      this.setState({
        add: false,
        email: "",
        error: "",
      });
    } else {
      this.setState({
        error: "Ungültige E-Mail-Adresse",
      });
    }

    event.preventDefault();
  };

  /**
   * On remove toggle handler
   * @method onToggleRemoveUser
   * @returns {undefined}
   */
  onToggleRemoveUser = (event, index) => {
    this.setState({
      modalRemoveUserIsOpen: !this.state.modalRemoveUserIsOpen,
      indexUserRemove: index,
    });

    event.preventDefault();
  };

  onRemoveUser = () => {
    this.props.removeUser(
      this.props.project,
      this.props.users[this.state.indexUserRemove].id
    );
  };

  /**
   * On edit show handler
   * @method onEditToggle
   * @param {Object} event Event object.
   * @param {Number} index User index
   * @returns {undefined}
   */
  onEditShow(event, index) {
    this.setState({
      error: "",
      indexUserEdit: index,
      admin: this.props.users[index].admin,
      permissions: this.props.users[index].permissions,
      edit: true,
    });

    event.preventDefault();
  }

  /**
   * On edit hide handler
   * @method onEditHide
   * @returns {undefined}
   */
  onEditHide = () => {
    this.setState({
      error: "",
      indexUserEdit: undefined,
      admin: false,
      permissions: [],
      edit: false,
    });
  };

  /**
   * On edit handler
   * @method onEdit
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onEdit = (event) => {
    this.props.updateUser(
      this.props.project,
      this.props.users[this.state.indexUserEdit].id,
      this.state.admin,
      this.state.permissions
    );
    this.setState({
      edit: false,
      indexUserEdit: undefined,
      admin: false,
      permissions: [],
    });

    event.preventDefault();
  };

  /**
   * On reload handler
   * @method onReload
   * @returns {undefined}
   */
  onReload = () => {
    this.props.getUsers(this.props.project);
  };

  /**
   * On change username handler
   * @method onChangeUsername
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  /**
   * On change admin handler
   * @method onChangeAdmin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangeAdmin = (event) => {
    this.setState({
      admin: !this.state.admin,
    });
  };

  /**
   * On change permissions handler
   * @method onChangePermissions
   * @param {String} permission Permission.
   * @returns {undefined}
   */
  onChangePermissions(permission) {
    this.setState({
      permissions: xor(this.state.permissions, [permission]),
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container style={{ maxWidth: 4000, padding: "100px 30px 0 30px" }}>
        <Card style={{ height: "auto" }}>
          <CardHeader>
            <CardTitle tag="h4" style={{ marginBottom: 0 }}>
              Benutzer
            </CardTitle>
            <div className="heading-elements">
              <ul className="list-inline mb-0">
                <li>
                  <a data-action="reload" onClick={this.onReload}>
                    <i className="ft-rotate-cw" />
                  </a>
                </li>
              </ul>
            </div>
          </CardHeader>
          <Table hover style={{ marginBottom: 0 }} className="table-xl">
            <thead>
              <tr>
                <th style={{ borderTop: 0 }}>E-Mail</th>
                <th style={{ borderTop: 0 }}>Name</th>
                <th style={{ borderTop: 0 }}>Administrator</th>
                {/* <th style={{ borderTop: 0 }}>Berechtigungen</th> */}
                <th style={{ borderTop: 0 }}>Registriert</th>
                <th style={{ borderTop: 0 }} />
                <th style={{ borderTop: 0 }} />
              </tr>
            </thead>
            <tbody>
              {this.props.users.map((item, index) => {
                return (
                  <tr key={item.username}>
                    <td>
                      <a
                        href="#"
                        onClick={(event) => this.onEditShow(event, index)}
                      >
                        {item.username}
                      </a>
                    </td>
                    <td>{item.fullname}</td>
                    <td>
                      {item.admin ? (
                        <i className="ft-check success" />
                      ) : (
                        <i className="ft-x danger" />
                      )}
                    </td>
                    <td>
                      {item.registered ? (
                        <i className="ft-check success" />
                      ) : (
                        <i className="ft-x danger" />
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <i
                        className="ft-edit-2 primary"
                        style={{ cursor: "pointer" }}
                        onClick={(event) => this.onEditShow(event, index)}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {
                        // TODO: Only show delete button for unregistered users?
                      }
                      <i
                        className="la la-close"
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          this.onToggleRemoveUser(event, index)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
              {this.props.projectAdmin ? (
                <tr>
                  <td colSpan="5" style={{ borderBottom: 0, borderTop: 0 }}>
                    <Button
                      className="btn-info"
                      onClick={this.onAddToggle}
                      style={{ borderRadius: "50%", padding: ".75rem" }}
                    >
                      <i className="la la-plus" />
                    </Button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Card>
        <Modal
          isOpen={this.state.add}
          toggle={this.onAddToggle}
          modalClassName="modal-dialog"
        >
          <ModalHeader toggle={this.onAddToggle}>
            Eine E-Mail-Adresse für die Registrierung freischalten
          </ModalHeader>
          <ModalBody>
            <Form className="form-horizontal" onSubmit={this.onAdd}>
              <FormGroup className="position-relative">
                <Input
                  placeholder="E-Mail-Adresse"
                  id="email"
                  required="required"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </FormGroup>
            </Form>
            {this.state.error && (
              <Alert color="danger">{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onAdd}>
              OK
            </Button>{" "}
            <Button color="secondary" onClick={this.onAddToggle}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.edit}
          toggle={this.onEditHide}
          modalClassName="modal-dialog"
        >
          <ModalHeader toggle={this.onEditHide}>
            Benutzer bearbeiten
          </ModalHeader>
          <ModalBody>
            <Form className="form-horizontal" onSubmit={this.onEdit}>
              <h4 className="form-section">Rollen</h4>
              <FormGroup
                className="position-relative"
                style={{ marginLeft: 17 }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    id="admin"
                    checked={this.state.admin}
                    onChange={this.onChangeAdmin}
                  />{" "}
                  Administrator
                </Label>
              </FormGroup>
              <h4 className="form-section">Berechtigungen</h4>
              {map(keys(permissions), (permission) => (
                <FormGroup
                  className="position-relative"
                  style={{ marginLeft: 17 }}
                >
                  <Label check>
                    <Input
                      type="checkbox"
                      id={permission}
                      checked={
                        this.state.permissions.indexOf(permission) !== -1
                      }
                      onChange={() => this.onChangePermissions(permission)}
                    />{" "}
                    {permissions[permission]}
                  </Label>
                </FormGroup>
              ))}
            </Form>
            {this.state.error && (
              <Alert color="danger">{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onEdit}>
              OK
            </Button>{" "}
            <Button color="secondary" onClick={this.onEditHide}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.modalRemoveUserIsOpen}
          toggle={this.onToggleRemoveUser}
        >
          <ModalHeader clossButton> User entfernen</ModalHeader>
          <ModalBody>
            Wollen sie den Benutzer{" "}
            {this.state.indexUserRemove !== undefined &&
              this.props.users[this.state.indexUserRemove].username}{" "}
            wirklich aus dem Projekt entfernen?
            {this.props.errorRemoveUser && (
              <Alert color="danger">{this.props.errorRemoveUser}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onRemoveUser}>
              Entfernen
            </Button>{" "}
            <Button color="secondary" onClick={this.onToggleRemoveUser}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

function compareUsers(userA, userB) {
  // Sort the users by name and whether they are registered
  // registered before unregistered. Then alphabetical order.

  // -1 if a before b, 1 if b before a, 0 else
  const sortName = userA.fullname.localeCompare(userB.fullname);
  const sortRegistered = userB.registered - userA.registered;

  return sortRegistered === 0 ? sortName : sortRegistered;
}

export default connect(
  (state) => {
    const usersSorted = [...state.users.users].sort(compareUsers);
    const haveAdmin = find(state.projects.projects, {
      id: state.projects.project,
    });

    return {
      users: usersSorted,
      project: state.projects.project,
      errorRemoveUser: state.users.errorRemoveUser,
      userRemoved: state.users.userRemoved,
      userRemoving: state.users.userRemoving,
      projectAdmin: haveAdmin ? haveAdmin.admin : false,
    };
  },
  { getUsers, addUser, updateUser, removeUser }
)(Users);
