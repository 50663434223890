import {
  VIEW_GRUNDDATEN,
  VIEW_INSPEKTIONSDATEN,
  VIEW_SCHADENSKLASSIFIZIERUNG,
  VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE,
  VIEW_OBJEKTKLASSIFIZIERUNG,
  VIEW_STRATEGIE,
  VIEW_STRATEGIE_VERGLEICH,
  VIEW_KARTE_DASHBOARD,
  VIEW_KARTE_EINZELOBJEKTANSICHT,
  VIEW_EINZELOBJEKTANSICHT_OBJEKTLISTE,
  VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE,
  VIEW_EINZELOBJEKTANSICHT_PROGNOSE,
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
  VIEW_GRUNDDATEN_STRATEGY,
} from "./NamesViews";
import * as charts from "./Charts";
import {
  CHART_BAR,
  CHART_LEGEND_PIE,
  CHART_LINE,
  CHART_LINE_DIFFERENCE,
  CHART_PIE,
  CHART_STACKED_BAR,
  CHART_TABLE,
} from "./TypesCharts";

export const DASHBOARD_GRUNDDATEN = "grunddaten";
export const DASHBOARD_INSPEKTIONSDATEN = "inspektionsdaten";
export const DASHBOARD_SCHADENSKLASSIFIZIERUNG = "schadensklassifizierung";
export const DASHBOARD_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE =
  "objektklassifizierungSchutzziele";
export const DASHBOARD_OBJEKTKLASSIFIZIERUNG = "objektklassifizierung";
export const DASHBOARD_STRATEGIE = "strategie";
export const DASHBOARD_STRATEGIE_VERGLEICH = "strategieVergleich";
export const DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE =
  "einzelobjektansichtObjekte";
export const DASHBOARD_EINZELOBJEKTANSICHT_PROGNOSE =
  "einzelobjektansichtPrognose";

// Store charts belonging to each view
const grunddaten = {
  [charts.BAUJAHR]: CHART_BAR,
  [charts.MATERIALGRUPPE]: CHART_PIE,
  [charts.MITTLERE_TIEFE]: CHART_BAR,
  [charts.KANALNUTZUNG]: CHART_PIE,
  [charts.HALTUNGSLAENGE]: CHART_BAR,
  [charts.KANALART]: CHART_PIE,
  [charts.NENNWEITE]: CHART_BAR,
  [charts.LAGE_IM_VERKEHRSRAUM]: CHART_PIE,
  [charts.PROFILART]: CHART_PIE,
  [charts.RESTNUTZUNGSDAUER]: CHART_BAR,
  [charts.ALTER]: CHART_BAR,
  [charts.SANIERUNGSLAENGE]: CHART_BAR,
  [charts.VERIFIKATION]: CHART_BAR,
};

const grunddatenStrategyComparison = {
  // TODO: instead of pie, use horizontal bar with labels
  [charts.BAUJAHR]: CHART_BAR,
  [charts.MATERIALGRUPPE]: CHART_PIE,
  [charts.MITTLERE_TIEFE]: CHART_BAR,
  [charts.KANALNUTZUNG]: CHART_PIE,
  [charts.HALTUNGSLAENGE]: CHART_BAR,
  [charts.KANALART]: CHART_PIE,
  [charts.NENNWEITE]: CHART_BAR,
  [charts.LAGE_IM_VERKEHRSRAUM]: CHART_PIE,
  [charts.PROFILART]: CHART_PIE,
  [charts.RESTNUTZUNGSDAUER]: CHART_BAR,
  [charts.ALTER]: CHART_BAR,
  [charts.SANIERUNGSLAENGE]: CHART_BAR,
  [charts.VERIFIKATION]: CHART_BAR,
};

const grunddatenStrategy = {
  // TODO: instead of pie, use horizontal bar with labels
  [charts.BAUJAHR]: CHART_BAR,
  [charts.MATERIALGRUPPE]: CHART_PIE,
  [charts.MITTLERE_TIEFE]: CHART_BAR,
  [charts.KANALNUTZUNG]: CHART_PIE,
  [charts.HALTUNGSLAENGE]: CHART_BAR,
  [charts.KANALART]: CHART_PIE,
  [charts.NENNWEITE]: CHART_BAR,
  [charts.LAGE_IM_VERKEHRSRAUM]: CHART_PIE,
  [charts.PROFILART]: CHART_PIE,
  [charts.RESTNUTZUNGSDAUER]: CHART_BAR,
  [charts.ALTER]: CHART_BAR,
  [charts.SANIERUNGSLAENGE]: CHART_BAR,
  [charts.VERIFIKATION]: CHART_BAR,
};

const inspektionsdaten = {
  [charts.INSPEKTIONSLAENGE]: CHART_BAR,
  [charts.INSPEKTIONSGRUND]: CHART_PIE,
  [charts.INSPEKTIONSJAHR]: CHART_BAR,
  [charts.INSPEKTIONSART]: CHART_PIE,
  [charts.KODIERSYSTEM]: CHART_PIE,
};

const schadensklassifizierung = {
  [charts.SCHADENSKLASSE_DICHTHEIT]: CHART_PIE,
  [charts.SCHADENSKLASSE_STANDSICHERHEIT]: CHART_PIE,
  [charts.SCHADENSKLASSE_BETRIEB]: CHART_PIE,
  [charts.SCHADENSART]: CHART_PIE,
};

const objektansichtSchutzziele = {
  [charts.ZUSTAND_STANDSICHERHEIT]: CHART_PIE,
  [charts.SUBSTANZ_STANDSICHERHEIT]: CHART_PIE,
  [charts.ZUSTAND_BETRIEB]: CHART_PIE,
  [charts.SUBSTANZ_BETRIEB]: CHART_PIE,
  [charts.ZUSTAND_DICHTHEIT]: CHART_PIE,
  [charts.SUBSTANZ_DICHTHEIT]: CHART_PIE,
  [charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR]: CHART_BAR,
};

const objektansichtSubstanz = {
  [charts.SUBSTANZKLASSE]: CHART_PIE,
  [charts.ZUSTANDSKLASSE]: CHART_PIE,
  [charts.SUBSTANZ_INSPEKTIONSJAHR]: CHART_BAR,
};

export const strategie = {
  // zustandStandsicherheitStrategie: CHART_STACKED_BAR,
  // substanzStandsicherheitStrategie: CHART_STACKED_BAR,
  // zustandBetriebStrategie: CHART_STACKED_BAR,
  // substanzBetriebStrategie: CHART_STACKED_BAR,
  // zustandDichtheitStrategie: CHART_STACKED_BAR,
  // substanzDichtheitStrategie: CHART_STACKED_BAR,
  [charts.ZUSTANDSKLASSE_STRATEGIE]: CHART_STACKED_BAR,
  [charts.SUBSTANZKLASSE_STRATEGIE]: CHART_STACKED_BAR,
  [charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE]: CHART_STACKED_BAR,
  [charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE]: CHART_STACKED_BAR,
  [charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE]: CHART_STACKED_BAR,
  // gebuehrenanteile: CHART_STACKED_BAR,
  [charts.SANIERUNGEN]: CHART_STACKED_BAR,
  [charts.SANIERUNGSKOSTEN]: CHART_STACKED_BAR,
  [charts.RBW_WBK]: CHART_LINE,
  [charts.LEGENDE_SANIERUNGEN]: CHART_LEGEND_PIE,
  [charts.LEGENDE_KLASSEN]: CHART_LEGEND_PIE,
  // sanierungskosten: CHART_STACKED_BAR
};

export const strategieVergleich = {
  // zustandStandsicherheitAvg: CHART_LINE,
  // substanzStandsicherheitAvg: CHART_LINE,
  // zustandBetriebAvg: CHART_LINE,
  // substanzBetriebAvg: CHART_LINE,
  // zustandDichtheitAvg: CHART_LINE,
  // substanzDichtheitAvg: CHART_LINE,
  [charts.ZUSTAND_AVG]: CHART_LINE,
  [charts.SUBSTANZ_AVG]: CHART_LINE,
  [charts.SUBSTANZ_BETRIEB_AVG]: CHART_LINE,
  [charts.SUBSTANZ_DICHTHEIT_AVG]: CHART_LINE,
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG]: CHART_LINE,
  [charts.ZUSTAND_AVG_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SUBSTANZ_AVG_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SUBSTANZ_BETRIEB_AVG_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  // gebuehrenanteileSum: CHART_LINE,
  [charts.SANIERUNGEN_SUM]: CHART_LINE,
  [charts.SANIERUNGEN_REP_SUM]: CHART_LINE,
  [charts.SANIERUNGEN_REN_SUM]: CHART_LINE,
  [charts.SANIERUNGEN_ERN_SUM]: CHART_LINE,
  [charts.SANIERUNGEN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGEN_REP_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGEN_REN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGEN_ERN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGSKOSTEN_SUM]: CHART_LINE,
  [charts.SANIERUNGSKOSTEN_REP_SUM]: CHART_LINE,
  [charts.SANIERUNGSKOSTEN_REN_SUM]: CHART_LINE,
  [charts.SANIERUNGSKOSTEN_ERN_SUM]: CHART_LINE,
  [charts.SANIERUNGSKOSTEN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  [charts.RBW_WBK_SUM]: CHART_LINE,
  [charts.RBW_WBK_SUM_DIFFERENCE]: CHART_LINE_DIFFERENCE,
  // sanierungskostenSum: CHART_LINE
};

const objectviewObject = {
  [charts.PROGNOSIS_OBJECT_SUBSTANZ]: CHART_LINE,
  [charts.PROGNOSIS_OBJECT_ZUSTAND]: CHART_LINE,
  [charts.PROGNOSIS_OBJECT_LEGEND]: CHART_LEGEND_PIE,
  [charts.PROGNOSIS_OBJECT_TABLE]: CHART_TABLE,
  // sanierungskostenSum: CHART_LINE
};

const objectviewInspections = {
  [charts.SUBSTANZKLASSE]: CHART_TABLE,
  [charts.SUBSTANZ_BETRIEB]: CHART_TABLE,
  [charts.SUBSTANZ_DICHTHEIT]: CHART_TABLE,
  [charts.SUBSTANZ_STANDSICHERHEIT]: CHART_TABLE,
};

// Rename to diagramsViews as this are the diagrams in each view
// and not in the Dashboard
export const diagramsViews = {
  [VIEW_GRUNDDATEN]: grunddaten,
  [VIEW_INSPEKTIONSDATEN]: inspektionsdaten,
  [VIEW_SCHADENSKLASSIFIZIERUNG]: schadensklassifizierung,
  [VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE]: objektansichtSchutzziele,
  [VIEW_OBJEKTKLASSIFIZIERUNG]: objektansichtSubstanz,
  [VIEW_STRATEGIE]: strategie,
  [VIEW_STRATEGIE_VERGLEICH]: strategieVergleich,
  [VIEW_GRUNDDATEN_STRATEGY_COMPARISON]: grunddatenStrategyComparison,
  [VIEW_GRUNDDATEN_STRATEGY]: grunddatenStrategy,
  [VIEW_EINZELOBJEKTANSICHT_PROGNOSE]: objectviewObject,
  [VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE]: objectviewInspections,
};

// Views that are visible on each dashboard that can be filtered
// Used for determining filter preset categories
export const viewsDashboardsFilters = {
  [DASHBOARD_GRUNDDATEN]: [VIEW_KARTE_DASHBOARD, VIEW_GRUNDDATEN],
  [DASHBOARD_INSPEKTIONSDATEN]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    VIEW_INSPEKTIONSDATEN,
  ],
  [DASHBOARD_SCHADENSKLASSIFIZIERUNG]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    VIEW_SCHADENSKLASSIFIZIERUNG,
  ],
  [DASHBOARD_OBJEKTKLASSIFIZIERUNG]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    VIEW_OBJEKTKLASSIFIZIERUNG,
  ],
  [DASHBOARD_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE,
  ],
  [DASHBOARD_STRATEGIE]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    // VIEW_STRATEGIE
  ],
  [DASHBOARD_STRATEGIE_VERGLEICH]: [
    VIEW_KARTE_DASHBOARD,
    VIEW_GRUNDDATEN,
    // VIEW_STRATEGIE_VERGLEICH
  ],
  [DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE]: [
    VIEW_EINZELOBJEKTANSICHT_OBJEKTLISTE,
    VIEW_KARTE_EINZELOBJEKTANSICHT,
  ],
  [DASHBOARD_EINZELOBJEKTANSICHT_PROGNOSE]: [],
};
