/**
 * chartSettigns actions.
 * @module actions/chartSettings/chartSettings
 */

import {
  SET_CHART_SCALE,
  SET_CHARTS_SCALE,
  SET_CHART_UNIT,
  SET_CHARTS_UNIT,
  TOGGLE_MAXIMIZED,
  TOGGLE_MINIMIZED,
  RESET_CHART_SETTINGS,
  SET_CHART_UNIT_VALID,
} from "../../constants/ActionTypes";

/**
 * Set chart scale.
 * @function setChartScale
 * @param {string} idChart Id of the chart
 * @param {string} value Value to set
 * @returns {Object} Set chart unit.
 */
export function setChartScale(idChart, value) {
  return {
    type: SET_CHART_SCALE,
    idChart: idChart,
    value: value,
  };
}

export function setChartUnitValid(idChart, isValid) {
  return {
    type: SET_CHART_UNIT_VALID,
    idChart: idChart,
    isValid: isValid,
  };
}

/**
 * Set chart unit.
 * @function setChartUnit
 * @param {string} idChart Id of the chart
 * @param {string} value Value to set
 * @returns {Object} Set chart unit.
 */
export function setChartUnit(idChart, value) {
  return {
    type: SET_CHART_UNIT,
    idChart: idChart,
    value: value,
  };
}

export function setChartsUnit(idsCharts, value) {
  return {
    type: SET_CHARTS_UNIT,
    idsCharts: idsCharts,
    value: value,
  };
}

export function setChartsScale(idsCharts, value) {
  return {
    type: SET_CHARTS_SCALE,
    idsCharts: idsCharts,
    value: value,
  };
}

export function toggleMinimized(idChart) {
  return {
    type: TOGGLE_MINIMIZED,
    idChart: idChart,
  };
}

export function toggleMaximized(idChart) {
  return {
    type: TOGGLE_MAXIMIZED,
    idChart: idChart,
  };
}

export function resetChartSettings() {
  return {
    type: RESET_CHART_SETTINGS,
  };
}
