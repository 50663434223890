import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";

import { mapColumns } from "../../../helpers/objectview/data";

import { labels } from "../../../constants/Filters";

// TODO: use column IDs

// TODO: write common function to reverse mapping for filtering

// TODO:

// TODO: enable vertical layout (For objectview)

// TODO: Sort rows by groups in some way? Or should backend manage that?

export class TableGeneric extends Component {
  // constructor(props) {
  //   super(props);
  // }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  renderHeadColumn(mappingColumns) {
    return <th key={mappingColumns[1]}>{mappingColumns[1]}</th>;
  }

  renderTable() {
    return (
      <Table className={this.props.classNameTable}>
        {this.renderTableContent()}
        {this.renderTableBody()}
      </Table>
    );
  }

  renderTableContent() {
    return (
      <thead>
        <tr>
          {this.props.namesColumnsMapped.map((mappingColumns) =>
            this.renderHeadColumn(mappingColumns)
          )}
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    return <tbody>{this.renderRows()}</tbody>;
  }

  renderRows() {
    return this.props.rows.map((row) => this.renderRow(row));
  }

  renderRow(row) {
    return <tr key={row}>{this.renderColumnCells(row)}</tr>;
  }

  renderColumnCells(row) {
    return row.map((value, index) =>
      this.renderColumnCell(this.props.idsColumns[index], value)
    );
  }

  renderColumnCell(idColumn, value) {
    return <td key={idColumn}>{this.mapColumnValue(idColumn, value)}</td>;
  }

  mapColumnValue(idColumn, value) {
    if (
      this.props.refMappings[idColumn] === undefined ||
      this.props.refMappings[idColumn][value] === undefined
    ) {
      return value;
    }
    const label = this.props.refMappings[idColumn][value];
    const labelHasMultipleValues =
      this.props.refMappingsInverted[idColumn][label].length > 1;

    // Draw the unmapped value in braces iff multiple values are mapped to the label.
    return labelHasMultipleValues ? `${label} (${value})` : label;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return this.renderTable();
  }
}

export default connect(
  (state, props) => ({
    namesColumnsMapped: mapColumns(props.idsColumns, labels),
    refMappings: state.projects.metadataProject.ref,
    refMappingsInverted: state.projects.metadataProject.refInverted,
  }),
  {}
)(TableGeneric);
