/**
 * Objektklassifizierung schutzziele component.
 * @module components/views/ObjektklassifizierungSchutzziele/ObjektklassifizierungSchutzziele
 */

import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { isEqual } from "lodash";

import { getObjektklassifizierungSchutzziele } from "../../../actions/objektklassifizierungSchutzziele/objektklassifizierungSchutzziele";

import Chart from "../../charts/Chart/Chart";
import Map from "../../elements/Map/Map";

import { diagramsViews } from "../../../constants/Dashboards";
import * as charts from "../../../constants/Charts";

import { VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE } from "../../../constants/NamesViews";
import InOutCarat from "../../elements/InOutCarat/InOutCarat";

/**
 * Objektklassifizierung schutzziele component class.
 * @class ObjektklassifizierungSchutzziele
 * @extends Component
 */
class ObjektklassifizierungSchutzziele extends Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getObjektklassifizierungSchutzziele(
      this.props.project,
      this.props.bucket,
      this.props.filtersApplied,
      this.props.scales,
      this.props.units,
      this.props.typeDashboard
    );
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  //  UNSAFE_componentWillReceiveProps(nextProps) {
  //    if (
  //      !isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
  //      !isEqual(this.props.scales, nextProps.scales) ||
  //      !isEqual(this.props.units, nextProps.units) ||
  //      !isEqual(this.props.bucket, nextProps.bucket) ||
  //      !isEqual(this.props.project, nextProps.project)
  //    ) {
  //      this.props.getObjektklassifizierungSchutzziele(
  //        nextProps.project,
  //        nextProps.bucket,
  //        nextProps.filtersApplied,
  //        nextProps.scales,
  //        nextProps.units,
  //        nextProps.typeDashboard
  //      );
  //    }
  //  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.filtersApplied, this.props.filtersApplied) ||
      !isEqual(prevProps.scales, this.props.scales) ||
      !isEqual(prevProps.units, this.props.units) ||
      !isEqual(prevProps.bucket, this.props.bucket) ||
      !isEqual(prevProps.project, this.props.project)
    ) {
      prevProps.getObjektklassifizierungSchutzziele(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.typeDashboard
      );
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      objektklassifizierungSchutzziele,
      typeDashboard,
      mapMaximized,
      mapMinimized,
    } = this.props;

    let height = "50%";
    if (mapMaximized) {
      height = "0%";
    } else if (mapMinimized) {
      height = "100%";
    }

    return (
      <div className="mapWrapper">
        <div className="mapDetail" style={{ height: height }}>
          <Container className="containerDiagrams inner">
            <Row>

              <Chart
                data={
                  objektklassifizierungSchutzziele[
                    charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR
                  ]
                }
                id={charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={
                  objektklassifizierungSchutzziele[
                    charts.ZUSTAND_STANDSICHERHEIT
                  ]
                }
                id={charts.ZUSTAND_STANDSICHERHEIT}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={
                  objektklassifizierungSchutzziele[
                    charts.SUBSTANZ_STANDSICHERHEIT
                  ]
                }
                id={charts.SUBSTANZ_STANDSICHERHEIT}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={objektklassifizierungSchutzziele[charts.ZUSTAND_BETRIEB]}
                id={charts.ZUSTAND_BETRIEB}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={objektklassifizierungSchutzziele[charts.SUBSTANZ_BETRIEB]}
                id={charts.SUBSTANZ_BETRIEB}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={
                  objektklassifizierungSchutzziele[charts.ZUSTAND_DICHTHEIT]
                }
                id={charts.ZUSTAND_DICHTHEIT}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />
              <Chart
                data={
                  objektklassifizierungSchutzziele[charts.SUBSTANZ_DICHTHEIT]
                }
                id={charts.SUBSTANZ_DICHTHEIT}
                typeView={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
              />

            </Row>
          </Container>
        </div>
        <InOutCarat />
        <Map
          view={VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}
          typeDashboard={typeDashboard}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const diagrams = diagramsViews[VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    return {
      objektklassifizierungSchutzziele:
        state.objektklassifizierungSchutzziele.data,
      filtersApplied: state.filters.filtersApplied,
      //reload: state.filters.reload || state.chartSettings.dirty,
      project: state.projects.project,
      bucket: state.projects.bucket,
      units: units,
      scales: scales,
      mapMaximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      mapMinimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
    };
  },
  { getObjektklassifizierungSchutzziele }
)(ObjektklassifizierungSchutzziele);
