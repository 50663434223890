import {
  SET_ACTIVE_OBJECT,
  SET_PROJECT,
  SET_BUCKET,
  APPLY_FILTER_TABLE_OBJECTS
} from "../../constants/ActionTypes";

const initialState = {
  idObjectActive: undefined
};

export default function objectview(state = initialState, action = {}) {
  switch (action.type) {
    // Remark if project is set, reset the state.
    case SET_ACTIVE_OBJECT:
      return {
        ...initialState,
        idObjectActive: action.idObject
      };
    case APPLY_FILTER_TABLE_OBJECTS:
      return {
        ...state,
        idObjectActive: initialState.idObjectActive
      };
    default:
      return state;
    case SET_PROJECT:
      return initialState;
    case SET_BUCKET:
      return initialState;
  }
}
