/**
 * Pie component.
 * @module components/charts/Pie/Pie
 */

import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import {
  Col,
  Card,
  Button,
  CardBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { map, isEqual } from "lodash";
import { connect } from "react-redux";
import { labels } from "../../../constants/Filters";
import { setDetailviewOpen } from "../../../actions/detailview/detailview";
import {
  setChartUnit,
  setChartScale,
  toggleMaximized,
  toggleMinimized,
} from "../../../actions/chartSettings/chartSettings";
import { units, scales } from "../../../constants/chartSettings";

import {
  calcDifferences,
  mapClassesConvention,
} from "../../../helpers/charts/common_functions";

import { diagramsViews } from "../../../constants/Dashboards";

import {
  CHART_TEMPORAL_HALF_PIE,
  CHART_BAR,
  CHART_LEGEND_PIE,
  CHART_LINE,
  CHART_LINE_DIFFERENCE,
  CHART_PIE,
  CHART_STACKED_BAR,
  CHART_TABLE,
} from "../../../constants/TypesCharts";
import {
  CHARTS_MAP_FOR_CONVENTION,
  CHARTS_MAP_CORRELATED_FOR_CONVENTION,
} from "../../../constants/ClassConventions";

import {
  applyFilter,
  unsetFilter,
  setFilterApplied,
} from "../../../actions/filters/filters";
import BarFilterSetter from "../../elements/Filterpanel/BarFilterSetter";

import BarGeneric from "../BarGeneric/BarGeneric";
import BarHorizontalGeneric from "../BarHorizontalGeneric/BarHorizontalGeneric";
import PieGeneric from "../PieGeneric/PieGeneric";
import LegendPieGeneric from "../LegendPieGeneric/LegendPieGeneric";
import StackedBarGeneric from "../StackedBarGeneric/StackedBarGeneric";
import LineGeneric from "../LineGeneric/LineGeneric";
import TemporalHalfPieGeneric from "../TemporalHalfPieGeneric/TemporalHalfPieGeneric";
import TableGeneric from "../TableGeneric/TableGeneric";

import { add_label_to_data } from "../../../helpers/charts/common_functions";

import ReactTooltip from "react-tooltip";
import {
  VIEW_GRUNDDATEN_STRATEGY,
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
} from "../../../constants/NamesViews";

const SvgSaver = require("svgsaver");

/**
 * Pie component class.
 * @class Pie
 * @extends Component
 */
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      dropdownOpen: false,
    };

    this.onToggleModal = this.onToggleModal.bind(this);
    this.refChart = React.createRef();
    this.refRangeBarMin = React.createRef();
    this.refRangeBarMax = React.createRef();
    this.saveImage = this.saveImage.bind(this);
    this.saveTableCsv = this.saveTableCsv.bind(this);
  }

  static propTypes = {
    id: PropTypes.string,
    showDetailButton: PropTypes.bool,
    showUnitButton: PropTypes.bool,
    showScaleButton: PropTypes.bool,
    showMaximizeButton: PropTypes.bool,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    id: "",
    showDetailButton: true,
    showUnitButton: true,
    showScaleButton: true,
    showMaximizeButton: true,
    showSaveImageButton: true,
    showButtonCsvExportTable: true,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const drawMark = this.checkDrawMark(nextProps);
    const maximized = this.props.isMaximized !== nextProps.isMaximized;
    const minimized = this.props.isMinimized !== nextProps.isMinimized;
    const newFilters =
      nextProps.filterApplied !== this.props.filterApplied ||
      nextProps.filterSet !== this.props.filterSet;
    const changedSettings =
      !isEqual(this.props.scale, nextProps.scale) ||
      !isEqual(this.props.unit, nextProps.unit);
    const newData = this.props.data !== nextProps.data;
    const markChanged =
      drawMark && this.props.positionMark !== nextProps.positionMark;
    const changedDrawMark = drawMark !== this.checkDrawMark(this.props);
    const modalToggled = this.state.showModal !== nextState.showModal;

    return (
      newData ||
      newFilters ||
      changedSettings ||
      maximized ||
      minimized ||
      markChanged ||
      modalToggled ||
      changedDrawMark
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Ensure that the unit is alway set to an available value
    // TODO: also do this for scale
    const unitsUse = this.getDefaultsIfMissing(nextProps.unitsDisplay, units);

    if (unitsUse !== undefined) {
      const keysUnits = Object.keys(unitsUse);
      if (!keysUnits.includes(nextProps.unit)) {
        this.props.setChartUnit(nextProps.id, keysUnits[0]);
      }
    }
    const scalesUse = this.getDefaultsIfMissing(
      nextProps.scalesDisplay,
      scales
    );

    if (scalesUse !== undefined) {
      const keysScales = Object.keys(scalesUse);
      if (!keysScales.includes(nextProps.scale)) {
        this.props.setChartScale(nextProps.id, keysScales[0]);
      }
    }
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    // Copy applied filters to set ones initially.
    this.props.setFilterApplied(this.props.id);
  }

  checkDrawMark(props) {
    return (
      props.id === props.idChartStrategy ||
      (props.idChartDifferences !== undefined &&
        props.id !== undefined &&
        props.id.replace("Difference", "") ===
          props.idChartDifferences.replace("Difference", "")) ||
      (props.diagramColor !== undefined &&
        props.id !== undefined &&
        props.id.replace("Difference", "") ===
          props.diagramColor.replace("Difference", ""))
    );
  }

  saveImage() {
    if (this.refChart.current) {
      var svgsaver = new SvgSaver();
      var svg = ReactDOM.findDOMNode(
        this.refChart.current
      ).getElementsByTagName("svg")[0];
      svgsaver.asSvg(svg, `${this.props.id}.svg`);
    }
  }

  saveTableCsv(data, value) {
    let rows = [data.namesColumns, ...data.rows];

    let csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((e) => e.join(value)).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${this.props.id}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    cookie.save("separatorDownload", value);
  }
  onToggleModal(event) {
    //    event.preventDefault();
    this.setState({
      showModal: true,
    });
  }
  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  fixNulls(typeChart) {
    // Fix nulls and provide indicator showing count of unknown values
    // Different regarding data layout
    if (
      [CHART_PIE, CHART_BAR, "HalfPie", CHART_LEGEND_PIE].includes(typeChart)
    ) {
      return this.filterNullsFromData(this.props.data);
    } else if (
      [CHART_STACKED_BAR, CHART_LINE, CHART_LINE_DIFFERENCE].includes(
        typeChart
      ) &&
      this.props.data !== undefined
    ) {
      var numInvalid = 0;
      var dataCleaned = [];
      // Iterate over all layers in multi-layer dataset
      for (var dataset of this.props.data) {
        const datasetCleaned = this.filterNullsFromData(dataset.data);
        // Use max of individual layers invalid elements
        // TODO: is this reasonable?
        numInvalid = Math.max(datasetCleaned.numInvalid, numInvalid);
        dataCleaned.push({ ...dataset, data: datasetCleaned.data });
      }
      return { numInvalid: numInvalid, data: dataCleaned };
    } else if (typeChart === CHART_TABLE) {
      // TODO: integrate into filterNullsFromData
      let numInvalid = 0;
      const datasetCleaned = this.props.data.rows.map((row) => {
        if (row[0] !== undefined && row[0] !== null) {
          return row;
        } else {
          numInvalid += row.slice(1).reduce((a, b) => a + b, 0);
          return ["Ungültig", ...row.slice(1)];
        }
      });
      dataCleaned = {
        namesColumns: this.props.data.namesColumns,
        rows: datasetCleaned,
      };
      return { numInvalid: numInvalid, data: dataCleaned };
    } else {
      return { numInvalid: 0, data: this.props.data };
    }
  }

  calcSum(typeChart) {
    if (
      [CHART_PIE, CHART_BAR, "HalfPie", CHART_LEGEND_PIE].includes(typeChart)
    ) {
      return this.sumValuesDataset(this.props.data);
    } else if (
      [CHART_STACKED_BAR, CHART_LINE, CHART_LINE_DIFFERENCE].includes(
        typeChart
      ) &&
      this.props.data !== undefined
    ) {
      var sum = 0;
      // Iterate over all layers in multi-layer dataset
      for (var dataset of this.props.data) {
        const sumDataset = this.sumValuesDataset(dataset.data);
        // Use max of individual layers sum
        // TODO: is this reasonable?
        sum = Math.max(sumDataset, sum);
      }
      return sum;
    } else if (typeChart === CHART_TABLE) {
      // TODO: integrate into filterNullsFromData
      var sums = Object.fromEntries(
        this.props.data.namesColumns.slice(1).map((name) => [name, 0])
      );
      this.props.data.rows.map((row) => {
        if (row[0] !== undefined && row[0] !== null) {
          row.slice(1).map((value, index) => {
            sums[Object.keys(sums)[index]] += value;
          });
        }
      });
      return sums;
    } else {
      return 0;
    }
  }

  calcAvg(typeChart) {
    if ([CHART_BAR].includes(typeChart)) {
      return this.avgValuesDataset(this.props.data);
    } else {
      return 0;
    }
  }

  calcMedian(typeChart) {
    if ([CHART_BAR].includes(typeChart)) {
      return this.medianValueDataset(this.props.data);
    } else {
      return undefined;
    }
  }

  filterNullsFromData(dataset) {
    var numInvalid = 0;
    var dataCleaned;
    if (dataset !== undefined) {
      dataCleaned = dataset.filter((datapoint) => {
        const isValid = datapoint.x !== null;
        if (!isValid) numInvalid += datapoint.y;
        return isValid;
      });
    } else {
      dataCleaned = [];
    }
    return { numInvalid: numInvalid, data: dataCleaned };
  }

  sumValuesDataset(dataset) {
    let sum = 0;
    if (dataset !== undefined) {
      dataset.map((datapoint) => {
        const isValid = datapoint.x !== null;
        if (isValid) sum += datapoint.y;
      });
    }
    return sum;
  }

  avgValuesDataset(dataset) {
    let avg = 0;
    let sum = 0;
    if (dataset !== undefined) {
      dataset.map((datapoint) => {
        const isValid = datapoint.x !== null;
        if (isValid) sum += datapoint.y * datapoint.x;
      });
    }
    avg = sum / this.sumValuesDataset(this.props.data);
    return avg.toFixed(0);
  }

  //  avgValuesDataset (dataset){
  //    let avg = 0;
  //    let totalX = 0
  //    if (dataset !== undefined) {
  //      dataset.map((datapoint) => {
  //        const isValid = datapoint.x !== null;
  //
  //        for (const [key, value] of Object.entries(datapoint)) {
  //            if (key === "x" && isValid){
  //                totalX = totalX + 1
  //            }
  //        }
  //      });
  //    }
  //    console.log('totalX: ' + totalX);
  //    if (totalX !== 0){
  //      avg = this.sumValuesDataset(this.props.data)/totalX;
  //    }
  //    return avg.toFixed(0);
  //  }

  medianValueDataset(dataset) {
    let median = 0;
    let yArray = [];
    let half = 0;
    if (dataset !== undefined) {
      dataset.map((datapoint) => {
        const isValid = datapoint.x !== null;
        for (const [key, value] of Object.entries(datapoint)) {
          if (key === "x" && isValid) {
            yArray.push(value);
          }
        }
      });
    }
    yArray.sort(function (a, b) {
      return a - b;
    });
    half = Math.floor(yArray.length / 2);
    if (yArray.length % 2) median = yArray[half];
    else median = (yArray[half - 1] + yArray[half]) / 2.0;
    //    console.log("array " + yArray);
    return median.toFixed(0);
  }

  renderChart(typeChart, data) {
    const {
      id,
      tickFormat,
      paddingTickValues,
      tickValues,
      positionMark,
      metadataProject,
      disable_convention_mapping,
    } = this.props;
    // Obtain the chart type and label based on the ID
    // Render the chart based on which type this is
    // TODO: Chart types should be constants

    let dataLabelled = add_label_to_data(
      data,
      id,
      typeChart,
      metadataProject.ref
    );

    // If the chart must be mapped according to class convention,
    //  there are multiple cases:
    //  - the chart must be mapped directly (5-value)
    //  - the chart shows a correlated value of a strategy chart that must be mapped.
    //    then, it is also 5 - value
    //  - the chart shows a corrrelated difference value from strategievergleich.
    //    Then, the sign must be inverted
    //  - The chart is linechart showing differences. THen, sign must be inverted.

    // TODO: the data should not be manipulated directly, but something like a label
    //  should be used so that colorization is still consistent using our values.
    //  but this needs infrastructure in the charts itself and good concept.
    //  as usual, we don't have time for this now.

    // Mapping is disabled for table in detailview because there, the columns are not individual charts but x and y of one chart,
    // so that the transformation cannot be applied here.
    // TODO: Further generalize the class convention transformation implementation so that this workaroudn is not needed
    //  anymore.
    if (!disable_convention_mapping) {
      if (CHARTS_MAP_FOR_CONVENTION.includes(id)) {
        dataLabelled = mapClassesConvention(
          metadataProject.class_convention,
          dataLabelled,
          typeChart,
          typeChart === CHART_LINE_DIFFERENCE
        );
      } else if (
        this.props.typeView === VIEW_GRUNDDATEN_STRATEGY &&
        CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
          this.props.idChartStrategy
        )
      ) {
        dataLabelled = mapClassesConvention(
          metadataProject.class_convention,
          dataLabelled,
          typeChart,
          false
        );
      } else if (
        this.props.typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON &&
        CHARTS_MAP_CORRELATED_FOR_CONVENTION.includes(
          this.props.idChartDifferences
        )
      ) {
        dataLabelled = mapClassesConvention(
          metadataProject.class_convention,
          dataLabelled,
          typeChart,
          true
        );
      }
    }

    switch (typeChart) {
      case CHART_PIE:
        return (
          <PieGeneric id={id} data={dataLabelled} reference={this.refChart} />
        );
      case CHART_BAR:
        return (
          <BarGeneric
            id={id}
            data={dataLabelled}
            reference={this.refChart}
            positionMark={positionMark}
            refRangeBarMin={this.refRangeBarMin}
            refRangeBarMax={this.refRangeBarMax}
            typeView={this.props.typeView}
          />
        );
      case "BarHorizontal":
        return (
          <BarHorizontalGeneric
            id={id}
            data={dataLabelled}
            reference={this.refChart}
            typeView={this.props.typeView}
          />
        );
      case CHART_LINE:
        return (
          <LineGeneric
            id={id}
            data={dataLabelled}
            tickFormat={tickFormat}
            tickValues={tickValues}
            paddingTickValues={paddingTickValues}
            reference={this.refChart}
            positionMark={
              this.checkDrawMark(this.props) ? positionMark : undefined
            }
            typeView={this.props.typeView}
          />
        );
      case CHART_LINE_DIFFERENCE:
        // Generate difference Data
        // TODO: Handle case that not exactly two layers are provided
        return (
          <LineGeneric
            id={id}
            data={calcDifferences(dataLabelled)}
            tickFormat={tickFormat}
            tickValues={tickValues}
            paddingTickValues={paddingTickValues}
            reference={this.refChart}
            positionMark={
              this.checkDrawMark(this.props) ? positionMark : undefined
            }
            typeView={this.props.typeView}
          />
        );
      case CHART_STACKED_BAR:
        return (
          <StackedBarGeneric
            id={id}
            data={dataLabelled}
            reference={this.refChart}
            positionMark={
              this.checkDrawMark(this.props) ? positionMark : undefined
            }
            typeView={this.props.typeView}
          />
        );
      case "HalfPie":
        return (
          <PieGeneric
            id={id}
            data={dataLabelled}
            isHalf={true}
            reference={this.refChart}
            typeView={this.props.typeView}
          />
        );
      case CHART_TEMPORAL_HALF_PIE:
        return (
          <TemporalHalfPieGeneric
            id={id}
            data={dataLabelled}
            reference={this.refChart}
            typeView={this.props.typeView}
          />
        );
      case CHART_LEGEND_PIE:
        return (
          <LegendPieGeneric
            id={id}
            data={dataLabelled}
            reference={this.refChart}
            typeView={this.props.typeView}
          />
        );
      case CHART_TABLE:
        return (
          <TableGeneric
            idsColumns={dataLabelled.namesColumns}
            rows={dataLabelled.rows}
            typeView={this.props.typeView}
          />
        );
      default:
    }
  }

  getDefaultsIfMissing(values, default_) {
    return values === undefined ? default_ : values;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      id,
      filterSet,
      filterApplied,
      showUnitButton,
      showDetailButton,
      showScaleButton,
      prefixLabel,
      typeView,
      typeChart,
      showMaximizeButton,
      isMaximized,
      isMinimized,
      title,
      unit,
      scale,
      showSaveImageButton,
      showButtonCsvExportTable,
      unitsDisplay,
      scalesDisplay,
    } = this.props;

    let titleRender = title;
    if (!titleRender) {
      titleRender = prefixLabel ? prefixLabel.concat(labels[id]) : labels[id];
    }

    const unitsUse = this.getDefaultsIfMissing(unitsDisplay, units);
    const scalesUse = this.getDefaultsIfMissing(scalesDisplay, scales);

    // Demanded render type may vary from the type in the dashboard.
    //  For example when rendering pie as HalfPie in Detailview
    // REMARK: Data must be compatible.
    const typeChartRender =
      typeof typeChart !== "undefined"
        ? typeChart
        : diagramsViews[typeView][id];

    // Show the button if a filter is set and it differs from
    // the one that is applied.
    // it may be set to same as applied for piecharts for example.
    const showApplyFilter = filterSet !== filterApplied;

    // Obtain the class Name for the chart column.
    // TODO: Different column name for detailview? Pass ClassName?
    var classColumn = undefined;
    switch (typeChartRender) {
      case CHART_BAR:
        classColumn = "columnBar";
        break;
      case CHART_PIE:
        classColumn = "columnPie";
        break;
      case CHART_LINE:
        classColumn = "columnBar";
        break;
      case CHART_LINE_DIFFERENCE:
        classColumn = "columnBar";
        break;
      case CHART_STACKED_BAR:
        classColumn = "columnBar";
        break;
      case "HalfPie":
        classColumn = "columnPie";
        break;
      case CHART_TEMPORAL_HALF_PIE:
        classColumn = "columnPie";
        break;
      case CHART_LEGEND_PIE:
        classColumn = "columnPie";
        break;
      case CHART_TABLE:
        classColumn = "columnTable";
        break;
      case "BarHorizontal":
        classColumn = "columnBar";
        break;
      default:
        classColumn = undefined;
    }

    // if maximized, add maximized class
    if (isMaximized || isMinimized) classColumn += " chartMaximized";

    let classChartDiv = "chartDiv";
    if (isMinimized) classChartDiv += " minimized";

    // Clean data from Nulls and get count of nulls to display
    //  Remark: use typeChart from diagramsDashboards to reflect data Format.
    var resultsFixed = this.fixNulls(typeChartRender);

    if (resultsFixed.data === undefined) {
      return null;
    }

    const sumValues = this.calcSum(typeChartRender);
    const avgValues = this.calcAvg(typeChartRender);
    const medianValues = this.calcMedian(typeChartRender);

    const showMaximizeButtonRender = showMaximizeButton && !isMinimized;

    return (
      <Col
        className={classColumn}
        sm={isMinimized ? { order: 12, size: 12 } : false}
      >
        <div className={classChartDiv}>
          <h4 className="chart-title">
            <Button
              className="ButtonChartTitle"
              onClick={() =>
                showMaximizeButtonRender && this.props.toggleMaximized(id)
              }
            >
              {titleRender}
            </Button>
            {(showDetailButton || showUnitButton || showScaleButton) && (
              <UncontrolledDropdown size="sm" style={{ display: "inline" }}>
                <DropdownToggle tag="span">
                  <span
                    className="la la-ellipsis-h"
                    style={{
                      cursor: "pointer",
                      color: "#2162a0",
                      marginLeft: 10,
                    }}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {showScaleButton &&
                    map(Object.keys(scalesUse), (key_scale) => (
                      <DropdownItem
                        key={key_scale}
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.setChartScale(id, key_scale)}
                      >
                        <span
                          className={
                            scale === key_scale
                              ? "dropdown-chart-menu-selected"
                              : undefined
                          }
                        >
                          {scalesUse[key_scale]}
                        </span>
                      </DropdownItem>
                    ))}
                  {showUnitButton && (
                    <React.Fragment>
                      <DropdownItem divider />
                      {map(Object.keys(unitsUse), (key_unit) => (
                        <DropdownItem
                          key={key_unit}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.props.setChartUnit(id, key_unit)}
                        >
                          <span
                            className={
                              unit === key_unit
                                ? "dropdown-chart-menu-selected"
                                : undefined
                            }
                          >
                            {unitsUse[key_unit]}
                          </span>
                        </DropdownItem>
                      ))}
                    </React.Fragment>
                  )}
                  {showSaveImageButton && (
                    <React.Fragment>
                      <DropdownItem divider />
                      <DropdownItem
                        key="saveImage"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.saveImage()}
                      >
                        Bild speichern
                      </DropdownItem>
                    </React.Fragment>
                  )}
                  {showDetailButton && (
                    <React.Fragment>
                      <DropdownItem divider />
                      <DropdownItem
                        key="detail"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.setDetailviewOpen(
                            true,
                            id,
                            typeChartRender,
                            typeView
                          )
                        }
                      >
                        Detail
                      </DropdownItem>
                    </React.Fragment>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            <Button
              className="maximizeChart"
              onClick={() => this.props.toggleMinimized(id)}
            >
              <span
                className={
                  this.props.isMinimized ? "la la-plus" : "la la-minus"
                }
              />
            </Button>
            {typeChartRender === CHART_BAR && (
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  marginBottom: "1px",
                }}
              >
                <BarFilterSetter
                  idChart={id}
                  data={this.props.data}
                  refInputMin={this.refRangeBarMin}
                  refInputMax={this.refRangeBarMax}
                />
              </div>
            )}
            {typeChart === CHART_TABLE && showButtonCsvExportTable && (
              <Button
                className="csvExportTable"
                //                onClick={() => this.saveTableCsv(resultsFixed.data)}
                onClick={this.onToggleModal}
              >
                <span className={"la la-download"} />
              </Button>
            )}
            {(!!sumValues || resultsFixed.numInvalid > 0) && (
              <span
                data-tip
                data-for={titleRender}
                style={{
                  cursor: "pointer",
                  color: "#2162a0",
                  marginLeft: 10,
                }}
                className="la la-info-circle"
              >
                <ReactTooltip
                  place="bottom"
                  id={titleRender}
                  type={"info"}
                  // className="tooltipWarningNulls"
                  delayHide={0}
                  delayShow={0}
                  // effect={"solid"}
                >
                  {!!sumValues && (
                    <span>
                      Summe (ohne ungültige): {JSON.stringify(sumValues)} <br />
                      Mittelwert (ohne ungültige): {avgValues} <br />
                      Median : {medianValues}
                      <br />
                    </span>
                  )}
                  {resultsFixed.numInvalid > 0 && (
                    <span>Ungültige Werte: {resultsFixed.numInvalid}</span>
                  )}
                </ReactTooltip>
              </span>
            )}
            {showApplyFilter && (
              <div style={{ display: "inline" }}>
                <Button
                  className="filter-apply"
                  onClick={() => this.props.unsetFilter(this.props.id)}
                >
                  <span className="la la-undo" />
                </Button>
                <Button
                  className="filter-apply"
                  onClick={() => this.props.applyFilter(this.props.id)}
                >
                  {/* <span className="la la-check" /> */}
                  Filter
                </Button>
              </div>
            )}
          </h4>
          <Card className="chartCard">
            <CardBody style={{ padding: "0px", margin: "0px" }}>
              {this.renderChart(typeChartRender, resultsFixed.data)}
            </CardBody>
          </Card>
        </div>
        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.showModal}
          toggle={this.onToggleModal}
        >
          <ModalHeader className="dataDownloadModal">
            Export Einstellung
          </ModalHeader>
          <ModalBody>
            <select
              value={cookie.load("separatorDownload")}
              ref="downloadSetting"
              /* todo ensure that cookies is not loaded at each render
               */
            >
              <option value=";">Semicolon</option>
              <option value=",">Comma </option>
              <option value={`\t`}>Tab</option>
            </select>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() =>
                this.saveTableCsv(
                  resultsFixed.data,
                  this.refs.downloadSetting.value
                )
              }
            >
              Download
            </Button>
            <Button color="secondary" onClick={this.hideModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}
export default connect(
  (state, props) => ({
    filterApplied: state.filters.filtersApplied[props.id],
    filterSet: state.filters.filtersSet[props.id],
    unit: state.chartSettings.unit[props.id],
    scale: state.chartSettings.scale[props.id],
    isMaximized: state.chartSettings.maximized.includes(props.id),
    isMinimized: state.chartSettings.minimized.includes(props.id),
    idChartDifferences: state.strategieVergleich.idChartDifferences,
    idChartStrategy: state.strategie.idChart,
    diagramColor: state.map.diagramColor,
    metadataProject: state.projects.metadataProject,
  }),
  {
    setDetailviewOpen,
    applyFilter,
    unsetFilter,
    setChartUnit,
    setChartScale,
    setFilterApplied,
    toggleMaximized,
    toggleMinimized,
  }
)(Chart);


//
//            {(id == "baujahr" || id == "alter") && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Mittelwert: " + avgValues}
//              </div>
//            )}

//{(id == "baujahr" || id == "alter") && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Median: " + medianValues}
//              </div>
//            )}
//            {(id == "restnutzungsdauer" || id == "verifikation") && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Mittelwert: " + avgValues}
//              </div>
//            )}
//            {(id == "restnutzungsdauer" || id == "verifikation") && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Median: " + medianValues}
//              </div>
//            )}
//            {id == "sanierungslaenge" && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Mittelwert: " + avgValues}
//              </div>
//            )}
//            {id == "sanierungslaenge" && (
//              <div
//                style={{
//                  color: "#fff",
//                  backgroundColor: "#8499b5",
//                  width: "Auto",
//                  display: "inline-block",
//                  marginLeft: "8px",
//                  borderRadius: "5px",
//                  padding: "3px",
//                }}
//              >
//                {"Median: " + medianValues}
//              </div>
//            )}
