/**
 * StrategieVergleich actions.
 * @module actions/strategieVergleich/strategieVergleich
 */

import { merge } from "lodash";

import {
  GET_STRATEGIEVERGLEICH,
  SET_STRATEGIES_COMPARISON,
  SET_YEAR_STRATEGY_COMPARISON,
  GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON,
  SET_ID_CHART_DIFFERENCES,
  SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH,
  SET_GROUP_CHARTS_STRATEGIEVERGLEICH,
} from "../../constants/ActionTypes";
import { parseParams } from "../../helpers/url/url";
import { parsePrependParams } from "../../helpers/Api/ParsePrependParams";

/**
 * Get strategievergleich.
 * @function getStrategieVergleich
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @params {Array} strategiesComparisonIds IDs for Strategies to compare.
 * @returns {Object} Get strategie action.
 */
export function getStrategieVergleich(
  id,
  bucket,
  filters,
  scales,
  units,
  strategiesComparisonIds,
  typeDashboard
) {
  return {
    type: GET_STRATEGIEVERGLEICH,
    request: {
      op: "get",
      path: `strategie-vergleich/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        strategiesComparisonIds ? { strategiesComparisonIds } : {},
        { typeDashboard: typeDashboard }
      ),
    },
  };
}

export function getGrunddatenDifferences(
  id,
  bucket,
  filters,
  scales,
  units,
  strategiesComparisonIds,
  typeDashboard,
  yearStrategy,
  idChartDifferences
) {
  return {
    type: GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON,
    request: {
      op: "get",
      path: `grunddaten-strategie-vergleich/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        { typeDashboard: typeDashboard },
        { yearStrategy: yearStrategy },
        strategiesComparisonIds ? { strategiesComparisonIds } : {},
        { idChartDifferences: idChartDifferences }
      ),
    },
  };
}

/**
 * Set strategies to compare.
 * @function setStrategiesComparison
 * @param {Array} ids Strategy ids.
 * @returns {Object} Set strategies action.
 */
export function setStrategiesComparison(ids) {
  return {
    type: SET_STRATEGIES_COMPARISON,
    ids: ids,
  };
}

export function setYearStrategy(year) {
  return {
    type: SET_YEAR_STRATEGY_COMPARISON,
    year: year,
  };
}

export function setIdChartDifferences(idChart) {
  return {
    type: SET_ID_CHART_DIFFERENCES,
    idChart: idChart,
  };
}

export function setGroupCharts(nameGroupCharts) {
  return {
    type: SET_GROUP_CHARTS_STRATEGIEVERGLEICH,
    nameGroupCharts: nameGroupCharts,
  };
}

export function setShowDifferences(showDifferences) {
  return {
    type: SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH,
    showDifferences,
  };
}
