/**
 * Api middleware.
 * @module middleware/api
 */

import cookie from "react-cookie";
import jwtDecode from "jwt-decode";

import { LOGIN } from "../constants/ActionTypes";

/**
 * Api middleware.
 * @function
 * @param {Object} api Api object.
 * @returns {Promise} Action promise.
 */
export default api => ({ dispatch, getState }) => next => action => {
  if (typeof action === "function") {
    return action(dispatch, getState);
  }

  const { request, type, ...rest } = action;
  let actionPromise;

  if (!request) {
    return next(action);
  }

  next({ ...rest, type: `${type}_PENDING` });

  actionPromise = Array.isArray(request)
    ? Promise.all(
        request.map(item =>
          api[item.op](item.path, { params: item.params, data: item.data })
        )
      )
    : api[request.op](request.path, {
        params: request.params,
        data: request.data
      });
  actionPromise.then(
    result => {
      if (type === LOGIN) {
        cookie.save("auth_token", result.token, {
          path: "/",
          expires: new Date(jwtDecode(result.token).exp * 1000)
        });
      }
      return next({ ...rest, result, type: `${type}_SUCCESS` });
    },
    error => next({ ...rest, error, type: `${type}_FAIL` })
  );

  return actionPromise;
};
