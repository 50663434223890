import { CLASS_CONVENTION_DWA } from "../../constants/ClassConventions";

import {
  CHART_BAR_HORIZONTAL,
  CHART_BAR,
  CHART_LINE,
  CHART_LINE_DIFFERENCE,
  CHART_TABLE,
} from "../../constants/TypesCharts";

export function getMinStepsizeX(values) {
  let stepSize = values[1] - values[0];
  values.slice(1).forEach((value, idx, array) => {
    const stepSizeCurrent = value - values[idx - 1];
    stepSize = stepSizeCurrent < stepSize ? stepSizeCurrent : stepSize;
  });
  return stepSize;
}

export function calcDifferences(data) {
  const valuesX = getValuesX(data);

  // if the data contains exactly two lines, construct difference.
  let dataDifference = [{ name: "Differenz", data: [] }];
  if (data.length === 2) {
    const differences = valuesX.reduce((collector, value, index) => {
      if (data[0].data[index] && data[1].data[index]) {
        let dataset = {
          x: valuesX[index],
          y: data[0].data[index].y - data[1].data[index].y,
        };
        collector.push(dataset);
      } else {
        let dataset = {
          x: valuesX[index],
          y: null,
        };
        collector.push(dataset);
      }
      return collector;
    }, []);
    dataDifference = [{ name: "Differenz", data: differences }];
  }
  return dataDifference;
}

/**
 * Obtain all x Values of the longest dataset in an array of datasets
 * @method getValuesX
 * @param {Array} dataChart Array of objects with property data
 * @returns {Array} X values
 */
export function getValuesX(dataChart) {
  // Obtain length of longest dataset
  const maxNumData = Math.max(
    ...dataChart.map((dataset) => dataset.data.length)
  );

  return dataChart.length
    ? dataChart
        .find((data) => data.data.length === maxNumData)
        .data.map((element) => element.x)
    : [];
}

/**
 * Get a strategy's title from ID
 * @method setStrategyCompare
 * @param {Integer} id The strategy's ID
 * @returns {String} The title
 */
export function getNameStrategy(id, strategies) {
  const strategy = strategies.find((strategy) => strategy.id === id);
  return strategy ? strategy.title : "";
}

export function add_label_x_xy_list(data, mapping) {
  return data.map((dataset) => ({
    ...dataset,
    label: mapValue(mapping, dataset.x),
  }));
}

export function add_label_list_layers(data, mapping) {
  return data.map((layer) => ({
    ...layer,
    label: mapValue(mapping, layer.name),
  }));
}

export function add_label_table(data, mapping) {
  // Iterate through all rows and replace each field
  const rowsLabelled = data.rows.map((row) =>
    row.map((value) => {
      return mapValue(mapping, value);
    })
  );

  return {
    ...data,
    rowsLabelled,
  };
  // const rowsMapped =  data.rows.map((row) => ({
  //   ...row,
  //   label: mapping[layer.name],
  // }));
}

// TODO: Consider case that multiple values are mapped to one label.
//  Data has to be merged then. In addition, when filtering, this has to be
//   Reversed. For example, A and B are mapped to LABEL_A, when the pie
//    section with LABEL_A is filtered, the filter must be set as [A, B]
export function add_label_to_data(data, idChart, typeChart, mappingLabels) {
  if (mappingLabels.hasOwnProperty(idChart) && data !== undefined) {
    // TODO: consider other data types than list of x y
    if (["Bar", "Pie", "LegendPie"].includes(typeChart)) {
      return add_label_x_xy_list(data, mappingLabels[idChart]);
    } else if (["StackedBar", "TemporalHalfPie"].includes(typeChart)) {
      return add_label_list_layers(data, mappingLabels[idChart]);
    } else if (["Table"].includes(typeChart)) {
      return add_label_table(data, mappingLabels[idChart]);
    } else {
      return data;
    }
  } else {
    return data;
  }
}

export function mapValue(mapping, value) {
  return mapping.hasOwnProperty(value) ? mapping[value] : value;
}

export function getScaleDisplayDifferences(
  showDifferences,
  idChart,
  scalesDifferencesAvg,
  scalesDifferencesSum
) {
  // Get units to display in chart dropdown in difference views
  //  for strategy and strategy comparison.
  // Displayed units are depending on whether the selected
  //  difference chart shows a summed or average difference.
  // if no differences are shown, the units are undefined so
  //  that the default units are used by the chart.
  let scalesDisplay = undefined;
  if (showDifferences && idChart.includes("Avg")) {
    scalesDisplay = scalesDifferencesAvg;
  } else if (showDifferences && idChart.includes("Sum")) {
    scalesDisplay = scalesDifferencesSum;
  }
  return scalesDisplay;
}

export function formatTick(value) {
  return !(Math.abs(value) < 10000) || (Math.abs(value) < 0.01 && value !== 0)
    ? value/1000
    : value;
}

export function formatTickBar(value) {
  return !(Math.abs(value) < 10000000) || (Math.abs(value) < 0.01 && value !== 0)
    ? value.toExponential(1)
    : value;
}
export function formatTickForStackBar(value) {
  return !(Math.abs(value) < 100) || (Math.abs(value) < 0.01 && value !== 0)
    ? value/1000
    : value;
}

export function getUnitsUse(unitsDisplay, units) {
  return unitsDisplay === undefined ? units : unitsDisplay;
}

export function mergeDataLabelled(data, groupsLabels) {
  // TODO: catch case that no label is present properly

  if (data[0] === undefined || data[0].label === undefined) {
    return data.map((datapoint) => {
      return { ...datapoint, x: [datapoint.x], label: datapoint.x };
    });
  }

  // Multiple datapoints may have the same labels.
  // Those datapoints should be displayed as one segment in the chart.
  //  Hence, the data is merged.

  // in the new data, x will be a list of all s values in the input data
  // that is mapped to the labels. This is then used to invert he mapping for
  // filtering.

  // First, collect the labels as groups

  // let labelsUnique = data.reduce(
  //   (labels, datapoint) => labels.includes(datapoint.label) ? labels : [...labels, datapoint.label],
  //   []
  // )

  const dataMergedMap = data.reduce(
    (dataMerged, datapoint) =>
      dataMerged[datapoint.label] !== undefined
        ? {
            ...dataMerged,
            [datapoint.label]: dataMerged[datapoint.label] + datapoint.y,
          }
        : { ...dataMerged, [datapoint.label]: datapoint.y },
    {}
  );

  const result = Object.entries(dataMergedMap).map((labelAndY) => {
    return {
      x:
        labelAndY[0] in groupsLabels
          ? groupsLabels[labelAndY[0]]
          : labelAndY[0],
      y: labelAndY[1],
      label: labelAndY[0],
    };
  });

  return result;

  // Map all datapints to the labels
}

export function mapClassesConvention(
  convention,
  dataChart,
  typeChart,
  mapByInverting
) {
  if (convention !== CLASS_CONVENTION_DWA) {
    return dataChart;
  }

  // TODO: MAP TABLE

  // We only map Bar Charts and line charts for now.
  // TODO: generalize the concept to map any chart type's data.
  //  But important: colorization should always be based on the unmapped values.

  switch (typeChart) {
    case CHART_BAR_HORIZONTAL:
    case CHART_BAR:
      return mapClassesXYList(convention, dataChart, mapByInverting);
    case CHART_TABLE:
      // TODO: Do not simply map all data. Data must be mapped
      //  for each column individually becaue each column represents
      //  something like an individual chart (except detailview, where the
      //  mapping is handled separately). Hence, the current mapping approach
      //  does not work for Table, so rewrite it.
      return {
        ...dataChart,
        rows: dataChart.rows.map((row) =>
          row.map((value) => {
            return mapClassConvention(convention, value, mapByInverting);
          })
        ),
      };
    case CHART_LINE_DIFFERENCE:
    case CHART_LINE:
      return dataChart.map((layer) => ({
        ...layer,
        data: mapClassesXYList(convention, layer.data, mapByInverting),
      }));
    default:
      return dataChart;
  }
}

export function mapClassesXYList(convention, data, mapByInverting) {
  if (convention !== CLASS_CONVENTION_DWA) {
    return data;
  }

  // Inverting is done for correlated difference charts for
  //  strategievergleich
  return data.map((dataset) => ({
    ...dataset,
    y: mapClassConvention(convention, dataset.y, mapByInverting),
  }));
}

export function mapClassConvention(convention, value, mapByInverting) {
  if (convention !== CLASS_CONVENTION_DWA) {
    return value;
  }
  return mapByInverting ? -value : 5 - value;
}

// TODO: implement function to invert the

export function formatLabelUnit(label, unit) {
  return unit !== null ? `${label} [${unit}]` : label;
}
