import * as charts from "./Charts";
import * as namesViews from "./NamesViews";

// TODO: this should not be named filters but labels.
//  what a stupid name. Congrats, kitconcept! Much awesome!

/**
 * Filtes labels.
 * @module constants/Filters
 */

export const labels = {
  // Grunddaten
  [charts.BAUJAHR]: "Baujahr",
  [charts.MATERIALGRUPPE]: "Materialgruppe",
  [charts.MITTLERE_TIEFE]: "Mittlere Tiefe",
  [charts.KANALNUTZUNG]: "Kanalnutzung",
  [charts.HALTUNGSLAENGE]: "Haltungslänge",
  [charts.NENNWEITE]: "Nennweite",
  [charts.LAGE_IM_VERKEHRSRAUM]: "Lage im Verkehrsraum",
  [charts.PROFILART]: "Profilart",
  [charts.KANALART]: "Kanalart",
  [charts.RESTNUTZUNGSDAUER]: "Restnutzungsdauer",
  [charts.ALTER]: "Alter",
  [charts.SANIERUNGSLAENGE]: "Sanierungslaenge",
  [charts.VERIFIKATION]: "Verifikation",

  // Inspektionsdaten
  [charts.INSPEKTIONSLAENGE]: "Inspektionslänge",
  [charts.INSPEKTIONSGRUND]: "Inspektionsgrund",
  [charts.INSPEKTIONSJAHR]: "Inspektionsjahr",
  [charts.INSPEKTIONSART]: "Inspektionsart",
  [charts.KODIERSYSTEM]: "Kodiersystem",

  // Schadenansicht
  [charts.SCHADENSKLASSE_DICHTHEIT]: "Dichtheit",
  [charts.SCHADENSKLASSE_STANDSICHERHEIT]: "Standsicherheit",
  [charts.SCHADENSKLASSE_BETRIEB]: "Betriebssicherheit",
  [charts.SCHADENSART]: "Schadensart",

  // Objektansicht: Substanz
  [charts.SUBSTANZKLASSE]: "Substanzklasse",
  [charts.ZUSTANDSKLASSE]: "Zustandsklasse",
  [charts.SUBSTANZ_INSPEKTIONSJAHR]: "Inspektionsjahr",

  // Objektansicht: Schutzziele
  [charts.ZUSTAND_STANDSICHERHEIT]: "S.sicherheit (Zustand)",
  [charts.SUBSTANZ_STANDSICHERHEIT]: "S.sicherheit (Substanz)",
  [charts.ZUSTAND_BETRIEB]: "Betrieb (Zustand)",
  [charts.SUBSTANZ_BETRIEB]: "Betrieb (Substanz)",
  [charts.ZUSTAND_DICHTHEIT]: "Dichtheit (Zustand)",
  [charts.SUBSTANZ_DICHTHEIT]: "Dichtheit (Substanz)",
  [charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR]: "Inspektionsjahr",

  // Strategie
  //zustandStandsicherheitStrategie: "Standsicherheit (Zustand)",
  //substanzStandsicherheitStrategie: "Standsicherheit (Substanz)",
  //zustandBetriebStrategie: "Betrieb (Zustand)",
  //substanzBetriebStrategie: "Betrieb (Substanz)",
  //zustandDichtheitStrategie: "Dichtheit (Zustand)",
  //substanzDichtheitStrategie: "Dichtheit (Substanz)",
  [charts.ZUSTANDSKLASSE_STRATEGIE]: "Zustandsklasse",
  [charts.ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE]: "Zustandsklasse (Dichtheit)",
  [charts.ZUSTANDSKLASSE_BETRIEB_STRATEGIE]: "Zustandsklasse (Betrieb)",
  [charts.ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE]:
    "Zustandsklasse (Standsicherheit)",
  [charts.SUBSTANZKLASSE_STRATEGIE]: "Substanzklasse",
  [charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE]: "Substanzklasse (Dichtheit)",
  [charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE]: "Substanzklasse (Betrieb)",
  [charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE]:
    "Substanzklasse (Standsicherheit)",
  // gebuehrenanteile: "Gebührenanteile",
  [charts.SANIERUNGEN]: "Sanierungen",
  [charts.SANIERUNGEN_REP]: "Sanierungen (Reparatur)",
  [charts.SANIERUNGEN_REN]: "Sanierungen (Renovierung)",
  [charts.SANIERUNGEN_ERN]: "Sanierungen (Erneuerung)",
  [charts.SANIERUNGSKOSTEN]: "Sanierungskosten",
  [charts.SANIERUNGSKOSTEN_REP]: "Sanierungskosten (Reparatur)",
  [charts.SANIERUNGSKOSTEN_REN]: "Sanierungskosten (Renovierung)",
  [charts.SANIERUNGSKOSTEN_ERN]: "Sanierungskosten (Erneuerung)",
  [charts.RBW_WBK]: "Restbuchwert (WBK)",
  // sanierungskosten: "Sanierungskosten",

  // StrategieVergleich
  [charts.ZUSTAND_AVG]: "Zustandsklasse",
  [charts.SUBSTANZ_AVG]: "Substanz",
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG]: "Substanz, Standsicherheit",
  [charts.SUBSTANZ_DICHTHEIT_AVG]: "Substanz, Dichtheit",
  [charts.SUBSTANZ_BETRIEB_AVG]: "Substanz, Betrieb",
  [charts.ZUSTAND_AVG_DIFFERENCE]: "Zustandsklasse (Differenz)",
  [charts.SUBSTANZ_AVG_DIFFERENCE]: "Substanz (Differenz)",
  [charts.SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE]: "Substanz, Dichtheit (Differenz)",
  [charts.SUBSTANZ_BETRIEB_AVG_DIFFERENCE]: "Substanz, Betrieb (Differenz)",
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE]:
    "Substanz, Standsicherheit (Differenz)",
  // gebuehrenanteileSum: "Gebührenanteile",
  [charts.SANIERUNGEN_SUM]: "Sanierungen",
  [charts.SANIERUNGEN_REP_SUM]: "Sanierungen, Reparatur",
  [charts.SANIERUNGEN_REN_SUM]: "Sanierungen, Renovierung",
  [charts.SANIERUNGEN_ERN_SUM]: "Sanierungen, Erneuerung",
  [charts.SANIERUNGEN_SUM_DIFFERENCE]: "Sanierungen (Differenz)",
  [charts.SANIERUNGEN_REP_SUM_DIFFERENCE]: "Sanierungen, Reparatur (Differenz)",
  [charts.SANIERUNGEN_REN_SUM_DIFFERENCE]:
    "Sanierungen, Renovierung (Differenz)",
  [charts.SANIERUNGEN_ERN_SUM_DIFFERENCE]:
    "Sanierungen, Erneuerung (Differenz)",
  [charts.SANIERUNGSKOSTEN_SUM]: "Sanierungskosten",
  [charts.SANIERUNGSKOSTEN_REP_SUM]: "Sanierungskosten, Reparatur",
  [charts.SANIERUNGSKOSTEN_REN_SUM]: "Sanierungskosten, Renovierung",
  [charts.SANIERUNGSKOSTEN_ERN_SUM]: "Sanierungskosten, Erneuerung",
  [charts.SANIERUNGSKOSTEN_SUM_DIFFERENCE]: "Sanierungskosten (Differenz)",
  [charts.SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE]:
    "Sanierungskosten, Reparatur (Differenz)",
  [charts.SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE]:
    "Sanierungskosten, Renovierung (Differenz)",
  [charts.SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE]:
    "Sanierungskosten, Erneuerung (Differenz)",
  [charts.RBW_WBK_SUM]: "Restbuchwert (WBK)",
  [charts.RBW_WBK_SUM_DIFFERENCE]: "Restbuchwert (WBK, Differenz)",

  // sanierungskostenSum: "Sanierungskosten",

  // Objectview grunddaten
  [charts.OBJEKTBEZEICHNUNG]: "Objektbezeichnung",
  [charts.STRASSE]: "Straße",
  [charts.KNOTENBEZEICHNUNG_UNTEN]: "Knoten Unten",
  [charts.KNOTENBEZEICHNUNG_OBEN]: "Knoten Oben",

  // Objectview inspection table (and subs also for prognosis)
  [charts.DATUM]: "Datum",
  [charts.SUBSTANZ_OBJECTVIEW]: "Substanz",
  [charts.SUBSTANZ_STB_OBJECTVIEW]: "S. Standsicherheit",
  [charts.SUBSTANZ_ECO_OBJECTVIEW]: "S. Dichtheit",
  [charts.SUBSTANZ_CAP_OBJECTVIEW]: "S. Betrieb",

  // additional charts for Objectview object
  [charts.PROGNOSIS_OBJECT_SUBSTANZ]: "Substanz",
  [charts.PROGNOSIS_OBJECT_ZUSTAND]: "Priorität",
  [charts.PROGNOSIS_OBJECT_LEGEND]: "Legende",
  [charts.PROGNOSIS_OBJECT_TABLE]: "Prognose",

  [charts.BODENGRUPPE]: "Bodengruppe",
  [charts.EINSTAUHAEUFIGKEIT]: "Einstauhäufigkeit",
  [charts.EIGENTUM]: "Eigentum",
  [charts.EINZUGSGEBIETSCHLUESSEL]: "Einzugsgebietsschlüssel",
  [charts.FUNKTIONSZUSTAND]: "Funktionszustand",
  [charts.GEBIETSSCHLUESSEL]: "Gebietsschlüssel",
  [charts.GEMEINDESCHLUESSEL]: "Gemeindeschlüssel",
  [charts.GRUNDWASSERSTAND]: "Grundwasserstand",
  [charts.MATERIALART]: "Materialart",
  [charts.ORTSTEIL]: "Ortsteil",
  [charts.ORTSTEILSCHLUESSEL]: "Ortsteilschlüssel",
  [charts.PROFILBREITE]: "Profilbreite",
  [charts.PROFILHOEHE]: "Profilhöhe",
  [charts.UEBERSCHWEMMUNGSGEBIET]: "Überschwemmungsgebiet",
  [charts.WASSERSCHUTZZONE]: "Wasserschutzzone",

  // Specific for objectview object prognosis table
  [charts.FORECAST_YEAR]: "Prognosejahr",
  [charts.PRIO_CLASS_OBJECTVIEW]: "Priorität",
  [charts.PRIO_CLASS_STB_OBJECTVIEW]: "P. Standsicherheit",
  [charts.PRIO_CLASS_ECO_OBJECTVIEW]: "P. Dichtheit",
  [charts.PRIO_CLASS_CAP_OBJECTVIEW]: "P. Betrieb",

  // Map filter
  [charts.MAP_DASHBOARD]: "Karte",
};

// Define the possible filters of each view
export const filtersViews = {
  [namesViews.VIEW_GRUNDDATEN]: [
    charts.BAUJAHR,
    charts.HALTUNGSLAENGE,
    charts.MATERIALGRUPPE,
    charts.MITTLERE_TIEFE,
    charts.KANALNUTZUNG,
    charts.HALTUNGSLAENGE,
    charts.KANALART,
    charts.NENNWEITE,
    charts.LAGE_IM_VERKEHRSRAUM,
    charts.PROFILART,
    charts.RESTNUTZUNGSDAUER,
    charts.ALTER,
    charts.SANIERUNGSLAENGE,
    charts.VERIFIKATION,
  ],
  [namesViews.VIEW_INSPEKTIONSDATEN]: [
    charts.INSPEKTIONSLAENGE,
    charts.INSPEKTIONSGRUND,
    charts.INSPEKTIONSJAHR,
    charts.INSPEKTIONSART,
    charts.KODIERSYSTEM,
  ],
  [namesViews.VIEW_SCHADENSKLASSIFIZIERUNG]: [
    charts.SCHADENSKLASSE_DICHTHEIT,
    charts.SCHADENSKLASSE_STANDSICHERHEIT,
    charts.SCHADENSKLASSE_BETRIEB,
    charts.SCHADENSART,
    charts.SUBSTANZ_INSPEKTIONSJAHR,
  ],
  [namesViews.VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE]: [
    charts.ZUSTAND_STANDSICHERHEIT,
    charts.ZUSTAND_DICHTHEIT,
    charts.ZUSTAND_BETRIEB,
    charts.SUBSTANZ_STANDSICHERHEIT,
    charts.SUBSTANZ_DICHTHEIT,
    charts.SUBSTANZ_BETRIEB,
    charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR,
  ],
  [namesViews.VIEW_OBJEKTKLASSIFIZIERUNG]: [
    charts.SUBSTANZKLASSE,
    charts.ZUSTANDSKLASSE,
  ],
  [namesViews.VIEW_KARTE_DASHBOARD]: [charts.MAP_DASHBOARD],
  [namesViews.VIEW_KARTE_EINZELOBJEKTANSICHT]: [charts.MAP_OBJECTVIEW],
  [namesViews.VIEW_EINZELOBJEKTANSICHT_OBJEKTLISTE]: [charts.EINZELOBJEKTLISTE],
};
