/**
 * Navbar component.
 * @module components/elements/Navbar/Navbar
 */

import React, { Component } from "react";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { find, map, filter } from "lodash";

import PulseLoader from "react-spinners/PulseLoader";

import { viewsDashboardsFilters } from "../../../constants/Dashboards";
import { namesViewsDisplay } from "../../../constants/NamesViews";
import { filtersViews } from "../../../constants/Filters";

import {
  addProject,
  getProjects,
  setProject,
  setBucket,
  getMetadataProject,
} from "../../../actions/projects/projects";

import {
  getFilterPresetUser,
  getFilterPresetProject,
  getFilterPresets,
  storeFilterPreset,
  deleteFilterPresetUser,
  deleteFilterPresetProject,
} from "../../../actions/filters/filters";

import Avatar from "../../../theme/app-assets/images/portrait/small/avatar-s-19.png";
import BrandLogo from "../../../theme/app-assets/images/logo/logo.png";

// TODO: move to constants
const PRESET_USER = "PRESET_USER";
const PRESET_PROJECT = "PRESET_PROJECT";

/**
 * User component class.
 * @class User
 * @extends Component
 */
class User extends Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs EditComponent
   */
  constructor(props) {
    super(props);

    this.defaultNewPreset = {
      newPresetView: "",
      newPresetName: "",
      newPresetIsPrivate: false,
    };

    this.state = {
      ...this.defaultNewPreset,
      modalStorePresetIsOpen: false,
      modalDeletePresetIsOpen: false,
      idPresetDelete: undefined,
      typePresetDelete: undefined,
    };

    this.onToggleStorePreset = this.onToggleStorePreset.bind(this);
    this.onToggleDeletePreset = this.onToggleDeletePreset.bind(this);
    this.onSubmitStorePreset = this.onSubmitStorePreset.bind(this);
    this.onDeletePreset = this.onDeletePreset.bind(this);
    this.onChangePresetIsPrivate = this.onChangePresetIsPrivate.bind(this);
    this.onChangePresetName = this.onChangePresetName.bind(this);
    this.onChangePresetView = this.onChangePresetView.bind(this);
    this.checkFilterViewApplied = this.checkFilterViewApplied.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getProjects();
    // If project ID is known already (e.g. if set by cookie),
    //  fetch the metadata
    this.props.project && this.props.getMetadataProject(this.props.project);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  //  componentWillReceiveProps(nextProps) {
  //    // If a new preset has been saved successfully, close and reset the dialog
  //    if (
  //      this.props.presetStoring &&
  //      nextProps.presetStored &&
  //      !nextProps.presetStoring
  //    ) {
  //      this.setState({
  //        modalStorePresetIsOpen: false,
  //        ...this.defaultNewPreset
  //      });
  //    }
  //  }

  componentDidUpdate(prevProps, prevState) {
    // If a new preset has been saved successfully, close and reset the dialog
    if (
      prevProps.presetStoring &&
      this.props.presetStored &&
      !this.props.presetStoring
    ) {
      this.setState({
        modalStorePresetIsOpen: false,
        ...this.defaultNewPreset,
      });
    }
  }

  onDeletePreset() {
    // TODO: Check if deleting has been successful. Else,
    // do not close modal and show error. Analog to Modal for
    // storing preset
    switch (this.state.typePresetDelete) {
      case PRESET_USER:
        this.props.deleteFilterPresetUser(
          this.props.project,
          this.state.idPresetDelete
        );
        break;
      case PRESET_PROJECT:
        this.props.deleteFilterPresetProject(
          this.props.project,
          this.state.idPresetDelete
        );
        break;
      default:
    }
    this.setState({ modalDeletePresetIsOpen: false });
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  /**
   * On toggle handler
   * @method onToggle
   * @returns {undefined}
   */
  onToggleStorePreset() {
    this.setState({
      modalStorePresetIsOpen: !this.state.modalStorePresetIsOpen,
      ...this.defaultNewPreset,
    });
  }
  onToggleDeletePreset(idPreset, typePreset) {
    this.setState({
      modalDeletePresetIsOpen: !this.state.modalDeletePresetIsOpen,
      typePresetDelete: typePreset,
      idPresetDelete: idPreset,
      ...this.defaultNewPreset,
    });
  }

  onChangePresetName(event) {
    this.setState({
      newPresetName: event.target.value,
    });
  }

  onChangePresetView(event) {
    this.setState({
      newPresetView: event.target.value,
    });
  }

  onChangePresetIsPrivate(event) {
    this.setState({
      newPresetIsPrivate: event.target.checked,
    });
  }

  /**
   * Handler for submitting the form for storing new preset
   * @method onSubmit
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onSubmitStorePreset(event) {
    // obtain all applied filters that are associated with corresponding view
    const filtersView = filtersViews[this.state.newPresetView];
    let filters = {};
    for (var nameFilter in this.props.filtersApplied) {
      if (filtersView.includes(nameFilter)) {
        filters[nameFilter] = this.props.filtersApplied[nameFilter];
      }
    }

    this.props.storeFilterPreset(
      this.props.project,
      this.state.newPresetView,
      this.state.newPresetName,
      this.state.newPresetIsPrivate,
      filters
    );

    event.preventDefault();
  }

  checkFilterViewApplied(idView) {
    // Check if any of the view's possible filters is applied
    const filtersView = filtersViews[idView];
    return Object.keys(this.props.filtersApplied).some((filter) =>
      filtersView.includes(filter)
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { presetsFilters, currentDashboard } = this.props;

    // TODO: Check if using uncontrolled Dropdown is viable here:
    //  When deleting a preset, the presets shall be reloaded and thus
    //  the state is updated, triggering a rerender.
    //  hence, the uncontrolled dropdown cloeses, but maybe it should stay opened
    //  Also, when opening the dropdown, the presets are fetched. When the request finished,
    //  A rerender is triggered, too, which will then close the dropdown, which is not desired.

    return (
      <nav className="user-nav header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center">
        <div className="navbar-wrapper">
          <div className="navbar-header">
            {this.props.showProjects && (
              <ul className="nav navbar-nav flex-row">
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {this.props.projectName}:
                  </DropdownToggle>
                  {this.props.projects.length > 1 && (
                    <DropdownMenu className="dropdown-menuUser">
                      {map(
                        filter(
                          this.props.projects,
                          (project) => project.id !== this.props.project
                        ),
                        (project) => (
                          <DropdownItem
                            key={project.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.setProject(project.id);
                              this.props.getMetadataProject(project.id);
                            }}
                          >
                            {project.name}
                          </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  )}
                </UncontrolledDropdown>
                {this.props.bucketName && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {this.props.bucketName}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menuUser">
                      {map(this.props.buckets, (bucket) => (
                        <DropdownItem
                          key={bucket.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.props.setBucket(bucket.id)}
                        >
                          {bucket.datum}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {currentDashboard && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret
                      onClick={() =>
                        this.props.getFilterPresets(
                          this.props.project,
                          currentDashboard
                        )
                      }
                    >
                      Filter
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menuUser">
                      {Object.keys(presetsFilters).map(
                        (nameView) =>
                          viewsDashboardsFilters[currentDashboard].includes(
                            nameView
                          ) && (
                            <UncontrolledDropdown direction="right">
                              <DropdownToggle
                                nav
                                caret
                                style={{ color: "#2162a0" }}
                              >
                                {namesViewsDisplay[nameView]}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>Privat</DropdownItem>
                                {presetsFilters[nameView].user.map(
                                  (dataPreset) => (
                                    <DropdownItem key={dataPreset.id}>
                                      <span
                                        onClick={() =>
                                          this.props.getFilterPresetUser(
                                            this.props.project,
                                            dataPreset.id,
                                            nameView
                                          )
                                        }
                                      >
                                        {dataPreset.name}
                                      </span>
                                      <span
                                        className="filter-dropdown-remove la la-close"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.onToggleDeletePreset(
                                            dataPreset.id,
                                            PRESET_USER
                                          )
                                        }
                                      />
                                    </DropdownItem>
                                  )
                                )}
                                <DropdownItem divider />
                                <DropdownItem header>Projekt</DropdownItem>
                                {presetsFilters[nameView].project.map(
                                  (dataPreset) => (
                                    <DropdownItem key={dataPreset.id}>
                                      <span
                                        onClick={() =>
                                          this.props.getFilterPresetProject(
                                            this.props.project,
                                            dataPreset.id,
                                            nameView
                                          )
                                        }
                                      >
                                        {dataPreset.name}
                                      </span>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          paddingLeft: "8px",
                                          paddingTop: "2px",
                                          float: "revert",
                                        }}
                                        className="filter-dropdown-remove la la-close"
                                        onClick={() =>
                                          this.onToggleDeletePreset(
                                            dataPreset.id,
                                            PRESET_PROJECT
                                          )
                                        }
                                      />
                                    </DropdownItem>
                                  )
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )
                      )}
                      <DropdownItem divider />

                      <DropdownItem
                        style={{ cursor: "pointer" }}
                        onClick={this.onToggleStorePreset}
                      >
                        Preset speichern
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </ul>
            )}
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left">
                <li className="nav-item">
                  <Link to="/" className="navbar-brand">
                    <img
                      className="brand-logo"
                      alt="modern admin logo"
                      src={BrandLogo}
                      height="27"
                    />
                  </Link>
                </li>
                <li className="nav-item loader">
                  <PulseLoader
                    sizeUnit={"px"}
                    size={10}
                    color={"#dddddd"}
                    loading={this.props.loading}
                  />
                </li>
                <li className="nav-item d-md-none">
                  <Link
                    to="/"
                    className="nav-link open-navbar-container"
                    data-toggle="collapse"
                    data-target="#navbar-mobile"
                  >
                    <i className="la la-ellipsis-v" />
                  </Link>
                </li>
              </ul>
              <ul className="nav navbar-nav float-right user">
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="dropdown-user-link">
                    <span className="mr-1">
                      <span className="user-name text-bold-700">
                        {this.props.fullname}
                      </span>
                    </span>
                    <span className="avatar avatar-online">
                      <img src={Avatar} alt="avatar" height="29" width="29" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu direction={"right"}>
                    {this.props.projectAdmin && (
                      <DropdownItem style={{ padding: 0 }}>
                        <Link to="/users" className="dropdown-item">
                          <i className="ft-users" />
                          Benutzer verwalten
                        </Link>
                      </DropdownItem>
                    )}
                    {this.props.projectAdmin && <DropdownItem divider />}
                    <DropdownItem style={{ padding: 0 }}>
                      <Link to="/logout" className="dropdown-item">
                        <i className="ft-power" />
                        Ausloggen
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.modalStorePresetIsOpen}
          toggle={this.onToggleStorePreset}
          autoFocus={false}
        >
          <ModalHeader toggle={this.onToggleStorePreset}>
            Filterpreset speichern
          </ModalHeader>
          <ModalBody>
            <Form className="form-horizontal" onSubmit={this.onLogin}>
              <FormGroup className="position-relative">
                <Input
                  autoFocus={true}
                  placeholder="Name des Presets"
                  id="name"
                  required="required"
                  value={this.state.newPresetName}
                  onChange={this.onChangePresetName}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Input
                  type="select"
                  id="view"
                  required="required"
                  defaultValue={""}
                  onChange={this.onChangePresetView}
                  style={{ height: "1.65rem" }}
                >
                  <option disabled value="">
                    Filterkategorie selektieren
                  </option>
                  {viewsDashboardsFilters[currentDashboard] &&
                    viewsDashboardsFilters[currentDashboard].map((idView) => {
                      if (this.checkFilterViewApplied(idView)) {
                        return (
                          <option key={idView} value={idView}>
                            {namesViewsDisplay[idView]}
                          </option>
                        );
                      }
                    })}
                </Input>
              </FormGroup>
              <FormGroup className="position-relative">
                <Label check>
                  <Input
                    type="checkbox"
                    id="newPresetIsPrivate"
                    value={this.state.newPresetIsPrivate}
                    onChange={this.onChangePresetIsPrivate}
                    style={{ marginLeft: "1px" }}
                  />
                  <span style={{ marginLeft: "20px" }}> </span> Privat (nur für
                  diesen Benutzer sichtbar)
                </Label>
              </FormGroup>
            </Form>
            {/* {this.props.errorStorePreset && (
              <Alert color="danger">{this.props.errorStorePreset}</Alert>
            )} */}
          </ModalBody>
          {/* // TODO: obtain the view here in order to get this done.
          //  But the view should not be obtained from the whole view viewer.
          //  It should be present here and there, so that no one can get that in  */}
          <ModalFooter>
            <Button
              disabled={
                this.state.newPresetName === "" ||
                this.state.newPresetView === ""
              }
              color="primary"
              onClick={this.onSubmitStorePreset}
            >
              Speichern
            </Button>{" "}
            <Button color="secondary" onClick={this.onToggleStorePreset}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.modalDeletePresetIsOpen}
          toggle={this.onToggleDeletePreset}
        >
          <ModalHeader> Preset Löschen</ModalHeader>
          <ModalBody>Wollen sie das Preset wirklich löschen ? </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDeletePreset}>
              Löschen
            </Button>{" "}
            <Button color="secondary" onClick={this.onToggleDeletePreset}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </nav>
    );
  }
}

export default connect(
  (state) => {
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });
    let bucket = project
      ? find(project.buckets, {
          id: state.projects.bucket,
        })
      : null;
    let buckets = [];
    if (!bucket && project && project.buckets && project.buckets.length > 0) {
      bucket = project.buckets[0];
      buckets = filter(project.buckets, (n, i) => i !== 0);
    } else if (bucket) {
      buckets = filter(
        project.buckets,
        (bucket) => bucket.id !== state.projects.bucket
      );
    }

    const loading =
      state.grunddaten.loading ||
      state.inspektionsdaten.loading ||
      state.schadensklassifizierung.loading ||
      state.objektklassifizierung.loading ||
      state.objektklassifizierungSchutzziele.loading ||
      state.strategie.loading ||
      state.strategie.loadingGrunddaten ||
      state.strategieVergleich.loadingStrategyComparison ||
      state.strategieVergleich.loadingDifferences ||
      state.map.loading ||
      state.objectviewGrunddaten.loadingTableObjects ||
      state.objectviewGrunddaten.loadingInspections ||
      state.objectviewObject.loadingHg ||
      state.objectviewObject.loadingPrognosis ||
      state.objectviewObject.loadingRnd ||
      state.projects.loading ||
      state.filters.presetLoading ||
      state.filters.presetsLoading ||
      state.filters.presetStoring;

    return {
      loading: loading,
      filtersApplied: state.filters.filtersApplied,
      presetsFilters: state.filters.presets,
      presetStoring: state.filters.presetStoring,
      presetStored: state.filters.presetStored,
      errorStorePreset: state.filters.errorStorePreset,
      fullname: state.userSession.fullname,
      admin: state.userSession.admin,
      projectAdmin: project ? project.admin : false,
      projectName: project ? project.name : "",
      token: state.userSession.token,
      project: state.projects.project,
      projects: state.projects.projects,
      bucket: state.projects.bucket,
      buckets: buckets,
      bucketName: bucket ? bucket.datum : false,
      currentDashboard: state.views.dashboard,
    };
  },
  {
    getProjects,
    setProject,
    addProject,
    setBucket,
    storeFilterPreset,
    getFilterPresetUser,
    getFilterPresetProject,
    getFilterPresets,
    deleteFilterPresetUser,
    deleteFilterPresetProject,
    getMetadataProject,
  }
)(User);
