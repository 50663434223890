/**
 * chartSettings reducer.
 * @module reducers/chartSettings/chartSettings
 */

import {
  SET_CHART_SCALE,
  SET_CHARTS_SCALE,
  SET_CHART_UNIT,
  SET_CHARTS_UNIT,
  GET_GRUNDDATEN,
  TOGGLE_MAXIMIZED,
  TOGGLE_MINIMIZED,
  RESET_CHART_SETTINGS,
  SET_PROJECT,
  SET_CHART_UNIT_VALID,
} from "../../constants/ActionTypes";

import { labels } from "../../constants/Filters";

const initialState = {
  // Generate objects with all charts as keys and standard values as values.
  scale: Object.keys(labels).reduce((collector, label) => {
    collector[label] = "abs";
    return collector;
  }, {}),
  unit: Object.keys(labels).reduce((collector, label) => {
    collector[label] = "cnt";
    return collector;
  }, {}),
  minimized: [],
  maximized: [],
  dirty: false,
  unitsAreValid: Object.keys(labels).reduce((collector, label) => {
    collector[label] = true;
    return collector;
  }, {}),
};

/**
 * chartSettings reducer.
 * @function chartSettings
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function chartSettings(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CHART_SCALE:
      return {
        ...state,
        scale: {
          ...state.scale,
          [action.idChart]: action.value,
        },
        dirty: true,
      };
    case SET_CHART_UNIT:
      return {
        ...state,
        unit: {
          ...state.unit,
          [action.idChart]: action.value,
        },
        dirty: true,
      };
    case SET_CHARTS_UNIT:
      const units = action.idsCharts.reduce(
        (accumulator, id) => {
          accumulator[id] = action.value;
          return accumulator;
        },
        { ...state.unit }
      );
      return {
        ...state,
        unit: units,
        dirty: true,
      };
    case SET_CHARTS_SCALE:
      const scales = action.idsCharts.reduce(
        (accumulator, id) => {
          accumulator[id] = action.value;
          return accumulator;
        },
        { ...state.unit }
      );
      return {
        ...state,
        scale: scales,
        dirty: true,
      };
    case SET_CHART_UNIT_VALID:
      return {
        ...state,
        unitsAreValid: {
          ...state.unitsAreValid,
          [action.idChart]: action.isValid,
        },
        dirty: true,
      };
    case TOGGLE_MAXIMIZED:
      let maximized = undefined;
      if (state.maximized.includes(action.idChart)) {
        maximized = state.maximized.filter((id) => id !== action.idChart);
      } else {
        maximized = state.maximized.concat([action.idChart]);
      }
      return {
        ...state,
        maximized: maximized,
      };
    case TOGGLE_MINIMIZED:
      let minimized = undefined;
      if (state.minimized.includes(action.idChart)) {
        minimized = state.minimized.filter((id) => id !== action.idChart);
      } else {
        minimized = state.minimized.concat([action.idChart]);
      }
      return {
        ...state,
        minimized: minimized,
      };
    case `${GET_GRUNDDATEN}_SUCCESS`:
      return {
        ...state,
        dirty: false,
      };
    case `${RESET_CHART_SETTINGS}`:
      return initialState;
    case `${SET_PROJECT}`:
      return initialState;
    default:
      return state;
  }
}
