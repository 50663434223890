import {
  COLOR_TYPE_DISCRETE,
  COLOR_TYPE_GRADIENT,
  COLOR_FALLBACK,
  mapping_colors_gradient,
  mapping_colors_discrete,
  mapping_types_colors,
  mapping_colors_barcharts_default,
  barFiltered,
} from "../../constants/Colors";

var space = require("color-space");

export function getColorBarDefault(idDiagram, value, rangeFilter) {
  if (rangeFilter === undefined) {
    return mapping_colors_barcharts_default[idDiagram];
  } else if (
    (rangeFilter[0] !== "" &&
      rangeFilter[1] !== "" &&
      rangeFilter[0] <= value &&
      value <= rangeFilter[1]) ||
    (rangeFilter[0] === "" &&
      rangeFilter[1] !== "" &&
      value <= rangeFilter[1]) ||
    (rangeFilter[0] !== "" && rangeFilter[1] === "" && rangeFilter[0] <= value)
  ) {
    return mapping_colors_barcharts_default[idDiagram];
  } else {
    return barFiltered;
  }
}

export function getColor(idDiagram, value, isMap, rangeFilter) {
  let idDiagram_use = idDiagram;
  if (isMap === true) {
    // Search for specific mapping for the map
    const idDiagram_map = `${idDiagram}_map`;
    if (mapping_types_colors[idDiagram_map] !== undefined) {
      idDiagram_use = idDiagram_map;
    }
  }
  const type_color = mapping_types_colors[idDiagram_use];
  if (typeof type_color === "undefined") {
    return COLOR_FALLBACK;
  }

  let color;
  if (type_color === COLOR_TYPE_DISCRETE) {
    color = getColorDiscrete(idDiagram_use, value);
  } else if (type_color === COLOR_TYPE_GRADIENT) {
    const colorGradient = getColorGradient(idDiagram_use, value);
    if (rangeFilter === undefined) {
      color = colorGradient;
    } else if (rangeFilter[0] <= value && value <= rangeFilter[1]) {
      color = colorGradient;
    } else {
      color = barFiltered;
    }
  }
  return color;
}

function getColorDiscrete(idDiagram, value) {
  // // TODO: Does it work with the ||?
  //

  // In case of mapped labels, value may be an array.
  // for now, colorize by first element.
  const valueUse = Array.isArray(value) ? value[0] : value;

  return mapping_colors_discrete[idDiagram][valueUse] || COLOR_FALLBACK;
}

function getColorGradient(idDiagram, value) {
  if (value == null) {
    return COLOR_FALLBACK;
  }

  // todo: build nice color map according to Kovesi 2015
  const colors_chart = mapping_colors_gradient[idDiagram];

  const color_min = colors_chart.color_min;
  const color_max = colors_chart.color_max;
  const color_center = colors_chart.color_center;

  const value_min = colors_chart.value_min;
  const value_max = colors_chart.value_max;

  // calculate position of value normed to scale
  const value_normed = (value - value_min) / (value_max - value_min);

  // clamp position to 0 and 1
  const value_normed_clamped = Math.min(Math.max(value_normed, 0), 1);

  // If the gradient has a center point, separate upper and lower gradient.
  let value_, color_min_, color_max_;
  if (color_center !== undefined) {
    const isUpper = value_normed_clamped >= 0.5;
    value_ = isUpper
      ? (value_normed_clamped - 0.5) * 2
      : value_normed_clamped * 2;
    color_min_ = isUpper ? color_center : color_min;
    color_max_ = isUpper ? color_max : color_center;
  } else {
    value_ = value_normed_clamped;
    color_min_ = color_min;
    color_max_ = color_max;
  }

  const color_interpolated = interpolateColor(
    color_min_,
    color_max_,
    value_,
    "sRGB",
    "hsl" // todo: make colorspace configurable
  );

  const color_interpolated_string = getRgbStringFromColor(color_interpolated);

  return color_interpolated_string;
}

function interpolateColor(
  vec_color_min,
  vec_color_max,
  value_normed,
  colorspaceSource,
  colorspaceConversion
) {
  // obtain vectors of min and max colors
  //const vec_color_min = getColorFromRgbString(color_min, colorspace);
  //const vec_color_max = getColorFromRgbString(color_max, colorspace);

  const vec_color_min_converted = convertColorspace(
    vec_color_min,
    colorspaceSource,
    colorspaceConversion
  );
  const vec_color_max_converted = convertColorspace(
    vec_color_max,
    colorspaceSource,
    colorspaceConversion
  );

  // interpolate position of value_normed between min and max color
  let color_interpolated = [];
  for (const [idx, component_min] of vec_color_min_converted.entries()) {
    const component_max = vec_color_max_converted[idx];
    color_interpolated.push(
      component_min + (component_max - component_min) * value_normed
    );
  }

  return convertColorspace(
    color_interpolated,
    colorspaceConversion,
    colorspaceSource
  );

  // convert resulting color vector to an sRGB hex string
  // todo: or is there a better format for the colors?
}

// function getColorFromRgbString(string_rgb, colorspaceTarget) {
//   const r = parseInt(string_rgb.substring(1, 3), 16);
//   const g = parseInt(string_rgb.substring(3, 5), 16);
//   const b = parseInt(string_rgb.substring(5, 7), 16);
//
//   let color_rgb = [r, g, b];
//
//   return convertColorspace(color_rgb, "sRGB", colorspaceTarget);
// }

function convertColorspace(vecColor, spaceSource, spaceTarget) {
  if (spaceSource === spaceTarget) {
    return vecColor;
  }

  // For some reason, sRGB is called rgb in webland
  if (spaceSource.toLowerCase() === "srgb") {
    spaceSource = "rgb";
  }
  if (spaceTarget.toLowerCase() === "srgb") {
    spaceTarget = "rgb";
  }

  return space[spaceSource.toLowerCase()][spaceTarget.toLowerCase()](vecColor);
}

function getRgbStringFromColor(vec_color) {
  return `rgb(${Math.round(vec_color[0])}, ${Math.round(
    vec_color[1]
  )}, ${Math.round(vec_color[2])})`;
}
