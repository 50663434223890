/**
 * Detailview componnt.
 * @module components/elements/Detailview/Detailview
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { getChangelog } from "../../../actions/changelog/changelog";

/**
 * Detailview component class. Represents a detailed view for a diagram.
 * @class Detailview
 * @extends Component
 */
class Changelog extends Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  componentDidMount() {
    this.props.getChangelog();
  }

  renderVersion(contentVersion) {
    return (
      <div>
        <h2>{contentVersion.version}</h2>
        <ul>
          {contentVersion.entries.map(entry => (
            <li key={entry.id}>{entry}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        {this.props.content.map(contentVersion =>
          this.renderVersion(contentVersion)
        )}
      </div>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { isOpen, onToggle, className, version, content } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        className={className}
        style={{ marginTop: 70, width: "60vw", maxWidth: "90vw" }}
      >
        <ModalHeader toggle={onToggle}>Aktuelle Version: {version}</ModalHeader>
        {content && (
          <ModalBody
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto"
            }}
          >
            {this.renderContent()}
          </ModalBody>
        )}
      </Modal>
    );
  }
}

export default connect(
  state => {
    return {
      content: state.changelog.content,
      version: state.changelog.version
    };
  },
  {
    getChangelog
  }
)(Changelog);
