import {
  GET_HG_OBJECT,
  GET_RND_OBJECT,
  GET_PROGNOSIS_OBJECT,
  OBJECT_RESET_DATA,
  GET_IDS_OBJECTS_NODE_UPPER,
  GET_IDS_OBJECTS_NODE_LOWER,
  RESET_IDS_OBJECTS_NODE_LOWER,
  RESET_IDS_OBJECTS_NODE_UPPER
} from "../../constants/ActionTypes";

export function getHgObject(idProject, objectId) {
  return {
    type: GET_HG_OBJECT,
    request: {
      op: "get",
      path: `objectview-object-hg/${idProject}`,
      params: {
        objectId
      }
    }
  };
}

export function resetData(idProject, objectId) {
  return {
    type: OBJECT_RESET_DATA
  };
}

export function getRndObject(idProject, objectId) {
  return {
    type: GET_RND_OBJECT,
    request: {
      op: "get",
      path: `objectview-object-rnd/${idProject}`,
      params: {
        objectId
      }
    }
  };
}

export function getPrognosisObject(idProject, objectId) {
  return {
    type: GET_PROGNOSIS_OBJECT,
    request: {
      op: "get",
      path: `objectview-object-prognosis/${idProject}`,
      params: {
        objectId
      }
    }
  };
}

export function getIdsObjectsNodeUpper(
  idProject,
  bucket,
  node,
  filters,
  limit
) {
  return {
    type: GET_IDS_OBJECTS_NODE_UPPER,
    request: {
      op: "get",
      path: `objectview-grunddaten-table-objects/${idProject}`,
      params: {
        ...filters,
        knotenbezeichnung_unten: node,
        bucket: bucket,
        numObjectsPage: limit,
        numPage: 1
      }
    }
  };
}

export function getIdsObjectsNodeLower(
  idProject,
  bucket,
  node,
  filters,
  limit
) {
  return {
    type: GET_IDS_OBJECTS_NODE_LOWER,
    request: {
      op: "get",
      path: `objectview-grunddaten-table-objects/${idProject}`,
      params: {
        ...filters,
        knotenbezeichnung_oben: node,
        bucket: bucket,
        numObjectsPage: limit,
        numPage: 1
      }
    }
  };
}

export function resetIdsObjectsNodeUpper() {
  return {
    type: RESET_IDS_OBJECTS_NODE_UPPER
  };
}

export function resetIdsObjectsNodeLower() {
  return {
    type: RESET_IDS_OBJECTS_NODE_LOWER
  };
}
