/**
 * FoldableSidebar component.
 * @module components/elements/FoldableSidebar/FoldableSidebar
 */

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";
import { map, isEmpty, isEqual } from "lodash";

import { setSidebarOpen } from "../../../actions/grunddaten/grunddaten";
import {
  applyFilters,
  removeFilters,
  removeFilter
} from "../../../actions/filters/filters";
import { labels } from "../../../constants/Filters";

// TODO: whether open should be an argument passed from parent because
//  this is different if this component is used for filterpanel in
//  detailview.

/**
 * FoldableSidebar component class.
 * @class FoldableSidebar
 * @extends Component
 */
class FoldableSidebar extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * On toggle handler
   * @method onToggle
   * @returns {undefined}
   */
  onToggle() {
    this.props.setSidebarOpen(!this.props.open);
  }

  checkFilterSet() {
    // deep comparison
    return !isEqual(this.props.filtersApplied, this.props.filtersSet);
  }

  /**
   * Draw the diagrams in the dashboard
   * @method componentDidMount
   * @returns {undefined}
   */

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const showApplyFilter = this.checkFilterSet();

    const { showFilteringUi, width } = this.props;

    return (
      // TODO: The sidebar div itself should fold and take
      //  its children (basedata). It can then be refactored into individual
      //  component reusable in detailView. Should have title attribute
      //  that can be passed.
      <div>
        <div className={this.props.open ? "open" : "closed"}>
          <div
            className="mainSidebar"
            style={{ width: this.props.open ? `${width}%` : "0%" }}
          >
            <div className="sidebarToggle" onClick={this.onToggle}>
              <span
                className={
                  this.props.open ? "la la-caret-left" : "la la-caret-right"
                }
              />
            </div>
            <div className="mainSidebarContainer">
              {this.props.token && (
                <div className="filter-bar">
                  {this.props.title}
                  {!isEmpty(this.props.filtersApplied) && showFilteringUi && (
                    <UncontrolledDropdown className="filter-dropdown">
                      <DropdownToggle className="filter-dropdown-button">
                        <span>Filter Entfernen</span>
                        <span className="la la-angle-down" />
                      </DropdownToggle>
                      <DropdownMenu right className="dropdownMenuFilter">
                        {map(
                          Object.keys(this.props.filtersApplied),
                          idFilter => (
                            <DropdownItem
                              key={idFilter}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.props.removeFilter(idFilter)}
                            >
                              <span className="filter-dropdown-entry">
                                {labels[idFilter]}
                              </span>
                              <span className="filter-dropdown-remove la la-close" />
                            </DropdownItem>
                          )
                        )}
                        <DropdownItem divider />
                        <DropdownItem
                          key={"RemoveAll"}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.props.removeFilters()}
                        >
                          <span className="filter-dropdown-entry">
                            Alle Filter entfernen
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                  {showApplyFilter && showFilteringUi && (
                    <Button
                      className="filter-apply"
                      onClick={() => this.props.applyFilters()}
                    >
                      <span className="la la-check" />
                      Alle Filter anwenden
                    </Button>
                  )}
                </div>
              )}
              <Container className="containerDiagrams">
                {this.props.children}
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      open: state.grunddaten.sidebarIsOpen,
      filtersApplied: state.filters.filtersApplied,
      filtersSet: state.filters.filtersSet,
      token: state.userSession.token
    };
  },
  {
    applyFilters,
    removeFilters,
    removeFilter,
    setSidebarOpen
  }
)(FoldableSidebar);
