/**
 * Projects actions.
 * @module actions/projects/projects
 */

import {
  ADD_PROJECT,
  GET_PROJECTS,
  SET_PROJECT,
  SET_BUCKET,
  GET_METADATA_PROJECT,
} from "../../constants/ActionTypes";

/**
 * Get projects.
 * @function getProjects
 * @returns {Object} Get projects action.
 */
export function getProjects() {
  return {
    type: GET_PROJECTS,
    request: {
      op: "get",
      path: "projects",
    },
  };
}

/**
 * Get project metadata.
 * @function getProjects
 * @returns {Object} Get projects action.
 */
export function getMetadataProject(id) {
  return {
    type: GET_METADATA_PROJECT,
    request: {
      op: "get",
      path: `meta/${id}`,
    },
  };
}

/**
 * Add project.
 * @function addProject
 * @param {Number} id Project id.
 * @param {String} name Project name.
 * @returns {Object} Add project action.
 */
export function addProject(id, name) {
  return {
    type: ADD_PROJECT,
    request: {
      op: "post",
      path: "projects",
      data: { id, name },
    },
  };
}

/**
 * Set project.
 * @function setProject
 * @param {Number} id Project id.
 * @returns {Object} Set project action.
 */
export function setProject(id) {
  return {
    type: SET_PROJECT,
    id,
  };
}

/**
 * Set bucket.
 * @function setBucket
 * @param {Number} id Bucket id.
 * @returns {Object} Set bucket action.
 */
export function setBucket(id) {
  return {
    type: SET_BUCKET,
    id,
  };
}
