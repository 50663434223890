/**
 * schadensklassifizierung actions.
 * @module actions/schadensklassifizierung/schadensklassifizierung
 */

import { merge } from "lodash";

import { GET_SCHADENSKLASSIFIZIERUNG } from "../../constants/ActionTypes";
import { parseParams } from "../../helpers/url/url";
import { parsePrependParams } from "../../helpers/Api/ParsePrependParams";

/**
 * Get schadensklassifizierung.
 * @function getSchadensklassifizierung
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @returns {Object} Get schadensklassifizierung action.
 */
export function getSchadensklassifizierung(
  id,
  bucket,
  filters,
  scales,
  units,
  typeDashboard
) {
  return {
    type: GET_SCHADENSKLASSIFIZIERUNG,
    request: {
      op: "get",
      path: `schadensklassifizierung/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        { typeDashboard: typeDashboard }
      )
    }
  };
}
