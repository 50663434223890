import * as charts from "./Charts";
import {
  scalesStrategyAvg,
  unitsStrategyComparison,
  scalesStrategySum,
} from "./chartSettings";

export const PRESETS_STRATEGIEVERGLEICH = {
  Gesamtansicht: {
    [charts.ZUSTAND_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SUBSTANZ_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SUBSTANZ_BETRIEB_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SUBSTANZ_DICHTHEIT_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SUBSTANZ_STANDSICHERHEIT_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_REP_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_REN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_ERN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_REP_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_REN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_ERN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.RBW_WBK_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
  },
  Kompakt: {
    [charts.ZUSTAND_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SUBSTANZ_AVG]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.RBW_WBK_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategyAvg,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
  },
  Sanierungen: {
    [charts.SANIERUNGEN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_REP_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_REN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGEN_ERN_SUM]: {
      showUnitButton: true,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: undefined,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_REP_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_REN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
    [charts.SANIERUNGSKOSTEN_ERN_SUM]: {
      showUnitButton: false,
      showScaleButton: false,
      scalesDisplay: scalesStrategySum,
      unitsDisplay: unitsStrategyComparison,
      showDetailButton: true,
    },
  },
};
