import { HashRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./components/views/App/App";
import store from "./store";

import "./theme/app-assets/css/vendors.css";
import "./theme/app-assets/css/app.css";
import "./theme/app-assets/css/core/menu/menu-types/horizontal-menu.css";
import "./theme/assets/css/style.css";

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
