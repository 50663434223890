/**
 * Map actions.
 * @module actions/map/map
 */

import { merge } from "lodash";

import {
  GET_MAP,
  SET_DIAGRAM_COLOR_MAP,
  SET_MAP_COLOR_RENDERING,
  SET_MAP_VIEWPORT,
  GET_OBJECT_DATA,
  RESET_MAP,
  SET_MAP_DASHBOARDS_HEIGHT,
  SET_MAP_DASHBOARDS_MAXIMIZED,
  SET_MAP_DASHBOARDS_MINIMIZED,
  SELECT_MAP_LAYER
} from "../../constants/ActionTypes";
import { parseParams } from "../../helpers/url/url";

/**
 * Get map.
 * @function getMap
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @params {Object} viewport Viewport.
 * @params {string} type Type.
 * @returns {Object} Get map action.
 */
export function getMap(
  id,
  bucket,
  filters,
  viewport,
  diagramColor,
  typeDashboard,
  strategiesComparisonIds,
  strategyDisplayId,
  yearStrategy
) {
  return {
    // todo: diagram_type must be changed to the diagram id also in api!
    type: GET_MAP,
    request: {
      op: "get",
      path: `map/${id}`,
      params: merge(
        {},
        viewport,
        parseParams(filters),
        bucket ? { bucket } : {},
        diagramColor !== "" ? { diagram_type: diagramColor } : {},
        { typeDashboard: typeDashboard },
        { strategiesComparisonIds, strategyDisplayId, yearStrategy }
      )
    }
  };
}

/**
 * Set height of map in dashboards
 * @function setMapDashboardsHeight
 * @params {float} height height in percent
 * @returns {Object} setMapDashboardsHeight action.
 */
export function setMapDashboardsHeight(height) {
  return {
    type: SET_MAP_DASHBOARDS_HEIGHT,
    height: height
  };
}

/**
 * Set maximized state of map in dashboards
 * @function setMapDashboardsMaximized
 * @params {bool} maximized state
 * @returns {Object} setMapDashboardsHeight action.
 */
export function setMapDashboardsMaximized(maximized) {
  return {
    type: SET_MAP_DASHBOARDS_MAXIMIZED,
    maximized: maximized
  };
}
export function setMapDashboardsMinimized(minimized) {
  return {
    type: SET_MAP_DASHBOARDS_MINIMIZED,
    minimized: minimized
  };
}

/**
 * Set diagram for displayed color
 * @function setDiagramColor
 * @params {string} idChart chart ID to colorize diagram with.
 * @returns {Object} setDiagramColor action.
 */
export function setDiagramColor(idChart) {
  return {
    type: SET_DIAGRAM_COLOR_MAP,
    idChart: idChart
  };
}

/**
 * Set map color rendering
 * @function setColorRendering
 * @params {colorRendering} whether to draw map in greyscale
 * @returns {Object} setColorRendering action.
 */
// TODO: Better use different color rendering keys instead of greyscale or not.
export function setColorRendering(colorRendering) {
  return {
    type: SET_MAP_COLOR_RENDERING,
    colorRendering: colorRendering
  };
}

/**
 * Set map color rendering
 * @function setColorRendering
 * @params {colorRendering} whether to draw map in greyscale
 * @returns {Object} setColorRendering action.
 */
// TODO: Better use different color rendering keys instead of greyscale or not.
export function setViewport(viewport) {
  return {
    type: SET_MAP_VIEWPORT,
    viewport: viewport
  };
}

/**
 * Obtain data of object for popup rendering
 * @function getObjectData
 * @params {objectId} whether to draw map in greyscale
 * @returns {Object} getObjectData action.
 */
// TODO: Better use different color rendering keys instead of greyscale or not.
export function getDataObject(
  idProject,
  objectId,
  layer,
  feature,
  setState,
  setIdObjectActive
) {
  return {
    type: GET_OBJECT_DATA,
    layer: layer,
    feature: feature,
    setState: setState,
    objectId: objectId,
    styleInitial: { ...layer.options },
    request: {
      op: "get",
      path: `mapObject/${idProject}`,
      params: {
        objectId
      }
    }
  };
}
export function resetMap() {
  return {
    type: RESET_MAP
  };
}
export function selectMapLayer(mapLayer) {
  return {
    type: SELECT_MAP_LAYER,
    mapLayer: mapLayer
  };
}
