/**
 * views actions.
 * @module actions/views/views
 */

import { SET_CURRENT_DASHBOARD } from "../../constants/ActionTypes";

/**
 * Set current dashboard.
 * @function setCurrentDashboard
 * @param {array} idChart Id of the chart
 * @returns {Object} Set chart unit.
 */
export function setCurrentDashboard(dashboard) {
  return {
    type: SET_CURRENT_DASHBOARD,
    dashboard: dashboard
  };
}
