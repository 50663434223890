import * as charts from "./Charts";
import * as chartSettings from "./chartSettings";
import { formatLabelUnit } from "../helpers/charts/common_functions";

// Axis labels for charts. See issue #187
// They are hardcoded here, but should be provided by API in the future.

// For each chart, different labels may exist for all chart settings.

// Units of the entity each chart represents.
// First entry is what the x axis shows for a bar chart.
// There may be additional units. For example, in a stacked bar chart,
// the data is 3D. There, the first unit is the dimension of the entity
// (e.g. Euro for Sanierungskosten), displayed in the stacked bar's y-axis,
//  and the second unit is what is displayed on the x-axis (e.g. Prognosejahr)
export const UNITS_CHARTS = {
  // Categorical base data charts for correlated plots
  [charts.MATERIALGRUPPE]: {
    unit: [null],
  },
  [charts.KANALNUTZUNG]: {
    unit: [null],
  },
  [charts.KANALART]: {
    unit: [null],
  },
  [charts.LAGE_IM_VERKEHRSRAUM]: {
    unit: [null],
  },
  [charts.PROFILART]: {
    unit: [null],
  },
  // continuouscharts
  [charts.BAUJAHR]: {
    unit: ["Jahr"],
  },
  [charts.MITTLERE_TIEFE]: {
    unit: ["m"],
  },
  [charts.ALTER]: {
    unit: ["Jahr"],
  },
  [charts.RESTNUTZUNGSDAUER]: {
    unit: ["Jahr"],
  },
  [charts.SANIERUNGSLAENGE]: {
    unit: ["m"],
  },
  [charts.VERIFIKATION]: {
    unit: ["% Punkte der subtenz"],
  },
  [charts.HALTUNGSLAENGE]: {
    unit: ["m"],
  },
  [charts.NENNWEITE]: {
    unit: ["mm"],
  },
  [charts.INSPEKTIONSLAENGE]: {
    unit: ["m"],
  },
  [charts.INSPEKTIONSJAHR]: {
    unit: ["Jahr"],
  },
  [charts.SUBSTANZ_INSPEKTIONSJAHR]: {
    unit: ["Jahr"],
  },
  [charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR]: {
    unit: ["Jahr"],
  },
  [charts.ZUSTANDSKLASSE_STRATEGIE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZKLASSE_STRATEGIE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.RBW_WBK]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.ZUSTAND_AVG]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZ_AVG]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_BETRIEB_AVG]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_DICHTHEIT_AVG]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.ZUSTAND_AVG_DIFFERENCE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SUBSTANZ_AVG_DIFFERENCE]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_BETRIEB_AVG_DIFFERENCE]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE]: {
    unit: ["%", "Prognosejahr"],
  },
  [charts.SANIERUNGEN_SUM]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_REP_SUM]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_REN_SUM]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_ERN_SUM]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_SUM_DIFFERENCE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_REP_SUM_DIFFERENCE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_REN_SUM_DIFFERENCE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGEN_ERN_SUM_DIFFERENCE]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_SUM]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_REP_SUM]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_REN_SUM]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_ERN_SUM]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_SUM_DIFFERENCE]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.RBW_WBK_SUM]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.RBW_WBK_SUM_DIFFERENCE]: {
    unit: ["t€", "Prognosejahr"],
  },
  [charts.PROGNOSIS_OBJECT_SUBSTANZ]: {
    unit: [null, "Prognosejahr"],
  },
  [charts.PROGNOSIS_OBJECT_ZUSTAND]: {
    unit: [null, "Prognosejahr"],
  },
};

export const CHART_SETTINGS = {
  units: {
    // TODO: Handle additional units for the stragegy comparison
    [chartSettings.UNIT_COUNT]: null,
    [chartSettings.UNIT_LENGTH]: "m",
  },
  scales: {
    // Scalings scale the data and hence modify the unit.
    //  Hence, scalings are functions that return the axis label of the dependent value.
    // TODO: handle null units
    [chartSettings.SCALE_RELATIVE]: (nameUnit, unit) => `Anteil [Anzahl]`,
    [chartSettings.SCALE_ABSOLUTE]: (nameUnit, unit) =>
      formatLabelUnit(nameUnit, unit),
    [chartSettings.SCALE_AVERAGE]: (nameUnit, unit) =>
      formatLabelUnit(`Durchschn.\n ${nameUnit}`, unit),
    [chartSettings.SCALE_SUM]: (nameUnit, unit) =>
      formatLabelUnit(`Summe\n ${nameUnit}`, unit),
    [chartSettings.SCALE_AVERAGE_DIFFEREENCE]: (nameUnit, unit) =>
      formatLabelUnit(`D. D. ${nameUnit}`, unit),
    [chartSettings.SCALE_COUNT_AVERAGE_DIFFERENCE]: (nameUnit, unit) =>
      `D. D. ${nameUnit} gew. Anzahl`,
    [chartSettings.SCALE_LENGTH_AVERAGE_DIFFERENCE]: (nameUnit, unit) =>
      `D. D. ${nameUnit} gew. Länge`,
    [chartSettings.SCALE_SUM_DIFFERENCE]: (nameUnit, unit) =>
      formatLabelUnit(`Summe  d. Differenz ${nameUnit}`, unit),
  },
};
//  this are the labels used for charts title (blue button
export const labels = {
  // Grunddaten
  [charts.BAUJAHR]: "Baujahr",
  [charts.MATERIALGRUPPE]: "Materialgruppe",
  [charts.MITTLERE_TIEFE]: "Mittlere Tiefe",
  [charts.KANALNUTZUNG]: "Kanalnutzung",
  [charts.HALTUNGSLAENGE]: "Haltungslänge",
  [charts.NENNWEITE]: "Nennweite",
  [charts.LAGE_IM_VERKEHRSRAUM]: "Lage im Verkehrsraum",
  [charts.PROFILART]: "Profilart",
  [charts.KANALART]: "Kanalart",
  [charts.RESTNUTZUNGSDAUER]: "Restnutzungsdauer",
  [charts.ALTER]: "Alter",
  [charts.SANIERUNGSLAENGE]: "Sanierungslaenge",
  [charts.VERIFIKATION]: "Verifikation",

  // Inspektionsdaten
  [charts.INSPEKTIONSLAENGE]: "Inspektionslänge",
  [charts.INSPEKTIONSGRUND]: "Inspektionsgrund",
  [charts.INSPEKTIONSJAHR]: "Inspektionsjahr",
  [charts.INSPEKTIONSART]: "Inspektionsart",
  [charts.KODIERSYSTEM]: "Kodiersystem",

  // Schadenansicht
  [charts.SCHADENSKLASSE_DICHTHEIT]: "Dichtheit",
  [charts.SCHADENSKLASSE_STANDSICHERHEIT]: "Standsicherheit",
  [charts.SCHADENSKLASSE_BETRIEB]: "Betriebssicherheit",
  [charts.SCHADENSART]: "Schadensart",

  // Objektansicht: Substanz
  [charts.SUBSTANZKLASSE]: "Substanzklasse",
  [charts.ZUSTANDSKLASSE]: "Zustandsklasse",
  [charts.SUBSTANZ_INSPEKTIONSJAHR]: "Inspektionsjahr",

  // Objektansicht: Schutzziele
  [charts.ZUSTAND_STANDSICHERHEIT]: "Standsicherheit (Zustand)",
  [charts.SUBSTANZ_STANDSICHERHEIT]: "Standsicherheit (Substanz)",
  [charts.ZUSTAND_BETRIEB]: "Betrieb (Zustand)",
  [charts.SUBSTANZ_BETRIEB]: "Betrieb (Substanz)",
  [charts.ZUSTAND_DICHTHEIT]: "Dichtheit (Zustand)",
  [charts.SUBSTANZ_DICHTHEIT]: "Dichtheit (Substanz)",
  [charts.SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR]: "Inspektionsjahr",

  // Strategie
  //zustandStandsicherheitStrategie: "Standsicherheit (Zustand)",
  //substanzStandsicherheitStrategie: "Standsicherheit (Substanz)",
  //zustandBetriebStrategie: "Betrieb (Zustand)",
  //substanzBetriebStrategie: "Betrieb (Substanz)",
  //zustandDichtheitStrategie: "Dichtheit (Zustand)",
  //substanzDichtheitStrategie: "Dichtheit (Substanz)",
  [charts.ZUSTANDSKLASSE_STRATEGIE]: "Zustandsklasse",
  [charts.ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE]: "Zustandsklasse (Dichtheit)",
  [charts.ZUSTANDSKLASSE_BETRIEB_STRATEGIE]: "Zustandsklasse (Betrieb)",
  [charts.ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE]:
    "Zustandsklasse (Standsicherheit)",
  [charts.SUBSTANZKLASSE_STRATEGIE]: "Substanzklasse",
  [charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE]: "Substanzklasse (Dichtheit)",
  [charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE]: "Substanzklasse (Betrieb)",
  [charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE]:
    "Substanzklasse (Standsicherheit)",
  // gebuehrenanteile: "Gebührenanteile",
  [charts.SANIERUNGEN]: "Sanierungen",
  [charts.SANIERUNGEN_REP]: "Sanierungen (Reparatur)",
  [charts.SANIERUNGEN_REN]: "Sanierungen (Renovierung)",
  [charts.SANIERUNGEN_ERN]: "Sanierungen (Erneuerung)",
  [charts.SANIERUNGSKOSTEN]: "Sanierungskosten",
  [charts.SANIERUNGSKOSTEN_REP]: "Sanierungskosten (Reparatur)",
  [charts.SANIERUNGSKOSTEN_REN]: "Sanierungskosten (Renovierung)",
  [charts.SANIERUNGSKOSTEN_ERN]: "Sanierungskosten (Erneuerung)",
  [charts.RBW_WBK]: "Restbuchwert (WBK)",
  // sanierungskosten: "Sanierungskosten",

  // StrategieVergleich
  [charts.ZUSTAND_AVG]: "Zustandsklasse",
  [charts.SUBSTANZ_AVG]: "Substanz",
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG]: "Substanz, Standsicherheit",
  [charts.SUBSTANZ_DICHTHEIT_AVG]: "Substanz, Dichtheit",
  [charts.SUBSTANZ_BETRIEB_AVG]: "Substanz, Betrieb",
  [charts.ZUSTAND_AVG_DIFFERENCE]: "Zustandsklasse (Differenz)",
  [charts.SUBSTANZ_AVG_DIFFERENCE]: "Substanz (Differenz)",
  [charts.SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE]: "Subs. Dichtheit (Differenz)",
  [charts.SUBSTANZ_BETRIEB_AVG_DIFFERENCE]: "Subs. Betrieb (Differenz)",
  [charts.SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE]:
    "Subs. Standsicherheit (Differenz)",
  // gebuehrenanteileSum: "Gebührenanteile",
  [charts.SANIERUNGEN_SUM]: "Sanierungen",
  [charts.SANIERUNGEN_REP_SUM]: "Sanierungen, Reparatur",
  [charts.SANIERUNGEN_REN_SUM]: "Sanierungen, Renovierung",
  [charts.SANIERUNGEN_ERN_SUM]: "Sanierungen, Erneuerung",
  [charts.SANIERUNGEN_SUM_DIFFERENCE]: "Sanierungen (Differenz)",
  [charts.SANIERUNGEN_REP_SUM_DIFFERENCE]: "Sani. Reparatur (Differenz)",
  [charts.SANIERUNGEN_REN_SUM_DIFFERENCE]: "Sani. Renovierung (Differenz)",
  [charts.SANIERUNGEN_ERN_SUM_DIFFERENCE]: "Sani. Erneuerung (Differenz)",
  [charts.SANIERUNGSKOSTEN_SUM]: "Sanierungskosten",
  [charts.SANIERUNGSKOSTEN_REP_SUM]: "Kosten, Reparatur",
  [charts.SANIERUNGSKOSTEN_REN_SUM]: "Kosten, Renovierung",
  [charts.SANIERUNGSKOSTEN_ERN_SUM]: "Kosten, Erneuerung",
  [charts.SANIERUNGSKOSTEN_SUM_DIFFERENCE]: "Kosten (Differenz)",
  [charts.SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE]: "Kosten, Reparatur (Differenz)",
  [charts.SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE]:
    "Kosten, Renovierung (Differenz)",
  [charts.SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE]:
    "Kosten, Erneuerung (Differenz)",
  [charts.RBW_WBK_SUM]: "Restbuchwert (WBK)",
  [charts.RBW_WBK_SUM_DIFFERENCE]: "Restbuchwert (WBK, Differenz)",

  // sanierungskostenSum: "Sanierungskosten",

  // Objectview grunddaten
  [charts.OBJEKTBEZEICHNUNG]: "Objektbezeichnung",
  [charts.STRASSE]: "Straße",
  [charts.KNOTENBEZEICHNUNG_UNTEN]: "Knoten Unten",
  [charts.KNOTENBEZEICHNUNG_OBEN]: "Knoten Oben",

  // Objectview inspection table (and subs also for prognosis)
  [charts.DATUM]: "Datum",
  [charts.SUBSTANZ_OBJECTVIEW]: "Substanz",
  [charts.SUBSTANZ_STB_OBJECTVIEW]: "S. Standsicherheit",
  [charts.SUBSTANZ_ECO_OBJECTVIEW]: "S. Dichtheit",
  [charts.SUBSTANZ_CAP_OBJECTVIEW]: "S. Betrieb",

  // additional charts for Objectview object
  [charts.PROGNOSIS_OBJECT_SUBSTANZ]: "Substanz",
  [charts.PROGNOSIS_OBJECT_ZUSTAND]: "Priorität",
  [charts.PROGNOSIS_OBJECT_LEGEND]: "Legende",
  [charts.PROGNOSIS_OBJECT_TABLE]: "Prognose",

  [charts.BODENGRUPPE]: "Bodengruppe",
  [charts.EINSTAUHAEUFIGKEIT]: "Einstauhäufigkeit",
  [charts.EIGENTUM]: "Eigentum",
  [charts.EINZUGSGEBIETSCHLUESSEL]: "Einzugsgebietsschlüssel",
  [charts.FUNKTIONSZUSTAND]: "Funktionszustand",
  [charts.GEBIETSSCHLUESSEL]: "Gebietsschlüssel",
  [charts.GEMEINDESCHLUESSEL]: "Gemeindeschlüssel",
  [charts.GRUNDWASSERSTAND]: "Grundwasserstand",
  [charts.MATERIALART]: "Materialart",
  [charts.ORTSTEIL]: "Ortsteil",
  [charts.ORTSTEILSCHLUESSEL]: "Ortsteilschlüssel",
  [charts.PROFILBREITE]: "Profilbreite",
  [charts.PROFILHOEHE]: "Profilhöhe",
  [charts.UEBERSCHWEMMUNGSGEBIET]: "Überschwemmungsgebiet",
  [charts.WASSERSCHUTZZONE]: "Wasserschutzzone",

  // Specific for objectview object prognosis table
  [charts.FORECAST_YEAR]: "Prognosejahr",
  [charts.PRIO_CLASS_OBJECTVIEW]: "Priorität",
  [charts.PRIO_CLASS_STB_OBJECTVIEW]: "P. Standsicherheit",
  [charts.PRIO_CLASS_ECO_OBJECTVIEW]: "P. Dichtheit",
  [charts.PRIO_CLASS_CAP_OBJECTVIEW]: "P. Betrieb",

  // Map filter
  [charts.MAP_DASHBOARD]: "Karte",
};
