/**
 * SidebarObjectview component.
 * @module components/elements/Sidebar/SidebarObjectview
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import FoldableSidebar from "../../elements/FoldableSidebar/FoldableSidebar";

/**
 * SidebarObjectview component class.
 * @class SidebarObjectview
 * @extends Component
 */
class SidebarObjectview extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // TODO: Render the view chooser with routes to the other views
    return (
      <FoldableSidebar title={this.props.title} width={this.props.width}>
        {this.props.children}
      </FoldableSidebar>
    );
  }
}

export default connect(
  state => {
    return {};
  },
  {}
)(SidebarObjectview);
