import {
  SET_PAGE_TABLE_OBJECTS,
  APPLY_FILTER_TABLE_OBJECTS,
  SET_FILTER_TABLE_OBJECTS,
  GET_DATA_OBJECT_GRUNDDATEN,
  GET_DATA_TABLE_OBJECTS,
  REMOVE_FILTER_TABLE_OBJECTS,
  RESET_OBJECTVIEW_GRUNDDATEN,
  GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN,
  RESET_PAGE_TABLE_OBJECTS,
  REMOVE_FILTERS_TABLE_OBJECTS,
  SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN,
  SET_FILTER_APPLIED_OBJECTVIEW,
  GET_PAGE_OBJECT,
  GET_COORDINATES_OBJECT,
  SET_COLUMN_SORT,
  SET_TYPE_SORT
} from "../../constants/ActionTypes";

export function getDataObjectGrunddaten(idProject, objectId) {
  return {
    type: GET_DATA_OBJECT_GRUNDDATEN,
    request: {
      op: "get",
      path: `objectview-grunddaten-object/${idProject}`,
      params: {
        objectId
      }
    }
  };
}

export function getDataTableObjects(
  idProject,
  bucket,
  filters,
  numObjectsPage,
  numPage,
  columnSort,
  typeSort
) {
  return {
    type: GET_DATA_TABLE_OBJECTS,
    request: {
      op: "get",
      path: `objectview-grunddaten-table-objects/${idProject}`,
      params: {
        ...filters,
        bucket,
        numObjectsPage,
        numPage,
        columnSort,
        typeSort
      }
    }
  };
}

export function getCoordinatesObject(idProject, bucket, objectId) {
  return {
    type: GET_COORDINATES_OBJECT,
    request: {
      op: "get",
      path: `geom-object/${idProject}`,
      params: {
        id: objectId
      }
    }
  };
}

export function getPageObject(
  idProject,
  bucket,
  filters,
  objectId,
  numObjectsPage,
  columnSort,
  typeSort
) {
  return {
    type: GET_PAGE_OBJECT,
    request: {
      op: "get",
      path: `objectview-grunddaten-page-object/${idProject}`,
      params: {
        ...filters,
        bucket,
        numObjectsPage,
        objectId,
        columnSort,
        typeSort
      }
    }
  };
}

export function getInspectionsObjectGrunddaten(idProject, bucket, idObject) {
  return {
    type: GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN,
    request: {
      op: "get",
      path: `objectview-grunddaten-inspections/${idProject}`,
      params: {
        idProject,
        bucket,
        idObject
      }
    }
  };
}

export function setPageTableObjects(numPage) {
  return {
    type: SET_PAGE_TABLE_OBJECTS,
    numPage
  };
}

export function resetPageTableObjects(numPage) {
  return {
    type: RESET_PAGE_TABLE_OBJECTS,
    numPage
  };
}

export function removeFilterTableObjects(nameColumn) {
  return {
    type: REMOVE_FILTER_TABLE_OBJECTS,
    nameColumn
  };
}

export function removeFiltersTableObjects() {
  return {
    type: REMOVE_FILTERS_TABLE_OBJECTS
  };
}

export function setFilterTableObjects(nameColumn, filter) {
  return {
    type: SET_FILTER_TABLE_OBJECTS,
    nameColumn,
    filter
  };
}

export function applyFilterTableObjects(nameColumn) {
  return {
    type: APPLY_FILTER_TABLE_OBJECTS,
    nameColumn
  };
}

export function setFilterMapObjects(value) {
  return {
    type: SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN,
    value
  };
}

export function setColumnSort(nameColumn) {
  return {
    type: SET_COLUMN_SORT,
    nameColumn
  };
}

export function setTypeSort(typeSort) {
  return {
    type: SET_TYPE_SORT,
    typeSort
  };
}

export function setFilterAppliedObjectview(idChart) {
  return {
    type: SET_FILTER_APPLIED_OBJECTVIEW,
    idChart
  };
}

export function reset() {
  return {
    type: RESET_OBJECTVIEW_GRUNDDATEN
  };
}
