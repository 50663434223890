import {
  SET_PAGE_TABLE_OBJECTS,
  APPLY_FILTER_TABLE_OBJECTS,
  SET_FILTER_TABLE_OBJECTS,
  GET_DATA_OBJECT_GRUNDDATEN,
  GET_DATA_TABLE_OBJECTS,
  REMOVE_FILTER_TABLE_OBJECTS,
  RESET_OBJECTVIEW_GRUNDDATEN,
  GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN,
  RESET_PAGE_TABLE_OBJECTS,
  SET_PROJECT,
  SET_BUCKET,
  REMOVE_FILTERS_TABLE_OBJECTS,
  SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN,
  SET_FILTER_APPLIED_OBJECTVIEW,
  GET_PAGE_OBJECT,
  GET_COORDINATES_OBJECT,
  SET_COLUMN_SORT,
  SET_TYPE_SORT,
} from "../../constants/ActionTypes";
import { omit } from "lodash";

import { SORT_ASCENDING } from "../../constants/types_sort";

// todo: is reload needed?

const initialState = {
  filtersApplied: {},
  filtersSet: {},
  pageTableObjects: 1,
  numObjectsPageTableObjects: 25,
  numPagesTableObjects: 1,
  dataTableObjects: {
    rows: [],
    namesColumns: [
      "id",
      "objektbezeichnung",
      "strasse",
      "baujahr",
      "haltungslaenge",
      "mamterialgruppe",
      "nennweite",
      "knotenbezeichnung_oben",
      "knotenbezeichnung_unten",
    ],
  },
  dataObject: {},
  dataInspectionsObject: {
    rows: [],
    namesColumns: [],
  },
  columnSort: "id",
  typeSort: SORT_ASCENDING,
  coordinatesObjectActive: undefined,
  pageObject: undefined,
  loadingObject: false,
  loadingTableObjects: false,
  loadingInspections: false,
  loadingPageObject: false,
  loadedObject: false,
  loadedTableObjects: false,
  loadedInspections: false,
  loadedPageObject: false,
  errorObject: null,
  errorTableObjects: null,
  errorInspections: null,
  errorPageObject: null,
};

/**
 * Filter reducer.
 * @function filters
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function objectviewGrunddaten(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case REMOVE_FILTER_TABLE_OBJECTS:
      return {
        ...state,
        filtersApplied: omit(state.filtersApplied, action.nameColumn),
        filtersSet: omit(state.filtersSet, action.nameColumn),
      };
    case REMOVE_FILTERS_TABLE_OBJECTS:
      return {
        ...state,
        filtersApplied: { ...initialState.filtersApplied },
        filtersSet: { ...initialState.filtersSet },
      };
    case APPLY_FILTER_TABLE_OBJECTS:
      // Insert a set filter into the applied filters
      return {
        ...state,
        filtersApplied: {
          ...state.filtersApplied,
          [action.nameColumn]: state.filtersSet[action.nameColumn],
        },
        pageTableObjects: initialState.pageTableObjects,
      };
    case SET_FILTER_TABLE_OBJECTS:
      return {
        ...state,
        filtersSet: {
          ...state.filtersSet,
          [action.nameColumn]: action.filter,
        },
      };
    case SET_PAGE_TABLE_OBJECTS:
      return {
        ...state,
        pageTableObjects: action.numPage,
      };
    case RESET_PAGE_TABLE_OBJECTS:
      return {
        ...state,
        pageTableObjects: initialState.pageTableObjects,
      };
    case `${GET_PAGE_OBJECT}_PENDING`:
      return {
        ...state,
        errorPageObject: null,
        loadedPageObject: false,
        loadingPageObject: true,
      };
    case `${GET_PAGE_OBJECT}_SUCCESS`:
      return {
        ...state,
        errorPageObject: null,
        pageTableObjects: action.result.numPage,
        loadedPageObject: true,
        loadingPageObject: false,
      };
    case `${GET_PAGE_OBJECT}_FAIL`:
      return {
        ...state,
        errorPageObject: action.error,
        loadedPageObject: false,
        loadingPageObject: false,
        pageTableObjects: initialState.pageTableObjects,
      };
    case `${GET_DATA_TABLE_OBJECTS}_PENDING`:
      return {
        ...state,
        errorTableObjects: null,
        loadedTableObjects: false,
        loadingTableObjects: true,
      };
    case `${GET_DATA_TABLE_OBJECTS}_SUCCESS`:
      return {
        ...state,
        errorTableObjects: null,
        dataTableObjects: action.result,
        numPagesTableObjects: action.result.numPages,
        numPage: initialState.numPage,
        loadedTableObjects: true,
        loadingTableObjects: false,
      };
    case `${GET_DATA_TABLE_OBJECTS}_FAIL`:
      return {
        ...state,
        errorTableObjects: action.error,
        loadedTableObjects: false,
        loadingTableObjects: false,
        dataTableObjects: initialState.dataTableObjects,
        numPagesTableObjects: initialState.numPagesTableObjects,
        pageTableObjects: initialState.pageTableObjects,
      };
    case `${GET_DATA_OBJECT_GRUNDDATEN}_PENDING`:
      return {
        ...state,
        errorObject: null,
        loadedObject: false,
        loadingObject: true,
      };
    case `${GET_DATA_OBJECT_GRUNDDATEN}_SUCCESS`:
      return {
        ...state,
        errorObject: null,
        dataObject: action.result,
        loadedObject: true,
        loadingObject: false,
      };
    case `${GET_DATA_OBJECT_GRUNDDATEN}_FAIL`:
      return {
        ...state,
        errorObject: action.error,
        loadedObject: false,
        loadingObject: false,
        dataObject: initialState.dataObject,
      };
    case `${GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN}_PENDING`:
      return {
        ...state,
        errorInspections: null,
        loadedInspections: false,
        loadingInspections: true,
      };
    case `${GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN}_SUCCESS`:
      return {
        ...state,
        errorInspections: null,
        dataInspectionsObject: action.result,
        loadedInspections: true,
        loadingInspections: false,
      };
    case `${GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN}_FAIL`:
      return {
        ...state,
        errorInspections: action.error,
        loadedInspections: false,
        loadingInspections: false,
        dataInspectionsObject: initialState.dataInspectionsObject,
      };
    case `${GET_COORDINATES_OBJECT}_SUCCESS`:
      return {
        ...state,
        errorCoordinatesObject: null,
        loadedCoordinatesObject: true,
        loadingCoordinatesObject: false,
        coordinatesObjectActive: action.result.features[0],
      };
    case `${GET_COORDINATES_OBJECT}_PENDING`:
      return {
        ...state,
        errorCoordinatesObject: null,
        loadedCoordinatesObject: false,
        loadingCoordinatesObject: true,
      };
    case `${GET_COORDINATES_OBJECT}_FAIL`:
      return {
        ...state,
        errorCoordinatesObject: action.error,
        loadedCoordinatesObject: false,
        loadingCoordinatesObject: false,
        coordinatesObjectActive: initialState.coordinatesObjectActive,
      };
    case SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN:
      const features = action.value.features;
      return {
        ...state,
        filtersSet:
          features !== undefined && features.length > 0
            ? {
                ...state.filtersSet,
                map: [JSON.stringify(action.value)],
              }
            : omit(state.filtersSet, ["map"]),
      };
    case SET_FILTER_APPLIED_OBJECTVIEW:
      // add a filter that has already been applied to the
      // set filters. Used for pie chart to get initially seleted segments
      return {
        ...state,
        filtersSet: state.filtersApplied[action.idChart]
          ? {
              ...state.filtersSet,
              [action.idChart]: state.filtersApplied[action.idChart],
            }
          : omit(state.filtersSet, action.idChart),
      };
    case RESET_OBJECTVIEW_GRUNDDATEN:
      return { ...initialState };
    case SET_PROJECT:
      return { ...initialState };
    case SET_BUCKET:
      return {
        ...initialState,
        filtersSet: state.filtersSet,
        filtersApplied: state.filtersApplied,
      };
    case SET_TYPE_SORT:
      return {
        ...state,
        typeSort: action.typeSort,
      };
    case SET_COLUMN_SORT:
      return {
        ...state,
        columnSort: action.nameColumn,
      };
    default:
      return state;
  }
}
