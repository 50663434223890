import { combineReducers } from "redux";

import { LOGOUT } from "../constants/ActionTypes";
import daten from "./daten/daten";
import grunddaten from "./grunddaten/grunddaten";
import inspektionsdaten from "./inspektionsdaten/inspektionsdaten";
import objektklassifizierung from "./objektklassifizierung/objektklassifizierung";
import objektklassifizierungSchutzziele from "./objektklassifizierungSchutzziele/objektklassifizierungSchutzziele";
import schadensklassifizierung from "./schadensklassifizierung/schadensklassifizierung";
import strategie from "./strategie/strategie";
import strategieVergleich from "./strategieVergleich/strategieVergleich";
import detailview from "./detailview/detailview";
import filters from "./filters/filters";
import chartSettings from "./chartSettings/chartSettings";
import userSession from "./userSession/userSession";
import projects from "./projects/projects";
import users from "./users/users";
import map from "./map/map";
import objectview from "./objectview/objectview";
import objectviewGrunddaten from "./objectview/objectviewGrunddaten";
import objectviewObject from "./objectview/objectviewObject";
import changelog from "./changelog/changelog";
import views from "./views/views";

const appReducer = combineReducers({
  filters,
  chartSettings,
  userSession,
  daten,
  grunddaten,
  inspektionsdaten,
  objektklassifizierung,
  objektklassifizierungSchutzziele,
  schadensklassifizierung,
  strategie,
  strategieVergleich,
  detailview,
  users,
  projects,
  map,
  objectview,
  objectviewGrunddaten,
  objectviewObject,
  changelog,
  views
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default () => rootReducer;
