/**
 * Filterpanel component.
 * @module components/elements/Filterpanel/Filterpanel
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, ButtonGroup, Button } from "reactstrap";
import { labels } from "../../../constants/Filters";
import {
  diagramsViews,
  viewsDashboardsFilters,
} from "../../../constants/Dashboards";
import { map } from "lodash";
import {
  applyFilter,
  setBarFilter,
  togglePieFilter,
} from "../../../actions/filters/filters";
import BarFilterSetter from "./BarFilterSetter";

import FoldableSidebar from "../../elements/FoldableSidebar/FoldableSidebar";

import {
  VIEW_GRUNDDATEN,
  VIEW_INSPEKTIONSDATEN,
  VIEW_OBJEKTKLASSIFIZIERUNG,
  VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE,
  VIEW_SCHADENSKLASSIFIZIERUNG,
  VIEW_STRATEGIE,
  VIEW_STRATEGIE_VERGLEICH,
} from "../../../constants/NamesViews";

// TODO BUG: If setting but not applying bar filter in Dashboard and
//  entering detailview, the set filter is displayed, but the button to
//  apply filter is not...
// TODO: ensure that filtersSet is initialized with filtersActive for all
//  diagrams? However, then set filters in the dashboard would be lost When
//  having switched to detailview and back.
//  maybe simply use filtersSet and ensure that the dashboards set that
//  correctly.
// TODO: Indicate updated filters. Maybe only show "Apply" - Button for this.
// TODO: Reuse Sidebar-Component so that also the apply filters button etc.
//  Is displayed.

// TODO: shouldComponentUpdate on set filter changes to update displayed filters

// TODO: remove "set" buttons and make it same as on diagrams

/**
 * Filterpanel component class.
 * @class Filterpanel
 * @extends Component
 */
class Filterpanel extends Component {
  renderFilters() {
    return viewsDashboardsFilters[this.props.typeDashboard].map((typeView) => {
      var dataCharts = null;

      // Obtain the chart data for displaying appropriate filter values
      switch (typeView) {
        case VIEW_GRUNDDATEN:
          dataCharts = this.props.grunddaten;
          break;
        case VIEW_INSPEKTIONSDATEN:
          dataCharts = this.props.inspektionsdaten;
          break;
        case VIEW_SCHADENSKLASSIFIZIERUNG:
          dataCharts = this.props.schadensklassifizierung;
          break;
        case VIEW_OBJEKTKLASSIFIZIERUNG:
          dataCharts = this.props.objektklassifizierung;
          break;
        case VIEW_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE:
          dataCharts = this.props.objektklassifizierungSchutzziele;
          break;
        case VIEW_STRATEGIE:
          dataCharts = this.props.strategie;
          break;
        case VIEW_STRATEGIE_VERGLEICH:
          dataCharts = this.props.strategieVergleich;
          break;
        default:
          break;
      }
      return (
        typeView in diagramsViews &&
        Object.keys(diagramsViews[typeView]).map((idChart) =>
          // Only if Bar or Pie because other diagrams do not support
          //  filtering.
          // TODO: Do not hardcode this, but find a generic way to check if a
          //  particular Diagram supports filtering.
          ["Bar", "Pie"].includes(diagramsViews[typeView][idChart]) &&
          typeView !== VIEW_SCHADENSKLASSIFIZIERUNG
            ? this.renderFilter(
                idChart,
                diagramsViews[typeView][idChart],
                dataCharts[idChart]
              )
            : null
        )
      );
    });
  }

  /**
   * Obtain filter Range of a chart, or [null, null] if no filter is set.
   * @method getFilterRange
   * @param {String} idChart the chart's identifier
   * @returns {Array} Filter range. First element is start second one is end.
   */
  getFilterRange(idChart) {
    const filtersChart = this.props.filtersSet[idChart];
    return filtersChart ? filtersChart : [null, null];
  }

  /**
   * Check whether a pie chart element is included in the chart's filter.
   * @method checkFilterActive
   * @param {String} idChart the chart's identifier
   * @param {Object} elementFilter The element, providing property x.
   * @returns {Array} Filter range. First element is start second one is end.
   */
  checkFilterActive(idChart, elementFilter) {
    const filtersChart = this.props.filtersSet[idChart];
    return filtersChart ? filtersChart.includes(elementFilter.x) : false;
  }

  /**
   * Render numerical filter input for bar of pie chart. For other charttypes,
   * Filtering is currently not supported.
   * @method renderFilter
   * @param {String} idChart the chart's identifier
   * @param {String} typeChart The chart type. "Bar" and "Pie" are supported.
   * @param {Array} dataChart The chart data
   * @returns {Array} Filter range. First element is start second one is end.
   */
  renderFilter(idChart, typeChart, dataChart) {
    // Show the button if a filter is set and it differs from
    // the one that is applied.
    // it may be set to same as applied for piecharts for example.
    const showApplyFilter =
      this.props.filtersSet[idChart] !== this.props.filtersApplied[idChart];
    return (
      //const filterRange = this.getFilterRange(idChart);
      <FormGroup>
        <Label>
          {labels[idChart]}
          {showApplyFilter && (
            <Button
              className="filter-apply filterpanel"
              onClick={() => this.props.applyFilter(idChart)}
            >
              <span className="la la-check" />
              Filter anwenden
            </Button>
          )}
        </Label>
        <br />
        {typeChart === "Bar" && (
          <BarFilterSetter
            idChart={idChart}
            dataChart={dataChart}
            size={"sm"}
          />
        )}
        {typeChart === "Pie" && (
          <ButtonGroup style={{ display: "inline-block" }}>
            {map(dataChart, (element) => (
              <Button
                onClick={() => this.props.togglePieFilter(idChart, element.x)}
                active={this.checkFilterActive(idChart, element)}
              >
                {element.x}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </FormGroup>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { width } = this.props;

    return (
      <FoldableSidebar
        title={this.props.title}
        showFilteringUi={true}
        width={width}
      >
        {this.renderFilters()}
        {/* {typeDashboard !== DASHBOARD_GRUNDDATEN ? this.renderFilters(dataCharts) : null} */}
      </FoldableSidebar>
    );
  }
}

export default connect(
  (state) => {
    return {
      idChart: state.grunddaten.detailviewIdChart,
      filtersSet: state.filters.filtersSet,
      filtersApplied: state.filters.filtersApplied,
      grunddaten: state.grunddaten.data,
      inspektionsdaten: state.inspektionsdaten.data,
      schadensklassifizierung: state.schadensklassifizierung.data,
      objektklassifizierung: state.objektklassifizierung.data,
      objektklassifizierungSchutzziele:
        state.objektklassifizierungSchutzziele.data,
      strategie: state.strategie.data,
      strategieVergleich: state.strategieVergleich.data,
      typeDashboard: state.views.dashboard,
    };
  },
  { setBarFilter, togglePieFilter, applyFilter }
)(Filterpanel);
