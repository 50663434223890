/**
 * Sidebar component.
 * @module components/elements/Sidebar/Sidebar
 */

import React from "react";
import { connect } from "react-redux";
import {
  Row,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { isEqual, map } from "lodash";
import { labels } from "../../../constants/Filters";

import { setSidebarOpen } from "../../../actions/grunddaten/grunddaten";
import {
  getGrunddatenDifferences,
  setIdChartDifferences,
} from "../../../actions/strategieVergleich/strategieVergleich";
import {
  setDetailviewOpen,
  resetDetailview,
} from "../../../actions/detailview/detailview";
import {
  applyFilters,
  removeFilters,
  removeFilter,
} from "../../../actions/filters/filters";
import {
  resetChartSettings,
  setChartsUnit,
  setChartsScale,
} from "../../../actions/chartSettings/chartSettings";
import { resetMap } from "../../../actions/map/map";

import {
  scalesDifferencesSum,
  scalesDifferencesAvg,
  units,
  scales,
} from "../../../constants/chartSettings";

import Chart from "../../charts/Chart/Chart";
import Detailview from "../Detailview/Detailview";
import FoldableSidebar from "../FoldableSidebar/FoldableSidebar";

import { setCurrentDashboard } from "../../../actions/views/views";

import { diagramsViews } from "../../../constants/Dashboards";
import * as charts from "../../../constants/Charts";
import {
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
  VIEW_STRATEGIE_VERGLEICH,
} from "../../../constants/NamesViews";
import { getScaleDisplayDifferences } from "../../../helpers/charts/common_functions";

// TODO: Detailview charts must also display correct data
//  and correct diagrams!

/**
 * Sidebar component class.
 * @class Sidebar
 * @extends Component
 */
class SidebarStrategyComparison extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * On toggle handler
   * @method onToggle
   * @returns {undefined}
   */
  onToggle() {
    this.props.setSidebarOpen(!this.props.open);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.typeDashboard &&
      this.props.setCurrentDashboard(this.props.typeDashboard);
    this.props.project !== null &&
      this.props.getGrunddatenDifferences(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.strategiesComparisonIds,
        this.props.typeDashboard,
        this.props.yearStrategy,
        this.props.idChartDifferences
      );
    // When opening a dashboard, the Detailview is closed initially.
    this.props.setDetailviewOpen(false, undefined, undefined, undefined);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.project !== nextProps.project) {
      this.props.resetMap();
      this.props.resetChartSettings();
      this.props.resetDetailview();
      this.props.removeFilters();
    }
    if (
      !isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
      !isEqual(this.props.scales, nextProps.scales) ||
      !isEqual(this.props.units, nextProps.units) ||
      !isEqual(this.props.bucket, nextProps.bucket) ||
      !isEqual(this.props.project, nextProps.project) ||
      ((!isEqual(
        this.props.strategiesComparisonIds,
        nextProps.strategiesComparisonIds
      ) ||
        !isEqual(this.props.yearStrategy, nextProps.yearStrategy) ||
        !isEqual(
          this.props.idChartDifferences,
          nextProps.idChartDifferences
        )) &&
        this.checkShowDifferences(nextProps)) ||
      (!this.checkShowDifferences(nextProps) &&
        this.checkShowDifferences(this.props))
    ) {
      this.props.getGrunddatenDifferences(
        nextProps.project,
        nextProps.bucket,
        nextProps.filtersApplied,
        nextProps.scales,
        nextProps.units,
        nextProps.strategiesComparisonIds,
        nextProps.typeDashboard,
        nextProps.yearStrategy,
        nextProps.idChartDifferences
      );
    }
  }

  getDefaultsIfMissing(values, default_) {
    return values === undefined ? default_ : values;
  }

  checkShowDifferences(props) {
    return (
      props.idChartDifferences !== undefined &&
      props.strategiesComparisonIds.length === 2 &&
      props.strategiesComparisonIds[0] !== undefined &&
      props.strategiesComparisonIds[1] !== undefined
    );
  }

  /**
   * Draw the diagrams in the dashboard
   * @method componentDidMount
   * @returns {undefined}
   */
  drawDashboard() {
    const {
      data,
      typeDashboard,
      widthSidebar,
      idChartDifferences,
    } = this.props;
    const widthContent = 100 - widthSidebar;
    const diagramsDifference = Object.keys(
      diagramsViews[VIEW_STRATEGIE_VERGLEICH]
    );

    const showDifferences = this.checkShowDifferences(this.props);

    let unitsDisplay = undefined;
    const scalesDisplay = getScaleDisplayDifferences(
      showDifferences,
      idChartDifferences,
      scalesDifferencesAvg,
      scalesDifferencesSum
    );

    const unitsDropdown = this.getDefaultsIfMissing(unitsDisplay, units);
    const scalesDropdown = this.getDefaultsIfMissing(scalesDisplay, scales);

    const showScaleButton = true;
    const showUnitButton = !showDifferences;

    return (
      // TODO: The sidebar div itself should fold and take
      //  its children (basedata). It can then be refactored into individual
      //  component reusable in detailView. Should have title attribute
      //  that can be passed.

      <div className="mainWrapper">
        <FoldableSidebar
          title={
            <div className="title-sidebar">
              <span className="filter-label">Basisdaten (Differenz)</span>
              {(showUnitButton || showScaleButton) && (
                <UncontrolledDropdown size="sm" style={{ display: "inline" }}>
                  <DropdownToggle tag="span">
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      Optionen (Global)
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {showScaleButton &&
                      map(Object.keys(scalesDropdown), (key_scale) => (
                        <DropdownItem
                          key={key_scale}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.setChartsScale(
                              Object.keys(
                                diagramsViews[
                                  VIEW_GRUNDDATEN_STRATEGY_COMPARISON
                                ]
                              ),
                              key_scale
                            )
                          }
                        >
                          <span>{scalesDropdown[key_scale]}</span>
                        </DropdownItem>
                      ))}
                    {showUnitButton && (
                      <React.Fragment>
                        <DropdownItem divider />
                        {map(Object.keys(unitsDropdown), (key_unit) => (
                          <DropdownItem
                            key={key_unit}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.props.setChartsUnit(
                                Object.keys(
                                  diagramsViews[
                                    VIEW_GRUNDDATEN_STRATEGY_COMPARISON
                                  ]
                                ),
                                key_unit
                              )
                            }
                          >
                            <span>{unitsDropdown[key_unit]}</span>
                          </DropdownItem>
                        ))}
                      </React.Fragment>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <UncontrolledDropdown
                style={{
                  display: "inline-block",
                  paddingRight: 6,
                }}
              >
                <DropdownToggle caret tag="span" style={{ cursor: "pointer" }}>
                  <span>
                    {idChartDifferences
                      ? labels[idChartDifferences]
                      : "Differenz-Diagramm"}
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem
                    key="none"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.setIdChartDifferences(undefined)}
                  >
                    Keine Diagramm (zeige Grunddaten)
                  </DropdownItem>
                  {diagramsDifference.map(
                    (idChart) =>
                      !idChart.includes("Difference") && (
                        <DropdownItem
                          key={idChart}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.setIdChartDifferences(idChart)
                          }
                        >
                          {labels[idChart]}
                        </DropdownItem>
                      )
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          }
          showFilteringUi={true}
          width={widthSidebar}
        >
          <Row>
            <Chart
              data={data[charts.BAUJAHR]}
              id={charts.BAUJAHR}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.MATERIALGRUPPE]}
              id={charts.MATERIALGRUPPE}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              typeChart={showDifferences ? "BarHorizontal" : undefined}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.MITTLERE_TIEFE]}
              id={charts.MITTLERE_TIEFE}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.KANALNUTZUNG]}
              id={charts.KANALNUTZUNG}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              typeChart={showDifferences ? "BarHorizontal" : undefined}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.HALTUNGSLAENGE]}
              id={charts.HALTUNGSLAENGE}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.KANALART]}
              id={charts.KANALART}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              typeChart={showDifferences ? "BarHorizontal" : undefined}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.NENNWEITE]}
              id={charts.NENNWEITE}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.LAGE_IM_VERKEHRSRAUM]}
              id={charts.LAGE_IM_VERKEHRSRAUM}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              typeChart={showDifferences ? "BarHorizontal" : undefined}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.ALTER]}
              id={charts.ALTER}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.PROFILART]}
              id={charts.PROFILART}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              typeChart={showDifferences ? "BarHorizontal" : undefined}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.RESTNUTZUNGSDAUER]}
              id={charts.RESTNUTZUNGSDAUER}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.VERIFIKATION]}
              id={charts.VERIFIKATION}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
            <Chart
              data={data[charts.SANIERUNGSLAENGE]}
              id={charts.SANIERUNGSLAENGE}
              typeView={VIEW_GRUNDDATEN_STRATEGY_COMPARISON}
              showUnitButton={!showDifferences}
              unitsDisplay={unitsDisplay}
              scalesDisplay={scalesDisplay}
            />
          </Row>
        </FoldableSidebar>
        <div
          className={
            this.props.open
              ? "mainColumn sidebarOpen"
              : "mainColumn sidebarClosed"
          }
          style={{
            width: this.props.open ? `${widthContent}%` : "100%",
          }}
        >
          {
            // Pass the toggleDetailView function to children diagram panels
            React.cloneElement(this.props.children, {
              typeDashboard: typeDashboard,
            })
          }
        </div>
      </div>
    );
  }

  drawContent() {
    if (this.props.detailviewIsOpen) {
      // REMARK: Need to draw the child dashboard so that updates
      //  are made in detailview
      return (
        <div>
          <div style={{ display: "none" }}>{this.props.children}</div>
          <Detailview>
            {
              // Pass the toggleDetailView function to children diagram panels
              React.cloneElement(this.props.children, {
                typeDashboard: this.props.typeDashboard,
              })
            }
          </Detailview>
        </div>
      );
    } else {
      return this.drawDashboard();
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return this.drawContent();
  }
}

export default connect(
  (state) => {
    const diagrams = diagramsViews[VIEW_GRUNDDATEN_STRATEGY_COMPARISON];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    return {
      widthSidebar: state.grunddaten.widthSidebar,
      open: state.grunddaten.sidebarIsOpen,
      isLoaded: state.grunddaten.loaded,
      data: state.strategieVergleich.dataDifferences,
      filtersApplied: state.filters.filtersApplied,
      project: state.projects.project,
      token: state.userSession.token,
      bucket: state.projects.bucket,
      detailviewIsOpen: state.detailview.isOpen,
      yearStrategy: state.strategieVergleich.yearStrategy,
      strategiesComparisonIds: state.strategieVergleich.strategiesComparisonIds,
      units: units,
      scales: scales,
      idChartDifferences: state.strategieVergleich.idChartDifferences,
    };
  },
  {
    getGrunddatenDifferences,
    setDetailviewOpen,
    applyFilters,
    removeFilters,
    removeFilter,
    setSidebarOpen,
    resetMap,
    resetChartSettings,
    resetDetailview,
    setCurrentDashboard,
    setIdChartDifferences,
    setChartsUnit,
    setChartsScale,
  }
)(SidebarStrategyComparison);
