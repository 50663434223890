/**
 * LegendPieGeneric component.
 * @module components/charts/LegendPieGeneric/LegendPieGeneric
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";

import { getColor } from "../../../helpers/colors/colors";

import { mergeDataLabelled } from "../../../helpers/charts/common_functions";

/**
 * Pie component class.
 * @class Pie
 * @extends Component
 */
class LegendPieGeneric extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const newData = !isEqual(this.props.data, nextProps.data);
    const id = this.props.id !== nextProps.id;
    return newData || id;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, data } = this.props;

    // get applied filter; is applied one or else the set ones
    // const filterActive = filtersApplied[id]
    //   ? filtersApplied[id]
    //   : filtersSet[id];

    // TODO: Toggle on click and indicate filtered entries

    const dataMerged = mergeDataLabelled(data, this.props.refInverted[id]);

    // todo: move style to css
    return (
      <div className="legendPieGeneric">
        <ul>
          {dataMerged.map((element) => (
            <li
              key={element.id}
              style={{
                // If element has an ID (like for strategies), use ID for color.
                // Otherwise, the color is defined for the displayed value directly.
                // TODO: ID should be used always for categorial data.
                color: element.id
                  ? getColor(id, element.id)
                  : getColor(id, element.x),
              }}
            >
              <span className="legend">
                {element.label ? element.label : element.x}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    refInverted: state.projects.metadataProject.refInverted,
  };
}, {})(LegendPieGeneric);
