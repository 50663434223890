/**
 * Grunddaten actions.
 * @module actions/grunddaten/grunddaten
 */

import { merge } from "lodash";

import {
  GET_GRUNDDATEN,
  SET_SIDEBAR_OPEN,
  SET_EXPORTDATA_PROPERTIES,
} from "../../constants/ActionTypes";
import { parseParams } from "../../helpers/url/url";
import { parsePrependParams } from "../../helpers/Api/ParsePrependParams";

/**
 * Get grunddaten.
 * @function getGrunddaten
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @returns {Object} Get grunddaten action.
 */
export function getGrunddaten(
  id,
  bucket,
  filters,
  scales,
  units,
  typeDashboard
) {
  return {
    type: GET_GRUNDDATEN,
    request: {
      op: "get",
      path: `grunddaten/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        { typeDashboard: typeDashboard }
      ),
    },
  };
}
export function setSidebarOpen(sidebarIsOpen) {
  return {
    type: SET_SIDEBAR_OPEN,
    sidebarIsOpen: sidebarIsOpen,
  };
}

export function exportDataProperties(properties) {
  return {
    type: SET_EXPORTDATA_PROPERTIES,
    properties: properties,
  };
}
