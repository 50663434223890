import React from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";

import { mapColumns } from "../../../helpers/objectview/data";

import { labels } from "../../../constants/Filters";

import { TableGeneric } from "./TableGeneric";

// TODO: use column IDs

// TODO: write common function to reverse mapping for filtering

// TODO:

// TODO: enable vertical layout (For objectview)

// TODO: Sort rows by groups in some way? Or should backend manage that?

export class TableGenericVertical extends TableGeneric {
  // constructor(props) {
  //   super(props);
  // }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  renderHeadColumn(mappingColumns) {
    return <th>{mappingColumns[1]}</th>;
  }

  renderTable() {
    return (
      <Table className={this.props.classNameTable}>
        {this.renderTableBody()}
      </Table>
    );
  }

  renderTableBody() {
    return <tbody>{this.renderRows()}</tbody>;
  }

  renderRows() {
    // Each row shall corresponds to a column due to vertical layout.
    // But rows are in the format that each row contains the values for columns.
    // Hence, transpose the rows
    const rowsTransposed = this.props.idsColumns.map((idColumn, indexColumn) =>
      this.props.rows.map((row) => row[indexColumn])
    );
    return rowsTransposed.map((row, indexColumn) =>
      this.renderRow(row, indexColumn)
    );
  }

  renderRow(row, indexColumn) {
    // First element is the column name, the other ones are the values that must be mapped.
    return (
      <tr>
        <td>{this.props.namesColumnsMapped[indexColumn][1]}</td>
        {this.renderColumnCells(row, indexColumn)}
      </tr>
    );
  }

  renderColumnCells(row, indexColumn) {
    return row.map((value) =>
      this.renderColumnCell(this.props.idsColumns[indexColumn], value)
    );
  }

  renderColumnCell(idColumn, value) {
    return <td>{this.mapColumnValue(idColumn, value)}</td>;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return this.renderTable();
  }
}

export default connect(
  (state, props) => ({
    namesColumnsMapped: mapColumns(props.idsColumns, labels),
    refMappings: state.projects.metadataProject.ref,
    refMappingsInverted: state.projects.metadataProject.refInverted,
  }),
  {}
)(TableGenericVertical);
