/**
 * Filters reducer.
 * @module reducers/filters/filters
 */

import { xor, omit } from "lodash";
import { filtersViews } from "../../constants/Filters";

import {
  APPLY_FILTERS,
  APPLY_FILTER,
  REMOVE_FILTERS,
  TOGGLE_PIE_FILTER,
  SET_BAR_FILTER,
  UNSET_FILTER,
  SET_MAP_FILTER,
  GET_GRUNDDATEN,
  SET_PROJECT,
  GET_PROJECTS,
  SET_STACKED_BAR_FILTER,
  REMOVE_FILTER,
  SET_FILTER_APPLIED,
  GET_FILTER_PRESET_USER,
  GET_FILTER_PRESET_PROJECT,
  GET_FILTER_PRESETS,
  STORE_FILTER_PRESET,
  DELETE_FILTER_PRESET_USER,
  DELETE_FILTER_PRESET_PROJECT
} from "../../constants/ActionTypes";

// todo: is reload needed?

const initialState = {
  // Filters that have been applied so that they are used for filtering
  filtersApplied: {},
  // Filters that have been set in the GUI but have not been applied yet
  filtersSet: {},
  presets: {},
  presetLoading: false,
  presetLoaded: false,
  presetsLoading: false,
  presetsLoaded: false,
  presetStoring: false,
  presetStored: false,
  errorGetPreset: null,
  errorGetPresets: null,
  errorStorePreset: null
};

/**
 * Filter reducer.
 * @function filters
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function filters(state = initialState, action = {}) {
  let pie;
  switch (action.type) {
    // Remark if project is set, reset the state.
    case SET_PROJECT:
      return {
        ...initialState
      };
    //case SET_BUCKET:  // Remark: for now, keep state on bucket change
    case `${GET_PROJECTS}_SUCCESS`:
      return {
        ...initialState
      };
    case REMOVE_FILTERS:
      return {
        ...state,
        filtersApplied: initialState.filtersApplied,
        filtersSet: initialState.filtersSet
      };
    case REMOVE_FILTER:
      return {
        ...state,
        filtersApplied: omit(state.filtersApplied, action.chartId),
        filtersSet: omit(state.filtersSet, action.chartId)
      };
    case APPLY_FILTERS:
      // Insert all set filters into the applied filters
      // and reset set filters
      // Can copy all set filters because they always reflect the
      // Applied filters initially
      // todo: no update performed
      return {
        ...state,
        filtersApplied: state.filtersSet
      };
    case APPLY_FILTER:
      // Insert a set filter into the applied filters
      return {
        ...state,
        filtersApplied: {
          ...state.filtersApplied,
          [action.idChart]: state.filtersSet[action.idChart]
        }
      };
    case TOGGLE_PIE_FILTER:
      pie = xor(state.filtersSet[action.filter], [action.value]);
      return {
        ...state,
        filtersSet:
          pie.length > 0
            ? {
                ...state.filtersSet,
                [action.filter]: pie
              }
            : omit(state.filtersSet, action.filter)
      };
    case SET_FILTER_APPLIED:
      // add a filter that has already been applied to the
      // set filters. Used for pie chart to get initially seleted segments
      return {
        ...state,
        filtersSet: state.filtersApplied[action.idChart]
          ? {
              ...state.filtersSet,
              [action.idChart]: state.filtersApplied[action.idChart]
            }
          : omit(state.filtersSet, action.idChart)
      };
    case SET_BAR_FILTER:
      return {
        ...state,
        // If min and max value are empty, the filter is removed
        filtersSet:
          action.value[0] !== "" || action.value[1] !== ""
            ? {
                ...state.filtersSet,
                [action.filter]: action.value
              }
            : omit(state.filtersSet, action.filter)
      };
    case UNSET_FILTER:
      return {
        ...state,
        filtersSet: state.filtersApplied.hasOwnProperty(action.idChart)
          ? {
              ...state.filtersSet,
              [action.idChart]: state.filtersApplied[action.idChart]
            }
          : omit(state.filtersSet, action.idChart)
      };
    case SET_STACKED_BAR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: action.value
        }
      };
    case SET_MAP_FILTER:
      const features = action.value.features;
      return {
        ...state,
        filtersSet:
          features !== undefined && features.length > 0
            ? {
                ...state.filtersSet,
                map: [JSON.stringify(action.value)]
              }
            : omit(state.filtersSet, ["map"])
      };
    case `${GET_GRUNDDATEN}_SUCCESS`:
      return {
        ...state,
        reload: false
      };
    case `${GET_FILTER_PRESETS}_SUCCESS`:
      return {
        ...state,
        presetsLoaded: true,
        presetsLoading: false,
        errorGetPresets: null,
        presets: action.result
      };
    case `${GET_FILTER_PRESETS}_PENDING`:
      return {
        ...state,
        presetsLoaded: false,
        presetsLoading: true,
        errorGetPresets: null
      };
    case `${GET_FILTER_PRESETS}_FAIL`:
      return {
        ...state,
        presets: initialState.presets,
        presetsLoaded: false,
        presetsLoading: false,
        errorGetPresets: action.error
      };
    case `${GET_FILTER_PRESET_USER}_SUCCESS`:
    case `${GET_FILTER_PRESET_PROJECT}_SUCCESS`:
      // Updates applied and set filters with the new filters
      // for the corresponding view. Separate update of applied
      // and set filters because in all other views,
      // all applied and set filters shall stay the same.

      const filtersView = filtersViews[action.nameView];
      let filtersNewApplied = {};
      let filtersNewSet = {};

      // Copy all set filters that are not in current view
      for (var nameFilterSet in state.filtersSet) {
        if (!filtersView.includes(nameFilterSet)) {
          filtersNewSet[nameFilterSet] = state.filtersSet[nameFilterSet];
        }
      }

      // Copy all applied filters that are not in current view
      for (var nameFilterApplied in state.filtersApplied) {
        if (!filtersView.includes(nameFilterApplied)) {
          filtersNewApplied[nameFilterApplied] =
            state.filtersApplied[nameFilterApplied];
        }
      }

      // only apply delivered filters belonging to the preset view
      // TODO: Error handling if a filter not belonging to preset view
      //  exists
      let filtersActionView = {};
      for (var nameFilter in action.result) {
        if (filtersView.includes(nameFilter)) {
          filtersActionView[nameFilter] = action.result[nameFilter];
        }
      }

      // Add the filters from the preset
      Object.assign(filtersNewApplied, filtersActionView);
      Object.assign(filtersNewSet, filtersActionView);

      return {
        ...state,
        filtersApplied: {
          ...filtersNewApplied
        },
        filtersSet: {
          ...filtersNewSet
        },
        presetLoaded: true,
        presetLoading: false,
        errorGetPreset: null
      };
    case `${GET_FILTER_PRESET_USER}_PENDING`:
    case `${GET_FILTER_PRESET_PROJECT}_PENDING`:
      // TODO: Parse JSON and set set and applied filters
      return {
        ...state,
        presetLoaded: false,
        presetLoading: true,
        errorGetPreset: null
      };
    case `${GET_FILTER_PRESET_USER}_FAIL`:
    case `${GET_FILTER_PRESET_PROJECT}_FAIL`:
      // TODO: Parse JSON and set set and applied filters
      return {
        ...state,
        presetLoaded: false,
        presetLoading: false,
        errorGetPreset: action.error
      };
    case `${STORE_FILTER_PRESET}_SUCCESS`:
      return {
        ...state,
        presetStored: true,
        presetStoring: false,
        errorStorePreset: null
      };
    case `${STORE_FILTER_PRESET}_PENDING`:
      return {
        ...state,
        presetStored: false,
        presetStoring: true,
        errorStorePreset: null
      };
    case `${STORE_FILTER_PRESET}_FAIL`:
      return {
        ...state,
        presetStored: false,
        presetStoring: false,
        errorStorePreset: action.error
      };
    case `${DELETE_FILTER_PRESET_PROJECT}_SUCCESS`:
    case `${DELETE_FILTER_PRESET_USER}_SUCCESS`:
      return {
        ...state,
        presetDeleted: true,
        presetDeleting: false,
        errorDeletePreset: null
      };
    case `${DELETE_FILTER_PRESET_PROJECT}_PENDING`:
    case `${DELETE_FILTER_PRESET_USER}_PENDING`:
      return {
        ...state,
        presetDeleted: false,
        presetDeleting: true,
        errorDeletePreset: null
      };
    case `${DELETE_FILTER_PRESET_PROJECT}_FAIL`:
    case `${DELETE_FILTER_PRESET_USER}_FAIL`:
      return {
        ...state,
        presetDeleted: false,
        presetDeleting: false,
        errorDeletePreset: action.error
      };
    default:
      return state;
  }
}
