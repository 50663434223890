/**
 * BarGeneric component.
 * @module components/charts/BarGeneric/BarGeneric
 */

import React, { Component } from "react";
import ReactDOM from "react-dom";

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  createContainer,
  VictoryVoronoi,
  VictoryLabel,
} from "victory";
import { getColor, getColorBarDefault } from "../../../helpers/colors/colors";
import { connect } from "react-redux";
import {
  getMinStepsizeX,
  formatTick,
  formatLabelUnit,
  formatTickBar,
  formatTickForStackBar,
} from "../../../helpers/charts/common_functions";

import {
  VIEW_GRUNDDATEN_STRATEGY,
  VIEW_GRUNDDATEN_STRATEGY_COMPARISON,
} from "../../../constants/NamesViews";

//import { labels } from "../../../constants/Filters";
import { labels } from "../../../constants/LabelsAxes";

import { VictoryTheme } from "../../../constants/VictoryTheme";
import {
  setBarFilter,
  setFilterApplied,
} from "../../../actions/filters/filters";

import { CHART_SETTINGS } from "../../../constants/LabelsAxes";
import { units as unitLabels } from "../../../constants/chartSettings";

/**
 * BarGeneric component class.
 * @class BarGeneric
 * @extends Component
 */

class BarGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
    };
  }
  handleChange = (e, value) => {
    this.setState({ value: value });
  };
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  componentDidMount() {
    this.props.setFilterApplied(this.props.id);
  }
  //
  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.forceUpdate()
    // if (this.props.filtersSet !== nextProps.filtersSet) {
    //   this.forceUpdate()
    // }
    // if (!!domainFilter) {
    //   this.refBrush.domain.x = domainFilter
    // } else {
    //   this.refBrush.domain.x = undefined
    // }
    if (this.state.value === undefined && nextProps.data.length !== 0) {
      this.setState({
        value: [
          nextProps.data[0].x,
          nextProps.data[nextProps.data.length - 1].x,
        ],
      });
    }
  }

  setDomainToNearest(domain, data) {
    // Obtain the step size
    const valuesX = data.map((datapoint) => datapoint.x);
    const valuesXSorted = valuesX.sort((a, b) => a - b);
    const HalfStepSize = getMinStepsizeX(valuesXSorted) / 2;

    // Create array of values shifted by half stepsize to get centers between
    // bars.
    let valuesXShifted = valuesXSorted.map((x) => x + HalfStepSize);
    // Consider first snapping point before first bar
    valuesXShifted.unshift(valuesXSorted[0] - HalfStepSize);

    // perform snapping
    domain.x = [
      this.getClosestNumberArray(domain.x[0], valuesXShifted),
      this.getClosestNumberArray(domain.x[1], valuesXShifted),
    ];
  }

  getClosestNumberArray(value, array) {
    return array.reduce(function (prev, curr) {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
  }

  setFilter(valueX) {
    // Set the bar filter on Click. Whether fist or last element is set
    // is determined by whether first or last input field in chart
    // is focused.

    const filterDefined = this.props.filterSet !== undefined;
    const { refRangeBarMax, refRangeBarMin, filterSet, id } = this.props;
    if (
      refRangeBarMax.current &&
      document.activeElement === ReactDOM.findDOMNode(refRangeBarMax.current)
    ) {
      if (!filterDefined || filterSet[0] < valueX) {
        this.props.setBarFilter(id, [
          filterDefined ? filterSet[0] : "",
          valueX,
        ]);
      }
    } else if (
      refRangeBarMin.current &&
      document.activeElement === ReactDOM.findDOMNode(refRangeBarMin.current)
    ) {
      if (!filterDefined || filterSet[1] > valueX) {
        this.props.setBarFilter(id, [
          valueX,
          filterDefined ? filterSet[1] : "",
        ]);
      }
    }
  }

  getLabelX() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.
    const titleChart = labels[this.props.id];
    const unit = this.props.unitsCharts[this.props.id].unit[0];

    return formatLabelUnit(titleChart, unit);
  }

  getLabelY() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.

    if (
      this.props.typeView === VIEW_GRUNDDATEN_STRATEGY &&
      this.props.idChartStrategy !== undefined
    ) {
      return CHART_SETTINGS.scales[this.props.scale](
        labels[this.props.idChartStrategy],
        this.props.unitsCharts[this.props.idChartStrategy].unit[0]
      );
    } else if (
      this.props.typeView === VIEW_GRUNDDATEN_STRATEGY_COMPARISON &&
      this.props.idChartDifferences !== undefined
    ) {
      return CHART_SETTINGS.scales[this.props.scale](
        labels[this.props.idChartDifferences],
        this.props.unitsCharts[this.props.idChartDifferences].unit[0]
      );
    }

    return CHART_SETTINGS.scales[this.props.scale](
      unitLabels[this.props.unit],
      CHART_SETTINGS.units[this.props.unit]
    );
  }

  secondLastYElement (dataset) {
    let yArray = [];
    let ySecLas = undefined;
    if (dataset !== undefined) {
        dataset.map(Ydata => yArray.push(Ydata.y));
    }
    ySecLas =  yArray[yArray.length -2];
    return ySecLas;

  }
  giveCapHeightToAxis(strategyId) {
  var capHeight ;
  switch( strategyId ) {
    case "zustandsklasseStrategie":
    case "substanzklasseStrategie":
    case "substanzklasseDichtheitStrategie":
    case "substanzklasseBetriebStrategie":
    case "substanzklasseStandsicherheitStrategie":
      capHeight = -1.5;
      break;
    default:
      capHeight = -5;
  }
  return capHeight;
}


  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, filterSet, data, diagramColorMap, reference, idChartStrategy } = this.props;
    // minimum y-value to draw x-axis at lowest value if
    // negative values are present
    const yMin = Math.min(...data.map((element) => element.y));
    const VictoryZoomVoronoiContainer = createContainer("voronoi", "zoom");
    return (
      <div>
        <VictoryChart
          key={Date.now()}
          theme={VictoryTheme}
          domainPadding={10}
          all
          height={280}
          width={this.props.isMaximized ? 920 : 845}
          ref={reference}
          padding={{ left: 90, right: 30, top: 30, bottom: 60 }}
          allowZoom={true}
          containerComponent={
            <VictoryZoomVoronoiContainer
              zoomDimension="x"
              mouseFollowTooltips
            />
          }
        >
          <VictoryBar
            height={250}
            width={800}
            style={{
              data: {
                // todo: if chart not displayed in map, use default color
                fill: ({ datum }) =>
                  diagramColorMap === id
                    ? getColor(id, datum.x, false, filterSet)
                    : getColorBarDefault(id, datum.x, filterSet),
              },
            }}
            data={data}
            labels={(datum) => {
              return `${datum.datum.x}: ${datum.datum.y}`;
            }}
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{ stroke: null }}
                constrainToVisibleArea
              />
            }
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "#cecece" },
              ticks: { stroke: "#cecece", size: 5 },
            }}
            tickFormat={formatTickForStackBar}
            axisLabelComponent={
              <VictoryLabel
                lineHeight={1}
                capHeight={this.giveCapHeightToAxis(idChartStrategy)}
                style={[{ fill: "black" }]}
              />
            }

            label={this.getLabelY()}
            crossAxis={yMin >= 0}
          />
          <VictoryAxis
            style={{
              axis: { stroke: "#cecece" },
              ticks: { stroke: "#cecece", size: 5 },
            }}
            tickFormat={formatTickBar}
            axisValue={Math.min(yMin, 0)}
            axisLabelComponent={
              <VictoryLabel capHeight={2} style={[{ fill: "black" }]} />
            }
            label={this.getLabelX()}
          />

          {yMin < 0 && (
            <VictoryAxis
              style={{
                axis: { stroke: "#cecece" },
                ticks: { stroke: "#transparent" },
                tickLabels: { fill: "none" },
              }}
              minDomain={-3}
              axisValue={0}
            />
          )}
          <VictoryVoronoi
            data={data.map((datapoint) => {
              return {
                x: datapoint.x,
                y: 0,
              };
            })}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: (event, data) => {
                    this.setFilter(data.datum.x);
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      filterSet: state.filters.filtersSet[props.id],
      diagramColorMap: state.map.diagramColor,
      isMaximized: state.chartSettings.maximized.includes(props.id),
      idChartDifferences: state.strategieVergleich.idChartDifferences,
      idChartStrategy: state.strategie.idChart,
      unit: state.chartSettings.unit[props.id],
      scale: state.chartSettings.scale[props.id],
      // FIXME: handle case that no unit for chart is present in state
      unitsCharts: state.projects.metadataProject.unitsCharts,
    };
  },
  { setBarFilter, setFilterApplied }
)(BarGeneric);
