/**
 * User session reducer.
 * @module reducers/userSession/userSession
 */

import { LOGIN, LOGOUT, REGISTER } from "../../constants/ActionTypes";
import cookie from "react-cookie";
import jwtDecode from "jwt-decode";

const token = cookie.load("auth_token");
const user = token && jwtDecode(token);

const initialState = {
  token,
  fullname: user && user.fullname,
  admin: user && user.admin,
  login: {
    loaded: false,
    loading: false,
    error: null,
  },
  register: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * User session reducer.
 * @function userSession
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function userSession(state = initialState, action = {}) {
  switch (action.type) {
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        token: null,
        fullname: "",
        admin: false,
        login: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${LOGIN}_SUCCESS`:
      const jwt_login = jwtDecode(action.result.token);
      return {
        ...state,
        token: action.result.token,
        fullname: jwt_login && jwt_login.fullname,
        admin: jwt_login && jwt_login.admin,
        login: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LOGIN}_FAIL`:
      return {
        ...state,
        token: null,
        fullname: "",
        admin: false,
        login: {
          loading: false,
          loaded: false,
          error: action.error.result,
        },
      };
    case LOGOUT:
      cookie.remove("auth_token", { path: "/" });
      cookie.remove("project", { path: "/" });
      return {
        ...state,
        token: null,
        fullname: "",
        admin: false,
      };
    case `${REGISTER}_PENDING`:
      return {
        ...state,
        register: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${REGISTER}_SUCCESS`:
      return {
        ...state,
        register: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${REGISTER}_FAIL`:
      return {
        ...state,
        register: {
          loading: false,
          loaded: false,
          error: action.error.result,
        },
      };
    default:
      return state;
  }
}
