/**
 * Sidebar component.
 * @module components/elements/Sidebar/Sidebar
 */

import React from "react";
import { connect } from "react-redux";
import {
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { isEqual, map } from "lodash";
import {
  getGrunddaten,
  setSidebarOpen,
} from "../../../actions/grunddaten/grunddaten";
import {
  setDetailviewOpen,
  resetDetailview,
} from "../../../actions/detailview/detailview";
import {
  applyFilters,
  removeFilters,
  removeFilter,
} from "../../../actions/filters/filters";
import {
  resetChartSettings,
  setChartsUnit,
  setChartsScale,
} from "../../../actions/chartSettings/chartSettings";
import { resetMap } from "../../../actions/map/map";
import { units, scales } from "../../../constants/chartSettings";
import Chart from "../../charts/Chart/Chart";
import Detailview from "../../elements/Detailview/Detailview";
import FoldableSidebar from "../../elements/FoldableSidebar/FoldableSidebar";

import { setCurrentDashboard } from "../../../actions/views/views";

import { diagramsViews } from "../../../constants/Dashboards";
import * as charts from "../../../constants/Charts";
import { VIEW_GRUNDDATEN } from "../../../constants/NamesViews";

// TODO: Dropdown

/**
 * Sidebar component class.
 * @class Sidebar
 * @extends Component
 */
class Sidebar extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * On toggle handler
   * @method onToggle
   * @returns {undefined}
   */
  onToggle() {
    this.props.setSidebarOpen(!this.props.open);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  componentDidMount() {
    this.props.typeDashboard &&
      this.props.setCurrentDashboard(this.props.typeDashboard);
    this.props.project !== null &&
      this.props.getGrunddaten(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.typeDashboard
      );
    // When opening a dashboard, the Detailview is closed initially.
    this.props.setDetailviewOpen(false, undefined, undefined, undefined);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.project !== this.props.project) {
      prevProps.resetMap();
      prevProps.resetChartSettings();
      prevProps.resetDetailview();
      prevProps.removeFilters();
    }
    if (
      !isEqual(prevProps.filtersApplied, this.props.filtersApplied) ||
      !isEqual(prevProps.scales, this.props.scales) ||
      !isEqual(prevProps.units, this.props.units) ||
      !isEqual(prevProps.bucket, this.props.bucket) ||
      !isEqual(prevProps.project, this.props.project)
    ) {
      prevProps.getGrunddaten(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.typeDashboard
      );
    }
  }

  getDefaultsIfMissing(values, default_) {
    return values === undefined ? default_ : values;
  }

  checkFilterSet() {
    // deep comparison
    return !isEqual(this.props.filtersApplied, this.props.filtersSet);
  }

  /**
   * Draw the diagrams in the dashboard
   * @method componentDidMount
   * @returns {undefined}
   */
  drawDashboard() {
    const { grunddaten, typeDashboard, widthSidebar } = this.props;

    const widthContent = 100 - widthSidebar;

    const unitsDropdown = this.getDefaultsIfMissing(undefined, units);
    const scalesDropdown = this.getDefaultsIfMissing(undefined, scales);
    const showScaleButton = true;
    const showUnitButton = true;
    //    console.log(grunddaten["alter"]);
    //    console.log(charts.ALTER);

    return (
      // TODO: The sidebar div itself should fold and take
      //  its children (basedata). It can then be refactored into individual
      //  component reusable in detailView. Should have title attribute
      //  that can be passed.
      <div className="mainWrapper">
        <FoldableSidebar
          title={
            <div className="title-sidebar">
              <span className="filter-label">Basisdaten</span>
              {(showUnitButton || showScaleButton) && (
                <UncontrolledDropdown size="sm" style={{ display: "inline" }}>
                  <DropdownToggle tag="span">
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      Optionen (Global)
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {showScaleButton &&
                      map(Object.keys(scalesDropdown), (key_scale) => (
                        <DropdownItem
                          key={key_scale}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.setChartsScale(
                              Object.keys(diagramsViews[VIEW_GRUNDDATEN]),
                              key_scale
                            )
                          }
                        >
                          <span>{scalesDropdown[key_scale]}</span>
                        </DropdownItem>
                      ))}
                    {showUnitButton && (
                      <React.Fragment>
                        <DropdownItem divider />
                        {map(Object.keys(unitsDropdown), (key_unit) => (
                          <DropdownItem
                            key={key_unit}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.props.setChartsUnit(
                                Object.keys(diagramsViews[VIEW_GRUNDDATEN]),
                                key_unit
                              )
                            }
                          >
                            <span>{unitsDropdown[key_unit]}</span>
                          </DropdownItem>
                        ))}
                      </React.Fragment>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          }
          showFilteringUi={true}
          width={widthSidebar}
        >
          <Row>
            <Chart
              data={grunddaten[charts.BAUJAHR]}
              id={charts.BAUJAHR}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.MATERIALGRUPPE]}
              id={charts.MATERIALGRUPPE}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.MITTLERE_TIEFE]}
              id={charts.MITTLERE_TIEFE}
              typeView={VIEW_GRUNDDATEN}
            />
             <Chart
              data={grunddaten[charts.KANALNUTZUNG]}
              id={charts.KANALNUTZUNG}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.HALTUNGSLAENGE]}
              id={charts.HALTUNGSLAENGE}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.KANALART]}
              id={charts.KANALART}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.NENNWEITE]}
              id={charts.NENNWEITE}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.LAGE_IM_VERKEHRSRAUM]}
              id={charts.LAGE_IM_VERKEHRSRAUM}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.ALTER]}
              id={charts.ALTER}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.PROFILART]}
              id={charts.PROFILART}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.RESTNUTZUNGSDAUER]}
              id={charts.RESTNUTZUNGSDAUER}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.VERIFIKATION]}
              id={charts.VERIFIKATION}
              typeView={VIEW_GRUNDDATEN}
            />
            <Chart
              data={grunddaten[charts.SANIERUNGSLAENGE]}
              id={charts.SANIERUNGSLAENGE}
              typeView={VIEW_GRUNDDATEN}
            />
          </Row>
        </FoldableSidebar>
        <div
          className={
            this.props.open
              ? "mainColumn sidebarOpen"
              : "mainColumn sidebarClosed"
          }
          style={{
            width: this.props.open ? `${widthContent}%` : "100%",
          }}
        >
          {
            // Pass the toggleDetailView function to children diagram panels
            React.cloneElement(this.props.children, {
              typeDashboard: typeDashboard,
            })
          }
        </div>
      </div>
    );
  }

  drawContent() {
    if (this.props.detailviewIsOpen) {
      // REMARK: Need to draw the child dashboard so that updates
      //  are made in detailview
      return (
        <div>
          <div style={{ display: "none" }}>{this.props.children}</div>
          <Detailview>{this.props.children}</Detailview>
        </div>
      );
    } else {
      return this.drawDashboard();
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return this.drawContent();
  }
}

export default connect(
  (state, props) => {
    const diagrams = diagramsViews[VIEW_GRUNDDATEN];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    return {
      widthSidebar: state.grunddaten.widthSidebar,
      open: state.grunddaten.sidebarIsOpen,
      isLoaded: state.grunddaten.loaded,
      grunddaten: state.grunddaten.data,
      filtersApplied: state.filters.filtersApplied,
      filtersSet: state.filters.filtersSet,
      project: state.projects.project,
      token: state.userSession.token,
      bucket: state.projects.bucket,
      detailviewIsOpen: state.detailview.isOpen,
      units: units,
      scales: scales,
    };
  },
  {
    getGrunddaten,
    setDetailviewOpen,
    applyFilters,
    removeFilters,
    removeFilter,
    setSidebarOpen,
    resetMap,
    resetChartSettings,
    resetDetailview,
    setCurrentDashboard,
    setChartsUnit,
    setChartsScale,
  }
)(Sidebar);
