/**
 * Objectklassifizierung schutzziele reducer.
 * @module reducers/objectklassifizierungSchutzziele/objectklassifizierungSchutzziele
 */

import {
  GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE,
  SET_PROJECT,
  SET_BUCKET,
} from "../../constants/ActionTypes";
import { isEmpty } from "lodash";

const initialState = {
  error: null,
  data: {
    zustandStandsicherheit: [],
    substanzStandsicherheit: [],
    zustandBetrieb: [],
    substanzBetrieb: [],
    zustandDichtheit: [],
    substanzDichtheit: [],
    SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR: [],
  },
  loaded: false,
  loading: false,
};

/**
 * Objectklassifizierung schutzziele reducer.
 * @function objectklassifizierungSchutzziele
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function objectklassifizierungSchutzziele(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case `${GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}_SUCCESS`:
      return {
        ...state,
        error: null,
        data: !isEmpty(action.result) ? action.result : initialState.data,
        loaded: true,
        loading: false,
      };
    case `${GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loaded: false,
        loading: false,
      };
    case SET_BUCKET:
      return {
        ...initialState,
      };
    case SET_PROJECT:
      return { ...initialState };
    default:
      return state;
  }
}
