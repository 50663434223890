/**
 * Pie colors.
 * @module constants/Permissions
 */

// Define consts for gradient types
export const COLOR_TYPE_GRADIENT = "COLOR_TYPE_GRADIENT";
export const COLOR_TYPE_DISCRETE = "COLOR_TYPE_DISCRETE";

export const COLOR_FALLBACK = "#555555";
export const COLOR_PIE_FILTERED = "#ff00ff";

export const COLOR_MAP_DEFAULT = "#0099ff";
export const COLOR_MAP_BACKGROUND = "#000000";
export const COLOR_MAP_SELECTED = COLOR_PIE_FILTERED;

export const COLOR_MARK_YEAR_STRATEGY = "red";

export const colors = ["#eb555d", "#49a3a6", "#65cd98", "#56637d", "#ef8459"];

// TODO: Consider correct handling of value types

const barColorMin = [209, 80, 0];
const barColorMax = [0, 148, 0];
const differenceColorMin = [255, 0, 0];
const differenceColorCenter = [100, 100, 100];
const differenceColorMax = [0, 255, 0];
const barDefault = "rgb(12, 126, 207)";
export const barFiltered = "rgb(150, 150, 150)";
const pieClass0 = "rgb(0, 134, 237)";
const pieClass1 = "rgb(0, 221, 237)";
const pieClass2 = "rgb(53, 226, 0)";
const pieClass3 = "rgb(223, 226, 0)";
const pieClass4 = "rgb(227, 140, 0)";
const pieClass5 = "rgb(227, 0, 0)";
const greyUnknown = "rgb(50, 50, 50)";

export const classes = {
  0: pieClass0,
  1: pieClass1,
  2: pieClass2,
  3: pieClass3,
  4: pieClass4,
  5: pieClass5,
};

export const classesReverse = {
  0: pieClass5,
  1: pieClass4,
  2: pieClass3,
  3: pieClass2,
  4: pieClass1,
  5: pieClass0,
};

const palettePie = [
  "rgb(208, 192, 53)",
  "rgb(184, 208, 53)",
  "rgb(21, 51, 222)",
  "rgb(21, 122, 222)",
  "rgb(24, 174, 191)",
  "rgb(127, 63, 145)",
  "rgb(216, 27, 27)",
  "rgb(145, 145, 145)",
  greyUnknown, // Use grey for unknown values
];

export const actionsRehab = {
  // Remark: Sonstige is removed, although bremen seems to have
  //  Other actions than erneuerung, reparatur and renovierung
  //Sonstige: greyUnknown,
  Erneuerung: palettePie[1],
  Renovierung: palettePie[2],
  Reparatur: palettePie[3],
  // TODO: Reparatur is removed for now. See #233
  // Reparatur: palettePie[4]
};

const strategien = {
  Weiterso: "rgb(222, 118, 21)",
  Substanzerhalt: "rgb(21, 222, 155)",
  Monitoring: "rgb(7, 144, 242)",

  // Bremen IDs
  1: "rgb(214, 30, 30)", // null
  2: "rgb(94, 94, 94)", // Weiterso
  3: "rgb(131, 201, 60)", // Substanz 69
  4: "rgb(60, 201, 74)",
  5: "rgb(60, 170, 201)",
  6: "rgb(60, 140, 201)",

  // Braunschweig
  7: "rgb(214, 30, 30)", // null
  8: "rgb(94, 94, 94)", // Weiterso
  9: "rgb(131, 201, 60)", // Substanz 69
  10: "rgb(60, 201, 74)",
  11: "rgb(60, 170, 201)",
  12: "rgb(60, 140, 201)",
};

const impacts = {
  general: "black",
  betrieb: "#ffb700",
  dichtheit: "#0000cc",
  standsicherheit: "#00bc00",
};

const isSani = {
  1: "rgb(0, 221, 237)",
};

const differences = "rgb(100, 200, 100)";

export const mapping_colors_gradient = {
  // Bachrarts Grunddaten
  baujahr: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 1900,
    value_max: 2020,
  },
  mittlereTiefe: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 10,
  },
  haltungslaenge: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 100,
  },
  inspektionslaenge: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 100,
  },
  nennweite: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 1000,
  },
  Restnutzungsdauer: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 1000,
  },
  Alter: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 1000,
  },
  Sanierungslaenge: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 1000,
  },
  Verifikation: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 1000,
  },
  inspektionsjahr: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 1990,
    value_max: 2020,
  },
  inspektionsjahrSubstanzSchutzziele: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 1990,
    value_max: 2020,
  },
  inspektionsjahrSubstanz: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 1990,
    value_max: 2020,
  },
  sanierungskosten_map: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 10000,
  },
  sanierungskostenRep_map: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 10000,
  },
  sanierungskostenRen_map: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 10000,
  },
  sanierungskostenErn_map: {
    color_min: barColorMin,
    color_max: barColorMax,
    value_min: 0,
    value_max: 10000,
  },
  rbwWbk_map: {
    color_min: differenceColorMin,
    color_max: differenceColorMax,
    value_min: 0,
    value_max: 80000,
  },
  substanzAvgDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -100,
    value_max: 100,
  },
  zustandAvgDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -5,
    value_max: 5,
  },
  substanzBetriebAvgDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -5,
    value_max: 5,
  },
  substanzDichtheitAvgDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -5,
    value_max: 5,
  },
  substanzStandsicherheitAvgDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -5,
    value_max: 5,
  },
  sanierungenSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -1,
    value_max: 1,
  },
  sanierungenRepSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -1,
    value_max: 1,
  },
  sanierungenRenSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -1,
    value_max: 1,
  },
  sanierungenErnSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -1,
    value_max: 1,
  },
  sanierungskostenSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -10000,
    value_max: 10000,
  },
  sanierungskostenRepSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -10000,
    value_max: 10000,
  },
  sanierungskostenRenSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -10000,
    value_max: 10000,
  },
  sanierungskostenErnSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -10000,
    value_max: 10000,
  },
  rbwWbkSumDifference_map: {
    color_min: differenceColorMin,
    color_center: differenceColorCenter,
    color_max: differenceColorMax,
    value_min: -10000,
    value_max: 10000,
  },
};

export const mapping_colors_discrete = {
  // strategys for labels in Legend
  strategien: {
    ...strategien,
  },

  // classes for colors of classes
  classes: {
    ...classes,
  },

  actionsRehab: {
    ...actionsRehab,
  },

  // objectview Object
  prognosisObjectSubstanz: {
    subs: impacts.general,
    seco: impacts.dichtheit,
    sstb: impacts.standsicherheit,
    scap: impacts.betrieb,
  },

  prognosisObjectZustand: {
    prio_class: impacts.general,
    zeco_class: impacts.dichtheit,
    zstb_class: impacts.standsicherheit,
    zcap_class: impacts.betrieb,
  },

  prognosisObjectLegend: {
    "Priorität / Substanz gesamt": impacts.general,
    Dichtheit: impacts.dichtheit,
    Standsicherheit: impacts.standsicherheit,
    Betrieb: impacts.betrieb,
  },

  // Grunddaten
  materialgruppe: {
    Mauerwerk: palettePie[0],
    Steinzeug: palettePie[1],
    Beton: palettePie[2],
    "Unbewehrter Beton": palettePie[3],
    Stahlbeton: palettePie[4],
    Stahl: palettePie[5],
    Kunststoff: palettePie[6],
    Sonstiges: palettePie[7],
  },
  kanalnutzung: {
    D: palettePie[0],
    M: palettePie[2],
    R: palettePie[4],
    S: palettePie[6],
    I: palettePie[7],
    G: palettePie[7],
    H: palettePie[7],
    T: palettePie[7],
    B: palettePie[7],
    X: palettePie[7],
    X1: palettePie[7],
    X2: palettePie[7],
    X3: palettePie[7],
    X4: palettePie[7],
    Z: palettePie[7],
    O: palettePie[7],
    K: palettePie[7],
    AB: palettePie[7],
  },
  kanalart: {
    F: palettePie[0],
    D: palettePie[2],
    U: palettePie[3],
    G: palettePie[4],
    K: palettePie[5],
    SBW: palettePie[6],
  },
  lageImVerkehrsraum: {
    // sTRASSEN 2,
    // Hauptstrassen 3
    // Gleise 4
    // Gruen 1
    // Privat 5
    // bebaut 6
    // Gewaesser 0
    // Unbekannt 7 (grey)
    0: palettePie[7],
    O: palettePie[7],
    A: palettePie[1],
    BA: palettePie[2],
    BG: palettePie[6],
    IB: palettePie[6],
    BO: palettePie[1],
    F: palettePie[2],
    FR: palettePie[1],
    FZ: palettePie[6],
    HF: palettePie[6],
    G: palettePie[0],
    GE: palettePie[6],
    GL: palettePie[4],
    GLDB: palettePie[4],
    GLPR: palettePie[4],
    GS: palettePie[1],
    GF: palettePie[1],
    GW: palettePie[6],
    RGW: palettePie[6],
    KA: palettePie[6],
    SP: palettePie[6],
    P: palettePie[2],
    PS: palettePie[2],
    PW: palettePie[5],
    PR: palettePie[5],
    RW: palettePie[2],
    W: palettePie[1],
    WD: palettePie[1],
    WB: palettePie[1],
    WU: palettePie[1],
    Z: palettePie[7],
    AU: palettePie[3],
    LB: palettePie[6],
    B: palettePie[3],
    H: palettePie[3],
    L: palettePie[3],
    N: palettePie[2],
    ZOO: palettePie[1],
  },
  profilart: {
    DP: palettePie[0],
    "DP_1:1": palettePie[0],
    DPG: palettePie[0],
    DPS: palettePie[0],
    DPU: palettePie[0],
    DPR: palettePie[0],
    E: palettePie[1],
    "E_2:3": palettePie[1],
    EB: palettePie[1],
    "EB_2:2.5": palettePie[1],
    EG: palettePie[1],
    "EG_1:1": palettePie[1],
    EGB: palettePie[1],
    EGN: palettePie[1],
    ELB: palettePie[1],
    "ELB_2:2.75": palettePie[1],
    ELG: palettePie[1],
    "ELG_2:2.25": palettePie[1],
    ELU: palettePie[1],
    "ELU_2:3.25": palettePie[1],
    EU: palettePie[1],
    "EU_2:3.5": palettePie[1],
    EW: palettePie[1],
    "EW_2:3": palettePie[1],
    SUE: palettePie[1],
    "SUE_2:3": palettePie[1],
    SUE_EB: palettePie[1],
    "SUE_EB_2:2.5": palettePie[1],
    F: palettePie[7],
    GG: palettePie[2],
    G: palettePie[2],
    GS: palettePie[2],
    "G_0.628": palettePie[2],
    "G_0.39": palettePie[2],
    "G_0.375": palettePie[2],
    GP: palettePie[2],
    H: palettePie[3],
    "H_2:2.5": palettePie[3],
    HT: palettePie[3],
    HU: palettePie[3],
    HD: palettePie[3],
    K: palettePie[4],
    KW: palettePie[4],
    KF: palettePie[4],
    KFW: palettePie[4],
    KDW: palettePie[4],
    KDA: palettePie[4],
    KIP: palettePie[4],
    "KIP_0.95:1": palettePie[4],
    KG: palettePie[4],
    "KG_2:2.5": palettePie[4],
    KU: palettePie[4],
    "KU_2:3": palettePie[4],
    M: palettePie[5],
    MB: palettePie[5],
    "MB_3:2": palettePie[5],
    MG: palettePie[5],
    "MG_2:1.25": palettePie[5],
    "MG_2:1.50": palettePie[5],
    MEB: palettePie[5],
    "MEB_2:1": palettePie[5],
    MS: palettePie[5],
    MT: palettePie[5],
    MU: palettePie[5],
    MUT2: palettePie[5],
    "MU_1:1": palettePie[5],
    MW: palettePie[5],
    "MW_2:1.66": palettePie[5],
    SUM: palettePie[5],
    MUL: palettePie[5],
    P: palettePie[7],
    "P_1:1": palettePie[7],
    PM: palettePie[7],
    R: palettePie[6],
    "R_1:2": palettePie[6],
    "R_3:4": palettePie[6],
    "R_1.1:1": palettePie[6],
    "R_1.2:1": palettePie[6],
    "R_3.4:2.7": palettePie[6],
    "R_1.3:1": palettePie[6],
    "R_1.4:1": palettePie[6],
    "R_1.5:1": palettePie[6],
    "R_1.6:1": palettePie[6],
    "R_1.7:1": palettePie[6],
    "R_1.8:1": palettePie[6],
    "R_1.9:1": palettePie[6],
    "R_2:1": palettePie[6],
    "R_4:1": palettePie[6],
    RB: palettePie[6],
    RE: palettePie[6],
    RG: palettePie[6],
    RSG: palettePie[6],
    RT: palettePie[6],
    RK: palettePie[6],
    "RK_1:1": palettePie[6],
    RKT: palettePie[6],
    "RKT_1:1": palettePie[6],
    RKG: palettePie[6],
    "RKG_1:1": palettePie[6],
    RI: palettePie[6],
    "RI_1:1": palettePie[6],
    "RI_3:4": palettePie[6],
    "RI_4:3": palettePie[6],
    RIB: palettePie[6],
    "RIB_1:1": palettePie[6],
    "RIB_3:4": palettePie[6],
    "RIB_4:3": palettePie[6],
    RIE: palettePie[6],
    "RIE_1:1": palettePie[6],
    "RIE_3:4": palettePie[6],
    "RIE_4:3": palettePie[6],
    S: palettePie[7],
    SG: palettePie[7],
    SK: palettePie[7],
    ST: palettePie[7],
    SP: palettePie[7],
    "SP_1.7:2.1": palettePie[7],
    "SP_1:1": palettePie[7],
    "SP_1.5:1.45": palettePie[7],
    "SP_2.6:2.5": palettePie[7],
    "SP_2.1:2": palettePie[7],
    "SP_1.8:1.65": palettePie[7],
    "SP_1.8:1.6": palettePie[7],
    "SP_1.81:1.53": palettePie[7],
    "SP_1.5:1.2": palettePie[7],
    "SP_1.8:1.3": palettePie[7],
    "SP_2.45:1.6": palettePie[7],
    "SP_2.6:1.6": palettePie[7],
    T: palettePie[6],
    TB: palettePie[6],
    TE: palettePie[6],
    TD: palettePie[6],
    TG: palettePie[6],
    "TG_2:1": palettePie[6],
    "TG_3:2": palettePie[6],
    "TG_1:1": palettePie[6],
    "TG_2:3": palettePie[6],
    "TG_1:2": palettePie[6],
    U: palettePie[7],
    UP: palettePie[7],
    KA: palettePie[7],
  },
  // inspektionsdaten
  inspektionsgrund: {
    A: palettePie[0],
    E: palettePie[1],
    G: palettePie[2],
    K: palettePie[7],
    N: palettePie[3],
    S: palettePie[4],
    V: palettePie[4],
    R: palettePie[5],
    H: palettePie[6],
    Z: palettePie[7],
    0: palettePie[7],
  },
  inspektionsart: {
    0: palettePie[7],
    BG: palettePie[0],
    TV: palettePie[1],
    KTV: palettePie[1],
    SP: palettePie[2],
    SK: palettePie[3],
    SAT: palettePie[4],
    VIR: palettePie[5],
    Z: palettePie[7],
  },
  kodiersystem: {
    ATV: palettePie[0],
    "ATV-M 143": palettePie[1],
    DWA: palettePie[2],
    "DWAM149:06": palettePie[3],
    "DWAM149:07": palettePie[3],
    "DWAM149:13": palettePie[3],
    "DWAM149:15": palettePie[3],
    "DWA-M 149 2006": palettePie[3],
    "DWA-M 149 2007": palettePie[3],
    "DWA-M 149 2013": palettePie[3],
    "DWA-M 149 2015": palettePie[3],
    "ISYBAU 1996": palettePie[4],
    "ISYBAU 2001": palettePie[4],
    "ISYBAU 2004": palettePie[4],
    "ISYBAU 2006": palettePie[5],
    "ISYBAU 2007": palettePie[5],
    "ISYBAU 2008": palettePie[5],
    "ISYBAU 2010": palettePie[5],
    "ISYBAU 2011": palettePie[5],
    "ISYBAU 2012": palettePie[5],
    "ISYBAU 2013": palettePie[5],
    "ISYBAU 2014.01": palettePie[5],
    "ISYBAU 2014.10": palettePie[5],
    "ISYBAU 2015": palettePie[5],
    "ISYBAU 2017": palettePie[5],
    "ISYBAU 2018": palettePie[5],
  },
  // objektklassifizierung
  substanzklasse: {
    ...classes,
  },
  zustandsklasse: {
    ...classes,
  },
  // objektklassifizierungSchutzziele
  zustandStandsicherheit: {
    ...classes,
  },
  substanzStandsicherheit: {
    ...classes,
  },
  zustandBetrieb: {
    ...classes,
  },
  substanzBetrieb: {
    ...classes,
  },
  zustandDichtheit: {
    ...classes,
  },
  substanzDichtheit: {
    ...classes,
  },
  // Schadensklassifizierung
  schadensklasseDichtheit: {
    ...classes,
  },
  schadensklasseStandsicherheit: {
    ...classes,
  },
  schadensklasseBetrieb: {
    ...classes,
  },
  schadensart: {
    //BCA: palettePie[7],
    //BCB: palettePie[],
    //BCC: palettePie[],
    //BCD: palettePie[],
    //BCE: palettePie[],
    //BDA: palettePie[],
    //BDC: palettePie[],
    BDD: palettePie[6],
    //BDF: palettePie[],
    //BDG: palettePie[],
    //AEC: palettePie[],
    //AED: palettePie[],
    BAA: palettePie[1],
    BAB: palettePie[0],
    BAC: palettePie[5],
    BAD: palettePie[7],
    BAE: palettePie[7],
    BAF: palettePie[4],
    BAG: palettePie[3],
    BAH: palettePie[7],
    BAI: palettePie[3],
    BAJ: palettePie[1],
    BAK: palettePie[7],
    BAL: palettePie[7],
    BAM: palettePie[7],
    BAN: palettePie[6],
    BAO: palettePie[7],
    BAP: palettePie[7],
    BBA: palettePie[3],
    BBB: palettePie[7],
    BBC: palettePie[3],
    BBD: palettePie[6],
    BBE: palettePie[3],
    BBF: palettePie[6],
    BBG: palettePie[6],
    BBH: palettePie[7],
    BDE: palettePie[6],
    //"A-": palettePie[],
    AN: palettePie[7],
    AP: palettePie[3],
    AR: palettePie[0],
    AU: palettePie[3],
    BA: palettePie[5],
    //BC: palettePie[],
    BK: palettePie[5],
    BR: palettePie[5],
    BS: palettePie[5],
    BT: palettePie[5],
    BW: palettePie[5],
    "C-": palettePie[2],
    CC: palettePie[2],
    CM: palettePie[2],
    "D-": palettePie[1],
    DR: palettePie[1],
    "F-": palettePie[7],
    "H-": palettePie[3],
    HD: palettePie[3],
    HE: palettePie[3],
    HF: palettePie[3],
    HG: palettePie[3],
    HI: palettePie[3],
    HK: palettePie[3],
    HM: palettePie[3],
    HP: palettePie[3],
    HS: palettePie[3],
    HZ: palettePie[3],
    //"K-": palettePie[],
    Ks: palettePie[7],
    Kn: palettePie[7],
    Kb: palettePie[7],
    Ku: palettePie[7],
    Kv: palettePie[7],
    Kf: palettePie[7],
    Ke: palettePie[7],
    Ka: palettePie[7],
    Kr: palettePie[7],
    Km: palettePie[7],
    LB: palettePie[1],
    LL: palettePie[1],
    LH: palettePie[1],
    LV: palettePie[1],
    RC: palettePie[0],
    RL: palettePie[0],
    RQ: palettePie[0],
    RS: palettePie[0],
    RX: palettePie[0],
    "S-": palettePie[8],
    SE: palettePie[8],
    SN: palettePie[8],
    SO: palettePie[8],
    SP: palettePie[8],
    SR: palettePie[8],
    SU: palettePie[8],
    UA: palettePie[6],
    UC: palettePie[6],
    UW: palettePie[6],
    "V-": palettePie[4],
    VA: palettePie[4],
    VC: palettePie[4],
    VW: palettePie[4],
    "W-": palettePie[7],
    //TK: palettePie[],
    //CK: palettePie[],
    //KN: palettePie[],
    //BDB: palettePie[],
  },
  // strategieVergleich
  zustandAvg: {
    ...strategien,
    Differenz: differences,
  },
  substanzAvg: {
    ...strategien,
    Differenz: differences,
  },
  substanzBetriebAvg: {
    ...strategien,
    Differenz: differences,
  },
  substanzDichtheitAvg: {
    ...strategien,
    Differenz: differences,
  },
  substanzStandsicherheitAvg: {
    ...strategien,
    Differenz: differences,
  },
  substanzAvgDifference: {
    Differenz: differences,
  },
  substanzBetriebAvgDifference: {
    Differenz: differences,
  },
  substanzDichtheitAvgDifference: {
    Differenz: differences,
  },
  substanzStandsicherheitAvgDifference: {
    Differenz: differences,
  },
  zustandAvgDifference: {
    Differenz: differences,
  },
  gebuehrenanteileSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenRepSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenRenSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenErnSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenRepSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenRenSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungenErnSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenRepSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenRenSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenErnSum: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenRepSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenRenSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  sanierungskostenErnSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  rbwWbkSum: {
    ...strategien,
    Differenz: differences,
  },
  rbwWbkSumDifference: {
    ...strategien,
    Differenz: differences,
  },
  // Strategie
  // TODO: Values for the following diagrams
  zustandsklasseStrategie: {
    ...classes,
  },
  substanzklasseStrategie: {
    ...classes,
  },
  substanzklasseDichtheitStrategie: {
    ...classes,
  },
  substanzklasseStandsicherheitStrategie: {
    ...classes,
  },
  substanzklasseBetriebStrategie: {
    ...classes,
  },
  gebuehrenanteile: {
    ...classes,
  },
  sanierungen: {
    ...actionsRehab,
  },
  sanierungskosten: {
    ...actionsRehab,
  },
  rbwWbk: {
    Restbuchwert: barDefault,
  },
  // Strategie Map
  zustandsklasseStrategie_map: {
    ...classes,
  },
  zustandsklasseBetriebStrategie_map: {
    ...classes,
  },
  zustandsklasseDichtheitStrategie_map: {
    ...classes,
  },
  zustandsklasseStandsicherheitStrategie_map: {
    ...classes,
  },
  substanzklasseStrategie_map: {
    ...classes,
  },
  substanzklasseBetriebStrategie_map: {
    ...classes,
  },
  substanzklasseDichtheitStrategie_map: {
    ...classes,
  },
  substanzklasseStandsicherheitStrategie_map: {
    ...classes,
  },
  sanierungen_map: {
    ...actionsRehab,
  },
  sanierungenRep_map: isSani,
  sanierungenRen_map: isSani,
  sanierungenErn_map: isSani,
};

// colors to show the barcharts with when they do not color the map
export const mapping_colors_barcharts_default = {
  baujahr: barDefault,
  mittlereTiefe: barDefault,
  haltungslaenge: barDefault,
  nennweite: barDefault,
  inspektionslaenge: barDefault,
  inspektionsjahr: barDefault,
  inspektionsjahrSubstanz: barDefault,
  inspektionsjahrSubstanzSchutzziele: barDefault,
  restnutzungsdauer: barDefault,
  alter: barDefault,
  sanierungslaenge: barDefault,
  verifikation: barDefault,
};

export const mapping_types_colors = {
  // constants for legends
  strategien: COLOR_TYPE_DISCRETE,
  classes: COLOR_TYPE_DISCRETE,
  actionsRehab: COLOR_TYPE_DISCRETE,

  // Grunddaten: Piecharts
  materialgruppe: COLOR_TYPE_DISCRETE,
  kanalnutzung: COLOR_TYPE_DISCRETE,
  kanalart: COLOR_TYPE_DISCRETE,
  lageImVerkehrsraum: COLOR_TYPE_DISCRETE,
  profilart: COLOR_TYPE_DISCRETE,
  // Grunddaten: Barcharts
  baujahr: COLOR_TYPE_GRADIENT,
  mittlereTiefe: COLOR_TYPE_GRADIENT,
  haltungslaenge: COLOR_TYPE_GRADIENT,
  nennweite: COLOR_TYPE_GRADIENT,
  // Inspektionsdaten Piecharts
  inspektionsgrund: COLOR_TYPE_DISCRETE,
  inspektionsart: COLOR_TYPE_DISCRETE,
  kodiersystem: COLOR_TYPE_DISCRETE,
  // Inspektionsdaten Barcharts
  inspektionslaenge: COLOR_TYPE_GRADIENT,
  inspektionsjahr: COLOR_TYPE_GRADIENT,
  // Schadensklassifizierung Piecharts
  schadensklasseDichtheit: COLOR_TYPE_DISCRETE,
  schadensklasseStandsicherheit: COLOR_TYPE_DISCRETE,
  schadensklasseBetrieb: COLOR_TYPE_DISCRETE,
  schadensart: COLOR_TYPE_DISCRETE,
  // objektansichtSchutzziele Piecharts
  zustandStandsicherheit: COLOR_TYPE_DISCRETE,
  substanzStandsicherheit: COLOR_TYPE_DISCRETE,
  zustandBetrieb: COLOR_TYPE_DISCRETE,
  substanzBetrieb: COLOR_TYPE_DISCRETE,
  zustandDichtheit: COLOR_TYPE_DISCRETE,
  substanzDichtheit: COLOR_TYPE_DISCRETE,
  inspektionsjahrSubstanzSchutzziele: COLOR_TYPE_GRADIENT,
  // objektansicht Piecharts
  substanzklasse: COLOR_TYPE_DISCRETE,
  zustandsklasse: COLOR_TYPE_DISCRETE,
  inspektionsjahrSubstanz: COLOR_TYPE_GRADIENT,
  // Strategievergleich: Line
  zustandAvg: COLOR_TYPE_DISCRETE,
  substanzAvg: COLOR_TYPE_DISCRETE,
  substanzBetriebAvg: COLOR_TYPE_DISCRETE,
  substanzDichtheitAvg: COLOR_TYPE_DISCRETE,
  substanzStandsicherheitAvg: COLOR_TYPE_DISCRETE,
  zustandAvgDifference: COLOR_TYPE_DISCRETE,
  substanzAvgDifference: COLOR_TYPE_DISCRETE,
  substanzBetriebAvgDifference: COLOR_TYPE_DISCRETE,
  substanzDichtheitAvgDifference: COLOR_TYPE_DISCRETE,
  substanzStandsicherheitAvgDifference: COLOR_TYPE_DISCRETE,
  gebuehrenanteileSum: COLOR_TYPE_DISCRETE,
  sanierungenSum: COLOR_TYPE_DISCRETE,
  sanierungenRepSum: COLOR_TYPE_DISCRETE,
  sanierungenRenSum: COLOR_TYPE_DISCRETE,
  sanierungenErnSum: COLOR_TYPE_DISCRETE,
  sanierungenSumDifference: COLOR_TYPE_DISCRETE,
  sanierungenRepSumDifference: COLOR_TYPE_DISCRETE,
  sanierungenRenSumDifference: COLOR_TYPE_DISCRETE,
  sanierungenErnSumDifference: COLOR_TYPE_DISCRETE,
  sanierungskostenSum: COLOR_TYPE_DISCRETE,
  sanierungskostenRepSum: COLOR_TYPE_DISCRETE,
  sanierungskostenRenSum: COLOR_TYPE_DISCRETE,
  sanierungskostenErnSum: COLOR_TYPE_DISCRETE,
  sanierungskostenSumDifference: COLOR_TYPE_DISCRETE,
  sanierungskostenRepSumDifference: COLOR_TYPE_DISCRETE,
  sanierungskostenRenSumDifference: COLOR_TYPE_DISCRETE,
  sanierungskostenErnSumDifference: COLOR_TYPE_DISCRETE,
  rbwWbkSum: COLOR_TYPE_DISCRETE,
  rbwWbkSumDifference: COLOR_TYPE_DISCRETE,
  // Map for Strategievergleich
  zustandAvgDifference_map: COLOR_TYPE_GRADIENT,
  substanzAvgDifference_map: COLOR_TYPE_GRADIENT,
  substanzBetriebAvgDifference_map: COLOR_TYPE_GRADIENT,
  substanzDichtheitAvgDifference_map: COLOR_TYPE_GRADIENT,
  substanzStandsicherheitAvgDifference_map: COLOR_TYPE_GRADIENT,
  sanierungenSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungenRepSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungenRenSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungenErnSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungskostenSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungskostenRepSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungskostenRenSumDifference_map: COLOR_TYPE_GRADIENT,
  sanierungskostenErnSumDifference_map: COLOR_TYPE_GRADIENT,
  rbwWbkSumDifference_map: COLOR_TYPE_GRADIENT,
  // Strategie: Stacked Bar
  zustandsklasseStrategie: COLOR_TYPE_DISCRETE,
  substanzklasseStrategie: COLOR_TYPE_DISCRETE,
  substanzklasseDichtheitStrategie: COLOR_TYPE_DISCRETE,
  substanzklasseStandsicherheitStrategie: COLOR_TYPE_DISCRETE,
  substanzklasseBetriebStrategie: COLOR_TYPE_DISCRETE,
  gebuehrenanteile: COLOR_TYPE_DISCRETE,
  sanierungen: COLOR_TYPE_DISCRETE,
  sanierungskosten: COLOR_TYPE_DISCRETE,
  rbwWbk: COLOR_TYPE_DISCRETE,
  // Strategie Map
  zustandsklasseStrategie_map: COLOR_TYPE_DISCRETE,
  zustandsklasseBetriebStrategie_map: COLOR_TYPE_DISCRETE,
  zustandsklasseDichtheitStrategie_map: COLOR_TYPE_DISCRETE,
  zustandsklasseStandsicherheitStrategie_map: COLOR_TYPE_DISCRETE,
  substanzklasseStrategie_map: COLOR_TYPE_DISCRETE,
  substanzklasseBetriebStrategie_map: COLOR_TYPE_DISCRETE,
  substanzklasseDichtheitStrategie_map: COLOR_TYPE_DISCRETE,
  substanzklasseStandsicherheitStrategie_map: COLOR_TYPE_DISCRETE,
  sanierungen_map: COLOR_TYPE_DISCRETE,
  sanierungenRep_map: COLOR_TYPE_DISCRETE,
  sanierungenRen_map: COLOR_TYPE_DISCRETE,
  sanierungenErn_map: COLOR_TYPE_DISCRETE,
  sanierungskosten_map: COLOR_TYPE_GRADIENT,
  sanierungskostenRep_map: COLOR_TYPE_GRADIENT,
  sanierungskostenRen_map: COLOR_TYPE_GRADIENT,
  sanierungskostenErn_map: COLOR_TYPE_GRADIENT,
  rbwWbk_map: COLOR_TYPE_GRADIENT,

  // objectview Object
  prognosisObjectZustand: COLOR_TYPE_DISCRETE,
  prognosisObjectSubstanz: COLOR_TYPE_DISCRETE,
  prognosisObjectLegend: COLOR_TYPE_DISCRETE,
};
