/**
 * User session actions.
 * @module actions/userSession/userSession
 */

import { LOGIN, LOGOUT, REGISTER } from "../../constants/ActionTypes";

/**
 * Login function.
 * @function login
 * @param {string} username Username.
 * @param {string} password Password.
 * @returns {Object} Login action.
 */
export function login(username, password) {
  return {
    type: LOGIN,
    request: {
      op: "post",
      path: "login",
      data: { login: username, password }
    }
  };
}

/**
 * Logout function.
 * @function logout
 * @returns {Object} Logout action.
 */
export function logout() {
  return {
    type: LOGOUT
  };
}

/**
 * Register function.
 * @function register
 * @param {string} username Username.
 * @param {string} password Password.
 * @returns {Object} Register action.
 */
export function register(username, password) {
  return {
    type: REGISTER,
    request: {
      op: "post",
      path: "register",
      data: {
        login: username,
        password: password
      }
    }
  };
}
