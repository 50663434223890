/**
 * Login component.
 * @module components/views/Register/Register
 */

import React from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import {
  register,
  logout,
  login,
} from "../../../actions/userSession/userSession";
import BrandLogo from "../../../theme/app-assets/images/logo/logo.png";

/**
 * Register component class.
 * @class Register
 * @extends Component
 */
class Register extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onRegister = this.onRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordVerfify = this.onChangePasswordVerify.bind(this);
    this.state = {
      username: "",
      password: "",
      passwordVerify: "",
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    document.body.classList.add("bg-full-screen-image", "blank-page");
    this.props.token && this.props.logout();
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentWillUnmount() {
    document.body.classList.remove("bg-full-screen-image", "blank-page");
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If registration succceded, login
    if (
      nextProps.registered &&
      !this.props.registered &&
      !nextProps.registering &&
      this.props.registering &&
      nextProps.registerError === null
    ) {
      this.props.login(this.state.username, this.state.password);
    }

    // go to dashboards if logged in
    if (nextProps.token) {
      this.props.history.push("/");
    }
  }

  /**
   * On login handler
   * @method onLogin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onRegister(event) {
    this.props.register(this.state.username, this.state.password);
    event.preventDefault();
  }

  /**
   * On change username handler
   * @method onChangeUsername
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangeUsername(event) {
    this.setState({
      username: event.target.value,
    });
  }

  /**
   * On change password handler
   * @method onChangePassword
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangePassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  /**
   * On change password handler
   * @method onChangePassword
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onChangePasswordVerify(event) {
    // TODO: Check whether password equals the verify password.
    //  Or should this be done when component is updated? No, should be done while rendering.
    this.setState({
      passwordVerify: event.target.value,
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // TODO: If register succeeded, login with the username
    // TODO: If register failed, print error message.
    //  The message should denote that the user is not allowed to register or that the
    //  User exists already.

    // whether the given password is valid for registration
    const passwordValid =
      this.state.password !== "" &&
      this.state.password === this.state.passwordVerify;

    // whether the password validation input field should show valid
    const passwordCheckInvalid = !passwordValid && this.state.password !== "";

    return (
      <Container>
        <section className="flexbox-container">
          <Row className="col-12 d-flex align-items-center justify-content-center">
            <Col className="col-10 box-shadow-2 p-0" md="4">
              <div className="card card-login border-grey border-lighten-3 px-1 py-1 m-0">
                <div className="card-header border-0">
                  <div className="card-title text-center">
                    <img
                      className="brand-logo"
                      alt="modern admin logo"
                      src={BrandLogo}
                    />
                  </div>
                </div>
                <div className="card-content">
                  <div className="card-body card-body-login">
                    <Form
                      className="form-horizontal"
                      onSubmit={this.onRegister}
                    >
                      <FormGroup className="position-relative has-icon-left">
                        <Input
                          placeholder="E-Mail-Adresse"
                          id="username"
                          required="required"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          style={{ height: "2.75rem" }}
                        />
                        <div className="form-control-position">
                          <i className="ft-user" />
                        </div>
                      </FormGroup>
                      <FormGroup className="position-relative has-icon-left">
                        <Input
                          placeholder="Passwort"
                          type="password"
                          id="password"
                          required="required"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          style={{ height: "2.75rem" }}
                        />
                        <div className="form-control-position">
                          <i className="la la-key" />
                        </div>
                      </FormGroup>
                      <FormGroup className="position-relative has-icon-left">
                        <Input
                          placeholder="Passwort bestätigen"
                          type="password"
                          id="passwordVerify"
                          required="required"
                          value={this.state.passwordVerify}
                          onChange={this.onChangePasswordVerfify}
                          valid={passwordValid}
                          invalid={passwordCheckInvalid}
                          style={{ height: "2.75rem" }}
                        />
                        <div className="form-control-position">
                          <i className="la la-key" />
                        </div>
                      </FormGroup>
                      <Button
                        className="btn btn-outline-info btn-block"
                        disabled={!passwordValid}
                      >
                        <i className="ft-unlock" /> Registrieren
                      </Button>
                    </Form>
                    <Link to="/login">
                      <Button
                        className="btn btn-outline-info btn-block"
                        style={{ marginTop: 20 }}
                      >
                        Zurück zum Login
                      </Button>
                    </Link>
                    <br />
                    {this.props.registerError && (
                      <Alert color="danger">{this.props.registerError}</Alert>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    token: state.userSession.token,
    registerError: state.userSession.register.error,
    registering: state.userSession.register.loading,
    registered: state.userSession.register.loaded,
  }),
  { register, logout, login }
)(withRouter(Register));
