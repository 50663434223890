import React from "react";

import { TableGeneric } from "../../charts/TableGeneric/TableGeneric";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  setPageTableObjects,
  setFilterTableObjects,
  applyFilterTableObjects,
  removeFilterTableObjects,
  resetPageTableObjects,
  getPageObject,
  setTypeSort,
  setColumnSort,
} from "../../../actions/objectview/objectviewGrunddaten";
import { mapColumns } from "../../../helpers/objectview/data";

import { labels } from "../../../constants/Filters";

import { setIdObjectActive } from "../../../actions/objectview/objectview";

import { Table, InputGroup, Input } from "reactstrap";

import { SORT_ASCENDING, SORT_DESCENDING } from "../../../constants/types_sort";

class TableGrunddaten extends TableGeneric {
  // constructor(props) {
  //     super(props);
  // }

  applyFilters(namesColumns) {
    namesColumns.map((nameColumn) => {
      if (this.props.filtersSet[nameColumn]) {
        this.props.applyFilterTableObjects(nameColumn);
      } else {
        this.props.removeFilterTableObjects(nameColumn);
      }
      this.props.resetPageTableObjects();
      return null;
    });
  }

  handleEnterPressFilter(event, namesColumns) {
    if (event.key === "Enter") {
      this.applyFilters(namesColumns);
    }
  }

  setFilter(idColumn, value) {
    const valuesSplitMapped = value.split(",");

    const reducer = (valuesInverseMapped, valueMapped) =>
      this.props.refMappingInverted[valueMapped] !== undefined
        ? valuesInverseMapped.concat(this.props.refMappingInverted[valueMapped])
        : valuesInverseMapped.concat([valueMapped]);
    const valuesInverseMapped = valuesSplitMapped.reduce(reducer, []);

    this.props.setFilter(idColumn, valuesInverseMapped);
  }

  onClickColumn(event, nameColumn) {
    // Handle setting of current column
    if (this.props.columnSort !== nameColumn) {
      this.props.setColumnSort(nameColumn);
      this.props.setTypeSort(SORT_DESCENDING);
    } else if (this.props.typeSort === SORT_DESCENDING) {
      this.props.setTypeSort(SORT_ASCENDING);
    } else {
      this.props.setTypeSort(SORT_DESCENDING);
    }
  }

  onEnterObject(event) {
    if (event.keyCode === 13) {
      this.props.history.push("/objektansicht-objekt");
    }
  }

  onDoubleclickObject(idObjectActive) {
    this.props.setIdObjectActive(idObjectActive);
    this.props.history.push("/objektansicht-objekt");
  }

  moveObjectSelection(event) {
    const rows = this.props.rows;

    // obtain the current selected row index
    let indexActive = rows.findIndex(
      (row) => row[0] === this.props.idObjectActive
    );

    switch (event.keyCode) {
      case 38:
        // move up
        indexActive--;
        break;
      case 40:
        indexActive++;
        break;
      default:
        break;
    }

    const rowActive = rows[Math.min(Math.max(indexActive, 0), rows.length - 1)];
    if (rowActive !== undefined) {
      const idActiveNew = rowActive[0];
      this.props.setIdObjectActive(idActiveNew);
    }
  }

  renderTable() {
    return (
      <Table
        className="TableObjectviewGrunddaten"
        tabIndex={-1}
        onKeyDown={(e) => this.moveObjectSelection(e)}
      >
        {this.renderTableContent()}
        {this.renderTableBody()}
      </Table>
    );
  }

  renderHeadColumn(mappingColumns) {
    return (
      <th>
        <a onClick={(event) => this.onClickColumn(event, mappingColumns[0])}>
          {mappingColumns[1]}
          {this.props.columnSort === mappingColumns[0] && (
            <span
              className={
                this.props.typeSort === SORT_ASCENDING
                  ? "la la-sort-up"
                  : "la la-sort-down"
              }
            />
          )}
        </a>
        <br />
        <InputGroup>
          <Input
            type="text"
            placeholder="Filter"
            onKeyPress={(event) =>
              this.handleEnterPressFilter(event, this.props.idsColumns)
            }
            value={
              this.props.filtersSet[mappingColumns[0]]
                ? this.props.filtersSet[mappingColumns[0]]
                : undefined
            }
            onChange={(event) => {
              if (event.target.value) {
                this.props.setFilterTableObjects(
                  mappingColumns[0],
                  event.target.value.split(",")
                );
              } else {
                this.props.removeFilterTableObjects(mappingColumns[0]);
              }
            }}
          />
        </InputGroup>
      </th>
    );
  }

  renderRow(row) {
    return (
      <tr
        onClick={(e) => this.props.setIdObjectActive(row[0])}
        onDoubleClick={(e) => this.onDoubleclickObject(row[0])}
        className={
          this.props.idObjectActive === row[0]
            ? "rowObjectview active"
            : "rowObjectview"
        }
      >
        {this.renderColumnCells(row)}
      </tr>
    );
  }

  renderTableBody() {
    return (
      <tbody onKeyDown={(e) => this.onEnterObject(e)} tabIndex={-1}>
        {this.renderRows()}
      </tbody>
    );
  }
}

export default connect(
  (state, props) => ({
    namesColumnsMapped: mapColumns(props.idsColumns, labels),
    refMappings: state.projects.metadataProject.ref,
    refMappingsInverted: state.projects.metadataProject.refInverted,
    filtersSet: state.objectviewGrunddaten.filtersSet,
    filtersApplied: state.objectviewGrunddaten.filtersApplied,
    pageTableObjects: state.objectviewGrunddaten.pageTableObjects,
    numPagesTableObjects: state.objectviewGrunddaten.numPagesTableObjects,
    columnSort: state.objectviewGrunddaten.columnSort,
    typeSort: state.objectviewGrunddaten.typeSort,
    idObjectActive: state.objectview.idObjectActive,
  }),
  {
    setIdObjectActive,
    setPageTableObjects,
    setFilterTableObjects,
    applyFilterTableObjects,
    removeFilterTableObjects,
    getPageObject,
    setTypeSort,
    setColumnSort,
    resetPageTableObjects,
  }
)(withRouter(TableGrunddaten));
