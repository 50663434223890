/**
 * Logout component.
 * @module components/views/Login/Login
 */

import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";

import { logout } from "../../../actions/userSession/userSession";

/**
 * Logout component class.
 * @class Login
 * @extends Component
 */
class Logout extends React.Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.logout();
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.token) {
      this.props.history.push("/");
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return <Container>Logging out..</Container>;
  }
}

export default connect(
  state => ({
    token: state.userSession.token
  }),
  { logout }
)(withRouter(Logout));
