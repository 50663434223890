/**
 * Grunddaten component.
 * @module components/views/Grunddaten/Grunddaten
 */

import React from "react";
import { connect } from "react-redux";
import {
  Map as Leaflet,
  TileLayer,
  GeoJSON,
  LayersControl,
  Pane,
  ZoomControl,
} from "react-leaflet";
import { map as _map, find, flatten, isEqual } from "lodash";
import "leaflet.pm";
import Control from "react-leaflet-control";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { withRouter } from "react-router-dom";

import { setIdObjectActive } from "../../../actions/objectview/objectview";
import {
  setFilterTableObjects,
  applyFilterTableObjects,
  removeFiltersTableObjects,
  setFilterMapObjects,
  setFilterAppliedObjectview,
  getPageObject,
} from "../../../actions/objectview/objectviewGrunddaten";

import {
  getMap,
  setDiagramColor,
  setColorRendering,
  setViewport,
  getDataObject,
  setMapDashboardsMaximized,
  setMapDashboardsMinimized,
  selectMapLayer,
} from "../../../actions/map/map";
import { COLOR_MAP_SELECTED } from "../../../constants/Colors";

import {
  ZUSTAND_AVG_DIFFERENCE,
  SUBSTANZ_AVG_DIFFERENCE,
  SUBSTANZ_BETRIEB_AVG_DIFFERENCE,
  SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE,
  SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE,
  SANIERUNGEN_SUM_DIFFERENCE,
  SANIERUNGEN_REP_SUM_DIFFERENCE,
  SANIERUNGEN_REN_SUM_DIFFERENCE,
  SANIERUNGEN_ERN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE,
  ZUSTANDSKLASSE_STRATEGIE,
  ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE,
  ZUSTANDSKLASSE_BETRIEB_STRATEGIE,
  ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE,
  SUBSTANZKLASSE_STRATEGIE,
  SUBSTANZKLASSE_DICHTHEIT_STRATEGIE,
  SUBSTANZKLASSE_BETRIEB_STRATEGIE,
  SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE,
  SANIERUNGEN,
  SANIERUNGEN_REP,
  SANIERUNGEN_REN,
  SANIERUNGEN_ERN,
  SANIERUNGSKOSTEN,
  SANIERUNGSKOSTEN_REP,
  SANIERUNGSKOSTEN_REN,
  SANIERUNGSKOSTEN_ERN,
  RBW_WBK,
  RBW_WBK_SUM_DIFFERENCE,
} from "../../../constants/Charts";

import {
  setMapFilter,
  setFilterApplied,
  applyFilter,
} from "../../../actions/filters/filters";
import { labels } from "../../../constants/Filters";
import { getColor } from "../../../helpers/colors/colors";
import {
  diagramsViews,
  DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE,
  DASHBOARD_STRATEGIE_VERGLEICH,
  DASHBOARD_STRATEGIE,
} from "../../../constants/Dashboards";

import {
  COLOR_MAP_DEFAULT,
  COLOR_MAP_BACKGROUND,
} from "../../../constants/Colors";

import "leaflet/dist/leaflet.css";
import { geoJSON } from "leaflet";
import "leaflet.pm/dist/leaflet.pm.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  VIEW_GRUNDDATEN,
  VIEW_STRATEGIE,
  VIEW_STRATEGIE_VERGLEICH,
  VIEW_SCHADENSKLASSIFIZIERUNG,
} from "../../../constants/NamesViews";

const { BaseLayer } = LayersControl;

// TODO: Use different filters and filter setters for objectview.
//  In SQ, add the Objectview filter attributes to the SQL
// TODO: In Objectview, do not draw popup. Instead, one click selects an object and ctrl-click
//  selects more than one object? No. Let's stick to only one object being selected.
// Three things to do:
//  1. If object is selected, switch to its page in table and make it active object, so that
//    map selection corresponds to table
//  2. Vice Versa: Active Object in Table must be highlighted on map.
// Remark: In Objectview, only the filtered objects, visible in the table, may be selected.
//

// TODO: Enable colorization by attributes shown in objectview inspections table

// TODO: Enable objectview filter with map.

const DIAGRAMS_COLOR_STRATEGY_COMPARISON = [
  ZUSTAND_AVG_DIFFERENCE,
  SUBSTANZ_AVG_DIFFERENCE,
  SUBSTANZ_BETRIEB_AVG_DIFFERENCE,
  SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE,
  SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE,
  SANIERUNGEN_SUM_DIFFERENCE,
  SANIERUNGEN_REP_SUM_DIFFERENCE,
  SANIERUNGEN_REN_SUM_DIFFERENCE,
  SANIERUNGEN_ERN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE,
  SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE,
  RBW_WBK_SUM_DIFFERENCE,
];

const DIAGRAMS_COLOR_STRATEGY = [
  ZUSTANDSKLASSE_STRATEGIE,
  ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE,
  ZUSTANDSKLASSE_BETRIEB_STRATEGIE,
  ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE,
  SUBSTANZKLASSE_STRATEGIE,
  SUBSTANZKLASSE_DICHTHEIT_STRATEGIE,
  SUBSTANZKLASSE_BETRIEB_STRATEGIE,
  SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE,
  SANIERUNGEN,
  SANIERUNGEN_REP,
  SANIERUNGEN_REN,
  SANIERUNGEN_ERN,
  SANIERUNGSKOSTEN,
  SANIERUNGSKOSTEN_REP,
  SANIERUNGSKOSTEN_REN,
  SANIERUNGSKOSTEN_ERN,
  RBW_WBK,
];

/**
 * Map component class.
 * @class Grunddaten
 * @extends Component
 */
class Map extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsComponent
   */
  constructor(props) {
    super(props);

    this.onEachFeature = this.onEachFeature.bind(this);

    this.onViewportChanged = this.onViewportChanged.bind(this);
    this.onViewportChange = this.onViewportChange.bind(this);
    this.onToggleMaximized = this.onToggleMaximized.bind(this);
    this.onToggleMinimized = this.onToggleMinimized.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onType = this.onType.bind(this);
    this.onMapColorRendering = this.onMapColorRendering.bind(this);
    this.onActivateObjectObjectview = this.onActivateObjectObjectview.bind(
      this
    );
    this.state = {
      viewportLastRequest: undefined, // viewport from when the last map request had been sent to check if map data must be reloaded
      map: "",
      isDragging: false,
      popupIsVisible: false,
      idObjectActive: undefined,
      modalObjectviewIsOpen: false,
      idObjectObjectview: undefined,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.modalObjectviewIsOpen !== this.state.modalObjectviewIsOpen) {
      return true;
    }

    if (
      nextState.isDragging ||
      this.state.isDragging ||
      nextState.popupIsVisible ||
      // Prevent unnecessary re-render while new data is being loaded
      nextProps.isMapRequestLoading
    ) {
      return false;
    }

    const newData =
      this.props.isMapRequestLoading === true &&
      nextProps.isMapRequestLoading === false &&
      nextProps.isMapRequestLoaded;
    const newColorRendering =
      this.props.colorRendering !== nextProps.colorRendering;
    const sidebarToggled = this.props.sidebarIsOpen !== nextProps.sidebarIsOpen;
    const maximizeToggled = this.props.maximized !== nextProps.maximized;
    const minimizeToggled = this.props.minimized !== nextProps.minimized;
    const updateActiveObjectview =
      this.props.isObjectview &&
      this.props.objectviewIdObjectActive !==
        nextProps.objectviewIdObjectActive;

    // TODO: enable update on popup close without preventing opening of a new popup of other section due to redraw.
    // const popupClosed = this.state.popupIsVisible && !nextState.popupIsVisible

    return (
      newData ||
      newColorRendering ||
      sidebarToggled ||
      maximizeToggled ||
      minimizeToggled ||
      updateActiveObjectview //||
      // updateActiveObjectview //|| popupClosed
    );
    // todo: enable instant popup opening when clicking on other section
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    const map = this.refs.map.leafletElement;

    // add geosearch
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      position: "topleft",
      animateZoom: false,
      showMarker: false,
    });
    map.addControl(searchControl);

    map.on("baselayerchange", (e) => {
      const layer = find(this.props.maps, { label: e.name });
      this.setMinMaxZoom(layer);
      this.props.selectMapLayer(e.name);
    });
    // Store map filter on mouseup
    map.on("mouseup", (e) => {
      window.setTimeout(() => {
        const features = [];
        let json;
        map.eachLayer((layer) => {
          if (layer.pm && layer.pm._layer) {
            json = layer.pm._layer.toGeoJSON();
            if (
              json.geometry.type === "Polygon" &&
              json.geometry.coordinates[0].length > 2
            ) {
              features.push(json);
            }
          }
        });

        const featuresFound = features.length ? features : undefined;

        const mapFilterSetter = this.props.isObjectview
          ? this.props.setFilterMapObjects
          : this.props.setMapFilter;

        if (!isEqual(featuresFound, this.props.filterSet)) {
          mapFilterSetter({
            type: "FeatureCollection",
            features: featuresFound,
          });
        }
      }, 100);
    });
    map.pm.addControls({
      position: "bottomleft",
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
    });

    // Seems like options can only be set by enabling and disabling
    // draw tools in leaflet.pm
    map.pm.enableDraw("Polygon", { snappable: false });
    map.pm.disableDraw("Polygon");
    map.pm.enableDraw("Rectangle", { snappable: false });
    map.pm.disableDraw("Rectangle");

    // Reset colorization diagram if current value is not accessible
    //  in current dashboard
    if (
      !this.props.diagramsColor.includes(this.props.diagramColor) &&
      this.props.diagramColor !== undefined
    ) {
      this.props.setDiagramColor(undefined);
    }

    this.props.project !== null && this.onReload(this.props);

    // initialize set filters with the Applied filter_state
    const mapFilterApplier = this.props.isObjectview
      ? this.props.setFilterAppliedObjectview
      : this.props.setFilterApplied;
    mapFilterApplier("map");

    this.addSelectionsFromFiltersMap(this.props.filterSet);

    // this.onReload(this.props);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentWillReceiveProps(nextProps) {
    if (this.refs.applyFilter !== undefined) {
      if (!isEqual(nextProps.filterSet, nextProps.filtersApplied["map"])) {
        this.refs.applyFilter.leafletElement._container.style.visibility =
          "visible";
      } else {
        this.refs.applyFilter.leafletElement._container.style.visibility =
          "hidden";
      }
    }

    if (!isEqual(this.props.filtersApplied, nextProps.filtersApplied)) {
      this.addSelectionsFromFiltersMap(nextProps.filterSet);
    }
    // If loaded initially, the baselayer is checked in the map
    //  component, but this is not reflected in the state yet.
    if (this.props.mapLayer === undefined && nextProps.maps.length > 0) {
      this.props.selectMapLayer(nextProps.maps[0].label);
      this.setMinMaxZoom(nextProps.maps[0]);
    }

    if (!this.state.isDragging) {
      const updateForStrategyComparison =
        nextProps.typeDashboard === DASHBOARD_STRATEGIE_VERGLEICH &&
        DIAGRAMS_COLOR_STRATEGY_COMPARISON.includes(nextProps.diagramColor) &&
        (this.props.yearStrategy !== nextProps.yearStrategy ||
          !isEqual(
            this.props.strategiesComparisonIds,
            nextProps.strategiesComparisonIds
          )) &&
        nextProps.strategiesComparisonIds.length === 2 &&
        nextProps.strategiesComparisonIds[0] !== undefined &&
        nextProps.strategiesComparisonIds[1] !== undefined;
      const updateForStrategy =
        nextProps.typeDashboard === DASHBOARD_STRATEGIE &&
        DIAGRAMS_COLOR_STRATEGY.includes(nextProps.diagramColor) &&
        (this.props.yearStrategy !== nextProps.yearStrategy ||
          (!isEqual(
            this.props.strategyDisplayId,
            nextProps.strategyDisplayId
          ) &&
            // both years strategy should be defined while checking displayIDs
            this.props.yearStrategy !== undefined &&
            nextProps.yearStrategy !== undefined));
      // const updateForStrategyComparison = this.props.yearStrategy !== nextProps.yearStrategy
      if (
        !isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
        // !isEqual(this.props.typeDashboard, nextProps.typeDashboard) ||
        this.props.diagramColor !== nextProps.diagramColor ||
        !isEqual(this.props.bucket, nextProps.bucket) ||
        // TODO: This triggers reloads while dragging as the viewport is updated on drag.
        !isEqual(nextProps.viewport, this.state.viewportLastRequest) ||
        this.props.maximized !== nextProps.maximized ||
        this.props.minimized !== nextProps.minimized ||
        updateForStrategyComparison ||
        updateForStrategy
      ) {
        this.onReload(nextProps);
      }
    }
  }

  /**
   * If no user-defined filter maps are present, but filters are present in
   * the application state, display the filter in the state
   * @method renderSelectionsFromFilters
   * @returns {undefined}
   */
  addSelectionsFromFiltersMap(geoFilter) {
    // Obtain geofilter from set filters, which have been initialized
    // with the applied filters

    // Delete all present geo selection
    const map = this.refs.map.leafletElement;
    map.eachLayer((layer) => {
      if (layer.pm && layer.pm._layer) {
        map.removeLayer(layer);
      }
    });

    // if geofilter present in set filters, add it to the map
    if (geoFilter && !!this.refs.map) {
      var gardenLayer = new geoJSON(JSON.parse(geoFilter[0]));
      map.addLayer(gardenLayer);
    }
  }

  /**
   * On viewport changed handler.
   * @method onViewportChanged
   * @param {Object} viewport Viewport data
   * @returns {undefined}
   */
  onViewportChanged(viewport) {
    this.setState({ isDragging: false });
    // this.onReload(this.props);
    this.props.viewport !== undefined &&
      viewport.center !== undefined &&
      viewport.zoom !== undefined &&
      this.props.setViewport(viewport);
  }

  /**
   * On viewport change handler.
   * @method onViewportChanged
   * @param {Object} viewport Viewport data
   * @returns {undefined}
   */
  onViewportChange(viewport) {
    this.setState({ isDragging: true });
    //     this.props.setViewport(viewport);
  }

  /**
   * On type handler.
   * @method onType
   * @param {Object} event Event
   * @returns {undefined}
   */
  onType(event) {
    this.props.setDiagramColor(event.target.value);
  }

  onToggleMaximized() {
    this.props.setMapDashboardsMaximized(!this.props.maximized);
  }
  onToggleMinimized() {
    this.props.setMapDashboardsMinimized(!this.props.minimized);
  }

  /**
   * On ColorRendering handler.
   * @method onMapColorRendering
   * @param {Object} event Event
   * @returns {undefined}
   */
  onMapColorRendering(event) {
    this.props.setColorRendering(event.target.value);
  }

  getPaneClass() {
    switch (this.props.colorRendering) {
      case "grayscale":
        return "leaflet-map-pane-sw";
      case "color":
        return "leaflet-map-pane-color";
      default:
        return null;
    }
  }

  enterObjectView() {
    const objectId = this.state.idObjectObjectview;
    this.props.removeFiltersTableObjects();

    this.props.setIdObjectActive(objectId);

    this.props.history.push("/objektansicht-grunddaten");
  }

  tryOpenObjectview(objectId) {
    this.setState({
      idObjectObjectview: objectId,
    });

    // if no filters are set in objectview or only filter is one ID,
    // then enter directly. Else, warn user
    if (Object.keys(this.props.filtersAppliedObjectview).length === 0) {
      this.enterObjectView();
    } else {
      this.onToggleModalObjectview();
    }
  }

  onToggleModalObjectview() {
    this.setState({
      modalObjectviewIsOpen: !this.state.modalObjectviewIsOpen,
      ...this.defaultNewPreset,
    });
    if (!this.state.modalObjectviewIsOpen) {
      this.setState({ idObjectObjectview: undefined });
    }
  }

  /**
   * On reload handler.
   * @method onReload
   * @param {Object} props Properties
   * @returns {undefined}
   */
  onReload(props) {
    if (!props.isMapRequestLoading && !props.objectLoading) {
      const map = this.refs.map.leafletElement;
      const bounds = map.getBounds();

      // Store viewport at request for later check if viewport has changed when request has been loaded
      this.setState({ viewportLastRequest: props.viewport });

      this.props.getMap(
        props.project,
        props.bucket,
        props.filtersApplied,
        {
          neLat: bounds._northEast.lat,
          neLong: bounds._northEast.lng,
          swLat: bounds._southWest.lat,
          swLong: bounds._southWest.lng,
          zoom: props.viewport.zoom,
        },
        // TODO: For some reason, even if setting diagramColor to null if not present in
        // Dashboard in componentDidMount(), the first onReload still uses the set value.
        // Find a way to update diagramColor in
        props.diagramsColor.includes(props.diagramColor)
          ? props.diagramColor
          : null,
        props.typeDashboard,
        props.typeDashboard === DASHBOARD_STRATEGIE_VERGLEICH
          ? props.strategiesComparisonIds
          : undefined,
        props.typeDashboard === DASHBOARD_STRATEGIE
          ? props.strategyDisplayId
          : undefined,
        props.yearStrategy
      );
    }
  }

  applyFilter(event) {
    // apply the map filter
    if (this.props.isObjectview) {
      this.props.applyFilterTableObjects("map");
    } else {
      this.props.applyFilter("map");
    }
  }

  getStyle(feature, objectviewIdObjectActive) {
    const idDiagram = this.props.diagramColor;
    // Default values for features that are not in filtered set.
    let opacity = 0.6;
    let color = COLOR_MAP_BACKGROUND;

    if (feature.properties.filter_state === 1) {
      color = idDiagram
        ? getColor(idDiagram, feature.properties.indication_value, true)
        : COLOR_MAP_DEFAULT;
      opacity = 1;
    }

    // idObjectActive is can be passed explicitly; else, it is used from props.
    //  Explicit passing is used when called from ComponentWillReceiveProps.
    const objectviewIdObjectActiveUse =
      objectviewIdObjectActive === undefined
        ? this.props.objectviewIdObjectActive
        : objectviewIdObjectActive;

    if (
      this.props.isObjectview &&
      objectviewIdObjectActiveUse !== undefined &&
      objectviewIdObjectActiveUse === feature.properties.ObjectId
    ) {
      color = COLOR_MAP_SELECTED;
      opacity = 1;
    }

    return {
      // todo: should color for base be uniform and not value-dependent?
      opacity: opacity,
      color: color,
    };
  }

  setMinMaxZoom(layer) {
    const map = this.refs.map.leafletElement;
    map.setMaxZoom(layer.maxZoom);
    map.setMinZoom(layer.minZoom);
  }

  getPopupContent(feature, layer) {
    // // Set active map object ID so that it can be accessed by the object view
    // this.props.setActiveObjectMap(feature.properties.ObjectId)

    // Obtain object data and bind popup within the action
    this.props.getDataObject(
      this.props.project,
      feature.properties.ObjectId,
      layer,
      feature,
      this.setState.bind(this),
      this.setIdObjectActive.bind(this)
    );

    // this.props.setActiveObjectId(feature.properties.ObjectId)

    return "Laden...";
  }

  // Wrapper to be able to dispatch the action from reducer when
  //  closing popup
  setIdObjectActive(idObject) {
    this.props.setIdObjectActive(idObject);
  }

  setToCenter(event) {
    const map = this.refs.map.leafletElement;
    map.setView(
      this.props.defaultViewport.center,
      this.props.defaultViewport.zoom
    );
  }

  zoomToActiveObject(event) {
    if (this.props.coordinatesObjectActiveObjectview !== undefined) {
      const coords = this.props.coordinatesObjectActiveObjectview.geometry
        .coordinates[1];
      const center = [coords[1], coords[0]];
      const map = this.refs.map.leafletElement;
      map.setView(center, 17);
      this.onReload(this.props);
    }
  }

  onActivateObjectObjectview(idObject) {
    // Set the selected object active
    this.props.setIdObjectActive(idObject);

    // Switch to the object's page
    this.props.getPageObject(
      this.props.project,
      this.props.bucket,
      this.props.filtersApplied,
      idObject,
      this.props.objectviewNumObjectsPage,
      this.props.columnSort,
      this.props.typeSort
    );
  }

  // Create tooltip on click
  onEachFeature(feature, layer) {
    // store the feature's sq id in order to refer it for setting color
    //  and doing other things without re-rendering
    // layer._leaflet_id = feature.properties.ObjectId;

    // Bind a popup with initial string value.
    // A request is sent and the result is then bind to a new popup in the
    // Reducer. This is a workaround in order to enable getting popup data
    // using redux while circumventing the need to re-render the map on
    // Popup opening when using React-leaflet Popup component.
    // TODO: Ensure that, if a section is clicked, the content of the previous
    // section's popup is not drawn until the current data is loaded.
    if (this.props.typeDashboard !== DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE) {
      layer.on({ click: () => this.getPopupContent(feature, layer) });
      layer.on({
        dblclick: () => this.tryOpenObjectview(feature.properties.ObjectId),
      });
    } else {
      if (feature.properties.filter_state === 1) {
        layer.on({
          click: () =>
            this.onActivateObjectObjectview(feature.properties.ObjectId),
          dblclick: () => this.props.history.push("/objektansicht-objekt"),
        });
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      geoobjectsMap,
      maps,
      diagramsColor,
      diagramColor,
      colorRendering,
      maximized,
      minimized,
      mapDashboardsHeight,
    } = this.props;

    if (this.refs.map) {
      const mapRef = this.refs.map.leafletElement;

      // remove json layer if present to avoid memory leak
      const gjson = this.refs.mapGeoJson;
      if (gjson && gjson.leafletElement._renderer !== undefined) {
        gjson.leafletElement.clearLayers();
        mapRef.removeLayer(gjson);
      }

      // check _mapPane to prevent crash (see #213)
      // invalidate size in order to update map viewport size on resizing gui
      setTimeout(function () {
        if (!(typeof mapRef._mapPane === "undefined")) {
          mapRef.invalidateSize();
        }
      }, 50);
    }

    const height = maximized ? 100 : minimized ? 0 : mapDashboardsHeight;

    // Get current minimum and maximum zoom for active layer provider
    let mapLayer = find(maps, { label: this.props.mapLayer });

    // Only allow dragging if viewport is valid.
    // This prevents crash by dragging after reload before data has been loaded.
    const allowDragging =
      this.props.defaultViewport !== undefined &&
      this.props.defaultViewport.center !== undefined &&
      this.props.defaultViewport.zoom !== undefined &&
      this.props.viewport !== undefined;

    return (
      <div className="mapView" style={{ height: `${height}%` }}>
        <Leaflet
          ref="map"
          viewport={this.props.viewport}
          dragging={allowDragging}
          onViewportChanged={this.onViewportChanged}
          onViewportChange={this.onViewportChange}
          style={{
            height: "100%",
          }}
          preferCanvas={true}
          zoomControl={false}
          renderer={L.canvas({ tolerance: 6 })}
          doubleClickZoom={false}
          maxZoom={mapLayer ? mapLayer.maxZoom : undefined}
          minZoom={mapLayer ? mapLayer.minZoom : undefined}
        >
          <Pane name="MyPane" className={this.getPaneClass()}>
            <LayersControl
              className=" layerControlBox"
              position="topright"
              collapsed={true}
            >
              {_map(maps, (layer, index) => (
                <BaseLayer
                  key={layer.label}
                  name={layer.label}
                  checked={layer.label === this.props.mapLayer}
                >
                  <TileLayer
                    attribution={layer.attribution}
                    url={layer.url}
                    style={{
                      "-webkit-filter": "grayscale(100%)",
                      filter: "grayscale(100%)",
                    }}
                    pane="MyPane"
                  />
                </BaseLayer>
              ))}
            </LayersControl>
          </Pane>
          <Control position="topright">
            {
              // TODO: use label of diagamColor instead of ID
            }
            <select value={diagramColor || ""} onChange={this.onType}>
              <option value="">Farbe:</option>
              {_map(diagramsColor, (idDiagram) => (
                <option key={idDiagram} value={idDiagram}>
                  {labels[idDiagram]}
                </option>
              ))}
            </select>
            <select value={colorRendering} onChange={this.onMapColorRendering}>
              <option value="grayscale">Graustufen</option>
              <option value="color">Farbe</option>
            </select>
          </Control>
          <ZoomControl position="bottomright" />
          <Control position="bottomright" className="leaflet-bar">
            <a onClick={(e) => this.setToCenter(e)} className="la la-home" />
          </Control>
          {this.props.isObjectview && (
            <Control position="bottomright" className="leaflet-bar">
              <a
                onClick={(e) => this.zoomToActiveObject(e)}
                className="la la-circle"
              />
            </Control>
          )}
          <Control
            ref="applyFilter"
            position="bottomleft"
            className="leaflet-bar"
          >
            <a onClick={(e) => this.applyFilter(e)} className="la la-check" />
          </Control>
          {geoobjectsMap && (
            <GeoJSON
              ref="mapGeoJson"
              key={`base-${Date.now()}`}
              data={geoobjectsMap}
              style={this.getStyle.bind(this)}
              pmIgnore={true}
              onEachFeature={this.onEachFeature}
            />
          )}
        </Leaflet>
        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.modalObjectviewIsOpen}
          toggle={() => this.onToggleModalObjectview()}
          autoFocus={false}
        >
          <ModalHeader>Zur Objektansicht wechseln</ModalHeader>
          <ModalBody>
            <p>
              Achtung: Aktivierte Filter in der Objektansicht werden entfernt!
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.enterObjectView()}>
              Zur Objektansicht
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => this.onToggleModalObjectview()}
            >
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function getDefaultViewport(state) {
  let viewport;
  if (state.projects.projects.length > 0) {
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });
    viewport = {
      center: [project.map.lat, project.map.long],
      zoom: project.map.zoom,
    };
    return viewport;
  }
}

function getViewport(state) {
  // If no viewport had been defined by previously visited dashboard, use project's
  // defaut viewport.

  // Use a fallback if no project viewport is present in projet or state in order to
  // prevent crash
  let viewport = {
    center: [50, 8],
    zoom: 16,
  };

  if (state.map.viewport === undefined) {
    if (state.projects.projects.length > 0) {
      const project = find(state.projects.projects, {
        id: state.projects.project,
      });
      viewport = {
        center: [project.map.lat, project.map.long],
        zoom: project.map.zoom,
      };
    }
  } else {
    viewport = state.map.viewport;
  }

  return viewport;
}

export default connect(
  (state, props) => {
    let diagramsColor = flatten(
      _map(
        // TODO: strategie and strategieVergleich is not usable for colorization
        //  yet (time-dependent values) and hence excluded.
        // TODO: handling must change if user-defined diagrams are introduced.
        props.view &&
          ![
            // VIEW_STRATEGIE,
            VIEW_STRATEGIE_VERGLEICH,
            VIEW_SCHADENSKLASSIFIZIERUNG,
            VIEW_STRATEGIE,
          ].includes(props.view)
          ? [VIEW_GRUNDDATEN, props.view]
          : [VIEW_GRUNDDATEN],
        (view) => Object.keys(diagramsViews[view])
      )
    );

    if (props.view === VIEW_STRATEGIE_VERGLEICH) {
      diagramsColor = [...diagramsColor, ...DIAGRAMS_COLOR_STRATEGY_COMPARISON];
    }

    if (props.view === VIEW_STRATEGIE) {
      diagramsColor = [...diagramsColor, ...DIAGRAMS_COLOR_STRATEGY];
    }

    let yearStrategy = undefined;
    if (props.typeDashboard === DASHBOARD_STRATEGIE_VERGLEICH) {
      yearStrategy = state.strategieVergleich.yearStrategy;
    } else if (props.typeDashboard === DASHBOARD_STRATEGIE) {
      yearStrategy = state.strategie.yearStrategy;
    }

    return {
      geoobjectsMap: state.map.data,
      objectLoading: state.map.objectLoading,
      mapDashboardsHeight: state.map.mapDashboardsHeight,
      maximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      minimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
      sidebarIsOpen: state.grunddaten.sidebarIsOpen,
      isMapRequestLoaded: state.map.loaded,
      isMapRequestLoading: state.map.loading,
      colorRendering: state.map.colorRendering,
      viewport: getViewport(state),
      defaultViewport: getDefaultViewport(state),
      isObjectview:
        props.typeDashboard === DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE,
      filterSet:
        props.typeDashboard === DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE
          ? state.objectviewGrunddaten.filtersSet.map
          : state.filters.filtersSet.map,
      filtersApplied:
        props.typeDashboard === DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE
          ? state.objectviewGrunddaten.filtersApplied
          : state.filters.filtersApplied,
      project: state.projects.project,
      dataObject: state.map.dataObject,
      coordinatesObjectActiveObjectview:
        state.objectviewGrunddaten.coordinatesObjectActive,
      objectviewNumObjectsPage:
        state.objectviewGrunddaten.numObjectsPageTableObjects,
      bucket: state.projects.bucket,
      objectviewIdObjectActive: state.objectview.idObjectActive,
      filtersAppliedObjectview: state.objectviewGrunddaten.filtersApplied,
      mapLayer: state.map.mapLayer,
      maps:
        state.projects.projects.length > 0
          ? find(state.projects.projects, { id: state.projects.project }).maps
          : [],
      diagramsColor,
      diagramColor: state.map.diagramColor,
      columnSort: state.objectviewGrunddaten.columnSort,
      typeSort: state.objectviewGrunddaten.typeSort,
      yearStrategy: yearStrategy,
      strategiesComparisonIds: state.strategieVergleich.strategiesComparisonIds,
      strategyDisplayId: state.strategie.strategyDisplayId,
    };
  },
  {
    getMap,
    setDiagramColor,
    setMapFilter,
    setColorRendering,
    setFilterApplied,
    setViewport,
    getDataObject,
    setMapDashboardsMaximized,
    setMapDashboardsMinimized,
    selectMapLayer,
    setIdObjectActive,
    setFilterTableObjects,
    applyFilterTableObjects,
    removeFiltersTableObjects,
    setFilterMapObjects,
    setFilterAppliedObjectview,
    applyFilter,
    getPageObject,
  }
)(withRouter(Map));
