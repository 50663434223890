/**
 * Detailview componnt.
 * @module components/elements/Detailview/Detailview
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import { isEqual, intersection, find } from "lodash";
import { withRouter } from "react-router-dom";
import { setIdObjectActive } from "../../../actions/objectview/objectview";
import Map from "../../elements/Map/Map";
import { DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE } from "../../../constants/Dashboards";

import TableGrunddaten from "./TableGrunddaten";

import {
  setPageTableObjects,
  setFilterTableObjects,
  getDataTableObjects,
  getInspectionsObjectGrunddaten,
  applyFilterTableObjects,
  removeFilterTableObjects,
  resetPageTableObjects,
  getPageObject,
  reset,
  getCoordinatesObject,
  setTypeSort,
  setColumnSort,
} from "../../../actions/objectview/objectviewGrunddaten";

import { setCurrentDashboard } from "../../../actions/views/views";

import PageChooser from "../../elements/PageChooser/PageChooser";
import BasicTable from "../../elements/Table/BasicTable";
import TableGeneric from "../../charts/TableGeneric/TableGeneric";
import { VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE } from "../../../constants/NamesViews";

// TODO: Implement button to switch to active inspection's inspection analysis

/**
 * Detailview component class. Represents a detailed view for a diagram.
 * @class Detailview
 * @extends Component
 */
class ObjectviewGrunddaten extends Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.setCurrentDashboard(DASHBOARD_EINZELOBJEKTANSICHT_OBJEKTE);
    this.getObjectsAndInspections(
      this.props.idProject,
      this.props.bucket,
      this.props.filtersApplied,
      this.props.numObjectsPageTableObjects,
      this.props.pageTableObjects,
      this.props.idObjectActive,
      this.props.columnSort,
      this.props.typeSort
    );
    this.props.idObjectActive !== undefined &&
      this.props.getPageObject(
        this.props.idProject,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.idObjectActive,
        this.props.numObjectsPageTableObjects,
        this.props.columnSort,
        this.props.typeSort
      );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.idProject !== nextProps.idProject ||
      this.props.bucket !== nextProps.bucket
    ) {
      // this.props.reset();
      // TODO: Necessary to get data again?
      // TODO: Possible bug as nextProps.numPage is not reset?
      this.getObjectsAndInspections(
        nextProps.idProject,
        nextProps.bucket,
        nextProps.filtersApplied,
        nextProps.numObjectsPageTableObjects,
        nextProps.pageTableObjects,
        nextProps.idObjectActive,
        nextProps.columnSort,
        nextProps.typeSort
      );
    }
    // TODO: Does the logic work correctly?
    if (
      this.props.pageTableObjects !== nextProps.pageTableObjects ||
      !isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
      this.props.typeSort !== nextProps.typeSort ||
      this.props.columnSort !== nextProps.columnSort
    ) {
      this.props.getDataTableObjects(
        nextProps.idProject,
        this.props.bucket,
        nextProps.filtersApplied,
        nextProps.numObjectsPageTableObjects,
        nextProps.pageTableObjects,
        nextProps.columnSort,
        nextProps.typeSort
      );
    }
    if (this.props.idObjectActive !== nextProps.idObjectActive) {
      this.props.getInspectionsObjectGrunddaten(
        nextProps.idProject,
        nextProps.bucket,
        nextProps.idObjectActive
      );
      this.props.getCoordinatesObject(
        nextProps.idProject,
        nextProps.bucket,
        nextProps.idObjectActive
      );
    }
  }

  getObjectsAndInspections(
    idProject,
    bucket,
    filtersApplied,
    numObjectsPageTableObjects,
    pageTableObjects,
    idObjectActive,
    columnSort,
    typeSort
  ) {
    this.props.getDataTableObjects(
      idProject,
      bucket,
      filtersApplied,
      numObjectsPageTableObjects,
      pageTableObjects,
      columnSort,
      typeSort
    );
    if (idObjectActive) {
      this.props.getInspectionsObjectGrunddaten(
        idProject,
        bucket,
        idObjectActive
      );
    }
  }

  /**
   * Has permission
   * @method hasPermission
   * @param {String|Array} permission Permission(s) to check.
   * @returns {Boolean} true if has permission.
   */
  hasPermission(permission) {
    if (typeof permission === "string" || permission instanceof String) {
      return this.props.permissions.indexOf(permission) !== -1;
    } else {
      return intersection(this.props.permissions, permission).length > 0;
    }
  }

  drawSidebarContent() {
    const { dataObject } = this.props;
    return (
      <div>
        <BasicTable
          rows={Object.keys(dataObject).map((nameAttribute) => [
            nameAttribute,
            dataObject[nameAttribute],
          ])}
        />
      </div>
    );
  }

  drawGrunddatenContent() {
    // TODO: Table of objects with filter option
    const { namesColumns, rows } = this.props.dataTableObjects;
    // TODO: Add Filters
    // TODO: Make Scrollable
    // TODO: Add Pager
    // TODO: Make scrollable!
    // TODO: Add ability to Jump (for getting shareable link)

    // TODO: What is the active object ID? is it objektbezeichnung or the
    //  SQ id?

    return (
      <Col className="colTableObjectviewGrunddaten">
        <div className="divTableObjectviewGrunddaten">
          <TableGrunddaten
            idsColumns={namesColumns}
            rows={rows}
          ></TableGrunddaten>
        </div>
        <div className="wrapperCentering">
          <PageChooser
            numPages={this.props.numPagesTableObjects}
            pageCurrent={this.props.pageTableObjects}
            numPagesLeftRight={2}
            onPageChange={this.props.setPageTableObjects}
          />
        </div>
      </Col>
    );
  }

  drawInspektionenContent() {
    // TODO: Table of objects with filter option
    const { namesColumns, rows } = this.props.dataInspectionsObject;
    // TODO: Add Filters
    // TODO: Make Scrollable
    // TODO: Add Pager
    // TODO: Make scrollable!
    // TODO: Add ability to Jump (for getting shareable link)

    // TODO: What is the active object ID? is it objektbezeichnung or the
    //  SQ id?

    // const namesColumnsMapped = mapColumns(
    //   namesColumns,
    //   mapping_columns_inspections
    // );

    return (
      <Col className="colTableObjectviewGrunddatenInspections">
        <div className="mapWrapper">
          <div className="mapDetail">
            <Container className="containerDiagrams inner">
              <div className="divTableObjectviewGrunddatenInspections">
                <TableGeneric
                  classNameTable="TableObjectviewGrunddatenInspections"
                  idsColumns={namesColumns}
                  rows={rows}
                ></TableGeneric>
                {/* <Table className="TableObjectviewGrunddatenInspections">
                  <thead>
                    <tr>
                      {namesColumnsMapped.map((namesMapped) => (
                        <th key={namesMapped}>{namesMapped[1]}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => (
                      <tr className={"rowObjectview"}>
                        {
                          // Exclude first item which is Statusquo-ID
                          formatSubstanceValues(
                            row.slice(1),
                            namesColumnsMapped.map((names) => names[1])
                          ).map((value) => (
                            <td>{value}</td>
                          ))
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
              </div>
            </Container>
          </div>
          <Map
            view={VIEW_EINZELOBJEKTANSICHT_INSPEKTIONSLISTE}
            typeDashboard={this.props.typeDashboard}
          />
        </div>
      </Col>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Row className="mainColumn rowObjectview">
        {this.drawGrunddatenContent()}
        {this.drawInspektionenContent()}
      </Row>
    );
  }
}

export default connect(
  (state) => {
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });
    return {
      sidebarIsOpen: state.grunddaten.sidebarIsOpen,
      idProject: state.projects.project,
      permissions: project ? project.permissions : [],
      bucket: state.projects.bucket,
      idObjectActive: state.objectview.idObjectActive,
      dataInspectionsObject: state.objectviewGrunddaten.dataInspectionsObject,
      dataTableObjects: state.objectviewGrunddaten.dataTableObjects,
      filtersSet: state.objectviewGrunddaten.filtersSet,
      filtersApplied: state.objectviewGrunddaten.filtersApplied,
      pageTableObjects: state.objectviewGrunddaten.pageTableObjects,
      numObjectsPageTableObjects:
        state.objectviewGrunddaten.numObjectsPageTableObjects,
      numPagesTableObjects: state.objectviewGrunddaten.numPagesTableObjects,
      columnSort: state.objectviewGrunddaten.columnSort,
      typeSort: state.objectviewGrunddaten.typeSort,
    };
  },
  {
    setIdObjectActive,
    setPageTableObjects,
    setFilterTableObjects,
    applyFilterTableObjects,
    removeFilterTableObjects,
    getDataTableObjects,
    getInspectionsObjectGrunddaten,
    resetPageTableObjects,
    reset,
    setCurrentDashboard,
    getPageObject,
    getCoordinatesObject,
    setTypeSort,
    setColumnSort,
  }
)(withRouter(ObjectviewGrunddaten));
