/**
 * LineGeneric component.
 * @module components/charts/LineGeneric/LineGeneric
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  VictoryLine,
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryTooltip,
  createContainer,
  VictoryLabel,
} from "victory";
import { VictoryTheme } from "../../../constants/VictoryTheme";
import {
  formatTick,
  formatLabelUnit,
  formatTickForStackBar
} from "../../../helpers/charts/common_functions";
import { flatten } from "lodash";
import { getColor } from "../../../helpers/colors/colors";
import { COLOR_MARK_YEAR_STRATEGY } from "../../../constants/Colors";

import { CHART_SETTINGS } from "../../../constants/LabelsAxes";
import { labels } from "../../../constants/LabelsAxes";

// TODO: Filtering might be needed in future, but is not implemented yet,
//  as currently, the Linechart is used to display time series in strategies,
//  which are not filtered by design.

/**
 * LineGeneric component class.
 * @class LineGeneric
 * @extends Component
 */
class LineGeneric extends Component {
  getLabelX() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.

    return formatLabelUnit(this.props.unitsCharts[this.props.id].unit[1], null);
  }

  getLabelY() {
    // Obtain label for X-Axis.
    // For BarCharts, this is the chart name, together with the unit.

    return CHART_SETTINGS.scales[this.props.scale](
      // unitLabels[this.props.unit],
      labels[this.props.id],
      this.props.unitsCharts[this.props.id].unit[0]
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      id,
      tickFormat,
      paddingTickValues,
      tickValues,
      reference,
      positionMark,
      data,
    } = this.props;
    const paddingLeft = paddingTickValues ? 100 + paddingTickValues : 100;
    const tickFormat_ = tickFormat === undefined ? formatTickForStackBar : tickFormat;
    //
    //    const xData= getValuesX(data);
    //    console.log(xData[xData.length - 1]);
    //    console.log(yMin);

    const yMin = data
      ? Math.min(
          flatten(
            ...data.map((line) =>
              line.data ? line.data.map((element) => element.y) : 0
            )
          )
        )
      : 0;

    const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

    return (
      <VictoryChart
        theme={VictoryTheme}
        key={Date.now()}
        domainPadding={10}
        responsive={false}
        padding={{
          left: paddingLeft,
          right: 30,
          top: 30,
          bottom: 60,
        }}
        containerComponent={
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            labels={(datum) =>
              // labels are built for all subcharts.
              //  use only scatter plot to avoid double labels
              datum.datum.y !== null &&
              datum.datum.childName.includes("scatter")
                ? `${datum.datum.x}: ${datum.datum.y.toFixed(0)}`
                : undefined
            }
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                flyoutStyle={{ stroke: null }}
              />
            }
          />
        }
        height={265}
        width={800}
        ref={reference}
      >
        {data !== null &&
          data.map((line, index) => (
            <VictoryLine
              key={index}
              data={line.data}
              style={{
                data: { stroke: getColor(id, line.name) },
              }}
            />
          ))}
        {data !== null &&
          data.map((line, index) => (
            <VictoryScatter
              key={index}
              data={line.data}
              style={{
                data: { fill: getColor(id, line.name) },
              }}
            />
          ))}
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "#cecece" },
            ticks: { stroke: "#cecece", size: 5 },
          }}
          tickFormat={tickFormat_}
          tickValues={tickValues}
          crossAxis={yMin >= 0}
          axisLabelComponent={
            <VictoryLabel
              lineHeight={1}
              capHeight={-2}
              style={[{ fill: "black" }]}
            />
          }
          label={this.getLabelY()}
        />
        {positionMark !== undefined && (
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: COLOR_MARK_YEAR_STRATEGY },
              ticks: { stroke: "#transparent" },
              tickLabels: { fill: "none" },
            }}
            axisValue={positionMark}
          />
        )}

        <VictoryAxis
          style={{
            axis: { stroke: "#cecece" },
            ticks: { stroke: "#cecece", size: 5 },
          }}
          tickFormat={formatTick}
          axisValue={Math.min(yMin, 0)}
          axisLabelComponent={
            <VictoryLabel capHeight={2} style={[{ fill: "black" }]} />
          }
          label={this.getLabelX()}
        />
        {yMin < 0 && (
          <VictoryAxis
            style={{
              axis: { stroke: "#cecece" },
              ticks: { stroke: "#transparent" },
              tickLabels: { fill: "none" },
            }}
            //            minDomain={-3}
            axisValue={0}
          />
        )}
      </VictoryChart>
    );
  }
}

export default connect((state, props) => {
  return {
    unit: state.chartSettings.unit[props.id],
    scale: state.chartSettings.scale[props.id],
    // FIXME: handle case that no unit for chart is present in state
    unitsCharts: state.projects.metadataProject.unitsCharts,
  };
}, {})(LineGeneric);
