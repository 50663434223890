/**
 * PieGeneric component.
 * @module components/charts/PieGeneric/PieGeneric
 */

import React, { Component } from "react";
import { map } from "lodash";
import { connect } from "react-redux";
import { VictoryPie } from "victory";

import { VictoryTheme } from "../../../constants/VictoryTheme";
import { getColor } from "../../../helpers/colors/colors";
import { COLOR_PIE_FILTERED } from "../../../constants/Colors";
import { togglePieFilter } from "../../../actions/filters/filters";
import LabelTooltipPie from "../LabelTooltipPie/LabelTooltipPie";

import { mergeDataLabelled } from "../../../helpers/charts/common_functions";

/**
 * Pie component class.
 * @class Pie
 * @extends Component
 */
class PieGeneric extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    // check for new filters must be done in applied and set ones because
    // both should be drawn
    const newFilters = nextProps.filterSet !== this.props.filterSet;
    const newData = this.props.data !== nextProps.data;
    return newData || newFilters;
  }

  applyFiltersMapped(xClicked) {
    // Apply filters for all datapoints grouped in the label
    xClicked.map((x) => this.props.togglePieFilter(this.props.id, x));
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      id,
      filterSet,
      data,
      isHalf,
      disableFilter,
      reference,
    } = this.props;

    const startAngle = isHalf ? 90 : undefined;
    const endAngle = isHalf ? -90 : undefined;
    const innerRadius = isHalf ? 100 : 15;
    const width = isHalf ? 400 : 300;
    const padding = isHalf
      ? { bottom: -200, left: 50, right: 30 }
      : { top: 40, bottom: 40, left: 50, right: 50 };

    // get applied filter; is applied one or else the set ones
    // const filterActive = filtersApplied[id]
    //   ? filtersApplied[id]
    //   : filtersSet[id];

    // drawn filter is always the set one.
    const filterActive = filterSet;

    const dataMerged = mergeDataLabelled(data, this.props.refInverted[id]);

    return (
      <VictoryPie
        innerRadius={innerRadius}
        key={Date.now()}
        width={width}
        height={203}
        ref={reference}
        padding={padding}
        startAngle={startAngle}
        endAngle={endAngle}
        theme={VictoryTheme}
        padAngle={2}
        labelComponent={
          <LabelTooltipPie showLabels={!isHalf} hideSmallData={true} />
        }
        data={dataMerged}
        events={
          !disableFilter && [
            {
              target: "data",
              eventHandlers: {
                onClick: (event, props) =>
                  this.applyFiltersMapped(props.data[props.index].x),
              },
            },
          ]
        }
        colorScale={map(dataMerged, (entry, index) =>
          filterActive && filterActive.indexOf(entry.x[0]) !== -1
            ? COLOR_PIE_FILTERED
            : getColor(id, entry.x[0])
        )}
        style={{
          data: {
            fill: ({ datum }) => getColor(id, datum.x),
            stroke: ({ datum }) =>
              filterActive && filterActive.indexOf(datum.x[0]) !== -1
                ? COLOR_PIE_FILTERED
                : undefined,
            strokeWidth: 3,
          },
        }}
      />
    );
  }
}

export default connect(
  (state, props) => {
    return {
      filterSet: state.filters.filtersSet[props.id],
      refInverted: state.projects.metadataProject.refInverted,
    };
  },
  { togglePieFilter }
)(PieGeneric);
