/**
 * FoldableSidebar component.
 * @module components/elements/FoldableSidebar/FoldableSidebar
 */

import React from "react";
import { connect } from "react-redux";
import {
  setMapDashboardsMaximized,
  setMapDashboardsMinimized,
} from "../../../actions/map/map";

class InOutCarat extends React.Component {
  constructor(props) {
    super(props);
    this.onToggleMaximized = this.onToggleMaximized.bind(this);
    this.onToggleMinimized = this.onToggleMinimized.bind(this);
  }

  onToggleMaximized() {
    this.props.setMapDashboardsMaximized(!this.props.minimized);
    this.props.setMapDashboardsMinimized(false);
  }
  onToggleMinimized() {
    this.props.setMapDashboardsMinimized(!this.props.maximized);
    this.props.setMapDashboardsMaximized(false);
  }

  render() {
    //  const { height } = this.props;
    return (
      <div className="containerInOutCaret">
        {!this.props.minimized && (
          <div style={{}} className="inOutCarat">
            <span
              onClick={this.onToggleMinimized}
              className={"la la-caret-down"}
            />
          </div>
        )}
        {!this.props.maximized && (
          <div
            style={{}}
            className="zoomOutCarat"
            onClick={this.onToggleMaximized}
          >
            <span className={"la la-caret-up"} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      maximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      minimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
    };
  },
  {
    setMapDashboardsMaximized,
    setMapDashboardsMinimized,
  }
)(InOutCarat);
