/**
 * Url helper.
 * @module helpers/url/url
 */

import { map, mapValues } from "lodash";

/**
 * Parse params.
 * @function parseParams
 * @param {Object} params Params
 * @returns {Object} Parsed params.
 */
export function parseParams(params) {
  return mapValues(params, param =>
    map(param, value => (typeof value === "number" ? Math.round(value) : value))
  );
}
