/**
 * Strategie actions.
 * @module actions/strategie/strategie
 */

import { merge } from "lodash";

import {
  GET_STRATEGIE,
  SET_STRATEGY_DISPLAY,
  SET_YEAR_STRATEGY,
  GET_GRUNDDATEN_STRATEGY,
  SET_ID_CHART_STRATEGY
} from "../../constants/ActionTypes";
import { parseParams } from "../../helpers/url/url";
import { parsePrependParams } from "../../helpers/Api/ParsePrependParams";

/**
 * Get strategie.
 * @function getStrategie
 * @params {Number} id Id of the project.
 * @params {Number} bucket Bucket of the project.
 * @params {Object} filters Object with filters.
 * @params {Number} strategyDisplay Strategy to display.
 * @returns {Object} Get strategie action.
 */
export function getStrategie(
  id,
  bucket,
  filters,
  scales,
  units,
  strategyDisplayId,
  typeDashboard
) {
  return {
    type: GET_STRATEGIE,
    request: {
      op: "get",
      path: `strategie/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        strategyDisplayId ? { strategyDisplayId } : {},
        { typeDashboard: typeDashboard }
      )
    }
  };
}

export function getGrunddatenStrategy(
  id,
  bucket,
  filters,
  scales,
  units,
  strategyDisplayId,
  typeDashboard,
  yearStrategy,
  idChart
) {
  return {
    type: GET_GRUNDDATEN_STRATEGY,
    request: {
      op: "get",
      path: `grunddaten-strategie/${id}`,
      params: merge(
        parseParams(filters),
        parsePrependParams(scales, "scale_"),
        parsePrependParams(units, "unit_"),
        bucket ? { bucket } : {},
        { typeDashboard: typeDashboard },
        { yearStrategy: yearStrategy },
        strategyDisplayId ? { strategyDisplayId } : {},
        { idChart: idChart }
      )
    }
  };
}

export function setIdChart(idChart) {
  return {
    type: SET_ID_CHART_STRATEGY,
    idChart
  };
}

/**
 * Set strategy to display.
 * @function setStrategyDisplay
 * @param {Number} id Strategy id.
 * @returns {Object} Set strategy action.
 */
export function setStrategyDisplay(id) {
  return {
    type: SET_STRATEGY_DISPLAY,
    id
  };
}

export function setYearStrategy(yearStrategy) {
  return {
    type: SET_YEAR_STRATEGY,
    yearStrategy
  };
}
