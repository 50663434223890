/**
 * Detailview reducer.
 * @module reducers/detailview/detailview
 */

import {
  SET_DETAILVIEW_OPEN,
  RESET_DETAILVIEW,
  SET_PROJECT
} from "../../constants/ActionTypes";

const initialState = {
  isOpen: false,
  idChart: undefined,
  typeChart: undefined,
  typeView: undefined
};

/**
 * Detailview reducer.
 * @function detailview
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function detailview(state = initialState, action = {}) {
  switch (action.type) {
    case `${SET_DETAILVIEW_OPEN}`:
      return {
        ...state,
        isOpen: action.isOpen,
        idChart: action.idChart,
        typeChart: action.typeChart,
        typeView: action.typeView
      };
    case `${RESET_DETAILVIEW}`:
      return initialState;
    case `${SET_PROJECT}`:
      return initialState;
    default:
      return state;
  }
}
