/**
 * Projects reducer.
 * @module reducers/projects/projects
 */

import cookie from "react-cookie";
import { UNITS_CHARTS } from "../../constants/LabelsAxes";

import {
  GET_PROJECTS,
  ADD_PROJECT,
  SET_PROJECT,
  SET_BUCKET,
  GET_METADATA_PROJECT,
} from "../../constants/ActionTypes";

const initialState = {
  error: null,
  errorMetadata: null,
  project: parseInt(cookie.load("project"), 10) || null,
  bucket: parseInt(cookie.load("bucket"), 10) || null,
  projects: [],
  metadataProject: {
    ref: {},
    refInverted: {},
    class_convention: "default",
    unitsCharts: UNITS_CHARTS,
  },
  loaded: false,
  loading: false,
  metadataLoading: false,
  metadataLoaded: false,
};

function invertMappings(mappings) {
  return Object.entries(mappings).reduce(
    (result, idChartWithMapping) => ({
      ...result,
      [idChartWithMapping[0]]: invertMapping(idChartWithMapping[1]),
    }),
    {}
  );
}

function invertMapping(mapping) {
  // Build dict where key is label and value is list ov values
  // mapped to the key
  const inverseWithArrays = Object.keys(mapping).reduce(
    (groups, keyMapping) =>
      Object.keys(groups).includes(mapping[keyMapping])
        ? {
            ...groups,
            [mapping[keyMapping]]: [
              ...groups[mapping[keyMapping]],
              keyMapping,
            ].sort(),
          }
        : { ...groups, [mapping[keyMapping]]: [keyMapping] },
    {}
  );

  return inverseWithArrays;
}

/**
 * Projects reducer.
 * @function projects
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function projects(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_METADATA_PROJECT}_PENDING`:
      return {
        ...state,
        errorMetadata: null,
        metadataLoaded: false,
        metadataLoading: true,
      };
    case `${GET_PROJECTS}_PENDING`:
    case `${ADD_PROJECT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_PROJECTS}_SUCCESS`:
    case `${ADD_PROJECT}_SUCCESS`:
      const project = state.project || action.result[0].id;
      if (!state.project) {
        cookie.save("project", project);
      }
      return {
        ...state,
        error: null,
        projects: action.result,
        project,
        loaded: true,
        loading: false,
      };
    case `${GET_METADATA_PROJECT}_SUCCESS`:
      return {
        ...state,
        errorMetadata: null,
        metadataProject: {
          ...action.result,
          refInverted: invertMappings(action.result.ref),
          unitsCharts: initialState.metadataProject.unitsCharts,
        },
        metadataLoaded: true,
        metadataLoading: false,
      };
    case `${GET_METADATA_PROJECT}_FAIL`:
      return {
        ...state,
        metadataProject: initialState.metadataProject,
        errorMetadata: action.result,
        metadataLoaded: false,
        metadataLoading: false,
      };
    case `${GET_PROJECTS}_FAIL`:
    case `${ADD_PROJECT}_FAIL`:
      return {
        ...state,
        error: action.result,
        loaded: false,
        loading: false,
      };
    case SET_PROJECT:
      // set the first bucket in new project as active
      const project_set = state.projects.find(
        (project) => project.id === action.id
      );
      const id_bucket = project_set.buckets[0].id;

      cookie.save("project", action.id);
      cookie.save("bucket", id_bucket);
      return {
        ...state,
        project: action.id,
        bucket: id_bucket,
      };
    case SET_BUCKET:
      cookie.save("bucket", action.id);
      return {
        ...state,
        bucket: action.id,
      };
    default:
      return state;
  }
}
