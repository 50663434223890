export const UNIT_COUNT = "cnt";
export const UNIT_LENGTH = "length";
export const UNIT_AMOUNT = "amount";
export const UNIT_NOTHING = "unitless"; // e.g. for Strategy comparison

// Strategy correlation with base data
export const SCALE_AVERAGE = "average";
export const SCALE_AVERAGE_DIFFEREENCE = "average_difference";
export const SCALE_COUNT_AVERAGE_DIFFERENCE = "cnt_average_difference";
export const SCALE_LENGTH_AVERAGE_DIFFERENCE = "length_average_difference";
export const SCALE_SUM_DIFFERENCE = "sum_average_difference";
export const SCALE_SUM = "sum";

export const SCALE_ABSOLUTE = "abs";
export const SCALE_RELATIVE = "rel";

export const units = {
  [UNIT_COUNT]: "Anzahl [in Tausend]",
  [UNIT_LENGTH]: "Länge",
};

export const unitsStrategyClasses = units;
// in strategy, the only possible unit for rehabs and consts
// is their amount.
export const unitsStrategySummed = {
  [UNIT_NOTHING]: "",
};

export const unitsStrategyComparison = unitsStrategySummed;

// For plots that show classes, the scale is count, but for money and sani
//  it must be defined explicitly as sum
export const scalesStrategySummed = { [SCALE_SUM]: "Summe" };

export const scalesStrategyAvg = {
  [SCALE_AVERAGE]: "Durschnitt",
};
export const scalesStrategySum = {
  [SCALE_SUM]: "Summe",
  [SCALE_AVERAGE]: "Durchschnitt",
};

export const scalesDifferencesAvg = {
  [SCALE_AVERAGE_DIFFEREENCE]: "Durschn. Differenz",
  [SCALE_COUNT_AVERAGE_DIFFERENCE]: "Durschn. Differenz gewichtet nach Anzahl",
  [SCALE_LENGTH_AVERAGE_DIFFERENCE]: "Durschn. Differenz gewichtet nach Länge",
};
export const scalesDifferencesSum = {
  [SCALE_AVERAGE_DIFFEREENCE]: "Durchschn. Differenz",
  [SCALE_SUM_DIFFERENCE]: "Differenz der Summe",
  // [UNIT_LENGTH_AVERAGE_DIFFERENCE]: "Differenz der Länge",
};

export const scales = {
  [SCALE_ABSOLUTE]: "Absolut",
  [SCALE_RELATIVE]: "Relativ",
};

export const unitLabels = { ...units, ...unitsStrategySummed };
