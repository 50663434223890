/**
 * Table component for deatil view.
 * @module components/charts/Line/Line
 */

import React, { Component } from "react";
import { Table } from "reactstrap";

/**
 * BasicTable component class.
 * @class BasicTable
 * @extends Component
 */
class BasicTable extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { namesColumns, rows, rowsLabelled, displayHead } = this.props;

    const rowsUse = rowsLabelled !== undefined ? rowsLabelled : rows;

    // TODO: Coerce values

    return (
      <div className="basicTableWrapper">
        <Table striped className="basicTable">
          {displayHead && (
            <thead>
              <tr>
                {namesColumns.map((nameColumn) => (
                  <th>{nameColumn}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {rowsUse.map((row) => (
              <tr>
                {row.map((value) => (
                  <td>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default BasicTable;
