/**
 * Grunddaten component.
 * @module components/views/Grunddaten/Grunddaten
 */

import React, { Component } from "react";
import Map from "../../elements/Map/Map";

/**
 * Grunddaten component class.
 * @class Grunddaten
 * @extends Component
 */
class Grunddaten extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="mapWrapper">
        <Map typeDashboard={this.props.typeDashboard} alwaysMaximized={true} />
      </div>
    );
  }
}

export default Grunddaten;
