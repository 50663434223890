import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

class PageChooser extends Component {
  render() {
    const {
      pageCurrent,
      numPages,
      numPagesLeftRight,
      onPageChange,
    } = this.props;

    // Get pages to display left and right
    const pageMin = Math.min(
      Math.max(pageCurrent - numPagesLeftRight, 1),
      numPages
    );
    const pageMax = Math.min(pageCurrent + numPagesLeftRight, numPages);
    let pagesDisplay = Array.from(
      new Array(pageMax - pageMin + 1),
      (x, i) => i + pageMin
    );

    return (
      <div className="pageChooser">
        <Button
          size="sm"
          className="la la-angle-left"
          disabled={pageCurrent === 1}
          onClick={(e) => onPageChange(pageCurrent - 1)}
          style={{ margin: "2px" }}
        />
        {pageMin > 1 && (
          <Button size="sm" onClick={(e) => onPageChange(1)}>
            1
          </Button>
        )}
        {pageMin > 1 && <span> ... </span>}
        {pagesDisplay.map((number) => (
          <Button
            key={number}
            size="sm"
            onClick={(e) => onPageChange(number)}
            active={pageCurrent === number}
            style={{ margin: "2px" }}
          >
            {number}
          </Button>
        ))}
        {pageMax < numPages && <span> ... </span>}
        {pageMax < numPages && (
          <Button
            size="sm"
            onClick={(e) => onPageChange(numPages)}
            style={{ margin: "2px" }}
          >
            {numPages}
          </Button>
        )}
        <Button
          size="sm"
          className="la la-angle-right"
          onClick={(e) => onPageChange(pageCurrent + 1)}
          disabled={pageCurrent === numPages}
          style={{ marginLeft: "2px" }}
        />
      </div>
    );
  }
}

export default connect((state) => {}, {})(PageChooser);
