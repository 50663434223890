import * as charts from "./Charts";

// Some continuous class values must be mapped dependent on
//  customer demands. Usually, we either display class values in our system (0 is best)
//  or according to dwa (0 is worst).

export const CLASS_CONVENTION_DWA = "dwa";
export const CLASS_CONVENTION_DEFAULT = "default";

// Charts that have to be mapped.
// Remark: this also affects the correlated base data plots if one of those charts is
// selected as strategy plot.
// but for strategy comparisons, it is not 5 - differnce, but the
//  sign must be inverted.

// TODO: Should substance percentage values also be mapped?
//  at first glance, not. Bot not mapping it makes interpretation
//  in strategievergleich more difficult, because in case of default mapping,
//  the charts for substance and zustandsklasse have different directions.
//  especially in correlated difference plots, this gets confusing.

// TODO: Take care that the colors are still based on the unmapped values.
//  is if possible to assign a label? Maybe for strategie, the classes
//  should have a label and mapping should only be made for correlated
//  base data.
export const CHARTS_MAP_FOR_CONVENTION = [
  charts.ZUSTAND_AVG_DIFFERENCE,
  charts.ZUSTAND_AVG,
];

// Those charts' values do not need to be remapped,
//  but the correlated values in grunddaten must be mapped.
export const CHARTS_MAP_CORRELATED_FOR_CONVENTION = [
  charts.ZUSTANDSKLASSE_STRATEGIE,
  charts.ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE,
  charts.ZUSTANDSKLASSE_BETRIEB_STRATEGIE,
  charts.ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE,
  charts.SUBSTANZKLASSE_STRATEGIE,
  charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE,
  charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE,
  charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE,
  charts.ZUSTAND_AVG,
];
