/**
 * StrategieVergleich reducer.
 * @module reducers/strategieVergleich/strategieVergleich
 */

import {
  GET_STRATEGIEVERGLEICH,
  SET_STRATEGIES_COMPARISON,
  SET_PROJECT,
  SET_BUCKET,
  SET_YEAR_STRATEGY_COMPARISON,
  GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON,
  SET_ID_CHART_DIFFERENCES,
  SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH,
  SET_GROUP_CHARTS_STRATEGIEVERGLEICH,
} from "../../constants/ActionTypes";
import { buildInitialStateData } from "../../helpers/common_functions";
import { diagramsViews } from "../../constants/Dashboards";
import { VIEW_STRATEGIE_VERGLEICH } from "../../constants/NamesViews";
import { isEmpty } from "lodash";
import { PRESETS_STRATEGIEVERGLEICH } from "../../constants/PresetsDashboards";

import cookie from "react-cookie";

const initialState = {
  error: null,
  data: buildInitialStateData(diagramsViews[VIEW_STRATEGIE_VERGLEICH]),
  dataDifferences: {
    baujahr: [],
    materialgruppe: [],
    mittlereTiefe: [],
    kanalnutzung: [],
    haltungslaenge: [],
    kanalart: [],
    nennweite: [],
    lageImVerkehrsraum: [],
    profilart: [],
  },
  strategiesComparisonIds: [],
  yearStrategy: undefined,
  idChartDifferences: undefined,
  loadedStrategyComparison: false,
  loadingStrategyComparison: false,
  loadedDifferences: false,
  loadingDifferences: false,
  groupsCharts: PRESETS_STRATEGIEVERGLEICH,
  showDifferenceCharts: true,
  groupCharts: "Gesamtansicht",
};

/**
 * Schadensklassifizierung reducer.
 * @function strategieVergleich
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function strategieVergleich(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_STRATEGIEVERGLEICH}_PENDING`:
      return {
        ...state,
        error: null,
        loadedStrategyComparison: false,
        loadingStrategyComparison: true,
      };
    case `${GET_STRATEGIEVERGLEICH}_SUCCESS`:
      // Order layers in data for each diagram so that reference strategy
      // is first element in order to ensure correct difference calculation
      // As there are only two strategies (refrence and comparison),
      // it is sufficient to invert the array if first element is not
      // reference strategy name
      var data = action.result;
      for (var nameChart in data) {
        if (data[nameChart][0] === undefined) {
          // TODO: For some reason, SUCCESS is dispatched when switching bucket
          //  while request is loading. Find proper way to handle this. See #
          return state;
        }
        // Ensure correct order of strategies
        // TODO: should be handled by backend
        if (data[nameChart][0].name !== state.strategiesComparisonIds[0]) {
          data[nameChart].reverse();
        }

        // Only show data for years that are present in both strategies
        //        if (data[nameChart].length === 2) {
        //          const years_second = data[nameChart][1].data.map(
        //            (element) => element.x
        //          );
        //          const years_first = data[nameChart][0].data.map(
        //            (element) => element.x
        //          );
        //          data[nameChart][0].data = data[nameChart][0].data.filter((element) =>
        //            years_second.includes(element.x)
        //          );
        //          data[nameChart][1].data = data[nameChart][1].data.filter((element) =>
        //            years_first.includes(element.x)
        //          );
        //        }
      }

      return {
        ...state,
        error: null,
        data: !isEmpty(data) ? data : initialState.data,
        loadedStrategyComparison: true,
        loadingStrategyComparison: false,
      };
    case `${GET_STRATEGIEVERGLEICH}_FAIL`:
      return {
        ...state,
        error: action.error,
        data: initialState.data,
        loadedStrategyComparison: false,
        loadingStrategyComparison: false,
      };
    case `${GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON}_PENDING`:
      return {
        ...state,
        error: null,
        loadedDifferences: false,
        loadingDifferences: true,
      };
    case `${GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON}_SUCCESS`:
      return {
        ...state,
        error: null,
        dataDifferences: !isEmpty(action.result)
          ? action.result
          : initialState.dataDifferences,
        loadedDifferences: true,
        loadingDifferences: false,
      };
    case `${GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON}_FAIL`:
      return {
        ...state,
        error: action.error,
        dataDifferences: initialState.data,
        loadedDifferences: false,
        loadingDifferences: false,
      };
    case SET_STRATEGIES_COMPARISON:
      cookie.save("strategiesComparisonIds", action.ids);
      return {
        ...state,
        strategiesComparisonIds: action.ids,
      };
    case SET_PROJECT:
      return { ...initialState };
    case SET_YEAR_STRATEGY_COMPARISON:
      return {
        ...state,
        yearStrategy: action.year,
      };
    case SET_ID_CHART_DIFFERENCES:
      return {
        ...state,
        idChartDifferences: action.idChart,
      };
    case SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH:
      return {
        ...state,
        showDifferenceCharts: action.showDifferences,
      };
    case SET_GROUP_CHARTS_STRATEGIEVERGLEICH:
      return {
        ...state,
        groupCharts: action.nameGroupCharts,
      };
    case SET_BUCKET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
