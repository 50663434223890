import { GET_CHANGELOG } from "../../constants/ActionTypes";

// todo: is reload needed?

const initialState = {
  version: "",
  content: ""
};

export default function changelog(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CHANGELOG}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true
      };
    case `${GET_CHANGELOG}_SUCCESS`:
      return {
        ...state,
        error: null,
        content: action.result.content,
        version: action.result.version,
        loaded: true,
        loading: false
      };
    case `${GET_CHANGELOG}_FAIL`:
      return {
        ...state,
        content: initialState.content,
        version: initialState.version,
        error: action.error,
        loaded: false,
        loading: false
      };
    default:
      return state;
  }
}
