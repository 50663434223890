/**
 * Navbar component.
 * @module components/elements/Navbar/Navbar
 */

import React, { Component } from "react";
import {
  Nav,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import cookie from "react-cookie";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { find, intersection, isEmpty } from "lodash";
import * as charts from "../../../constants/Charts";
import { formatUrl } from "../../../helpers/Api/Api";
//import  {CheckBox}  from './checkbox'
/**
 * Navbar component class.
 * @class Navbar
 * @extends Component
 */
class Navbar extends Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      //      properties: [],
      linkProps: {
        id_customer: true, // default checked
        objektbezeichnung: true, // default checked
        knotenbezeichnung: false,
        baujahr: true,
        mittlereTiefe: true,
        materialgruppe: true,
        mittlereTiefe: true,
        haltungslaenge: false,
        kanalart: false,
        nennweite: false,
        lageImVerkehrsraum: false,
        profilart: false,
        inspektionslaenge: false,
        inspektionsgrund: false,
        inspektionsjahr: false,
        inspektionsart: false,
        kodiersystem: false,
        zustandsklasse: false,
        zustandStandsicherheit: false,
        zustandBetrieb: false,
        zustandDichtheit: false,
        substanzklasse: false,
        substanzStandsicherheit: false,
        substanzBetrieb: false,
        substanzDichtheit: false,
      },
    };

    this.hasPermission = this.hasPermission.bind(this);
    this.onToggleModal = this.onToggleModal.bind(this);
  }
  //
  //  handleCheckboxChange = (event) => {
  //    let newArray = [...this.state.properties, event.target.id];
  //    if (this.state.properties.includes(event.target.id)) {
  //      newArray = newArray.filter((property) => property !== event.target.id);
  //    }
  //    this.setState({
  //      properties: newArray,
  //    });
  //  };

  handleClick = (event) => {
    const { id, checked } = event.target;

    this.setState((prevState) => {
      const linkProps = prevState.linkProps;
      linkProps[id] = checked;
      return linkProps;
    });
  };

  // issue #545
  getDataLink = (
    properties,
    project_id,
    filters,
    currentView,
    value,
    bucketId
  ) => {
    if (properties !== undefined) {
      var link_string =
        "export/" +
        project_id +
        "?" +
        //        "&" +
        "typeDashboard=" +
        currentView +
        "&" +
        "bucket=" +
        bucketId +
        "&" +
        "delimiter=" +
        value +
        "&";
      for (var i = 0; i < properties.length; i++) {
        var property = properties[i]; // lowercase
        link_string = link_string + "column=" + property;
        if (i !== properties.length - 1) {
          link_string = link_string + "&";
        }
      }
      if (isEmpty(filters) === false) {
        for (var j = 0; j < Object.keys(filters).length; j++) {
          for (var k = 0; k < Object.values(filters)[j].length; k++) {
            link_string =
              link_string +
              "&" +
              Object.keys(filters)[j] +
              "=" +
              Object.values(filters)[j][k];
          }
        }
      }
    }
    var encodedLink = encodeURI(link_string);
    var link = document.createElement("a");
    link.setAttribute("href", formatUrl(encodedLink));
    link.setAttribute("download", `${currentView}.csv`);
    document.body.appendChild(link);
    link.click();
    cookie.save("separatorDownload", value);
    console.log(link);
  };

  onToggleModal(event) {
    this.setState({
      showModal: true,
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  /**
   * Has permission
   * @method hasPermission
   * @param {String|Array} permission Permission(s) to check.
   * @returns {Boolean} true if has permission.
   */
  hasPermission(permission) {
    if (typeof permission === "string" || permission instanceof String) {
      return this.props.permissions.indexOf(permission) !== -1;
    } else {
      return intersection(this.props.permissions, permission).length > 0;
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const favColors = Object.keys(this.state.linkProps)
      .filter((key) => this.state.linkProps[key])
      .join(",");
    var properties = favColors.split(",");

    return (
      <div
        className="section-nav header-navbar navbar-expand-sm navbar navbar-horizontal navbar-fixed navbar-dark navbar-without-dd-arrow navbar-shadow navbarDashboards"
        role="navigation"
        data-menu="menu-wrapper"
        style={{ justifyContent: "space-between" }}
      >
        <span
          style={{
            fontWeight: "500",
            color: "#464855",
            fontSize: "18px",
            padding: "12px ",
            cursor: "pointer",
          }}
          onClick={this.onToggleModal}
        >
          Datenexport
        </span>

        {this.props.token && (
          <div
            className="navbar-container main-menu-content"
            data-menu="menu-container"
          >
            <Nav id="main-menu-navigation" className="navbar-nav">
              {this.hasPermission(["objektansicht-grunddaten"]) && (
                <li className="nav-item">
                  <Link
                    to="/objektansicht-grunddaten"
                    className="nav inNavbar nav-link"
                  >
                    Objektansicht
                  </Link>
                </li>
              )}
              {this.hasPermission(["grunddaten", "inspektionsdaten"]) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Basisdaten
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menuNav">
                    {this.hasPermission("grunddaten") && (
                      <DropdownItem
                        tag={Link}
                        to="/grunddaten"
                        className="dropdown-itemNav"
                      >
                        Grunddaten
                      </DropdownItem>
                    )}
                    {this.hasPermission("inspektionsdaten") && (
                      <DropdownItem
                        tag={Link}
                        to="/inspektionsdaten"
                        className="dropdown-itemNav"
                      >
                        Inspektionsdaten
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {this.hasPermission([
                "schadensklassifizierung",
                "objektklassifizierung",
                "objektklassifizierung-schutzziele",
              ]) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Analysedaten
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menuNav">
                    {this.hasPermission("schadensklassifizierung") && (
                      <DropdownItem
                        tag={Link}
                        to="/schadensklassifizierung"
                        className="dropdown-itemNav"
                      >
                        Schadensklassifizierung
                      </DropdownItem>
                    )}
                    {this.hasPermission("objektklassifizierung") && (
                      <DropdownItem
                        tag={Link}
                        to="/objektklassifizierung"
                        className="dropdown-itemNav"
                      >
                        Objektklassifizierung
                      </DropdownItem>
                    )}
                    {this.hasPermission(
                      "objektklassifizierung-schutzziele"
                    ) && (
                      <DropdownItem
                        tag={Link}
                        to="/objektklassifizierung-schutzziele"
                        className="dropdown-itemNav"
                      >
                        Objektklassifizierung: Schutzziele
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {this.hasPermission(["strategie", "strategie-vergleich"]) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Strategieanalyse
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menuNav">
                    {this.hasPermission("strategie") && (
                      <DropdownItem
                        tag={Link}
                        to="/strategie"
                        className="dropdown-itemNav"
                      >
                        Strategie
                      </DropdownItem>
                    )}
                    {this.hasPermission("strategie-vergleich") && (
                      <DropdownItem
                        tag={Link}
                        to="/strategie-vergleich"
                        className="dropdown-itemNav"
                      >
                        Strategievergleich (Monitoring)
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </div>
        )}

        <Modal
          modalClassName="modal-dialog"
          isOpen={this.state.showModal}
          toggle={this.onToggleModal}
        >
          <ModalHeader>
            <h3>Datenexport</h3>
          </ModalHeader>
          <ModalBody className="raj">
            <Form>
              <h4> Grunddaten </h4>
              <fieldset className="checkbox">
                <Label for="id_customer">
                  <Input
                    type="checkbox"
                    name="property"
                    id="id_customer"
                    onChange={this.handleClick}
                    checked={this.state.linkProps.id_customer}
                  />
                  Objekt-ID
                </Label>
                <Label for="objektbezeichnung">
                  <Input
                    type="checkbox"
                    name="property"
                    id="objektbezeichnung"
                    onChange={this.handleClick}
                    checked={this.state.linkProps.objektbezeichnung}
                  />
                  Objektbezeichnung
                </Label>
                <Label for="knotenbezeichnung">
                  <Input
                    type="checkbox"
                    name="property"
                    id="knotenbezeichnung"
                    onChange={this.handleClick}
                    checked={this.state.linkProps.knotenbezeichnung}
                  />
                  Knotenbezeichnung
                </Label>
                <Label for="baujahr">
                  <Input
                    type="checkbox"
                    id={charts.BAUJAHR}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.baujahr}
                  />
                  Baujahr
                </Label>
                <Label for="mittlereTiefe">
                  <Input
                    type="checkbox"
                    id={charts.MITTLERE_TIEFE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.mittlereTiefe}
                  />
                  Mittlere Tiefe
                </Label>
                <Label for="materialgruppe">
                  <Input
                    type="checkbox"
                    id={charts.MATERIALGRUPPE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.materialgruppe}
                  />
                  Materialgruppe
                </Label>
                <Label for="kanalnutzung">
                  <Input
                    type="checkbox"
                    id="kanalnutzung"
                    onChange={this.handleClick}
                    checked={this.state.linkProps.kanalnutzung}
                  />
                  Kanalnutzung
                </Label>
                <Label for="haltungslaenge">
                  <Input
                    type="checkbox"
                    id={charts.HALTUNGSLAENGE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.haltungslaenge}
                  />
                  Haltungslaenge
                </Label>
                <Label for="kanalart">
                  <Input
                    type="checkbox"
                    id={charts.KANALART}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.kanalart}
                  />
                  Kanalart
                </Label>
                <Label for="nennweite">
                  <Input
                    type="checkbox"
                    id={charts.NENNWEITE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.nennweite}
                  />
                  Nennweite
                </Label>
                <Label for="lageImVerkehrsraum">
                  <Input
                    type="checkbox"
                    id={charts.LAGE_IM_VERKEHRSRAUM}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.lageImVerkehrsraum}
                  />
                  Lage im Verkehrsraum
                </Label>
                <Label for="profilart">
                  <Input
                    type="checkbox"
                    id={charts.PROFILART}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.profilart}
                  />
                  Profilart
                </Label>
              </fieldset>
              <h4> Inspektionsdaten </h4>
              <fieldset className="checkbox">
                <Label for="inspektionslaenge">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.INSPEKTIONSLAENGE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.inspektionslaenge}
                  />
                  Inspektionslaenge
                </Label>
                <Label for="inspektionsgrund">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.INSPEKTIONSGRUND}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.inspektionsgrund}
                  />
                  Inspektionsgrund
                </Label>
                <Label for="inspektionsjahr">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.INSPEKTIONSJAHR}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.inspektionsjahr}
                  />
                  Inspektionsjahr
                </Label>
                <Label for="inspektionsart">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.INSPEKTIONSART}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.inspektionsart}
                  />
                  Inspektionsart
                </Label>
                <Label for="kodiersystem">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.KODIERSYSTEM}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.kodiersystem}
                  />
                  Kodiersystem
                </Label>
              </fieldset>
              <h4> Objektklassifizierung </h4>
              <fieldset className="checkbox">
                <Label for="zustandsklasse">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.ZUSTANDSKLASSE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.zustandsklasse}
                  />
                  Zustandsklasse
                </Label>
                <fieldset className="checkbox">
                  <Label for="zustandStandsicherheit">
                    <Input
                      type="checkbox"
                      name="property"
                      id="zustandStandsicherheit"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.zustandStandsicherheit}
                    />
                    Standsicherheit
                  </Label>
                  <Label for="zustandBetrieb">
                    <Input
                      type="checkbox"
                      name="property"
                      id="zustandBetrieb"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.zustandBetrieb}
                    />
                    Betrieb
                  </Label>
                  <Label for="zustandDichtheit">
                    <Input
                      type="checkbox"
                      name="property"
                      id="zustandDichtheit"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.zustandDichtheit}
                    />
                    Dichtheit
                  </Label>
                </fieldset>

                <Label for="substanzklasse">
                  <Input
                    type="checkbox"
                    name="property"
                    id={charts.SUBSTANZKLASSE}
                    onChange={this.handleClick}
                    checked={this.state.linkProps.substanzklasse}
                  />
                  Substanzklasse
                </Label>
                <fieldset className="checkbox">
                  <Label for="substanzStandsicherheit">
                    <Input
                      type="checkbox"
                      name="property"
                      id="substanzStandsicherheit"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.substanzStandsicherheit}
                    />
                    Standsicherheit
                  </Label>
                  <Label for="substanzBetrieb">
                    <Input
                      type="checkbox"
                      name="property"
                      id="substanzBetrieb"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.substanzBetrieb}
                    />
                    Betrieb
                  </Label>
                  <Label for="substanzDichtheit">
                    <Input
                      type="checkbox"
                      name="property"
                      id="substanzDichtheit"
                      onChange={this.handleClick}
                      checked={this.state.linkProps.substanzDichtheit}
                    />
                    Dichtheit
                  </Label>
                </fieldset>
              </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
            <span>
              Datentrennzeichen
              <select
                defaultValue={cookie.load("separatorDownload")}
                ref="downloadSetting"
              >
                <option value="s">Semikolon</option>
                <option value="c">Komma</option>
                <option value="t">Tabulator</option>
              </select>
            </span>
            <Button
              color="secondary"
              onClick={() =>
                this.getDataLink(
                  properties,
                  this.props.project,
                  this.props.filtersApplied,
                  this.props.typeDashboard,
                  this.refs.downloadSetting.value,
                  this.props.bucketId
                )
              }
            >
              Export data
            </Button>
            <Button color="secondary" onClick={this.closeModal}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => {
  const project = find(state.projects.projects, {
    id: state.projects.project,
  });

  return {
    filtersApplied: state.filters.filtersApplied,
    token: state.userSession.token,
    permissions: project ? project.permissions : [],
    project: state.projects.project,
    typeDashboard: state.views.dashboard,
    bucketId: state.projects.bucket,
  };
}, {})(Navbar);
