/**
 * Detailview componnt.
 * @module components/elements/Detailview/Detailview
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

import {
  getHgObject,
  getRndObject,
  getPrognosisObject,
  resetData,
  getIdsObjectsNodeLower,
  getIdsObjectsNodeUpper,
  resetIdsObjectsNodeLower,
  resetIdsObjectsNodeUpper,
} from "../../../actions/objectview/objectviewObject";

import TableGenericVertical from "../../charts/TableGeneric/TableGenericVertical";

import {
  getDataTableObjects,
  getPageObject,
} from "../../../actions/objectview/objectviewGrunddaten";

import { setIdObjectActive } from "../../../actions/objectview/objectview";

import SidebarObjectview from "../../elements/Sidebar/SidebarObjectview";

import BasicTable from "../../elements/Table/BasicTable";
import Chart from "../../charts/Chart/Chart";

import { formatSubstanceValues } from "../../../helpers/objectview/data";
import { VIEW_EINZELOBJEKTANSICHT_PROGNOSE } from "../../../constants/NamesViews";

import { mapValue } from "../../../helpers/charts/common_functions";
import {
  PROGNOSIS_OBJECT_LEGEND,
  PROGNOSIS_OBJECT_SUBSTANZ,
  PROGNOSIS_OBJECT_TABLE,
  PROGNOSIS_OBJECT_ZUSTAND,
} from "../../../constants/Charts";

// TODO: Implement button to switch to active inspection's inspection analysis

// TODO: Sidebar-Tables for RND and base data.
// TODO: Discrete-y-chart with horizontal lines. Discrete will not be
//  A problem? But Horizontal lines must be delivered and colors must be
//  defined
// TODO: Separate request for prognosis, HG and RND.

// TODO: do not define ticks and table value mapping for zustand here
//  but make add_label_to_data() handle this instead

/**
 * Detailview component class. Represents a detailed view for a diagram.
 * @class Detailview
 * @extends Component
 */
class ObjectviewObject extends Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.resetData();
    this.getData(this.props.idProject, this.props.idObjectActive);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.idProject !== nextProps.idProject ||
      this.props.idObjectActive !== nextProps.idObjectActive ||
      this.props.bucket !== nextProps.bucket
    ) {
      this.getData(nextProps.idProject, nextProps.idObjectActive);
    }
    if (!isEqual(this.props.hgObject, nextProps.hgObject)) {
      // Obtain neighbor objects
      this.getNeighbors(nextProps);
    }
  }

  // TODO: Consider the set applied filters.
  // Important: Also handle an already set node filter correctly!

  getNeighbors(props) {
    /* For getting neighbor objects when filters are set
    in the objectview object's object-list.
    If node filters are set, two cases exist:
    1. The node is not present in the node filters. 
      Then, the search for the neighbor should not return any result
      because all possible candidates are filtered out
      by node filter. Hence, action should return empty list.
    2. The node is present in the node filters. In this case,
      The node filter to use should only be the corresponding node.
      This is handled in the action.
  */
    if (
      (props.hgObject.knotenbezeichnung_unten !== undefined &&
        props.filtersApplied.knotenbezeichnung_oben === undefined) ||
      (props.filtersApplied.knotenbezeichnung_oben !== undefined &&
        props.filtersApplied.knotenbezeichnung_oben.includes(
          props.hgObject.knotenbezeichnung_unten
        ))
    ) {
      props.getIdsObjectsNodeLower(
        props.idProject,
        props.bucket,
        props.hgObject.knotenbezeichnung_unten,
        props.filtersApplied,
        10
      );
    } else {
      this.props.resetIdsObjectsNodeLower();
    }

    if (
      (props.hgObject.knotenbezeichnung_oben !== undefined &&
        props.filtersApplied.knotenbezeichnung_unten === undefined) ||
      (props.filtersApplied.knotenbezeichnung_unten !== undefined &&
        props.filtersApplied.knotenbezeichnung_unten.includes(
          props.hgObject.knotenbezeichnung_oben
        ))
    ) {
      props.getIdsObjectsNodeUpper(
        props.idProject,
        props.bucket,
        props.hgObject.knotenbezeichnung_oben,
        props.filtersApplied,
        10
      );
    } else {
      this.props.resetIdsObjectsNodeUpper();
    }
  }

  getData(idProject, idObjectActive) {
    if (idObjectActive) {
      this.props.getHgObject(idProject, idObjectActive);
      this.props.getRndObject(idProject, idObjectActive);
      this.props.getPrognosisObject(idProject, idObjectActive);
    }
  }

  drawSidebarContent() {
    const { hgObject, rndObject } = this.props;

    return (
      <div>
        <span>Grunddaten:</span>
        <TableGenericVertical
          rows={[Object.values(hgObject)]}
          idsColumns={Object.keys(hgObject)}
          classNameTable="tableObjectviewHg"
        />
        {/* 
        <BasicTable
          rows={orderBase.map((nameAttribute) => [
            mapping_columns_base[nameAttribute],
            hgObject[nameAttribute],
          ])}
        /> */}
        <span>Restnutzungsdauern:</span>
        <BasicTable
          rows={Object.keys(rndObject).map((nameAttribute) => [
            nameAttribute,
            rndObject[nameAttribute],
          ])}
        />
      </div>
    );
  }

  getDataDiagramFromPrognosis(namesLayers, applyShift) {
    if (applyShift) {
      const indicesColumns = namesLayers.map((nameLayer) =>
        this.props.prognosisObject.namesColumns.indexOf(nameLayer)
      );
      var maxShift = 0.02;
      var shift = (maxShift / (indicesColumns.length - 1)) * 2;
    }

    return namesLayers.map((nameLayer, index) => {
      const idxColumn = this.props.prognosisObject.namesColumns.indexOf(
        nameLayer
      );
      return {
        name: nameLayer,
        data: this.props.prognosisObject.rows.map((row) => ({
          x: row[0], // Year
          // If wanted, shift curves a little so that they do not overlap
          y: applyShift
            ? row[idxColumn] - maxShift + shift * index
            : row[idxColumn],
        })),
      };
    });
  }

  drawViewContent() {
    const typeView = VIEW_EINZELOBJEKTANSICHT_PROGNOSE;

    // split the dataset into Subtanz and Prio plot data
    const layersSubs = ["subs", "seco", "sstb", "scap"];
    const layersPrio = ["prio_class", "zeco_class", "zstb_class", "zcap_class"];

    // Needed layout: List of layers with name and List of {x, y}
    const dataSubs = this.getDataDiagramFromPrognosis(layersSubs);
    const dataPrio = this.getDataDiagramFromPrognosis(layersPrio, true);
    // const labelsLegend = layersPrio.concat(layersSubs).map(nameColumn => ({
    //   x: nameColumn
    // }));

    const labelsLegend = [
      { x: "Priorität / Substanz gesamt" },
      { x: "Dichtheit" },
      { x: "Standsicherheit" },
      { x: "Betrieb" },
    ];

    // const namesColumnsPrognosisMapped = this.props.prognosisObject.namesColumns.map(
    //   name => mapping_columns_prognosis[name]
    // );

    const dataTable = {
      namesColumns: this.props.prognosisObject.namesColumns,
      rows: this.props.prognosisObject.rows.map((row) =>
        formatSubstanceValues(row, this.props.prognosisObject.namesColumns)
      ),
    };

    return (
      <div className="wrapperObjectviewObject">
        <Row>
          <Chart
            data={dataSubs}
            id={PROGNOSIS_OBJECT_SUBSTANZ}
            typeView={typeView}
            showUnitButton={false}
            showDetailButton={false}
            showScaleButton={false}
            showMaximizeButton={true}
          />
          <Chart
            data={dataPrio}
            id={PROGNOSIS_OBJECT_ZUSTAND}
            typeView={typeView}
            showUnitButton={false}
            showDetailButton={false}
            showScaleButton={false}
            showMaximizeButton={true}
            tickFormat={(value) =>
              this.props.refMappings[PROGNOSIS_OBJECT_ZUSTAND] !== undefined
                ? mapValue(
                    this.props.refMappings[PROGNOSIS_OBJECT_ZUSTAND],
                    value
                  )
                : value
            }
            tickValues={[0, 1, 2, 3, 4, 5]}
            paddingTickValues={50}
          />
          <Chart
            data={labelsLegend}
            id={PROGNOSIS_OBJECT_LEGEND}
            showUnitButton={false}
            showDetailButton={false}
            showScaleButton={false}
            showMaximizeButton={false}
            typeView={typeView}
          />
        </Row>
        <Row>
          <Chart
            data={dataTable}
            id={PROGNOSIS_OBJECT_TABLE}
            showUnitButton={false}
            showDetailButton={false}
            showScaleButton={false}
            showMaximizeButton={false}
            typeView={typeView}
          />
        </Row>
      </div>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const widthSidebar = this.props.widthSidebar;
    const widthContent = 100 - widthSidebar;
    return (
      <div>
        <SidebarObjectview
          width={this.props.widthSidebar}
          title={
            <div
              style={{
                position: "fixed",
                background: "#FFF",
                width: "24.02%",
                height: "27px",
                padding: "4px",
                marginLeft: "-6px",
                marginTop: "-5px",
              }}
            >
              <span className="filter-label">Objektansicht: Haltung</span>
              <Link to="objektansicht-grunddaten" className="filter-apply">
                Übersicht
              </Link>

              {this.props.idsObjectsNodeLower.length > 1 && (
                <UncontrolledDropdown className="dropdownObjectviewNeighbor">
                  <DropdownToggle className="la la-angle-right filter-apply" />
                  <DropdownMenu>
                    {this.props.idsObjectsNodeLower.map((id) => (
                      <DropdownItem
                        onClick={() => this.props.setIdObjectActive(id)}
                      >
                        Nachbar-ID: {id}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {this.props.idsObjectsNodeLower.length <= 1 && (
                <Button
                  onClick={() =>
                    this.props.setIdObjectActive(
                      this.props.idsObjectsNodeLower[0]
                    )
                  }
                  disabled={this.props.idsObjectsNodeLower.length === 0}
                  className="la la-angle-right filter-apply"
                />
              )}

              {this.props.idsObjectsNodeUpper.length > 1 && (
                <UncontrolledDropdown className="dropdownObjectviewNeighbor">
                  <DropdownToggle className="la la-angle-left filter-apply" />
                  <DropdownMenu>
                    {this.props.idsObjectsNodeUpper.map((id) => (
                      <DropdownItem
                        onClick={() => this.props.setIdObjectActive(id)}
                      >
                        Nachbar-ID: {id}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {this.props.idsObjectsNodeUpper.length <= 1 && (
                <Button
                  onClick={() =>
                    this.props.setIdObjectActive(
                      this.props.idsObjectsNodeUpper[0]
                    )
                  }
                  disabled={this.props.idsObjectsNodeUpper.length === 0}
                  className="la la-angle-left filter-apply"
                />
              )}
            </div>
          }
        >
          {this.drawSidebarContent()}
        </SidebarObjectview>
        <div
          className={
            this.props.sidebarIsOpen
              ? "mainColumn sidebarOpen"
              : "mainColumn sidebarClosed"
          }
          style={{
            width: this.props.sidebarIsOpen ? `${widthContent}%` : "100%",
          }}
        >
          {this.drawViewContent()}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      widthSidebar: state.objectviewObject.widthSidebar,
      sidebarIsOpen: state.grunddaten.sidebarIsOpen,
      idProject: state.projects.project,
      bucket: state.projects.bucket,
      idObjectActive: state.objectview.idObjectActive,
      hgObject: state.objectviewObject.hg,
      rndObject: state.objectviewObject.rnd,
      prognosisObject: state.objectviewObject.prognosis,
      idsObjectsNodeLower: state.objectviewObject.idsObjectsNodeLower,
      idsObjectsNodeUpper: state.objectviewObject.idsObjectsNodeUpper,
      filtersApplied: state.objectviewGrunddaten.filtersApplied,
      refMappings: state.projects.metadataProject.ref,
    };
  },
  {
    getHgObject,
    getRndObject,
    getPrognosisObject,
    resetData,
    getIdsObjectsNodeLower,
    getIdsObjectsNodeUpper,
    setIdObjectActive,
    getDataTableObjects,
    getPageObject,
    resetIdsObjectsNodeLower,
    resetIdsObjectsNodeUpper,
  }
)(ObjectviewObject);
