/**
 * Inspektionsdaten component.
 * @module components/views/Inspektionsdaten/Inspektionsdaten
 */

import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { getInspektionsdaten } from "../../../actions/inspektionsdaten/inspektionsdaten";

import { isEqual } from "lodash";

import Chart from "../../charts/Chart/Chart";
import Map from "../../elements/Map/Map";

import { diagramsViews } from "../../../constants/Dashboards";
import * as charts from "../../../constants/Charts";

import { VIEW_INSPEKTIONSDATEN } from "../../../constants/NamesViews";
import InOutCarat from "../../elements/InOutCarat/InOutCarat";

/**
 * Inspektionsdaten component class.
 * @class Inspektionsdaten
 * @extends Component
 */
class Inspektionsdaten extends Component {
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getInspektionsdaten(
      this.props.project,
      this.props.bucket,
      this.props.filtersApplied,
      this.props.scales,
      this.props.units,
      this.props.typeDashboard
    );
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.filtersApplied, this.props.filtersApplied) ||
      !isEqual(prevProps.scales, this.props.scales) ||
      !isEqual(prevProps.units, this.props.units) ||
      !isEqual(prevProps.bucket, this.props.bucket) ||
      !isEqual(prevProps.project, this.props.project)
    ) {
      this.props.getInspektionsdaten(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.typeDashboard
      );
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      inspektionsdaten,
      typeDashboard,
      mapMaximized,
      mapMinimized,
    } = this.props;

    let height = "50%";
    if (mapMaximized) {
      height = "0%";
    } else if (mapMinimized) {
      height = "100%";
    }

    return (
      <div className="mapWrapper">
        <div className="mapDetail" style={{ height: height }}>
          <Container className="containerDiagrams inner">
            <Row>
              <Chart
                data={inspektionsdaten[charts.INSPEKTIONSLAENGE]}
                id={charts.INSPEKTIONSLAENGE}
                typeView={VIEW_INSPEKTIONSDATEN}
              />
              <Chart
                data={inspektionsdaten[charts.INSPEKTIONSGRUND]}
                id={charts.INSPEKTIONSGRUND}
                typeView={VIEW_INSPEKTIONSDATEN}
              />
              <Chart
                data={inspektionsdaten[charts.INSPEKTIONSJAHR]}
                id={charts.INSPEKTIONSJAHR}
                typeView={VIEW_INSPEKTIONSDATEN}
              />
              <Chart
                data={inspektionsdaten[charts.INSPEKTIONSART]}
                id={charts.INSPEKTIONSART}
                typeView={VIEW_INSPEKTIONSDATEN}
              />
              <Chart
                data={inspektionsdaten[charts.KODIERSYSTEM]}
                id={charts.KODIERSYSTEM}
                typeView={VIEW_INSPEKTIONSDATEN}
              />
            </Row>
          </Container>
        </div>
        <InOutCarat />
        <Map view={VIEW_INSPEKTIONSDATEN} typeDashboard={typeDashboard} />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const diagrams = diagramsViews[VIEW_INSPEKTIONSDATEN];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    return {
      inspektionsdaten: state.inspektionsdaten.data,
      filtersApplied: state.filters.filtersApplied,
      //reload: state.filters.reload || state.chartSettings.dirty,
      project: state.projects.project,
      bucket: state.projects.bucket,
      units: units,
      scales: scales,
      mapMaximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      mapMinimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
    };
  },
  { getInspektionsdaten }
)(Inspektionsdaten);
