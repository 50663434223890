/**
 * Prepend a string to all properties in an object.
 * Used to construct unique parameter identifiers for requests.
 * @module helpers/API/AppendToParams
 */

export function parsePrependParams(params, prefix) {
  const result = Object.keys(params).reduce((collector, key) => {
    collector[prefix.concat(key)] = params[key];
    return collector;
  }, {});

  return result;
}
