/**
 * Daten component.
 * @module components/views/Daten/Daten
 */

import React from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Container,
  Spinner,
  Table,
} from "reactstrap";
// import { readAsDataURL } from "promise-file-reader";

import {
  getProjects,
  setProject,
  setBucket,
  getMetadataProject,
} from "../../../actions/projects/projects";

import Changelog from "../../elements/Changelog/Changelog";

/**
 * Daten component class.
 * @class Daten
 * @extends Component
 */
class Daten extends React.Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsComponent
   */
  constructor(props) {
    super(props);
    //    this.onSelect = this.onSelect.bind(this);
    //    this.onCancel = this.onCancel.bind(this);
    //    this.onAddRow = this.onAddRow.bind(this);
    //    this.onShowData = this.onShowData.bind(this);
    //    this.onChangeHintergrund = this.onChangeHintergrund.bind(this);
    //    this.onDeleteDatei = this.onDeleteDatei.bind(this);
    //    this.onChangeDatei = this.onChangeDatei.bind(this);
    //    this.onUpload = this.onUpload.bind(this);
    //    this.onToggle = this.onToggle.bind(this);
    //    this.onChangeId = this.onChangeId.bind(this);
    //    this.onChangeName = this.onChangeName.bind(this);
    //    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      id: "",
      name: "",
      selected: {},
      files: [],
      filesProject: null,
      modal: false,
      changelogVisible: false,
      error: false,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getProjects();
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  //  UNSAFE_componentWillReceiveProps(nextProps) {
  //    if (this.props.loading && nextProps.loaded) {
  //      this.setState({
  //        files: [],
  //        filesProject: null,
  //        error: false,
  //      });
  //    }
  //  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading && this.props.loaded) {
      this.setState({
        files: [],
        filesProject: null,
        error: false,
      });
    }
  }

  // /**
  //  * On submit handler
  //  * @method onSubmit
  //  * @param {Object} event Event object.
  //  * @returns {undefined}
  //  */
  // onSubmit(event) {
  //   const id = parseInt(this.state.id);
  //   if (id && this.state.name.length > 0) {
  //     this.props.addProject(this.state.id, this.state.name);
  //     this.setState({
  //       modal: false,
  //       id: "",
  //       name: "",
  //       error: ""
  //     });
  //   } else {
  //     this.setState({
  //       error: "Ungültige Eingabe"
  //     });
  //   }

  //   event.preventDefault();
  // }

  /**
   * On toggle handler
   * @method onToggle
   * @returns {undefined}
   */
  onToggle = () => {
    this.setState({
      error: "",
      modal: !this.state.modal,
    });
  };

  /**
   * On change id handler
   * @method onChangeId
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  //  onChangeId(event) {
  //    this.setState({
  //      id: event.target.value
  //    });
  //  }

  /**
   * On change name handler
   * @method onChangeName
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  //  onChangeName(event) {
  //    this.setState({
  //      name: event.target.value
  //    });
  //  }

  /**
   * On select handler
   * @method onSelect
   * @param {Number} index Index
   * @param {String} project Project
   * @returns {undefined}
   */
  onSelect = (index, project) => {
    this.setState({
      selected: {
        ...this.state.selected,
        [project]: this.state.selected[project] === index ? -1 : index,
      },
    });
  };

  /**
   * On cancel handler
   * @method onCancel
   * @returns {undefined}
   */
  //  onCancel() {
  //    this.setState({
  //      error: false,
  //      files: [],
  //      filesProject: null
  //    });
  //  }

  /**
   * On show datra handler
   * @method onShowData
   * @returns {undefined}
   */
  onShowData = (project, bucket) => {
    this.props.setProject(project);
    this.props.getMetadataProject(project);
    this.props.setBucket(bucket);
    this.props.history.push("/grunddaten");
  };

  // /**
  //  * On change hintergrund handler
  //  * @method onChangeHintergrund
  //  * @param {Number} index Index
  //  * @param {object} event Event object
  //  * @returns {undefined}
  //  */
  // onChangeHintergrund(index, event) {
  //   const files = this.state.files;
  //   files[index].hintergrund = event.target.value;
  //   this.setState({
  //     files
  //   });
  // }

  // /**
  //  * On change datei handler
  //  * @method onChangeDatei
  //  * @param {Number} index Index
  //  * @param {object} event Event object
  //  * @returns {undefined}
  //  */
  // onChangeDatei(index, event) {
  //   const files = this.state.files;
  //   files[index].datei = event.target.files[0];
  //   this.setState({
  //     files
  //   });
  // }

  // /**
  //  * On delete datei handler
  //  * @method onDeleteDatei
  //  * @param {Number} index Index
  //  * @param {object} event Event object
  //  * @returns {undefined}
  //  */
  // onDeleteDatei(index, event) {
  //   const files = this.state.files;
  //   files.splice(index, 1);
  //   this.setState({
  //     files
  //   });
  // }

  /**
   * On add row handler
   * @method onAddRow
   * @param {Number} index Index
   * @param {object} event Event object
   * @returns {undefined}
   */
  onAddRow(project) {
    this.setState({
      files: [
        ...(project === this.state.filesProject ? this.state.files : []),
        { hintergrund: "", datei: null },
      ],
      filesProject: project,
    });
  }

  // /**
  //  * On upload
  //  * @method onUpload
  //  * @returns {undefined}
  //  */
  // onUpload() {
  //   let error = false;
  //   this.state.files.map(file => {
  //     if (file.hintergrund === "" || file.datei === null) {
  //       error = true;
  //     }
  //     return error;
  //   });

  //   this.setState({
  //     error
  //   });

  //   if (!error) {
  //     Promise.all(this.state.files.map(file => readAsDataURL(file.datei))).then(
  //       files => {
  //         this.props.addDaten(
  //           this.state.filesProject,
  //           this.state.files.map((file, index) => {
  //             const fields = files[index].match(/^data:(.*);(.*),(.*)$/);
  //             return {
  //               hintergrund: file.hintergrund,
  //               filename: file.datei.name,
  //               data: fields[3],
  //               encoding: fields[2],
  //               "content-type": fields[1]
  //             };
  //           })
  //         );
  //       }
  //     );
  //   }
  // }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { projects } = this.props;

    return (
      <div className="datenMain">
        {this.props.loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              textAlign: "center",
              zIndex: 1,
              padding: "6em",
            }}
          >
            <Spinner color="primary" />
          </div>
        )}
        <Row style={{ opacity: this.props.loading ? 0.1 : 1 }}>
          <Col
            style={{ padding: "15px 15px 15px 30px", marginTop: "30px" }}
            md={{ size: 12, offset: 0 }}
          >
            <Container style={{}}>
              {/* <div style={{ paddingRight: 43 }}>
                <div style={{ float: "right" }}>
                  <Button
                    className="btn-info btn-add"
                    onClick={this.onToggle}
                    style={{
                      borderRadius: "50%",
                      padding: ".75rem"
                    }}
                  >
                    <i className="la la-plus" />
                  </Button>
                  &nbsp;&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    Neues Projekt anlegen
                  </span>
                </div>
                <h1 className="dashboard">Dashboard</h1>
              </div> */}
              {projects.map((project) => (
                <Card key={project.id} style={{ height: "auto" }}>
                  <CardHeader as="h4">
                    <CardTitle className="project-name">
                      {project.name}
                    </CardTitle>
                  </CardHeader>
                  <Table hover style={{ marginBottom: 0 }} className="table-xl">
                    <colgroup>
                      <col width="*" />
                      <col width="200" />
                      <col width="82" />
                    </colgroup>
                    <tbody>
                      {project.buckets.map((item, index) => (
                        <tr key={item.datum}>
                          <td>
                            <i className="la la-file" />
                            &nbsp;
                            {item.datum}&nbsp;
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                this.onSelect(index, project.id);
                              }}
                            >
                              <div className="badge badge-information round">
                                <span>i</span>
                              </div>
                            </a>
                            {index === this.state.selected[project.id] && (
                              <div>
                                <br />
                                <h4 className="project-sub">Report</h4>
                                {item.report ? (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.report,
                                    }}
                                  />
                                ) : (
                                  <p>Kein Report vorhanden</p>
                                )}
                              </div>
                            )}
                          </td>
                          <td>
                            <span
                              className={
                                item.status === "verarbeitet"
                                  ? "bullit success"
                                  : "bullit red"
                              }
                            />
                            &nbsp;{item.status}
                            {/* {index === this.state.selected[project.id] && (
                              <div>
                                <br />
                                <h4 className="project-sub">Export</h4>
                                {item.export.length > 0 ? (
                                  item.export.map((link, idx_download) => (
                                    <p
                                      key={link.titel}
                                      style={{ marginBottom: 4 }}
                                    >
                                      <i
                                        className="la la-download"
                                        style={{
                                          fontSize: "inherit",
                                          color: "#376790"
                                        }}
                                      />
                                      <a
                                        href={`/api/download/${
                                          project.id
                                        }/file/${idx_download}`}
                                        style={{
                                          color: "#376790"
                                        }}
                                      >
                                        {link.titel}
                                      </a>
                                    </p>
                                  ))
                                ) : (
                                  <p>Kein Export zum Export vorhanden</p>
                                )}
                              </div>
                            )} */}
                          </td>
                          <td>
                            <div className="btn-group" role="group">
                              {/* <button
                                type="button"
                                style={{ marginBottom: "5px" }}
                                className="btn btn-outline-primary btn-sm"
                                onClick={this.onShowData.bind(
                                  this,
                                  project.id,
                                  item.id
                                )}
                              >
                                <i className="la la-bar-chart" />
                              </button> */}
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm"
                                style={{ marginBottom: "5px" }}
                                onClick={() => {
                                  this.onShowData(project.id, item.id);
                                }}
                              >
                                Daten anzeigen
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {/* {this.state.error && this.state.filesProject === project.id && (
                    <Alert color="danger" style={{ margin: "8px 15px" }}>
                      Alle Felder sind erforderlich
                    </Alert>
                  )} */}
                  {this.props.error && this.state.filesProject === project.id && (
                    <Alert color="danger" style={{ margin: "8px 15px" }}>
                      {this.props.error.message}
                    </Alert>
                  )}
                  {/* {this.state.filesProject !== project.id && (
                    <div className="upload">
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-blue-right"
                          onClick={this.onAddRow.bind(this, project.id)}
                        >
                          <i className="la la-upload" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => this.props.getUrlUpload(project.id)}
                        >
                          Datensatz hochladen
                        </button>
                      </div>
                    </div>
                  )} */}
                  {/* {this.state.files.length > 0 &&
                    this.state.filesProject === project.id && (
                      <Table style={{ marginBottom: 0 }} className="table-xl">
                        <colgroup>
                          <col width="8" />
                          <col width="50%" />
                          <col width="50%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td colspan="3" className="upload-sub">
                              Nueun Datensatz hinzufügen
                            </td>
                          </tr>
                          {this.state.files.map((file, index) => (
                            <tr>
                              <td>
                                <Button
                                  style={{ marginTop: 10 }}
                                  close
                                  onClick={this.onDeleteDatei.bind(this, index)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="select"
                                  value={file.hintergrund}
                                  onChange={this.onChangeHintergrund.bind(
                                    this,
                                    index
                                  )}
                                >
                                  <option value="" />
                                  {hintergrunds.map(hintergrund => (
                                    <option>{hintergrund}</option>
                                  ))}
                                </Input>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <Input
                                  type="file"
                                  onChange={this.onChangeDatei.bind(
                                    this,
                                    index
                                  )}
                                />
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              colSpan="3"
                              style={{
                                borderBottom: 0,
                                borderTop: 0,
                                textAlign: "right",
                                paddingTop: 16,
                                paddingBottom: 16
                              }}
                            >
                              <Button
                                className="btn-info btn-add"
                                onClick={this.onAddRow.bind(this, project.id)}
                                style={{
                                  borderRadius: "50%",
                                  padding: ".75rem"
                                }}
                              >
                                <i className="la la-plus" />
                              </Button>
                              &nbsp;&nbsp;
                              <span style={{ fontWeight: "bold" }}>
                                Neue Datei
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan="3"
                              style={{ borderTop: 0, paddingBottom: "1.5em" }}
                            >
                              <Button
                                onClick={this.onCancel}
                                style={{
                                  marginRight: 12,
                                  backgroundColor: "#5e6060"
                                }}
                              >
                                Abbrechen
                              </Button>
                              <Button
                                style={{
                                  float: "right",
                                  backgroundColor: "#779854"
                                }}
                                onClick={this.onUpload}
                              >
                                Daten hochladen
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )} */}
                </Card>
              ))}
            </Container>
          </Col>
          <Button
            className="la la-info-circle buttonChangelog"
            onClick={() => this.setState({ changelogVisible: true })}
          />
        </Row>
        <Changelog
          isOpen={this.state.changelogVisible}
          onToggle={() =>
            this.setState({ changelogVisible: !this.state.changelogVisible })
          }
        />
        {/* <Modal
          isOpen={this.state.modal}
          toggle={this.onToggle}
          style={{ marginTop: 100 }}
        >
          <ModalHeader toggle={this.onToggle}>Projekt hinzufügen</ModalHeader>
          <ModalBody>
            <Form className="form-horizontal" onSubmit={this.onLogin}>
              <FormGroup className="position-relative">
                <Input
                  placeholder="Projekt ID eingeben"
                  id="id"
                  required="required"
                  value={this.state.id}
                  onChange={this.onChangeId}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Input
                  placeholder="Project Name eingeben"
                  id="name"
                  required="required"
                  value={this.state.name}
                  onChange={this.onChangeName}
                />
              </FormGroup>
            </Form>
            {this.state.error && (
              <Alert color="danger">{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ backgroundColor: "#5e6060" }}
              onClick={this.onToggle}
            >
              Abbrechen
            </Button>{" "}
            <Button
              style={{ backgroundColor: "#779854" }}
              onClick={this.onSubmit}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    projects: state.projects.projects,
    loading: state.daten.add.loading,
    loaded: state.daten.add.loaded,
    error: state.daten.add.error,
  }),
  { getProjects, setProject, setBucket, getMetadataProject }
)(Daten);
