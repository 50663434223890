// Define names of data fields, which is also unique ID,
// for each chart

export const BAUJAHR = "baujahr";
export const MATERIALGRUPPE = "materialgruppe";
export const MITTLERE_TIEFE = "mittlereTiefe";
export const KANALNUTZUNG = "kanalnutzung";
export const HALTUNGSLAENGE = "haltungslaenge";
export const NENNWEITE = "nennweite";
export const ALTER = "alter";
export const RESTNUTZUNGSDAUER = "restnutzungsdauer";
export const SANIERUNGSLAENGE = "sanierungslaenge";
export const VERIFIKATION = "verifikation";
export const LAGE_IM_VERKEHRSRAUM = "lageImVerkehrsraum";
export const PROFILART = "profilart";
export const KANALART = "kanalart";
export const INSPEKTIONSLAENGE = "inspektionslaenge";
export const INSPEKTIONSGRUND = "inspektionsgrund";
export const INSPEKTIONSJAHR = "inspektionsjahr";
export const INSPEKTIONSART = "inspektionsart";
export const KODIERSYSTEM = "kodiersystem";
export const SCHADENSKLASSE_DICHTHEIT = "schadensklasseDichtheit";
export const SCHADENSKLASSE_STANDSICHERHEIT = "schadensklasseStandsicherheit";
export const SCHADENSKLASSE_BETRIEB = "schadensklasseBetrieb";
export const SCHADENSART = "schadensart";
export const SUBSTANZKLASSE = "substanzklasse";
export const ZUSTANDSKLASSE = "zustandsklasse";
export const SUBSTANZ_INSPEKTIONSJAHR = "inspektionsjahrSubstanz";
export const ZUSTAND_STANDSICHERHEIT = "zustandStandsicherheit";
export const SUBSTANZ_STANDSICHERHEIT = "substanzStandsicherheit";
export const ZUSTAND_BETRIEB = "zustandBetrieb";
export const SUBSTANZ_BETRIEB = "substanzBetrieb";
export const ZUSTAND_DICHTHEIT = "zustandDichtheit";
export const SUBSTANZ_DICHTHEIT = "substanzDichtheit";
export const SUBSTANZ_SCHUTZZIELE_INSPEKTIONSJAHR =
  "inspektionsjahrSubstanzSchutzziele";
export const ZUSTANDSKLASSE_STRATEGIE = "zustandsklasseStrategie";
export const ZUSTANDSKLASSE_DICHTHEIT_STRATEGIE =
  "zustandsklasseDichtheitStrategie";
export const ZUSTANDSKLASSE_BETRIEB_STRATEGIE =
  "zustandsklasseBetriebStrategie";
export const ZUSTANDSKLASSE_STANDSICHERHEIT_STRATEGIE =
  "zustandsklasseStandsicherheitStrategie";
export const SUBSTANZKLASSE_STRATEGIE = "substanzklasseStrategie";
export const SUBSTANZKLASSE_DICHTHEIT_STRATEGIE =
  "substanzklasseDichtheitStrategie";
export const SUBSTANZKLASSE_BETRIEB_STRATEGIE =
  "substanzklasseBetriebStrategie";
export const SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE =
  "substanzklasseStandsicherheitStrategie";
export const SANIERUNGEN = "sanierungen";
export const SANIERUNGEN_REP = "sanierungenRep";
export const SANIERUNGEN_REN = "sanierungenRen";
export const SANIERUNGEN_ERN = "sanierungenErn";
export const SANIERUNGSKOSTEN = "sanierungskosten";
export const SANIERUNGSKOSTEN_REP = "sanierungskostenRep";
export const SANIERUNGSKOSTEN_REN = "sanierungskostenRen";
export const SANIERUNGSKOSTEN_ERN = "sanierungskostenErn";
export const RBW_WBK = "rbwWbk";
export const ZUSTAND_AVG = "zustandAvg";
export const SUBSTANZ_AVG = "substanzAvg";
export const SUBSTANZ_BETRIEB_AVG = "substanzBetriebAvg";
export const SUBSTANZ_DICHTHEIT_AVG = "substanzDichtheitAvg";
export const SUBSTANZ_STANDSICHERHEIT_AVG = "substanzStandsicherheitAvg";
export const ZUSTAND_AVG_DIFFERENCE = "zustandAvgDifference";
export const SUBSTANZ_AVG_DIFFERENCE = "substanzAvgDifference";
export const SUBSTANZ_DICHTHEIT_AVG_DIFFERENCE =
  "substanzDichtheitAvgDifference";
export const SUBSTANZ_BETRIEB_AVG_DIFFERENCE = "substanzBetriebAvgDifference";
export const SUBSTANZ_STANDSICHERHEIT_AVG_DIFFERENCE =
  "substanzStandsicherheitAvgDifference";
export const SANIERUNGEN_SUM = "sanierungenSum";
export const SANIERUNGEN_REP_SUM = "sanierungenRepSum";
export const SANIERUNGEN_REN_SUM = "sanierungenRenSum";
export const SANIERUNGEN_ERN_SUM = "sanierungenErnSum";
export const SANIERUNGEN_SUM_DIFFERENCE = "sanierungenSumDifference";
export const SANIERUNGEN_REP_SUM_DIFFERENCE = "sanierungenRepSumDifference";
export const SANIERUNGEN_REN_SUM_DIFFERENCE = "sanierungenRenSumDifference";
export const SANIERUNGEN_ERN_SUM_DIFFERENCE = "sanierungenErnSumDifference";
export const SANIERUNGSKOSTEN_SUM = "sanierungskostenSum";
export const SANIERUNGSKOSTEN_REP_SUM = "sanierungskostenRepSum";
export const SANIERUNGSKOSTEN_REN_SUM = "sanierungskostenRenSum";
export const SANIERUNGSKOSTEN_ERN_SUM = "sanierungskostenErnSum";
export const SANIERUNGSKOSTEN_SUM_DIFFERENCE = "sanierungskostenSumDifference";
export const SANIERUNGSKOSTEN_REP_SUM_DIFFERENCE =
  "sanierungskostenRepSumDifference";
export const SANIERUNGSKOSTEN_REN_SUM_DIFFERENCE =
  "sanierungskostenRenSumDifference";
export const SANIERUNGSKOSTEN_ERN_SUM_DIFFERENCE =
  "sanierungskostenErnSumDifference";
export const RBW_WBK_SUM = "rbwWbkSum";
export const RBW_WBK_SUM_DIFFERENCE = "rbwWbkSumDifference";
export const LEGENDE_KLASSEN = "classes";
export const LEGENDE_SANIERUNGEN = "actionsRehab";
export const EINZELOBJEKTLISTE = "einzelobjektliste";
export const PROGNOSIS_OBJECT_SUBSTANZ = "prognosisObjectSubstanz";
export const PROGNOSIS_OBJECT_ZUSTAND = "prognosisObjectZustand"; // Needs ref mapping
export const PROGNOSIS_OBJECT_LEGEND = "prognosisObjectLegend";
export const PROGNOSIS_OBJECT_TABLE = "prognosisObjectTable";
export const MAP_DASHBOARD = "map";
export const MAP_OBJECTVIEW = "mapObjectview";

// Tables in objectview grunddaten
export const OBJEKTBEZEICHNUNG = "objektbezeichnung";
export const STRASSE = "strasse";
export const KNOTENBEZEICHNUNG_UNTEN = "knotenbezeichnung_unten";
export const KNOTENBEZEICHNUNG_OBEN = "knotenbezeichnung_oben";

// Objectview inspection table (and subs also for prognosis)
export const DATUM = "datum";
export const SUBSTANZ_OBJECTVIEW = "subs";
export const SUBSTANZ_STB_OBJECTVIEW = "sstb";
export const SUBSTANZ_ECO_OBJECTVIEW = "seco";
export const SUBSTANZ_CAP_OBJECTVIEW = "scap";

// Values specific to objectview object hg table
export const BODENGRUPPE = "bodengruppe";
export const EINSTAUHAEUFIGKEIT = "einstauhaeufigkeit";
export const EIGENTUM = "eigentum";
export const EINZUGSGEBIETSCHLUESSEL = "einzugsgebietschluessel";
export const FUNKTIONSZUSTAND = "funktionszustand";
export const GEBIETSSCHLUESSEL = "gebietsschluessel";
export const GEMEINDESCHLUESSEL = "gemeindeschluessel";
export const GRUNDWASSERSTAND = "grundwasserstand";
export const MATERIALART = "materialart";
export const ORTSTEIL = "ortsteil";
export const ORTSTEILSCHLUESSEL = "ortsteilschluessel";
export const PROFILBREITE = "profilbreite";
export const PROFILHOEHE = "profilhoehe";
export const UEBERSCHWEMMUNGSGEBIET = "ueberschwemmungsgebiet";
export const WASSERSCHUTZZONE = "wasserschutzzone";

// Specific for objectview object prognosis table
export const FORECAST_YEAR = "forecast_year";
export const PRIO_CLASS_OBJECTVIEW = "prio_class";
export const PRIO_CLASS_STB_OBJECTVIEW = "zeco_class";
export const PRIO_CLASS_ECO_OBJECTVIEW = "zcap_class";
export const PRIO_CLASS_CAP_OBJECTVIEW = "zstb_class";
