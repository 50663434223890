import {
  GET_HG_OBJECT,
  GET_RND_OBJECT,
  GET_PROGNOSIS_OBJECT,
  SET_PROJECT,
  SET_BUCKET,
  OBJECT_RESET_DATA,
  GET_IDS_OBJECTS_NODE_UPPER,
  GET_IDS_OBJECTS_NODE_LOWER,
  RESET_IDS_OBJECTS_NODE_LOWER,
  RESET_IDS_OBJECTS_NODE_UPPER,
} from "../../constants/ActionTypes";
// todo: is reload needed?

const initialState = {
  hg: {},
  rnd: {},
  prognosis: {
    namesColumns: [],
    rows: [],
  },
  loadingHg: false,
  loadingRnd: false,
  loadingPrognosis: false,
  loadedHg: false,
  loadedRnd: false,
  loadedPrognosis: false,
  errorHg: null,
  errorRnd: null,
  errorPrognosis: null,
  widthSidebar: 25,
  idsObjectsNodeLower: [],
  idsObjectsNodeUpper: [],
};

/**
 * Filter reducer.
 * @function filters
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function objectviewGrunddaten(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case OBJECT_RESET_DATA:
      return {
        ...initialState,
        widthSidebar: state.widthSidebar,
      };
    case RESET_IDS_OBJECTS_NODE_LOWER:
      return {
        ...state,
        idsObjectsNodeLower: initialState.idsObjectsNodeLower,
      };
    case RESET_IDS_OBJECTS_NODE_UPPER:
      return {
        ...state,
        idsObjectsNodeUpper: initialState.idsObjectsNodeUpper,
      };
    case `${GET_HG_OBJECT}_PENDING`:
      return {
        ...state,
        errorHg: null,
        loadedHg: false,
        loadingHg: true,
      };
    case `${GET_HG_OBJECT}_SUCCESS`:
      return {
        ...state,
        errorHg: null,
        hg: action.result,
        loadedHg: true,
        loadingHg: false,
      };
    case `${GET_HG_OBJECT}_FAIL`:
      return {
        ...state,
        errorHg: action.error,
        loadedHg: false,
        loadingHg: false,
        hg: initialState.hg,
      };
    case `${GET_RND_OBJECT}_PENDING`:
      return {
        ...state,
        errorRnd: null,
        loadedRnd: false,
        loadingRnd: true,
      };
    case `${GET_RND_OBJECT}_SUCCESS`:
      return {
        ...state,
        errorRnd: null,
        rnd: action.result,
        loadedRnd: true,
        loadingRnd: false,
      };
    case `${GET_RND_OBJECT}_FAIL`:
      return {
        ...state,
        errorRnd: action.error,
        loadedRnd: false,
        loadingRnd: false,
        rnd: initialState.rnd,
      };
    case `${GET_PROGNOSIS_OBJECT}_PENDING`:
      return {
        ...state,
        errorPrognosis: null,
        loadedPrognosis: false,
        loadingPrognosis: true,
      };
    case `${GET_PROGNOSIS_OBJECT}_SUCCESS`:
      return {
        ...state,
        errorPrognosis: null,
        prognosis: action.result,
        loadedPrognosis: true,
        loadingPrognosis: false,
      };
    case `${GET_PROGNOSIS_OBJECT}_FAIL`:
      return {
        ...state,
        errorPrognosis: action.error,
        loadedPrognosis: false,
        loadingPrognosis: false,
        prognosis: initialState.prognosis,
      };
    case `${GET_IDS_OBJECTS_NODE_UPPER}_PENDING`:
      return {
        ...state,
        errorIdsObjects: null,
        loadedIdsObjects: false,
        loadingIdsObjects: true,
      };
    case `${GET_IDS_OBJECTS_NODE_UPPER}_SUCCESS`:
      return {
        ...state,
        errorIdsObjects: null,
        idsObjectsNodeUpper: action.result.rows.map((row) => row[0]),
        loadedIdsObjects: true,
        loadingIdsObjects: false,
      };
    case `${GET_IDS_OBJECTS_NODE_UPPER}_FAIL`:
      return {
        ...state,
        errorIdsObjects: action.error,
        loadedIdsObjects: false,
        loadingIdsObjects: false,
        idsObjectsNodeUpper: initialState.idsObjectsNodeUpper,
      };
    case `${GET_IDS_OBJECTS_NODE_LOWER}_PENDING`:
      return {
        ...state,
        errorIdsObjects: null,
        loadedIdsObjects: false,
        loadingIdsObjects: true,
      };
    case `${GET_IDS_OBJECTS_NODE_LOWER}_SUCCESS`:
      return {
        ...state,
        errorIdsObjects: null,
        idsObjectsNodeLower: action.result.rows.map((row) => row[0]),
        loadedIdsObjects: true,
        loadingIdsObjects: false,
      };
    case `${GET_IDS_OBJECTS_NODE_LOWER}_FAIL`:
      return {
        ...state,
        errorIdsObjects: action.error,
        loadedIdsObjects: false,
        loadingIdsObjects: false,
        idsObjectsNodeLower: initialState.idsObjectsNodeLower,
      };
    case SET_PROJECT:
      return initialState;
    case SET_BUCKET:
      return {
        ...initialState,
        widthSidebar: state.widthSidebar,
      };
    default:
      return state;
  }
}
