/**
 * Users reducer.
 * @module reducers/users/users
 */

import {
  GET_USERS,
  ADD_USER,
  UPDATE_USER,
  REMOVE_USER
} from "../../constants/ActionTypes";

const initialState = {
  error: null,
  users: [],
  loaded: false,
  loading: false,
  errorRemoveUser: null,
  userRemoved: false,
  userRemoving: false
};

/**
 * Users reducer.
 * @function users
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_USERS}_PENDING`:
    case `${ADD_USER}_PENDING`:
    case `${UPDATE_USER}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true
      };
    case `${GET_USERS}_SUCCESS`:
    case `${ADD_USER}_SUCCESS`:
    case `${UPDATE_USER}_SUCCESS`:
      return {
        error: null,
        users: action.result,
        loaded: true,
        loading: false
      };
    case `${GET_USERS}_FAIL`:
    case `${ADD_USER}_FAIL`:
    case `${UPDATE_USER}_FAIL`:
      return {
        ...state,
        error: action.result,
        loaded: false,
        loading: false
      };
    case `${REMOVE_USER}_SUCCESS`:
      return {
        ...state,
        errorRemoveUser: null,
        userRemoved: true,
        userRemoving: false
      };
    case `${REMOVE_USER}_PENDING`:
      return {
        ...state,
        errorRemoveUser: null,
        userRemoved: false,
        userRemoving: true
      };
    case `${REMOVE_USER}_FAIL`:
      return {
        ...state,
        errorRemoveUser: action.result,
        userRemoved: false,
        userRemoving: false
      };
    default:
      return state;
  }
}
