import { mappingZustandsklasse } from "../../constants/MappingsValuesHumanReadable";

export function formatSubstanceValues(valuesRow, namesColumns) {
  return valuesRow.map((value, index) => {
    var valueMapped = value;
    if (
      ["Substanz", "S. Standsicherheit", "S. Dichtheit", "S. Betrieb"].includes(
        namesColumns[index]
      )
    ) {
      valueMapped = `${Math.round(value * 10) / 10}%`;
    } else if (
      [
        "Priorität",
        "P. Standsicherheit",
        "P. Dichtheit",
        "P. Betrieb",
      ].includes(namesColumns[index])
    ) {
      valueMapped = mappingZustandsklasse[value];
    }
    return valueMapped;
  });
}

export function mapColumns(idsColumns, mapping) {
  return idsColumns.map((idColumn) => [
    idColumn,
    mapping[idColumn] ? mapping[idColumn] : idColumn,
  ]);
}
