/**
 * Daten reducer.
 * @module reducers/daten/daten
 */

import {
  ADD_DATEN,
  GET_DATEN,
  GET_URL_UPLOAD
} from "../../constants/ActionTypes";

const initialState = {
  data: [],
  get: {
    error: null,
    loaded: false,
    loading: false
  },
  add: {
    error: null,
    loaded: false,
    loading: false
  },
  get_url: {
    error: null,
    loaded: false,
    loading: false
  }
};

/**
 * Daten reducer.
 * @function daten
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function daten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_DATEN}_PENDING`:
      return {
        ...state,
        get: {
          error: null,
          loaded: false,
          loading: true
        }
      };
    case `${GET_DATEN}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        get: {
          error: null,
          loaded: true,
          loading: false
        }
      };
    case `${GET_DATEN}_FAIL`:
      return {
        ...state,
        data: initialState.data,
        get: {
          error: action.error,
          loaded: false,
          loading: false
        }
      };
    case `${GET_URL_UPLOAD}_PENDING`:
      return {
        ...state,
        get_url: {
          error: null,
          loaded: false,
          loading: true
        }
      };
    case `${GET_URL_UPLOAD}_SUCCESS`:
      // TODO: quite hacky. Better store URL in state and open in view?
      window.open(action.result.redirect_url);
      return {
        ...state,
        get_url: {
          error: null,
          loaded: true,
          loading: false
        }
      };
    case `${GET_URL_UPLOAD}_FAIL`:
      return {
        ...state,
        get_url: {
          error: action.error,
          loaded: false,
          loading: false
        }
      };
    case `${ADD_DATEN}_PENDING`:
      return {
        ...state,
        add: {
          error: null,
          loaded: false,
          loading: true
        }
      };
    case `${ADD_DATEN}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        add: {
          error: null,
          loaded: true,
          loading: false
        }
      };
    case `${ADD_DATEN}_FAIL`:
      return {
        ...state,
        add: {
          error: action.error,
          loaded: false,
          loading: false
        }
      };
    default:
      return state;
  }
}
