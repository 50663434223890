export const CHART_BAR = "Bar";
export const CHART_BAR_HORIZONTAL = "BarHorizontal";
export const CHART_PIE = "Pie";
export const CHART_STACKED_BAR = "StackedBar";
export const CHART_LINE = "Line";
export const CHART_LINE_DIFFERENCE = "LineDifference";
export const CHART_LEGEND_PIE = "LegendPie";
export const CHART_TABLE = "Table";
export const CHART_HALF_PIE = "HalfPie";
export const CHART_TEMPORAL_HALF_PIE = "TemporalHalfPie";
