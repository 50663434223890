/**
/**
 * Action types.
 * @module constants/ActionTypes
 */

export const ADD_DATEN = "ADD_DATEN";
export const GET_DATEN = "GET_DATEN";
export const GET_URL_UPLOAD = "GET_URL_UPLOAD";
export const GET_GRUNDDATEN = "GET_GRUNDDATEN";
export const GET_INSPEKTIONSDATEN = "GET_INSPEKTIONSDATEN";
export const GET_SCHADENSKLASSIFIZIERUNG = "GET_SCHADENSKLASSIFIZIERUNG";
export const GET_OBJEKTKLASSIFIZIERUNG = "GET_OBJEKTKLASSIFIZIERUNG";
export const GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE =
  "GET_OBJEKTKLASSIFIZIERUNG_SCHUTZZIELE";
export const GET_STRATEGIE = "GET_STRATEGIE";
export const GET_STRATEGIEVERGLEICH = "GET_STRATEGIEVERGLEICH";
export const GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON =
  "GET_GRUNDDATEN_DIFFERENCES_STRATEGY_COMPARISON";
export const GET_GRUNDDATEN_STRATEGY = "GET_GRUNDDATEN_STRATEGY";
export const SET_YEAR_STRATEGY_COMPARISON = "SET_YEAR_STRATEGY_COMPARISON";
export const SET_YEAR_STRATEGY = "SET_YEAR_STRATEGY";
export const SET_ID_CHART_DIFFERENCES = "SET_ID_CHART_DIFFERENCES";
export const SET_ID_CHART_STRATEGY = "SET_ID_CHART_STRATEGY";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";

// Control which charts are drawn in strategy comparison
export const SET_GROUP_CHARTS_STRATEGIEVERGLEICH =
  "SET_GROUP_CHARTS_STRATEGIEVERGLEICH";
export const SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH =
  "SET_SHOW_DIFFERENCES_STRATEGIEVERGLEICH";

export const APPLY_FILTERS = "APPLY_FILTERS";
export const APPLY_FILTER = "APPLY_FILTER";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SET_FILTER_APPLIED = "SET_FILTER_APPLIED";
export const TOGGLE_PIE_FILTER = "TOGGLE_PIE_FILTER";
export const SET_BAR_FILTER = "SET_BAR_FILTER";
export const UNSET_FILTER = "UNSET_FILTER";
export const SET_STACKED_BAR_FILTER = "SET_STACKED_BAR_FILTER";
export const SET_MAP_FILTER = "SET_MAP_FILTER";
export const GET_FILTER_PRESETS = "GET_FILTER_PRESETS";
export const GET_FILTER_PRESET_USER = "GET_FILTER_PRESET_USER";
export const GET_FILTER_PRESET_PROJECT = "GET_FILTER_PRESET_PROJECT";
export const STORE_FILTER_PRESET = "STORE_FILTER_PRESET";
export const DELETE_FILTER_PRESET_PROJECT = "DELETE_FILTER_PRESET_PROJECT";
export const DELETE_FILTER_PRESET_USER = "DELETE_FILTER_PRESET_USER";

export const SET_CHART_SCALE = "SET_CHART_SCALE";
export const SET_CHARTS_SCALE = "SET_CHARTS_SCALE";
export const SET_CHART_UNIT = "SET_CHART_UNIT";
export const SET_CHARTS_UNIT = "SET_CHARTS_UNIT";
export const TOGGLE_MAXIMIZED = "TOGGLE_MAXIMIZED";
export const TOGGLE_MINIMIZED = "TOGGLE_MINIMIZED";
export const RESET_CHART_SETTINGS = "RESET_CHART_SETTINGS";
export const SET_CHART_UNIT_VALID = "SET_CHART_UNIT_VALID";

export const SET_DETAILVIEW_OPEN = "SET_DETAILVIEW_OPEN";
export const RESET_DETAILVIEW = "RESET_DETAILVIEW";

export const GET_PROJECTS = "GET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const SET_PROJECT = "SET_PROJECT";
export const SET_BUCKET = "SET_BUCKET";
export const GET_METADATA_PROJECT = "GET_METADATA_PROJECT";
export const SET_STRATEGY_DISPLAY = "SET_STRATEGY_DISPLAY";
export const SET_STRATEGIES_COMPARISON = "SET_STRATEGIES_COMPARISON";
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const GET_MAP = "GET_MAP";
export const SELECT_MAP_LAYER = "SELECT_MAP_LAYER";
export const GET_OBJECT_DATA = "GET_OBJECT_DATA";
export const SET_MAP_DASHBOARDS_MAXIMIZED = "SET_MAP_DASHBOARDS_MAXIMIZED";
export const SET_MAP_DASHBOARDS_MINIMIZED = "SET_MAP_DASHBOARDS_MINIMIZED";
export const SET_MAP_DASHBOARDS_HEIGHT = "SET_MAP_DASHBOARDS_HEIGHT";

export const SET_DIAGRAM_COLOR_MAP = "SET_DIAGRAM_COLOR_MAP";
export const SET_MAP_COLOR_RENDERING = "SET_MAP_COLOR_RENDERING";
export const SET_MAP_VIEWPORT = "SET_MAP_VIEWPORT";
export const RESET_MAP = "RESET_MAP";
export const SET_ID_OBJECT_ACTIVE = "SET_ID_OBJECT_ACTIVE";

export const REMOVE_FILTER_TABLE_OBJECTS = "REMOVE_FILTER_TABLE_OBJECTS";
export const REMOVE_FILTERS_TABLE_OBJECTS = "REMOVE_FILTERS_TABLE_OBJECTS";
export const SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN =
  "SET_MAP_FILTER_OBJECTVIEW_GRUNDDATEN";
export const SET_FILTER_APPLIED_OBJECTVIEW = "SET_FILTER_APPLIED_OBJECTVIEW";
export const SET_ACTIVE_OBJECT = "SET_ACTIVE_OBJECT";
export const SET_PAGE_TABLE_OBJECTS = "SET_PAGE_TABLE_OBJECTS";
export const RESET_PAGE_TABLE_OBJECTS = "RESET_PAGE_TABLE_OBJECTS";
export const SET_FILTER_TABLE_OBJECTS = "SET_FILTER_TABLE_OBJECTS";
export const APPLY_FILTER_TABLE_OBJECTS = "APPLY_FILTER_TABLE_OBJECTS";
export const GET_DATA_OBJECT_GRUNDDATEN = "GET_DATA_OBJECT_GRUNDDATEN";
export const GET_DATA_TABLE_OBJECTS = "GET_DATA_TABLE_OBJECTS";
export const GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN =
  "GET_DATA_INSPECTIONS_OBJECT_GRUNDDATEN";
export const GET_PAGE_OBJECT = "GET_PAGE_OBJECT";
export const RESET_OBJECTVIEW_GRUNDDATEN = "RESET_OBJECTVIEW_GRUNDDATEN";
export const GET_COORDINATES_OBJECT = "GET_COORDINATES_OBJECT";
export const SET_COLUMN_SORT = "SET_COLUMN_SORT";
export const SET_TYPE_SORT = "SET_TYPE_SORT";

export const GET_HG_OBJECT = "GET_HG_OBJECT";
export const GET_RND_OBJECT = "GET_RND_OBJECT";
export const GET_PROGNOSIS_OBJECT = "GET_PROGNOSIS_OBJECT";
export const OBJECT_RESET_DATA = "OBJECT_RESET_DATA";
export const GET_IDS_OBJECTS_NODE_LOWER = "GET_IDS_OBJECTS_NODE_LOWER";
export const GET_IDS_OBJECTS_NODE_UPPER = "GET_IDS_OBJECTS_NODE_UPPER";
export const RESET_IDS_OBJECTS_NODE_UPPER = "RESET_IDS_OBJECTS_NODE_UPPER";
export const RESET_IDS_OBJECTS_NODE_LOWER = "RESET_IDS_OBJECTS_NODE_LOWER";

export const GET_CHANGELOG = "GET_CHANGELOG";

export const SET_CURRENT_DASHBOARD = "SET_CURRENT_DASHBOARD";
export const SET_EXPORTDATA_PROPERTIES = "SET_EXPORTDATA_PROPERTIES";
