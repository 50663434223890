/**
 * Strategie component.
 * @module components/views/Strategie/Strategie
 */

// TODO: Set default strategyDisplayId to null in reducer and handle this
// correctly. Maybe see how this is done for buckets

// TODO: When changing the project, is the strategyDisplayId,
//   strategyDisplayName and the strategies reset properly?

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { find, map, filter, isEqual } from "lodash";
import Slider from "@material-ui/core/Slider";

import {
  setChartUnit,
  setChartScale,
} from "../../../actions/chartSettings/chartSettings";

import {
  getStrategie,
  setStrategyDisplay,
  setYearStrategy,
} from "../../../actions/strategie/strategie";

import Chart from "../../charts/Chart/Chart";
import Map from "../../elements/Map/Map";

import { classes, actionsRehab } from "../../../constants/Colors";

import { diagramsViews } from "../../../constants/Dashboards";
import * as charts from "../../../constants/Charts";

import { VIEW_STRATEGIE } from "../../../constants/NamesViews";
import InOutCarat from "../../elements/InOutCarat/InOutCarat";
import {
  scalesStrategySummed,
  unitsStrategySummed,
} from "../../../constants/chartSettings";

/**
 * Strategie component class.
 * @class Objektklassifizierung
 * @extends Component
 */
class Strategie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearStrategy: undefined,
      yearStrategyInput: undefined,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.strategyDisplayId) {
      this.props.getStrategie(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.strategyDisplayId,
        this.props.typeDashboard
      );
    }

    // Object.keys(chartsStrategie).map((idChart) => this.props.setChartScale(idChart, SCALE_SUM))
  }

  shouldComponentUpdate(nextProps, nextState) {
    const newStrategies = this.props.strategies !== nextProps.strategies;
    const strategyDisplayChanged =
      this.props.strategyDisplayName !== nextProps.strategyDisplayName;
    const positionMark = this.state.yearStrategy !== nextState.yearStrategy;
    const yearStrategyInput =
      this.state.yearStrategyInput !== nextState.yearStrategyInput;

    return (
      newStrategies ||
      strategyDisplayChanged ||
      positionMark ||
      yearStrategyInput
    );
  }

  /**
   * Component will receive props
   * @method UNSAFE_componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  //  UNSAFE_componentWillReceiveProps(nextProps) {
  //    if (
  //      (!isEqual(this.props.filtersApplied, nextProps.filtersApplied) ||
  //        this.props.strategyDisplayId !== nextProps.strategyDisplayId ||
  //        !isEqual(this.props.scales, nextProps.scales) ||
  //        !isEqual(this.props.units, nextProps.units) ||
  //        !isEqual(this.props.bucket, nextProps.bucket) ||
  //        !isEqual(this.props.project, nextProps.project)) &&
  //      nextProps.strategyDisplayId // Only query if id is set
  //    ) {
  //      this.props.getStrategie(
  //        nextProps.project,
  //        nextProps.bucket,
  //        nextProps.filtersApplied,
  //        nextProps.scales,
  //        nextProps.units,
  //        nextProps.strategyDisplayId,
  //        nextProps.typeDashboard
  //      );
  //    }
  //    // Set yearStrategy to first year after receiving strategy
  //    if (
  //      nextProps.yearStrategy === undefined &&
  //      nextProps.minYearStrategy !== undefined &&
  //      !isNaN(nextProps.minYearStrategy)
  //    ) {
  //      this.setState({ yearStrategy: nextProps.minYearStrategy });
  //      this.props.setYearStrategy(nextProps.minYearStrategy);
  //    }
  //    if (
  //      this.state.yearStrategyInput === undefined &&
  //      nextProps.minYearStrategy !== undefined &&
  //      !isNaN(nextProps.minYearStrategy)
  //    ) {
  //      this.setState({ yearStrategyInput: nextProps.minYearStrategy });
  //    }
  //  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!isEqual(prevProps.filtersApplied, this.props.filtersApplied) ||
        prevProps.strategyDisplayId !== this.props.strategyDisplayId ||
        !isEqual(prevProps.scales, this.props.scales) ||
        !isEqual(prevProps.units, this.props.units) ||
        !isEqual(prevProps.bucket, this.props.bucket) ||
        !isEqual(prevProps.project, this.props.project)) &&
      this.props.strategyDisplayId // Only query if id is set
    ) {
      prevProps.getStrategie(
        this.props.project,
        this.props.bucket,
        this.props.filtersApplied,
        this.props.scales,
        this.props.units,
        this.props.strategyDisplayId,
        this.props.typeDashboard
      );
    }
    // Set yearStrategy to first year after receiving strategy
    if (
      this.props.yearStrategy === undefined &&
      this.props.minYearStrategy !== undefined &&
      !isNaN(this.props.minYearStrategy)
    ) {
      this.setState({ yearStrategy: this.props.minYearStrategy });
      prevProps.setYearStrategy(this.props.minYearStrategy);
    }
    if (
      this.state.yearStrategyInput === undefined &&
      this.props.minYearStrategy !== undefined &&
      !isNaN(this.props.minYearStrategy)
    ) {
      this.setState({ yearStrategyInput: this.props.minYearStrategy });
    }
  }
  //
  //  shouldComponentUpdate(nextProps, nextState) {
  //      if(this.props.typeDashboard != nextProps.typeDashboard){
  //          return false;
  //      } else {
  //          return true;
  //      }
  //    }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { strategie, typeDashboard, mapMaximized, mapMinimized } = this.props;

    let height = "50%";
    if (mapMaximized) {
      height = "0%";
    } else if (mapMinimized) {
      height = "100%";
    }

    const yearStrategyInputValid =
      this.state.yearStrategyInput >= this.props.minYearStrategy &&
      this.state.yearStrategyInput <= this.props.maxYearStrategy;

    return (
      <div className="mapWrapper">
        <div className="viewHeader">
          <Row>
            <Col>
              <Slider
                // Remark: Due to bug in Slider, key must be set so that slider updates on value change
                key={this.props.yearStrategy}
                className="yearSlider"
                step={1}
                disabled={
                  !this.props.strategyDisplayId ||
                  this.props.minYearStrategy === undefined
                }
                defaultValue={this.props.minYearStrategy}
                value={this.state.yearStrategy ? this.state.yearStrategy : " "}
                min={this.props.minYearStrategy}
                max={this.props.maxYearStrategy}
                onChangeCommitted={(event, value) =>
                  this.props.strategyDisplayId &&
                  this.props.setYearStrategy(value)
                }
                onChange={(event, value) =>
                  this.setState({
                    yearStrategy: value,
                    yearStrategyInput: value,
                  })
                }
                onValueChange={this.state.yearStrategy}
              />
              <input
                className="yearNumber"
                style={{ width: "70px", float: "left" }}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                value={
                  this.state.yearStrategyInput
                    ? this.state.yearStrategyInput
                    : " "
                }
                disabled={
                  !this.props.strategyDisplayId ||
                  this.props.minYearStrategy === undefined
                }
                invalid={
                  !yearStrategyInputValid &&
                  this.props.minYearStrategy !== undefined
                }
                onChange={(event) =>
                  this.setState({
                    yearStrategyInput: parseInt(event.target.value),
                  })
                }
                onKeyPress={(event) => {
                  if (yearStrategyInputValid && event.key === "Enter") {
                    this.setState({
                      yearStrategy: this.state.yearStrategyInput,
                    });
                    this.props.setYearStrategy(this.state.yearStrategyInput);
                  }
                }}
                onKeyUp={(event) => {
                  if (
                    yearStrategyInputValid &&
                    (event.key === "ArrowUp" || event.key === "ArrowDown")
                  ) {
                    this.setState({
                      yearStrategy: this.state.yearStrategyInput,
                    });
                    this.props.setYearStrategy(this.state.yearStrategyInput);
                  }
                }}
              />
            </Col>
            <Col>
              <UncontrolledDropdown
                style={{
                  float: "right",
                  paddingBottom: 10,
                  paddingRight: 11,
                }}
              >
                <DropdownToggle caret tag="span" style={{ cursor: "pointer" }}>
                  {this.props.strategyDisplayName
                    ? this.props.strategyDisplayName
                    : "Strategie Wählen"}
                </DropdownToggle>
                <DropdownMenu right>
                  {map(this.props.strategies, (strategyDisplay) => (
                    <DropdownItem
                      key={strategyDisplay.id}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.props.setStrategyDisplay(strategyDisplay.id)
                      }
                    >
                      {strategyDisplay.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
        <div className="mapDetail" style={{ height: height }}>
          <Container className="containerDiagrams inner">
            <Row>
              <Chart
                data={strategie[charts.ZUSTANDSKLASSE_STRATEGIE]}
                id={charts.ZUSTANDSKLASSE_STRATEGIE}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.SUBSTANZKLASSE_STRATEGIE]}
                id={charts.SUBSTANZKLASSE_STRATEGIE}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE]}
                id={charts.SUBSTANZKLASSE_BETRIEB_STRATEGIE}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE]}
                id={charts.SUBSTANZKLASSE_DICHTHEIT_STRATEGIE}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={
                  strategie[charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE]
                }
                id={charts.SUBSTANZKLASSE_STANDSICHERHEIT_STRATEGIE}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.SANIERUNGEN]}
                id={charts.SANIERUNGEN}
                showScaleButton={false}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.SANIERUNGSKOSTEN]}
                id={charts.SANIERUNGSKOSTEN}
                showUnitButton={false}
                showScaleButton={false}
                scalesDisplay={scalesStrategySummed}
                unitsDisplay={unitsStrategySummed}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={strategie[charts.RBW_WBK]}
                id={charts.RBW_WBK}
                showUnitButton={false}
                showScaleButton={false}
                scalesDisplay={scalesStrategySummed}
                unitsDisplay={unitsStrategySummed}
                typeView={VIEW_STRATEGIE}
                positionMark={this.state.yearStrategy}
              />
              <Chart
                data={Object.keys(actionsRehab).map((class_) => ({
                  x: class_,
                }))}
                id={charts.LEGENDE_SANIERUNGEN}
                typeChart="LegendPie"
                typeView={VIEW_STRATEGIE}
                title="Legende Sanierung"
                showDetailButton={false}
                showUnitButton={false}
                showScaleButton={false}
                showMaximizeButton={false}
              />
              <Chart
                data={Object.keys(classes).map((class_) => ({
                  x: class_,
                }))}
                id={charts.LEGENDE_KLASSEN}
                typeChart="LegendPie"
                typeView={VIEW_STRATEGIE}
                title="Legende Klassen"
                showDetailButton={false}
                showUnitButton={false}
                showScaleButton={false}
                showMaximizeButton={false}
              />
              {/*
              <Chart
                data={strategie.sanierungskosten}
                id="sanierungskosten"
                typeDashboard={typeDashboard}
              />
              */}
              <Col />
            </Row>
          </Container>
        </div>
        <InOutCarat />
        <Map view={VIEW_STRATEGIE} typeDashboard={typeDashboard} />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const project = find(state.projects.projects, {
      id: state.projects.project,
    });
    let strategyDisplay = project
      ? find(project.strategies, {
          id: state.strategie.strategyDisplayId,
        })
      : undefined;
    let strategies = [];

    if (project !== undefined) {
      strategies = filter(
        project.strategies,
        (strategy) => strategy.id !== state.strategie.strategyDisplayId
      );
    }

    const diagrams = diagramsViews[VIEW_STRATEGIE];
    const units = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.unit[key];
      return collector;
    }, {});
    const scales = Object.keys(diagrams).reduce((collector, key) => {
      collector[key] = state.chartSettings.scale[key];
      return collector;
    }, {});

    // Get min and max year
    const years =
      state.strategie.data.substanzklasseStrategie[0] !== undefined
        ? state.strategie.data.substanzklasseStrategie[0].data.map(
            (element) => element.x
          )
        : undefined;

    const minYearStrategy =
      years !== undefined ? Math.min(...years) : undefined;
    const maxYearStrategy =
      years !== undefined ? Math.max(...years) : undefined;

    return {
      strategie: state.strategie.data,
      filtersApplied: state.filters.filtersApplied,
      //reload: state.filters.reload || state.chartSettings.dirty,
      project: state.projects.project,
      bucket: state.projects.bucket,
      strategyDisplayId: state.strategie.strategyDisplayId,
      strategies: strategies,
      strategyDisplayName:
        strategyDisplay !== undefined ? strategyDisplay.title : undefined,
      units: units,
      scales: scales,
      yearStrategy: state.strategie.yearStrategy,
      minYearStrategy: minYearStrategy,
      maxYearStrategy: maxYearStrategy,
      mapMaximized: props.alwaysMaximized || state.map.mapDashboardsMaximized,
      mapMinimized: props.alwaysMinimized || state.map.mapDashboardsMinimized,
    };
  },
  {
    getStrategie,
    setStrategyDisplay,
    setYearStrategy,
    setChartScale,
    setChartUnit,
  }
)(Strategie);
