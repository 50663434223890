/**
 * TemporalHalfPie component.
 * @module components/charts/TemporalHalfPie/TemporalHalfPie
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { getValuesX } from "../../../helpers/charts/common_functions";
import PieGeneric from "../PieGeneric/PieGeneric";

import { Range } from "react-range";

/**
 * TemporalHalfPie component class.
 * @class TemporalHalfPie
 * @extends Component
 */
class TemporalHalfPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Remark: must be string because the slider also outputs strings in event
      x: undefined,
    };
  }

  getData() {
    let x = this.state.x;

    // For all layers, find the y value for the given x-value
    let result = this.props.data
      ? this.props.data.reduce((result, layer) => {
          // Remark: x is string
          let dataMatching = layer.data.find((datapoint) => datapoint.x === x);
          if (dataMatching) {
            result.push({
              x: layer.name,
              y: dataMatching.y,
              label: layer.label,
            });
          }
          return result;
        }, [])
      : [];

    return result;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, data, reference } = this.props;

    const valuesX = getValuesX(data);

    const dataCurrent = this.getData();

    // Set initial slider value when data is isLoaded
    if (typeof this.state.x === "undefined" && this.props.data[0]) {
      this.setState({ x: this.props.data[0].data[0].x });
    }

    return (
      <div style={{ width: "100%" }}>
        <PieGeneric
          id={id}
          key={Date.now()}
          data={dataCurrent}
          isHalf={true}
          showLabels={false}
          disableFilter={true}
          ref={reference}
        />
        <div className="temporalPieIndicator"> {this.state.x} </div>
        <hr className="temporalPieHLine" />
        <Range
          step={1}
          min={valuesX[0]}
          max={valuesX[valuesX.length - 1]}
          values={[this.state.x]}
          onChange={(values) => this.setState({ x: values[0] })}
          renderTrack={({ props, children }) => (
            <div {...props} className="temporalPieRange">
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} className="temporalPieRangeThumb" />
          )}
        />
      </div>
    );
  }
}

export default connect((state) => ({}), {})(TemporalHalfPie);
