import { GET_CHANGELOG } from "../../constants/ActionTypes";

export function getChangelog() {
  return {
    type: GET_CHANGELOG,
    request: {
      op: "get",
      path: `changelog`
    }
  };
}
